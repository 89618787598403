import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import style from './styles.module.css'
import Logo from '../../assets/logos/postei-logo-color.png'
import { useTranslation } from 'react-i18next'
import {
  Home,
  Picture,
  Retangle_group,
  Datepiker,
  Bookmark,
  Mockups,
  CurvedArrow,
  SupermarketCart,
  Target,
  Clock,
  Users,
  UserCircle,
  UserGroup,
  Subtract,
  CurrencyDollar,
  LogOut,
  Cross,
  ChatIcon,
} from '../../../src/assets/svgs/icons'

import { useAuth } from '../../hooks/useAuth'
import { useClient } from '../../hooks/useClient'

const SideMenu = () => {
  const { clientData, setClientData } = useClient()

  const { logout } = useAuth()

  const [expand, setExpand] = useState(true)

  const location = useLocation()
  const { t } = useTranslation()

  function handleExpandMenu() {
    setExpand(true)
  }

  function handleReduceMenu() {
    setExpand(false)
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <div className={expand ? style.sidebar_expanded : style.sidebar_shortened}>
      <div className={style.header_slidebar}>
        <div className={style.header_slidebar_logo}>
          <img
            src={Logo}
            onClick={handleExpandMenu}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
        <div
          className={style.crossContainer}
          style={{ cursor: 'pointer', display: expand ? '' : 'none' }}
          onClick={handleReduceMenu}
        >
          <Cross />
        </div>
      </div>

      <div className={style.body_slidebar}>
        <ul>
          <li
            className={`${
              location.pathname === '/HomePage' ? style.selected : ''
            }`}
          >
            <Link to="/HomePage">
              <div className={style.iconContainer}>
                <Home
                  color={
                    location.pathname === '/HomePage' ? '#6A0098' : '#898989'
                  }
                />
              </div>
              <p
                className={`${
                  location.pathname === '/HomePage'
                    ? style.selected
                    : style.navigation_text
                }`}
              >
                Dashboard
              </p>
            </Link>
          </li>
          <div className={style.sidebar_divider}></div>
          <li
            className={`${
              location.pathname === '/NewPostPage' ? style.selected : ''
            }`}
          >
            <Link to="/NewPostPage">
              <div className={style.iconContainer}>
                <Picture
                  color={
                    location.pathname === '/NewPostPage' ? '#6A0098' : '#898989'
                  }
                />
              </div>
              <p
                className={`${
                  location.pathname === '/NewPostPage'
                    ? style.selected
                    : style.navigation_text
                }`}
              >
                {t('side_menu.publication')}
              </p>
            </Link>
          </li>
          <li
            className={`${
              location.pathname === '/Campaign' ? style.selected : ''
            }`}
          >
            <Link to="/Campaign">
              <div className={style.iconContainer}>
                <Retangle_group
                  color={
                    location.pathname === '/Campaign' ? '#6A0098' : '#898989'
                  }
                />
              </div>
              <p
                className={`${
                  location.pathname === '/Campaign'
                    ? style.selected
                    : style.navigation_text
                }`}
              >
                {t('side_menu.campaign')}
              </p>
            </Link>
          </li>
          <li
          // className={`${
          //   location.pathname === '/SchedulePublicationPage' ||
          //   location.pathname === '/SchedulingPage'
          //     ? style.selected
          //     : style.navigation_text
          // }`}
          >
            <Link
              to={{
                pathname:
                  clientData?.uuid === undefined
                    ? '/SchedulingPage'
                    : '/SchedulePublicationPage',
              }}
            >
              <div className={style.iconContainer}>
                <Datepiker
                  color={
                    location.pathname === '/SchedulePublicationPage' ||
                    location.pathname === '/SchedulingPage'
                      ? '#6A0098'
                      : '#898989'
                  }
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  color:
                    location.pathname === '/SchedulePublicationPage' ||
                    location.pathname === '/SchedulingPage'
                      ? '#190027'
                      : '#898989',
                  fontWeight:
                    location.pathname === '/SchedulePublicationPage' ||
                    location.pathname === '/SchedulingPage'
                      ? 500
                      : 300,
                }}
              >
                {t('side_menu.calendar')}
              </p>
            </Link>
          </li>

          <li
            className={`${
              location.pathname === '/ChatPage' ? style.selected : ''
            }`}
          >
            <Link
            //to="/ChatPage"
            >
              <div className={style.iconContainer}>
                <div
                  className={
                    location.pathname === '/ChatPage'
                      ? style.selected
                      : style.notSelected
                  }
                >
                  <ChatIcon
                    color={
                      location.pathname === '/ChatPage' ? '#6A0098' : '#898989'
                    }
                  />
                </div>
              </div>
              <p
                className={`${
                  location.pathname === '/ChatPage'
                    ? style.selected
                    : style.navigation_text
                }`}
              >
                Mensagens
              </p>
            </Link>
            <div className={style.betaIndicator}>Em Breve</div>
          </li>

          {/* <li>
            <Link to="/Draft">
              <div className={style.iconContainer}>
                <Bookmark
                  color={location.pathname === '/Draft' ? '#6A0098' : '#898989'}
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  color: location.pathname === '/Draft' ? '#190027' : '#898989',
                  fontWeight: location.pathname === '/Draft' ? 500 : 300,
                }}
              >
                Rascunhos Salvos
              </p>
            </Link>
          </li> */}
          {/* <li>
            <Link>
              <div className={style.iconContainer}>
                <Mockups
                  color={location.pathname === '/' ? '#6A0098' : '#898989'}
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  color: location.pathname === '/' ? '#190027' : '#898989',
                }}
              >
                Mockups
              </p>
            </Link>
          </li>
          <li>
            <Link>
              <div className={style.iconContainer}>
                <CurvedArrow
                  color={location.pathname === '/' ? '#6A0098' : '#898989'}
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  color: location.pathname === '/' ? '#190027' : '#898989',
                }}
              >
                Métricas
              </p>
            </Link>
          </li> */}
          <div className={style.sidebar_divider}></div>
          <li
            className={`${
              location.pathname === '/ProductsPage' ? style.selected : ''
            }`}
          >
            <Link to="/ProductsPage">
              <div className={style.iconContainer}>
                <SupermarketCart
                  color={
                    location.pathname === '/ProductsPage'
                      ? '#6A0098'
                      : '#898989'
                  }
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  fontWeight: location.pathname === '/ProductsPage' ? 500 : 300,
                  color:
                    location.pathname === '/ProductsPage'
                      ? '#190027'
                      : '#898989',
                }}
              >
                {t('side_menu.product')}
              </p>
            </Link>
          </li>
          <li>
            <Link to="/TargetAudience">
              <div className={style.iconContainer}>
                <Target
                  color={
                    location.pathname === '/TargetAudience'
                      ? '#6A0098'
                      : '#898989'
                  }
                />
              </div>{' '}
              <p
                className={style.navigation_text}
                style={{
                  fontWeight:
                    location.pathname === '/TargetAudience' ? 500 : 300,
                  color:
                    location.pathname === '/TargetAudience'
                      ? '#190027'
                      : '#898989',
                }}
              >
                {t('side_menu.target_audience')}
              </p>
            </Link>
          </li>
          <div className={style.sidebar_divider}></div>

          <li>
            <Link to="/History">
              <div className={style.iconContainer}>
                <Clock
                  color={
                    location.pathname === '/History' ? '#6A0098' : '#898989'
                  }
                />
              </div>{' '}
              <p
                className={style.navigation_text}
                style={{
                  fontWeight: location.pathname === '/History' ? 500 : 300,
                  color:
                    location.pathname === '/History' ? '#190027' : '#898989',
                }}
              >
                {t('side_menu.history')}
              </p>
            </Link>
          </li>

          {/* <li>
            <Link to="/Clients">
              <div className={style.iconContainer}>
                <Users
                  color={
                    location.pathname === '/Clients' ? '#6A0098' : '#898989'
                  }
                />
              </div>{' '}
              <p
                className={style.navigation_text}
                style={{
                  fontWeight: location.pathname === '/Clients' ? 500 : 300,
                  color:
                    location.pathname === '/Clients' ? '#190027' : '#898989',
                }}
              >
                {t('side_menu.customers')}
              </p>
            </Link>
          </li> */}

          <div className={style.sidebar_divider}></div>
          <li>
            <Link to={'/MyAccountPage'}>
              <div className={style.iconContainer}>
                <UserCircle
                  color={
                    location.pathname === '/MyAccountPage'
                      ? '#6A0098'
                      : '#898989'
                  }
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  color:
                    location.pathname === '/MyAccountPage'
                      ? '#190027'
                      : '#898989',
                  fontWeight:
                    location.pathname === '/MyAccountPage' ? 500 : 300,
                }}
              >
                {t('side_menu.my_account')}
              </p>
            </Link>
          </li>
          <li>
            <Link to={'/UserList'}>
              <div className={style.iconContainer}>
                <UserGroup
                  color={
                    location.pathname === '/UserList' ? '#6A0098' : '#898989'
                  }
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  color:
                    location.pathname === '/UserList' ? '#190027' : '#898989',
                  fontWeight: location.pathname === '/UserList' ? 500 : 300,
                }}
              >
                {t('side_menu.users')}
              </p>
            </Link>
          </li>
          <li>
            <Link to={'/Settings'}>
              <div className={style.iconContainer}>
                <Subtract
                  color={
                    location.pathname === '/Settings' ? '#6A0098' : '#898989'
                  }
                />
              </div>
              <p
                className={style.navigation_text}
                style={{
                  color:
                    location.pathname === '/Settings' ? '#190027' : '#898989',
                  fontWeight: location.pathname === '/Settings' ? 500 : 300,
                }}
              >
                {t('side_menu.configurations')}
              </p>
            </Link>
          </li>
          {/*
          <li>
            <Link>
              <div style={{ display: 'flex' }}>
                <div className={style.iconContainer}>
                  <CurrencyDollar
                    color={location.pathname === '/' ? '#6A0098' : '#898989'}
                  />
                </div>
              </div>
              <p className={style.navigation_text}>Planos</p>
            </Link>
          </li> */}
        </ul>
      </div>
      <div className={style.footer_slidebar}>
        <div
          className={style.iconContainer}
          onClick={handleLogout}
          style={{ cursor: 'pointer' }}
        >
          <LogOut color={'#E40000'} />
        </div>
      </div>
    </div>
  )
}

//   ${sidebarOpen ? 'open' : ''}

export default SideMenu
