/* eslint-disable react/prop-types */
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'

import {
  PagarMeService,
  PlanService,
  UserAccountsService,
  SubscriptionService,
  PublicationService,
  BusinessClient,
  BusinessUser,
  SocialMediaConnectionService,
  BusinessService,
  ProductService,
  TargetAudienceService,
} from '../../services/'

import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'

import { useNavigate } from 'react-router-dom'

import AddCard from '../../components/AddCard'

import ModalComponent from '../../components/ModalComponent'

import React, { useEffect, useState } from 'react'

import { CheckIcon } from '../../assets/svgs/icons'
import ModalDowngrade from './ModalDowngrade'
import ModalAwaitPayment from './ModalAwaitPayment'

const formattedPrice = (price) => {
  // Converte o preço para float e arredonda para duas casas decimais
  const formatted = parseFloat(price).toFixed(2)

  // Transforma o preço em uma string com formato de moeda
  return parseFloat(formatted).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}

function PlansPage({ welcomePage = false }) {
  const { t } = useTranslation()
  const storage = window.localStorage

  const [userAcount, setUserAccount] = useState()
  const [userCard, setCard] = useState()

  const [plansData, setPlansData] = useState([])

  useEffect(() => {
    loadPlans()
  }, [storage.i18nextLng])

  const TIPO_DESCRIPTION = [
    {
      id: 0,
      value: t('plansPage.essentialPlanDescription'),
      subtitle: t('plansPage.subtitleEssential'),
    },
    {
      id: 1,
      value: t('plansPage.entrepreneurPlanDescription'),
      subtitle: t('plansPage.subtitleGrowth'),
    },
    {
      id: 2,
      value: t('plansPage.professionalPlanDescription'),
      subtitle: t('plansPage.subtitleMaster'),
    },
  ]

  const TIPO_SUPORTE = [
    {
      id: '1',
      value: 'Blog',
    },
    {
      id: '2',
      value: 'Blog',
    },
    {
      id: '3',
      value: 'E-mail',
    },
    {
      id: '4',
      value: 'Chat',
    },
    {
      id: '05',
      value: 'Chat e Whatsapp',
    },
  ]

  const loadPlans = async () => {
    setUserAccount(await UserAccountsService.getUserAccountsByLogin())

    setCard(await PagarMeService.getCardByUser())

    const { data } = await PlanService.getPlansBaseValues(
      storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
    )

    await data.forEach(async (plan, index) => {
      switch (index) {
        case 0:
          plan.realPrice = 'R$ 119,90'
          break
        case 1:
          plan.realPrice = 'R$ 239,90'
          break
        case 2:
          plan.realPrice = 'R$ 479,90'
          break

        default:
          break
      }
      switch (plan.description) {
        case 'Plano Essencial':
          plan.description =
            storage.i18nextLng == 'en' ? 'Essential Plan' : 'Plano Essencial'
          break
        case 'Plano Growth':
          plan.description =
            storage.i18nextLng == 'en' ? 'Growth Plan' : 'Plano Growth'
          break
        case 'Plano Master':
          plan.description =
            storage.i18nextLng == 'en' ? 'Master Plan' : 'Plano Master'
          break
        default:
          break
      }

      plan.priceReal = formattedPrice(plan.price)
      plan.resume = TIPO_DESCRIPTION[index].value
      plan.subtitle = TIPO_DESCRIPTION[index].subtitle
      plan.PlanItems = plan.PlanItems.filter(
        (item) => item.type !== 'price_quantity'
      )
      // await plan.PlanItems.forEach(async (item) => {
      //   // if (
      //   //   !item.quantity &&
      //   //   (item.displayName == 'Agendamentos' ||
      //   //     item.displayName == 'Quantidade de Postagens' ||
      //   //     item.displayName == 'Clientes')
      //   // ) {
      //   //   item.quantity = 'Ilimitado'
      //   // }
      //   // if (item.type == 'storage_cloud_quantity') {
      //   //   item.quantity = item.quantity > 0 ? item.quantity + ' GB' : ''
      //   // }

      //   // if (item.type == 'support') {
      //   //   item.quantity = TIPO_SUPORTE[item.quantity].value
      //   // }
      // })
    })

    const mockPlans = [
      {
        id: 1,
        description: storage.i18nextLng == 'en' ? 'Free Plan' : 'Plano Grátis',
        price: 100,
        PlanItems: [
          {
            displayName: t('plans.design_posts'),
            quantity: '8',
          },
          {
            displayName: t('plans.users'),
            quantity: '1',
          },
          {
            displayName: t('plans.scheduling'),
            quantity: '20',
          },
          {
            displayName: t('plans.brands'),
            quantity: '1',
          },
          {
            displayName: t('plans.ai_image_bank'),
            quantity: '8',
          },
          {
            displayName: t('plans.ai_generated_images'),
            quantity: '10',
          },
          {
            displayName: t('plans.ai_generated_legends'),
            quantity: '50',
          },
          {
            displayName: t('plans.ai_generated_messages'),
            quantity: '30',
          },
          {
            displayName: t('plans.products'),
            quantity: '30',
          },
          {
            displayName: t('plans.target_tudience'),
            quantity: '30',
          },
        ],
        priceReal: 'R$ 0,00',
        realPrice: 'R$ 19,90',
        resume: t('plansPage.freeVersionDescription'),
        subtitle: t('plansPage.subtitleFree'),
        premium: false,
        uuid: '1',
        tier: 0,
        quantity: '1',
        mock: true,
      },
    ]

    setPlansData([...mockPlans, ...data])

    return mockPlans
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div className={styles.page}>
        <h1 className={styles.mainTitle}>{t('plansPage.selectAPlan')}</h1>
        <h2 className={styles.subtitle}>
          {t('plansPage.chooseAPlanThatBestFitsYourBusiness')}
        </h2>

        <div className={styles.plansContainer}>
          {plansData.map((plano, index) => (
            <PlanCard
              key={index}
              plan={plano}
              titulo={plano.description}
              subtitle={plano.subtitle}
              preco={plano.priceReal}
              realPrice={plano.realPrice}
              features={plano.PlanItems}
              quantity={plano.quantity}
              resume={plano.resume}
              premium={plano.tier == 2 ? true : false}
              uuid={plano.uuid}
              tier={plano.tier}
              userAcount={userAcount}
              welcomePage={welcomePage}
            />
          ))}
        </div>
      </div>
    </motion.div>
  )
}

const PlanCard = ({
  plan,
  titulo,
  preco,
  realPrice,
  features,
  resume,
  subtitle,
  premium,
  uuid,
  tier,
  userAcount,
  welcomePage,
}) => {
  const { t } = useTranslation()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tierPlan, setTierPlan] = useState(null)
  const [tierCard, setTierCard] = useState(null)
  const [downgradeNow, setDowngradeNow] = useState(false)
  const [openModalAwaitPayment, setOpenModalAwaitPayment] = useState(false)

  const [openModalDowngrade, setOpenModalDowngrade] = useState(false)
  useEffect(() => {
    if (!modalIsOpen && tierCard > 0) {
      selectPlan(tierPlan)
    }
  }, [modalIsOpen])

  useEffect(() => {
    if (downgradeNow) {
      selectPlan(tier)
    }
  }, [downgradeNow])

  const closeModal = () => {
    setModalIsOpen(false)
    setTierCard(0)
  }

  const selectPlan = async (tier) => {
    if (modalIsOpen) {
      return
    }

    const userCardActive = await PagarMeService.getCardByUser()

    if (userCardActive.data.length <= 0 && tier !== 0) {
      setModalIsOpen(true)
      setTierCard(tier)
      //setShowCard(true)
      return
    }

    setOpenModalAwaitPayment(true)
    setLoading(true)
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    // if (userSubscription.data.length > 0) {
    //   setLoading(false)
    // }

    // const data = await SubscriptionService.getSubscriptionByUser()
    const body = {
      planId: uuid,
      accountId: userAcount?.data.uuid,
      cardId: userCardActive.data[0].uuid,
      paymentMethod: 'credit_card',
    }

    if (userSubscription.data.length > 0) {
      const currentTier = userSubscription.data[0].Plan.tier

      if (tier == currentTier) {
        notifyWarningCustom(t('plansPage.youCannotChooseTheSamePlan'))
        setOpenModalAwaitPayment(false)
        setLoading(false)
        return
      }

      if (tier < currentTier && !downgradeNow) {
        setOpenModalDowngrade(true)
        setOpenModalAwaitPayment(false)
        setLoading(false)
        return
      }

      if (tier === 0) {
        await SubscriptionService.subscriptionsInativeDowngrade(
          userSubscription?.data[0]?.uuid,
          userSubscription?.data[0]?.pagarmeSubscriptionId
        )
        setOpenModalAwaitPayment(false)
        notifySuccessCustom('Procedimento concluido com sucesso.')
        navigate('/Settings')
        return
      }

      const retPost = await SubscriptionService.putSubscription(
        userSubscription?.data[0]?.uuid,
        body
      )

      if (retPost.responseStatus == 204) {
        setOpenModalAwaitPayment(false)
        notifySuccessCustom(t('plansPage.planChangedSuccessfully'))

        if (welcomePage) {
          navigate('/StepsPage', {
            state: { fastPublication: true },
          })
        } else {
          navigate('/Settings')
        }
        return
      } else {
        setOpenModalAwaitPayment(false)
        notifyErrorCustom(retPost.response.data.message)
      }
    } else {
      if (tier === 0) {
        setOpenModalAwaitPayment(false)
        if (welcomePage) {
          navigate('/StepsPage', {
            state: { fastPublication: true },
          })
        } else {
          notifyWarningCustom(t('plansPage.youCannotChooseTheSamePlan'))
          return
          // navigate('/Settings')
        }
        return
      }
      const retPost = await SubscriptionService.postSubscription(body)
      if (retPost.responseStatus == 200) {
        setOpenModalAwaitPayment(false)
        notifySuccessCustom(t('plansPage.planCreatedSuccessfully'))

        if (welcomePage) {
          navigate('/StepsPage', {
            state: { fastPublication: true },
          })
        } else {
          navigate('/Settings')
        }
        setLoading(false)

        return
      } else {
        setOpenModalAwaitPayment(false)
        notifyErrorCustom(retPost.response.data.message)
      }
    }
  }

  const handleCloseModal = () => {
    setOpenModalDowngrade(false)
  }
  const navigate = useNavigate()

  return (
    <>
      {/* <ModalComponent
        isOpen={openModalDowngrade}
        onClose={() => handleCloseModal()}
        // className={teste}
      > */}
      <ModalDowngrade
        // setImageAIArray={setImageAIArray}
        tier={tier}
        setDowngradeNow={setDowngradeNow}
        isOpen={openModalDowngrade}
        onClose={() => handleCloseModal()}
        handleCloseModal={handleCloseModal}
      />
      <ModalAwaitPayment isOpen={openModalAwaitPayment} />
      {/* </ModalComponent> */}

      <ModalComponent
        isOpen={modalIsOpen}
        onClose={() => closeModal}
        className="Modal"
        overlayClassName="Overlay"
        style={{
          flex: 1,
          margin: '0 10px',
          maxWidth: 500,
          // background: 'red',
        }}
      >
        <div>
          <button
            onClick={closeModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            {t('plansPage.button.cancel')}
          </button>
        </div>
        <div>
          <AddCard
            setModalIsOpen={setModalIsOpen}
            selectPlan={selectPlan}
            tierCard={tierCard}
            setTierPlan={setTierPlan}
          />
        </div>
      </ModalComponent>

      <div className={premium ? styles.planCardPremium : styles.planCard}>
        {premium && (
          <div className={styles.premium}>
            <p className={styles.premiumText}>{t('plans.most_recommended')}</p>
          </div>
        )}
        <h3 className={styles.planTitle}>
          {titulo.charAt(0).toUpperCase() + titulo.slice(1)}
        </h3>
        <p className={styles.realPlanPrice}>
          <span className={styles.currency}>{t('plans.from')} </span>
          <span className={styles.strikethrough}> {realPrice} </span>
          <span className={styles.currencyMonth}></span>
        </p>
        <p className={styles.planPrice}>
          <span className={styles.currency}>{t('plans.to')} R$</span>
          {preco.replace('R$', '')}
          <span className={styles.currencyMonth}>
            {t('plansPage.button.month')}
          </span>
        </p>
        <p className={styles.planSubtitle}>{subtitle}</p>
        <p className={styles.planResume}>{resume}</p>

        <button
          onClick={() => {
            if (welcomePage) {
              if (plan.mock) {
                navigate('/StepsPage', {
                  state: { fastPublication: true },
                })
                return
              } else {
                selectPlan(tier)
              }
            } else {
              // if (!plan.mock) {
              selectPlan(tier)
              return
              // }
            }
          }}
          className={styles.addButton}
        >
          {t('plansPage.button.select')}
        </button>

        <div className={styles.featuresTitle}>Features:</div>
        <div className={styles.featuresDivider} />

        <ul className={styles.featuresList}>
          {features.map((feature, index) => (
            <li key={index} className={styles.featureItem}>
              <CheckIcon size="10" />
              {feature?.displayName}
              {': '}
              {feature?.quantity}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default PlansPage
