import React from 'react'
import styles from './styles.module.css'
import PropTypes from 'prop-types'

export const ImageStep = ({ images }) => {
  return (
    <div className={styles.step_container}>
      {images.map((image, index) => (
        <div key={index} className={styles.image_container}>
          <img
            src={image.url}
            alt={image.alt ?? 'image-alt'}
            onContextMenu={(e) => e.preventDefault()}
          />
        </div>
      ))}
    </div>
  )
}

ImageStep.propTypes = {
  images: PropTypes.array,
}
