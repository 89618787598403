import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getSubscriptionId = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}subscriptions/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getSubscriptionId', error.response.data)
      return error
    })
}

export const postSubscription = async (body) => {
  const config = {
    withCredentials: true,
  }

  return await axios
    .post(`${BASE_URL}subscriptions`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postSubscription', error.response.data)
      return error
    })
}

export const getSubscriptionByUser = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))
  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  // const config = {
  //   withCredentials: true,
  // }

  return await axios
    .get(`${BASE_URL}subscriptions-by-user`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getSubscriptionByUser', error.response.data)
      return error
    })
}

export const getSubscriptionFeatures = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))
  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }
  // const config = {
  //   withCredentials: true,
  // }

  return await axios
    .get(`${BASE_URL}subscriptions-by-user-feature`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getSubscriptionByUser', error.response.data)
      return error
    })
}

export const putSubscription = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}subscriptions/${id}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error putSubscription', error.response.data)
      return error
    })
}
export const subscriptionsInative = async (id, code) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}subscriptions-cancel/${id}/${code}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error subscriptionsInative', error.response.data)
      return error
    })
}

export const subscriptionsInativeDowngrade = async (id, code) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}subscriptions-cancel-downgrade/${id}/${code}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error subscriptionsInativeDowngrade', error.response.data)
      return error
    })
}

export const getSubscriptionIdUserAccount = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}subscriptions-by-useraccount/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getSubscriptionId', error.response.data)
      return error
    })
}
