/* eslint-disable react/prop-types */
import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'
import {
  Accept,
  // CircleX,
  Cross,
  NumberOne,
  NumberThree,
  NumberTwo,
  Pen,
  Pen2,
  // Refresh,
  WhiteRefresh,
} from '../../../assets/svgs/icons'
import { useEffect, useState } from 'react'
import { SelectRound } from '../../../components/Select/SelectRound'
import { motion } from 'framer-motion'
import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'
import {
  BusinessService,
  EditorialLineService,
  ProductService,
  PythonService,
  SubscriptionService,
  TargetAudienceService,
  UserPlanCount,
  VoiceToneService,
} from '../../../services'
import { getLegendsService } from '../../../services/postsServices/getLegendsService'
import Loading from '../../../components/Loading'
import { useTranslation } from 'react-i18next'
import { postPromptGPT } from '../../../services/pythonService'
import { notifyErrorCustom, notifyWarningCustom } from '../../../utils/notify'

const STEPS = {
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
  STEP_FIFTH_PASS_SCHEDULE: 'stepSchedule',
}

function StepFourtPass({
  setStep,
  imageArray,
  selectedArrayImage,
  headlineText,
  legend,
  legendList,
  setLegendList,
  setLegend,
  campaignRef,
  headlineList,
  selectFunnel,
  headlineGpt,
  imagelayers,
  // stateLegend,
  // setStateLegend,
  // selectedObjectItem,
  imageLayers,
  selectedArrayImageObject,
}) {
  const { t } = useTranslation()
  // const navigation = useNavigate()
  const { clientData } = useClient()

  const [loading, setLoading] = useState(false)
  const [loop, setloop] = useState(selectedArrayImage.length)
  const [gptInput, setGptInput] = useState('')
  // const [editableItems, setEditableItems] = useState([])

  useEffect(() => {
    if (legendList.length === 0) {
      setloop(selectedArrayImage.length)
      legendGenerate()
    }
  }, [selectedArrayImage])

  const deleteLegend = async (index) => {
    const legendListCopy = { ...legendList }

    if (index in legendListCopy) {
      delete legendListCopy[index]
      setLegendList(legendListCopy)
    }

    setLegendList(legendListCopy)
    const copyState = { ...legendList }
    copyState[index] = false
    // setStateLegend(copyState)
    return
  }

  const confirmLegend = async (index) => {
    const copyState = { ...stateLegend }
    copyState[index] = false
    // setStateLegend(copyState)
  }

  const legendGenerate = async (indexLegend, regenerate) => {
    setLoading(true)

    const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()
    const mesAtual = new Date(new Date().setDate(1)) // Primeiro dia do mês atual
    mesAtual.setDate(mesAtual.getDate() + 30)
    mesAtual.setHours(0, 0, 0, 0)

    if (!userPlanCount.data) {
      const postUserPlanCount = await UserPlanCount.postUserPlanCount({
        ownScheduleCount: 0,
        postOnDesignCount: 0,
        imageBankCount: 0,
        aiImageCount: 0,
        aiLegendCount: 1,
        aiMessageCount: 0,
        renewalDate:
          userSubscription.data.length > 0
            ? userSubscription.data[0].expiration
            : mesAtual,
      })
    } else {
      const renewalDate = new Date(userPlanCount.data.renewalDate)
      const dataAtual = new Date()

      const expirationPlan =
        userSubscription.data.length > 0
          ? new Date(userSubscription.data[0].expiration)
          : new Date(mesAtual)

      if (
        renewalDate.getTime() < dataAtual.getTime() ||
        renewalDate.getTime() != expirationPlan.getTime()
      ) {
        await UserPlanCount.postUserPlanCount({
          ownScheduleCount: 0,
          postOnDesignCount: 0,
          imageBankCount: 0,
          aiImageCount: 0,
          aiLegendCount: 1,
          aiMessageCount: 0,
          renewalDate:
            userSubscription.data.length > 0
              ? userSubscription.data[0].expiration
              : mesAtual,
        })
      } else {
        if (
          userSubscription.data.length <= 0 &&
          userPlanCount.data.aiLegendCount >= 50
        ) {
          notifyWarningCustom(t('plans.user_plan_count_legend'))
          setLoading(false)
          return
        } else if (userSubscription.data.length > 0) {
          const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
            (item) => item.type === 'ia_legend_generative_quantity'
          )
          const quantityLegendst =
            userSubscription.data[0].Plan.PlanItems[index]?.quantity

          if (
            userPlanCount.data.aiLegendCount >= quantityLegendst &&
            quantityLegendst != null
          ) {
            notifyWarningCustom(t('plans.user_plan_count_legend'))
            setLoading(false)
            return
          }
        }
        await UserPlanCount.putUserPlanCount(userPlanCount.data.uuid, {
          ownScheduleCount: userPlanCount.data.ownScheduleCount,
          postOnDesignCount: userPlanCount.data.postOnDesignCount,
          imageBankCount: userPlanCount.data.imageBankCount,
          aiImageCount: userPlanCount.data.aiImageCount,
          aiLegendCount: userPlanCount.data.aiLegendCount + loop,
          aiMessageCount: userPlanCount.data.aiMessageCount,
          renewalDate: userPlanCount.data.renewalDate,
        })
      }
    }

    let businessId
    let descriptionBusines

    let getEditorialLine
    let editorialLine

    for (let index = 0; index < loop; index++) {
      if (clientData?.uuid) {
        getEditorialLine = await EditorialLineService.getEditorialLineByClient(
          clientData?.uuid
        )
        editorialLine = getEditorialLine.data[0].bodyJson
      } else {
        getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
        editorialLine = getEditorialLine.data[0].bodyJson
      }

      const targetAudiencesInfos =
        await TargetAudienceService.getAllTargetAudienceById(
          campaignRef?.targetAudienceId
        )

      const productInfos = campaignRef?.productId
        ? await ProductService.getProductById(campaignRef?.productId)
        : undefined

      const retGpt = {
        flow: 'multiples_publications',
        prompt: 'legend',
        post_description: campaignRef?.objective, //editorialLine.dados_da_marca.descricao_negocio,
        brand: editorialLine.dados_da_marca.nicho,
        campaign_name: campaignRef?.name,
        funil: selectFunnel,
        niche: editorialLine.dados_da_marca.nicho,
        subniche: editorialLine.dados_da_marca.subnicho,
        campaign_description: campaignRef?.objective,
        target_public: {
          name: targetAudiencesInfos?.data[0]?.name || '',
          gender: targetAudiencesInfos?.data[0]?.genderValue || '',
          social_class:
            targetAudiencesInfos?.data[0]?.socialClassValue.join(', ') || '',

          profession: targetAudiencesInfos?.data[0]?.profession || '',
          region: 'Nacional, com foco em grandes centros urbanos',
        },
        voice_tone: editorialLine.tom_de_voz,
        product: {
          name: productInfos?.data?.name || '',
          type: productInfos?.data?.productTypeValue || '',
          description: productInfos?.data?.description || '',
          value: productInfos?.data?.value || '',
          promotional_value: productInfos?.data?.promotionalValue || '',
        },
        headlines: headlineGpt.data.data.headlines[index],
        language:
          localStorage.i18nextLng == 'en' ? localStorage.i18nextLng : 'pt',
      }

      let retEditorialLine = await PythonService.postPromptGPT(retGpt)

      // if (clientData?.uuid) {
      //   const { data } = await getBusinessByClientId(clientData?.uuid)

      //   businessId = data[0]?.uuid
      //   descriptionBusines = data[0]?.description
      // } else {
      //   const { data } = await BusinessService.getBusinessByLogin()

      //   businessId = data[0]?.uuid
      //   descriptionBusines = data[0]?.description
      // }

      // const productInfos = await ProductService.getProductById(
      //   campaignRef?.current?.productId
      // )

      // const voiceToneInfos = await VoiceToneService.getVoiceToneByBusiness(
      //   businessId
      // )
      // // const businessInfos = await BusinessService.getBusinessByLogin()
      // const targetAudiencesInfos =
      //   await TargetAudienceService.getAllTargetAudienceById(
      //     campaignRef?.current?.targetAudienceId
      //   )

      // const bodyLegend = {
      //   headlineSelected: headlineText,
      //   publicationPurpose: campaignRef?.current?.objective,
      //   descriptionBusiness: descriptionBusines,
      //   voiceTone: voiceToneInfos?.data[0]?.tone,
      //   socialClass: targetAudiencesInfos?.data[0]?.educationLevelValue,
      //   gender: targetAudiencesInfos?.data[0]?.genderValue,
      //   profession: targetAudiencesInfos?.data[0]?.profession,
      //   nameProduct: productInfos?.data?.name,
      //   productType: productInfos?.data?.productTypeValue,
      //   productDescription: productInfos?.data?.description,
      //   value: productInfos?.data?.value,
      //   promotionalValue: productInfos?.data?.promotionalValue,
      // }

      // const data = await getLegendsService(bodyLegend)

      //setLegend(retEditorialLine?.data?.data[0]?.message)

      if (regenerate === 'regenerate') {
        if (indexLegend === index && indexLegend !== undefined) {
          // Atualiza a legenda na posição específica do array
          setLegendList((prevList) => {
            const newList = [...prevList]
            newList[indexLegend] = retEditorialLine.data.data[0]
            return newList
          })
        } else {
          setLegendList((prevList) => {
            const newList = [...prevList]
            newList[index] = legendList[index]
            return newList
          })
          // Atualiza a legenda normalmente
          // setLegend(retEditorialLine.data.data[0])
          // setStateLegend(retEditorialLine.data.data[0])
          // return
        }
      } else {
        setLegendList((prevList) => {
          const newList = [...prevList]
          newList[index] = retEditorialLine.data.data[0]
          return newList
        })
      }

      // if (
      //   regenerate === 'regenerate' &&
      //   indexLegend === index &&
      //   indexLegend !== undefined
      // ) {

      //   // Atualiza a legenda na posição específica do array
      //   setLegendList((prevList) => {
      //     const newList = [...prevList]
      //     newList[indexLegend] = retEditorialLine.data.data[0]
      //     return newList
      //   })
      // } else {

      //   setLegendList((prevList) => {
      //     const newList = [...prevList]
      //     newList[index] = retEditorialLine.data.data[0]
      //     return newList
      //   })
      //   // Atualiza a legenda normalmente
      //   // setLegend(retEditorialLine.data.data[0])
      //   // setStateLegend(retEditorialLine.data.data[0])
      //   // return
      // }

      // setStateLegend((prevState) => [
      //   ...prevState,
      //   retEditorialLine?.data?.data[0],
      // ])

      //legendList[0] = retEditorialLine?.data?.data[0]?.message

      // setLegend(retEditorialLine.data.data[0])
    }
    setLoading(false)
  }

  const loadGptOutput = async (index) => {
    const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()
    const mesAtual = new Date(new Date().setDate(1)) // Primeiro dia do mês atual
    mesAtual.setDate(mesAtual.getDate() + 30)
    mesAtual.setHours(0, 0, 0, 0)

    if (!userPlanCount.data) {
      const postUserPlanCount = await UserPlanCount.postUserPlanCount({
        ownScheduleCount: 0,
        postOnDesignCount: 0,
        imageBankCount: 0,
        aiImageCount: 0,
        aiLegendCount: 1,
        aiMessageCount: 0,
        renewalDate:
          userSubscription.data.length > 0
            ? userSubscription.data[0].expiration
            : mesAtual,
      })
    } else {
      const renewalDate = new Date(userPlanCount.data.renewalDate)
      const dataAtual = new Date()

      const expirationPlan =
        userSubscription.data.length > 0
          ? new Date(userSubscription.data[0].expiration)
          : new Date(mesAtual)

      if (
        renewalDate.getTime() < dataAtual.getTime() ||
        renewalDate.getTime() != expirationPlan.getTime()
      ) {
        await UserPlanCount.postUserPlanCount({
          ownScheduleCount: 0,
          postOnDesignCount: 0,
          imageBankCount: 0,
          aiImageCount: 0,
          aiLegendCount: 1,
          aiMessageCount: 0,
          renewalDate:
            userSubscription.data.length > 0
              ? userSubscription.data[0].expiration
              : mesAtual,
        })
      } else {
        if (
          userSubscription.data.length <= 0 &&
          userPlanCount.data.aiLegendCount >= 50
        ) {
          notifyWarningCustom(t('plans.user_plan_count_legend'))
          setLoading(false)
          return
        } else if (userSubscription.data.length > 0) {
          const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
            (item) => item.type === 'ia_legend_generative_quantity'
          )
          const quantityLegendst =
            userSubscription.data[0].Plan.PlanItems[index]?.quantity

          if (
            userPlanCount.data.aiLegendCount >= quantityLegendst &&
            quantityLegendst != null
          ) {
            notifyWarningCustom(t('plans.user_plan_count_legend'))
            setLoading(false)
            return
          }
        }
        await UserPlanCount.putUserPlanCount(userPlanCount.data.uuid, {
          ownScheduleCount: userPlanCount.data.ownScheduleCount,
          postOnDesignCount: userPlanCount.data.postOnDesignCount,
          imageBankCount: userPlanCount.data.imageBankCount,
          aiImageCount: userPlanCount.data.aiImageCount,
          aiLegendCount: userPlanCount.data.aiLegendCount + 1,
          aiMessageCount: userPlanCount.data.aiMessageCount,
          renewalDate: userPlanCount.data.renewalDate,
        })
      }
    }

    let businesData = undefined
    let voiceToneData = ''

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businesData = data[0]

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusinessId(
        businesData?.uuid
      )
    } else {
      const { data } = await BusinessService.getBusinessByLogin()

      businesData = data[0]

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusiness()
    }

    const body = {
      flow: 'own_publication',
      prompt: 'legend',

      niche: businesData?.BusinessNiche?.name,
      subniche: businesData?.subNiche,
      input: gptInput,
      voice_tone: voiceToneData?.data[0]?.tone,
      business_description: businesData?.description,
    }

    const response = await postPromptGPT(body)

    if (response?.data?.status === 200) {
      setLegendList((prevLegendList) => {
        const newLegendList = [...prevLegendList]
        newLegendList[index] = response?.data?.data?.legenda
        return newLegendList
      })
    } else {
      notifyErrorCustom('Erro ao gerar legenda!')
    }

    setLoading(false)
  }

  // const [activeImputLegend, setActiveImputLegend] = useState(true)
  // const [activeInputs, setActiveInputs] = useState(
  //   Array(selectedArrayImage.length).fill(false)
  // )

  // const [legendValue, setLegendValue] = useState(legendList)

  // const legendUpdate = (index) => {

  //   const findItemOpen = editableItems.find((item) => item === index)

  //   if (findItemOpen !== undefined) {
  //     const newEditableItems = editableItems.filter((item) => item !== index)

  //     setEditableItems(newEditableItems)
  //   } else {
  //     editableItems.push(index)
  //   }
  // }

  // const handleLegendChange = (e, index) => {
  //   const updatedLegendValue = [...legendValue] // Faz uma cópia do array original
  //   updatedLegendValue[index] = e.target.value // Atualiza o valor no índice especificado
  //   setLegendValue(updatedLegendValue) // Define o novo array como o estado
  //   setLegendList(updatedLegendValue)
  // }

  // const handleLegendChange = (e, index) => {
  //   const newLegend = [...stateLegend]
  //   newLegend[index] = e.target.value
  //   setStateLegend(newLegend)
  // }

  const handleLegendChange = (value, index) => {
    setLegendList((prevLegendList) => {
      const newLegendList = [...prevLegendList]
      newLegendList[index] = value
      return newLegendList
    })
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      if (legendList.length > 0) {
        setLegendList([])
      }
      setStep('stepArts')
    }
    if (navigation === 'next') {
      setStep(STEPS.STEP_FIFTH_PASS_SCHEDULE)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <Loading enable={loading} />
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>
          {t('stepFourthPass.title')}
        </p>
        <p className={styles.title_new_posts_title}>
          {t('stepFourthPass.subtitle')}
        </p>
      </div>
      <div className={styles.title_steps}>
        <div className={styles.first_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>
              {t('stepFourthPass.pass1')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFourthPass.pass_subtitle1')}
            </p>
          </div>
        </div>

        <div className={styles.second_pass}>
          <div>
            <NumberTwo width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>
              {t('stepFourthPass.pass2')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFourthPass.pass_subtitle2')}
            </p>
          </div>
        </div>

        <div className={styles.third_pass}>
          <div>
            <NumberThree width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>
              {t('stepFourthPass.pass3')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFourthPass.pass_subtitle3')}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.container_content}>
        {selectedArrayImage.map((item, index) => {
          const [openEdit, setOpenEdit] = useState(true)

          const legendUpdate = (index) => {
            setOpenEdit((current) => !current)
          }

          return (
            <div key={index} className={styles.card_subtitle}>
              <div className={styles.box_image_publication}>
                <img
                  src={item}
                  className={styles.image_publication}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </div>
              <div className={styles.box_title_publication}>
                {/* <p className={styles.title_publication}>{headlineText}</p> */}
                <p className={styles.title_image}>
                  {/* {
                      selectedObjectItem[index]?.params.filter(
                        (item) => item.name === 'titulo'
                      )[0].value
                    } */}
                  {selectedArrayImageObject[
                    index
                  ]?.tempImageLayers?.params.filter(
                    (item) => item?.name === 'titulo'
                  )[0]?.value || ''}
                </p>
                <p className={styles.title_publication}>
                  {headlineList[index]}
                </p>
              </div>
              {legendList[index] && (
                <>
                  {/* <div>
                    <p className={styles.}>
                      {
                        selectedObjectItem[index].params.filter(
                          (item) => item.name === 'titulo'
                        )[0].value
                      }
                    </p>
                  </div> */}
                  <div>
                    {/* <SelectRound
                      className={styles.select_search}
                      htmlFor="select"
                      placeholder="Selecione"
                      defaultSelected={{ label: 'Orientação', value: '' }}
                      options={[
                        { label: 'Teste', value: 1 },
                        { label: 'Teste2', value: 2 },
                      ]}
                      variant={'right'}
                    /> */}
                  </div>
                  {/* <div className={styles.box_buttons}>
                   
                    <div
                      className={styles.button_subtitle_refresh}
                      onClick={() => legendUpdate(index)}
                    >
                      <Pen2 color="#000" width={'24px'} height={'24px'} />
                    </div>
                    <div
                      className={styles.button_subtitle_refresh}
                      onClick={() => legendGenerate(index, 'regenerate')}
                    >
                      <WhiteRefresh width={'24px'} height={'24px'} />
                    </div>
                    
                  </div> */}
                </>
              )}
              <div className={styles.box_description_publication}>
                {/* <p className={styles.description_publication}>{legend}</p> */}

                {/* <textarea
                  // className={
                  //   !activeImputLegend[index]
                  //     ? styles.input_legend_readOnly
                  //     : styles.input_legend
                  // }
                  // readOnly={!activeImputLegend[index]}
                  className={
                    openEdit
                      ? styles.input_legend_readOnly
                      : styles.input_legend
                  }
                  readOnly={openEdit}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  onChange={(e) => handleLegendChange(e.target.value, index)}
                  value={legendList[index] || ''}
                /> */}

                <div
                  style={{
                    // marginTop: 30,

                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    cursor: 'default',
                  }}
                  // className={styles.selectedModalCard}
                >
                  <div
                    className={styles.form_container_input}
                    style={{
                      cursor: 'default',
                    }}
                  >
                    <label
                      style={{
                        fontSize: 14,
                        color: '#190027',
                        fontWeight: 500,
                      }}
                    >
                      {t('stepMyPublication.legend')}
                    </label>

                    <textarea
                      readOnly={false}
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      value={legendList[index] || ''}
                      // value={publicationLegend}
                      style={{
                        width: '100%',
                        height: 150,
                        borderRadius: 6,
                        border: '1px solid #ededed',
                        backgroundColor: 'rgba(224, 224, 224, 1)',
                        padding: '10px',
                        color: '#373737',
                        resize: 'none',
                        outline: 'none',
                        fontFamily: 'Poppins',
                        fontSize: 16,
                      }}
                      onChange={(e) =>
                        handleLegendChange(e.target.value, index)
                      }
                      // onChange={(e) => setPublicationLegend(e.target.value)}
                      placeholder={t('stepMyPublication.legendPlaceholder')}
                    />

                    <div
                      style={{
                        display: 'flex',
                        gap: 20,
                      }}
                    >
                      <input
                        // value={gptInput}
                        onChange={(e) => setGptInput(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setLoading(true)
                            loadGptOutput(index)
                          }
                        }}
                        type="text"
                        placeholder={t('legendPlaceholder')}
                        style={{
                          width: '100%',
                          height: 40,
                          borderRadius: 5,
                          border: '1px solid #ededed',
                          backgroundColor: '#ededed',
                          padding: '0 10px',
                          marginTop: 10,
                          marginBottom: 20,

                          color: '#190027',
                        }}
                      />
                      <div
                        onClick={() => {
                          setLoading(true)
                          loadGptOutput(index)
                        }}
                        style={{
                          display: 'flex',
                          marginTop: 10,
                          height: 40,
                          width: 100,
                          borderRadius: 5,
                          backgroundColor: '#6A0098',

                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        <div
                          style={{
                            color: '#fff',
                            textAlign: 'center',
                            fontSize: 14,
                          }}
                        >
                          {t('stepMyPublication.apply')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <p className={styles.description_publication}>
                {legendList[index]}
              </p> */}
              </div>
            </div>
          )
        })}
      </div>
      <div className={styles.button_nav}>
        <button
          className={styles.button_back}
          onClick={() => {
            nextButton('back')
          }}
        >
          <p className={styles.button_title}>
            {t('stepFourthPass.button.back')}
          </p>
        </button>

        <button
          className={styles.button_next}
          onClick={() => {
            nextButton('next')
          }}
        >
          <p className={styles.button_title}>
            {t('stepFourthPass.button.next')}
          </p>
        </button>
      </div>
    </motion.div>
  )
}

export default StepFourtPass
