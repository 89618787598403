import { Stars } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { object, node, func } from 'prop-types'
import { useTranslation } from 'react-i18next'

export const ImageCard = ({
  image,
  children,
  onClickButton,
  onRemoveBackground,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.box_cards}>
      <div className={styles.card_image_upload}>
        <img
          src={image?.fileUrl ? image?.fileUrl : image}
          alt=""
          className={styles.image_upload}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <p className={styles.card_image_title_upload}>{image?.fileName}</p>

      {onRemoveBackground && (
        <button
          onClick={onRemoveBackground}
          className={styles.card_remove_background}
        >
          {t('productsPage.removeBackground')}
          <Stars />
        </button>
      )}

      {children && (
        <button
          onClick={onClickButton}
          className={styles.card_remove_button_upload}
        >
          {children}
        </button>
      )}
    </div>
  )
}

ImageCard.propTypes = {
  image: object,
  children: node,
  onClickButton: func,
  onRemoveBackground: func,
}
