import styles from './styles.module.css'
import { useEffect, useState } from 'react'
// import { LeonardoService } from '../../services'
import { useNavigate } from 'react-router-dom'
import { SelectRound } from '../../components/Select/SelectRound'

import CarouselDashboard from '../../components/CarouselDashboard'
import CarouselCampaing from '../../components/CarouselCampaing'
import { motion } from 'framer-motion'

import {
  BusinessClient,
  CampaignService,
  EditorialLineService,
  SubscriptionService,
  UserAccountsService,
} from '../../services/'

import { useClient } from '../../hooks/useClient'
// import { notifyErrorCustom, notifySuccessCustom } from '../../utils/notify'

import SimpleEditionModal from '../../components/SimpleEditionModalTemplate'
import { Link } from 'react-router-dom'

import {
  BrandingService,
  BusinessService,
  LogosService,
  ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
  GenerateMenuService,
} from '../../services/'
// import { ProductFruits } from 'react-product-fruits'
import { useAuth } from '../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { setUsetifulTags } from 'usetiful-sdk'
import { loadUsetifulScript } from 'usetiful-sdk'
import ModalValidDowngrade from '../../components/ModalValidDowngrade'
loadUsetifulScript('ca0d7698d37b78611a557e662cb73913', {
  identifyUser: true,
})

// loadUsetifulScript('ca0d7698d37b78611a557e662cb73913', { identifyUser: true })

function HomePage() {
  const { t } = useTranslation()
  const { clientData } = useClient()

  const [campaigns, setCampaigns] = useState([])
  const storage = window.localStorage

  const [items, setItems] = useState([{}, {}, {}, {}, {}, {}])
  const [editorialLineGenerateArray, setEditorialLineGenerateArray] = useState(
    []
  )

  const [qtdLoadTemplates, setQtdLoadTemplates] = useState(0)

  let editorialLineGenerate = null

  let qtdTemplatesLimit = 5
  const daysUntilArtExpires = 7

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [indexModalSelected, setIndexModalSelected] = useState(undefined)

  const [imageUrl, setImageUrl] = useState(undefined)
  const [imageLayers, setImageLayers] = useState(undefined)
  const [isParameterized, setIsParameterized] = useState(true)

  const [idTemplateRemove, setIdTemplateRemove] = useState([])
  const [hasSignature, setHasSignature] = useState(false)

  const [isDashboardExpired, setIsDashboardExpired] = useState(false)

  const [editorialChatDashboard, setEditorialChatDashboard] = useState([])
  const { setUserPlan, userData } = useAuth()

  const [verifyFirstLogin, setVerifyFirstLogin] = useState(false)

  const navigation = useNavigate()
  const [blockDowngrade, setBlockDowngrade] = useState(false)

  const handleNavigate = (route) => {
    navigation(`${route}`)
  }
  const [settingsCompleted, setSettingsCompleted] = useState({
    parameterizationCompleted: false,
  })
  useEffect(() => {
    handleCheckedUsetiful()
    handleFirstLogin()
    handleGetEditorial()
  }, [])

  const handleCheckedUsetiful = async () => {
    // console.log('USETIFUL', window.USETIFUL)
    // console.log(
    //   'USETIFU222L',
    //   JSON.parse(window.localStorage.getItem('userData'))?.userEmail
    // )
    const storageMail =
      JSON.parse(window.localStorage.getItem('userData'))?.userEmail ?? null

    // window.USETIFUL?.user?.clearProgress?.()
    window.usetifulTags = { userId: storageMail }

    // if (storageMail) {
    //   console.log('sasasaasas')
    //   setUsetifulTags({
    //     userId: storageMail, // This identifies the user and should be unique. Change it.
    //     // firstName: 'EXAMPLE_FIRST_NAME', // This property defines user’s first name. Change it.
    //     // lastName: 'EXAMPLE_LAST_NAME', // This property defines user’s last name. Change it.
    //   })
    // }
  }

  const handleGetEditorial = async () => {
    if (!clientData?.uuid) {
      const antiDuplicatedChat =
        await GenerateMenuService.getGenerateMenuByIndex('0')

      setEditorialChatDashboard(antiDuplicatedChat.data.chatJson)
      setQtdLoadTemplates(0)
    } else {
      const antiDuplicatedChat =
        await GenerateMenuService.getGenerateMenuByClientIndex(
          clientData?.uuid,
          '0'
        )
      setEditorialChatDashboard(antiDuplicatedChat.data.chatJson)
      setQtdLoadTemplates(0)
    }
  }

  const handleFirstLogin = async () => {
    const { data: dataFirstLogin } =
      await UserAccountsService.getUserAccountsByLogin()

    if (dataFirstLogin.firstLogin === true) {
      navigation('/FastPublishing1', {
        state: { loginRedirect: true },
      })
    }
    setVerifyFirstLogin(true)
  }

  useEffect(() => {
    if (!verifyFirstLogin) return
    loadData()
    setItems([{}, {}, {}, {}, {}, {}])
    // setQtdLoadTemplates(0)
  }, [clientData, verifyFirstLogin])

  useEffect(() => {
    // if (!verifyFirstLogin) return

    if (qtdLoadTemplates <= qtdTemplatesLimit) {
      loadTemplates()
    }
  }, [qtdLoadTemplates])
  // }, [qtdLoadTemplates, clientData, verifyFirstLogin])

  const handleNavigateParam = () => {
    navigation('/StepsPage', {
      state: { param: true },
    })
  }

  const loadTemplates = async () => {
    let generatedArt
    let expirationDate = null
    let parameterized
    let editorialLine
    let editorialLineUpdate
    let generatedArtUpdate
    const today = new Date()
    const dataProduct = await ProductService.getProductDefault(
      storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
    )

    if (!clientData?.uuid) {
      parameterized =
        await UserAccountsService.getUserAccountsParameterizationCompleted()

      setIsParameterized(parameterized.data.parameterizationCompleted)
      if (!parameterized.data.parameterizationCompleted) {
        return
      }

      generatedArt = await GenerateMenuService.getGenerateMenuByIndex(
        qtdLoadTemplates.toString()
      )

      editorialLine = await EditorialLineService.getEditorialLinebyLogin()

      editorialLineUpdate = dayjs(editorialLine.data[0].updatedAt)
      generatedArtUpdate = dayjs(generatedArt.data.updatedAt)
    } else {
      parameterized = await BusinessClient.getBusinessClientParameterization(
        clientData?.uuid
      )

      setIsParameterized(parameterized.data.parameterizationCompleted)
      if (!parameterized.data.parameterizationCompleted) {
        return
      }

      generatedArt = await GenerateMenuService.getGenerateMenuByClientIndex(
        clientData?.uuid,
        qtdLoadTemplates.toString()
      )

      editorialLine = await EditorialLineService.getEditorialLineByClient(
        clientData?.uuid
      )

      editorialLineUpdate = dayjs(editorialLine.data[0].updatedAt)
      generatedArtUpdate = dayjs(generatedArt.data.updatedAt)
    }

    if (generatedArt.data.uuid) {
      expirationDate = new Date(generatedArt.data.expirationDate)

      if (
        expirationDate >= today &&
        generatedArtUpdate.isAfter(editorialLineUpdate)
      ) {
        setTimeout(() => {
          setItems(
            items.map((item, index) => {
              if (index.toString() === qtdLoadTemplates.toString()) {
                return {
                  ...generatedArt.data.bodyJson,
                }
              }
              return item
            })
          )

          // editorialLineGenerateArray.push(generatedArt?.data?.chatJson)

          setEditorialLineGenerateArray([
            ...editorialLineGenerateArray,
            generatedArt?.data?.chatJson,
          ])

          setQtdLoadTemplates(qtdLoadTemplates + 1)
        }, 2000)
        return
      }
    }

    if (
      editorialChatDashboard.length <= 0 ||
      (editorialLineUpdate.isAfter(generatedArtUpdate) && qtdLoadTemplates == 0)
    ) {
      // const getEditorialLine = await EditorialLineService.getEditorialLineByClient(clientData?.uuid)
      const getEditorialLine = clientData?.uuid
        ? await EditorialLineService.getEditorialLineByClient(clientData?.uuid)
        : await EditorialLineService.getEditorialLinebyLogin()
      const editorialLine = getEditorialLine?.data[0]?.bodyJson

      const bodyEditorial = {
        flow: 'editorial_line',
        prompt: 'calendary',
        brand: editorialLine?.dados_da_marca?.nicho,
        editorial_line: {
          primary: {
            titulo: editorialLine?.linha_conteudo_principal?.titulo,
            descricao: editorialLine?.linha_conteudo_principal?.descricao,
          },
          secondary: [
            {
              titulo: editorialLine?.linhas_conteudo_secundarias[1]?.titulo,
              descricao:
                editorialLine?.linhas_conteudo_secundarias[1]?.descricao,
              exemplos_conteudo:
                editorialLine?.linhas_conteudo_secundarias[1]
                  ?.exemplos_conteudo,
            },
            {
              titulo: editorialLine?.linhas_conteudo_secundarias[2]?.titulo,
              descricao:
                editorialLine?.linhas_conteudo_secundarias[2]?.descricao,
              exemplos_conteudo:
                editorialLine?.linhas_conteudo_secundarias[1]
                  ?.exemplos_conteudo,
            },
            {
              titulo: editorialLine?.linhas_conteudo_secundarias[3]?.titulo,
              descricao:
                editorialLine?.linhas_conteudo_secundarias[3]?.descricao,
              exemplos_conteudo:
                editorialLine?.linhas_conteudo_secundarias[1]
                  ?.exemplos_conteudo,
            },
            {
              titulo: editorialLine?.linhas_conteudo_secundarias[4]?.titulo,
              descricao:
                editorialLine?.linhas_conteudo_secundarias[4]?.descricao,
              exemplos_conteudo:
                editorialLine?.linhas_conteudo_secundarias[1]
                  ?.exemplos_conteudo,
            },
          ],
        },
        niche: editorialLine?.dados_da_marca.nicho,
        subniche: editorialLine?.dados_da_marca.subnicho,
        business_description: editorialLine?.dados_da_marca?.descricao_negocio,
        target_public: {
          name: editorialLine?.persona.nome,
          gender: editorialLine?.persona.genero,
          social_class: 'Classes A, Classe B',
          profession: editorialLine?.persona.cargo,
          region: 'Nacional, com foco em grandes centros urbanos',
        },
        product: {
          name: dataProduct?.data?.name || '',
          type: dataProduct?.data?.productTypeValue || '',
          description: dataProduct?.data?.description || '',
          value: dataProduct?.data?.value || '',
          promotional_value: dataProduct?.data?.promotionalValue || '',
        },
        voice_tone: editorialLine?.tom_de_voz,
        voice_tone_description: editorialLine?.tipo_post,
        language:
          localStorage.i18nextLng == 'en' ? localStorage.i18nextLng : 'pt',
      }
      let retEditorialLine = await PythonService.postPromptGPT(bodyEditorial)

      editorialLineGenerate = retEditorialLine
      setEditorialChatDashboard(retEditorialLine)
      // setEditorialLineGenerate(retEditorialLine)
    } else {
      editorialLineGenerate = editorialChatDashboard
    }

    const loadBusinessData = async () => {
      let obj = {}

      let logoBase64 = []

      if (clientData?.uuid) {
        let businessByClientReturn =
          await BusinessService.getBusinessByClientId(clientData?.uuid)

        let businessByClientReturnId = businessByClientReturn?.data[0].uuid

        const brandingInfos = await BrandingService.getAllBrandingByBusinessId(
          businessByClientReturnId
        )
        const voiceToneInfos =
          await VoiceToneService.getAllVoiceToneByBusinessId(
            businessByClientReturnId
          )
        const businessInfos = businessByClientReturn

        const targetAudiencesInfos =
          await TargetAudienceService.getAllTargetAudienceByClientId(
            businessByClientReturnId
          )

        const clientLogo = await LogosService.getLogosByClientBase64(
          clientData?.uuid
        )
        //const campaignLogo = await LogosService.getLogosByCampaignBase64(campaignId)
        if (clientLogo?.length !== 0 && clientLogo) {
          clientLogo.data.forEach((logo) => {
            logoBase64.push({ base64: logo.fileBase64 })
          })
        }

        obj = {
          brandingInfos: brandingInfos,
          voiceToneInfos: voiceToneInfos,
          businessInfos: businessInfos,
          targetAudiencesInfos: targetAudiencesInfos,
          logoBase64,
        }
      } else {
        const brandingInfos = await BrandingService.getAllBrandingByBusiness()
        const voiceToneInfos =
          await VoiceToneService.getAllVoiceToneByBusiness()
        const businessInfos = await BusinessService.getBusinessByLogin()
        const targetAudiencesInfos =
          await TargetAudienceService.getAllTargetAudience()

        const userLogo = await LogosService.getLogosUserBase64()

        if (userLogo?.length !== 0 && userLogo) {
          userLogo.data.forEach((logo) => {
            logoBase64.push({ base64: logo.fileBase64 })
          })
        }

        obj = {
          brandingInfos: brandingInfos,
          voiceToneInfos: voiceToneInfos,
          businessInfos: businessInfos,
          targetAudiencesInfos: targetAudiencesInfos,
          logoBase64,
        }
      }

      return obj
    }

    const businessData = await loadBusinessData()
    const products = clientData?.uuid
      ? await ProductService.getProductByBusinessClientId(clientData?.uuid)
      : await ProductService.getProductByNotBusinessClient()

    const productsValue = products?.data

    const imgBase64AllImages =
      productsValue[Math.floor(Math.random() * (productsValue.length - 1))]

    const { data } = await ProductService.getProductImage64(
      imgBase64AllImages?.uuid
    )

    const imgBase64 = []

    data.forEach((image) => {
      imgBase64.push({
        base64: image.fileBase64,
        imageCategory: 'Principal',
        productId: image.productId,
        productName: image.productName,
        productDescription: image.description,
        productValue: image.value,
        productPromotionValue: image.promotionalValue,
      })
    })

    const complexityList = ['Simples', 'Mediana', 'Avançado']
    // const funnelList = ['Topo', 'Meio', 'Fim']

    const complexity = complexityList[Math.floor(Math.random() * 3)]
    //const funnel = funnelList[Math.floor(Math.random() * 3)]

    let bodyGenerateMenu = {
      complexity: complexity,

      art_width: 1080,
      art_height: 1350,
      fonte_1:
        businessData?.brandingInfos?.data[0]?.fontsBase64?.fontTitleBase64
          ?.name || 'abel',
      fonte_2:
        businessData?.brandingInfos?.data[0]?.fontsBase64?.fontSubtitleBase64
          ?.name || 'alegreyasanssc',
      fonte_3:
        businessData?.brandingInfos?.data[0]?.fontsBase64?.fontTextBase64
          ?.name || 'allert',
      color_palette_1: businessData.brandingInfos.data[0].primaryColor, //'(183,55,121)',
      color_palette_2: businessData.brandingInfos.data[0].secondaryColor, //'(252,137,97)',
      color_palette_3: businessData.brandingInfos.data[0].tertiaryColor, //'(242,243,191)',

      prohibitedTemplates: idTemplateRemove,

      specificTemplate:
        editorialLineGenerate?.data?.data?.calendary[qtdLoadTemplates]?.id +
        '.json',
      niche: 'random',

      ...editorialLineGenerate?.data?.data?.calendary[qtdLoadTemplates],

      funil:
        editorialLineGenerate?.data?.data?.calendary[qtdLoadTemplates]?.funil,
      palavras_destacadas:
        editorialLineGenerate?.data?.data?.calendary[qtdLoadTemplates]
          ?.palavras_destacadas,

      template_numbers: 1,

      num_imgs: [1],
      img_base64: imgBase64,
      logo_base64: businessData?.logoBase64,

      name_product: dataProduct?.data?.name || '',
      product_type: dataProduct?.data?.productTypeValue || '',
      product_description: dataProduct?.data?.description || '',
      value: dataProduct?.data?.value || '',
      promotional_value: dataProduct?.data?.promotionalValue || '',
      produto: dataProduct?.data?.name || '',
      hasSignature: hasSignature,
    }

    const generateArtReturn = await PythonService.postGenerateMenu(
      bodyGenerateMenu
    )

    if (generateArtReturn?.data?.status === 200) {
      setItems(
        items.map((item, index) => {
          if (index === qtdLoadTemplates) {
            return {
              ...generateArtReturn?.data?.data[0],
            }
          }
          return item
        })
      )

      // setEditorialLineGenerateArray(...editorialLineGenerateArray, [
      //   editorialLineGenerate,
      // ])

      // editorialLineGenerateArray.push(editorialLineGenerate)
      setEditorialLineGenerateArray([
        ...editorialLineGenerateArray,
        editorialLineGenerate,
      ])

      setIdTemplateRemove((prevState) => [
        ...prevState,
        generateArtReturn?.data?.data[0]?.templateId,
      ])

      const currentDate = new Date()
      const newDate = new Date(currentDate)
      newDate.setDate(currentDate.getDate() + daysUntilArtExpires)

      let antiDuplicity
      if (!clientData?.uuid) {
        antiDuplicity = await GenerateMenuService.getGenerateMenuByIndex(
          qtdLoadTemplates.toString()
        )
      } else {
        antiDuplicity = await GenerateMenuService.getGenerateMenuByClientIndex(
          clientData?.uuid,
          qtdLoadTemplates.toString()
        )
      }

      if (!clientData?.uuid) {
        if (!antiDuplicity.data.uuid) {
          const bodySaveGenerateMenu = {
            bodyJson: generateArtReturn.data.data[0],
            chatJson: editorialLineGenerate,
            indexArt: qtdLoadTemplates.toString(),
            expirationDate: newDate,
          }

          const revBody = await GenerateMenuService.postGenerateMenuBody(
            bodySaveGenerateMenu
          )
        } else {
          const bodySaveGenerateMenu = {
            bodyJson: generateArtReturn.data.data[0],
            chatJson: editorialLineGenerate,
            indexArt: qtdLoadTemplates.toString(),
            expirationDate: newDate,
          }

          await GenerateMenuService.putGenerateMenuBody(
            antiDuplicity.data.uuid,
            bodySaveGenerateMenu
          )
        }
      } else {
        if (expirationDate == null) {
          const bodySaveGenerateMenu = {
            bodyJson: generateArtReturn.data.data[0],
            chatJson: editorialLineGenerate,
            indexArt: qtdLoadTemplates.toString(),
            expirationDate: newDate,
            businessClientId: clientData?.uuid ?? null,
          }
          await GenerateMenuService.postGenerateMenuBody(bodySaveGenerateMenu)
        } else {
          const bodySaveGenerateMenu = {
            bodyJson: generateArtReturn.data.data[0],
            chatJson: editorialLineGenerate,
            indexArt: qtdLoadTemplates.toString(),
            expirationDate: newDate,
            businessClientId: clientData?.uuid ?? null,
          }

          await GenerateMenuService.putGenerateMenuBody(
            generatedArt.data.uuid,
            bodySaveGenerateMenu
          )
        }
      }

      setQtdLoadTemplates(qtdLoadTemplates + 1)
    } else {
      setItems(
        items.map((item, index) => {
          if (index === qtdLoadTemplates) {
            return {
              ...null,
            }
          }
          return item
        })
      )

      setEditorialLineGenerateArray([
        ...editorialLineGenerateArray,
        editorialLineGenerate,
      ])

      setIdTemplateRemove((prevState) => [...prevState, null])

      setQtdLoadTemplates(qtdLoadTemplates + 1)
    }
  }

  const loadData = async () => {
    const subscription = await SubscriptionService.getSubscriptionByUser()

    if (subscription.data.length > 0) {
      setHasSignature(true)
    }

    if (!clientData?.uuid) {
      const clientDataResponse =
        await CampaignService.getAllCampaignByNotClient()

      if (clientDataResponse?.responseStatus === 200) {
        const campaigns = clientDataResponse.data.map((campaign) => {
          return {
            id: campaign.campaignId,
            nome: campaign.campaignName,
            posts: campaign.publications.map((post) => {
              return {
                id: post.uuid,
                url: post.publicationImageUrl,
              }
            }),
          }
        })
        setCampaigns(campaigns)
      }
    } else {
      const clientDataResponse = await CampaignService.getAllCampaignByClient(
        clientData.uuid
      )

      if (clientDataResponse?.responseStatus === 200) {
        const campaigns = clientDataResponse.data.map((campaign) => {
          return {
            id: campaign.campaignId,
            nome: campaign.campaignName,
            posts: campaign.publications.map((post) => {
              return {
                id: post.uuid,
                url: post.publicationImageUrl,
              }
            }),
          }
        })

        setCampaigns(campaigns)
      }
    }
  }
  const handleCloseModal = () => {
    setBlockDowngrade(false)
  }

  const SuggestedPublications = () => (
    <>
      <ModalValidDowngrade
        // setImageAIArray={setImageAIArray}
        // setDowngradeNow={setDowngradeNow}
        isOpen={blockDowngrade}
        onClose={() => handleCloseModal()}
        handleCloseModal={handleCloseModal}
      />
      <div className={styles.header_home}>
        <div className={styles.box_header_title}>
          <div className={styles.title_new_posts}>
            <p className={styles.title_new_posts_label}>
              {t('homePage.suggestedPublications')}
            </p>
            <p className={styles.title_new_posts_title}>
              {t('homePage.publication')}
            </p>
          </div>
          {/* <div className={styles.container_selects}>
            <div className={styles.box_select}>
              <SelectRound
                htmlFor="select"
                placeholder="Selecione"
                variant={'right'}
                defaultSelected={{
                  label: 'Comercial',
                  value: '',
                }}
                options={[
                  { label: 'Teste', value: 1 },
                  { label: 'Teste2', value: 2 },
                ]}
              />
            </div>
            <div className={styles.box_select}>
              <SelectRound
                htmlFor="select"
                placeholder="Selecione"
                variant={'right'}
                defaultSelected={{
                  label: 'Formato de post',
                  value: '',
                }}
                options={[
                  { label: 'Teste', value: 1 },
                  { label: 'Teste2', value: 2 },
                ]}
              />
            </div>

            <div className={styles.box_select}>
              <SelectRound
                htmlFor="select"
                placeholder="Selecione"
                variant={'right'}
                defaultSelected={{
                  label: 'Plataforma',
                  value: '',
                }}
                options={[
                  { label: 'Teste', value: 1 },
                  { label: 'Teste2', value: 2 },
                ]}
              />
            </div>
          </div> */}
        </div>
        <div className={styles.box_button_new_publication}>
          <button
            className={styles.button_new_publication}
            onClick={() => {
              handleNavigate('/NewPostPage')
              // handleNavigate('/NewPublicationPage')
            }}
          >
            <p className={styles.button_new_publication_title}>
              {t('homePage.newPublication')}
            </p>
          </button>
        </div>
      </div>
      <div className={styles.container_carouselDashboard}>
        {isParameterized ? (
          <CarouselDashboard
            items={items}
            loading={true}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            imageLayers={imageLayers}
            setImageLayers={setImageLayers}
            setIndexModalSelected={setIndexModalSelected}
            setBlockDowngrade={setBlockDowngrade}
          />
        ) : (
          <div className={styles.box_company_card}>
            <div className={styles.box_title_card}>
              <p
                className={styles.title_card}
                style={{ color: '#5e0685', fontSize: '30px' }}
              >
                {t('homePage.finalizeYourRegistrationTo')}
              </p>
            </div>
            <div className={styles.box_title_card}>
              <p
                className={styles.title_card}
                style={{ color: '#5e0685', fontSize: '30px' }}
              >
                {t('homePage.generatePublications')}
              </p>
            </div>
            <div className={styles.box_title_card}>
              <p style={{ color: '#5e0685' }}>
                {t('homePage.theRegistrationForm')}
              </p>
            </div>
            <div className={styles.box_title_card}>
              <p
                style={{
                  color: '#5e0685',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('homePage.clickHere')}{' '}
                <p className={styles.redLink} onClick={handleNavigateParam}>
                  {t('homePage.here')}
                </p>{' '}
                {t('homePage.toFinish')}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  )

  const Campaigns = () => (
    <div
      className={styles.container_module_campaign}
      style={{
        marginLeft: 16,
      }}
    >
      <div className={styles.header_module}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>{t('homePage.your')}</p>
          <p className={styles.title_new_posts_title}>
            {t('homePage.campaigns')}
          </p>
        </div>
      </div>
      <div className={styles.container_carouselCampaign}>
        <CarouselCampaing items={campaigns} />
      </div>
    </div>
  )
  // const storageMail = JSON.parse(
  //   window.localStorage.getItem('userData')
  // ).userEmail
  // // ProductFruits.init('FzMKHdjfiHGoWeMW', 'pt-BR', {
  // //   username: storage,
  // // })
  // const userInfo = {
  //   username: storageMail, // REQUIRED, must be unique
  // }
  // setUsetifulTags({
  //   userId: storageMail, // This identifies the user and should be unique. Change it.
  //   // firstName: 'EXAMPLE_FIRST_NAME', // This property defines user’s first name. Change it.
  //   // lastName: 'EXAMPLE_LAST_NAME', // This property defines user’s last name. Change it.
  // })

  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%' }}
    >
      <div className={styles.page}>
        {/* <ProductFruits
          workspaceCode="FzMKHdjfiHGoWeMW"
          language="en"
          user={userInfo}
        /> */}
        <SuggestedPublications />
        <Campaigns />
        <SimpleEditionModal
          isOpen={isModalOpen}
          setNextStep={(item1, item2) => {
            const getGptData =
              editorialLineGenerateArray[indexModalSelected || 0]

            navigation('/NewPublicationPage', {
              state: {
                dashboad: true,
                layers: item2?.tempImageLayers,
                img: item1,
                gptData: JSON.stringify(getGptData),
                indexModalSelected: indexModalSelected,
              },
            })

            return
          }}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          imageLayers={imageLayers}
          setImageLayers={setImageLayers}
          onClose={() => setIsModalOpen(false)}
          title={''}
          subtitle={''}
          publicTargetOptions={[]}
          productOptions={[]}
          onChangeColorPalleteSubtittle={(e) => {}}
          onChangeColorPalleteTittle={(e) => {}}
        />

        {/* <p>{t("Welcome")}</p> */}
      </div>
    </motion.div>
  )
}

export default HomePage
