/* eslint-disable react/prop-types */
import { useState } from 'react'
import styles from './styles.module.css'
// import { CircleX, Cross, Interrogation } from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'

import { CircleX, Cross, Interrogation } from '../../../assets/svgs/icons'
import {
  BrandingService,
  BusinessClient,
  BusinessService,
  BusinessUser,
  ProductService,
  PublicationService,
  PythonNodeService,
  PythonService,
  SocialMediaConnectionService,
  TargetAudienceService,
  UserService,
  VoiceToneService,
} from '../../../services'
import Loading from '../../../components/Loading'
import { notifyErrorCustom } from '../../../utils/notify'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
const ModalAwaitPayment = ({ isOpen }) => {
  if (!isOpen) return null

  const { t } = useTranslation()

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal}>
        {/* <div className={styles.header_close}>
          <div className={styles.close_modal} onClick={onClose}>
            <Cross width={'20px'} height={'20px'} />
          </div>
        </div> */}

        <div className={styles.modal_title}>
          <h2>Aguarde!</h2>
        </div>

        <div className={styles.modal_sub_title}>
          <p className={styles.modal_sub_title_text}>
            {t('Estamos validando seu pagamento')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ModalAwaitPayment
