/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import {
  ButtonFavoriteStarsPress,
  FavoriteStarsPress,
  NavigateArrowRigth,
  NumberFour,
  NumberOne,
  NumberThree,
  NumberTwo,
  Pen2,
  WhiteRefresh,
} from '../../../assets/svgs/icons'
import { useEffect, useState } from 'react'
import ModalComponent from '../../../components/ModalComponent'
import CalendarModal from '../../../components/CalendarModal'
import { SelectRound } from '../../../components/Select/SelectRound'
import { useNavigate } from 'react-router-dom'
import { getLegendsService } from '../../../services/postsServices/getLegendsService'
import Loading from '../../../components/Loading'
import {
  BrandingService,
  BusinessService,
  ProductService,
  TargetAudienceService,
  VoiceToneService,
  HeadlineHasPublicationService,
} from '../../../services'
import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'

const STEPS = {
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
}

function StepFourthPass({
  setStep,
  iWantPublicationRef,
  targetAudienceRef,
  productRef,
  publicationFormatRef,
  purposeOfPublicationRef,
  publicationDraft,
  publicationRet,

  imageUrl,
  imageLayers,
  setImageUrl,
  headlineText,
  setHeadlineText,
  legend,
  setLegend,

  draftData,
  draft,
  repost,
  schedule,

  setHeadlineList,
  headlineList,
  setLegendList,
  legendList,

  //dados da tela anterior
}) {
  const navigate = useNavigate()
  const nextButton = async (navigation) => {
    if (draft) {
      navigate('/Draft')
      return
    }
    if (repost) {
      navigate('/History')
      return
    }
    if (schedule) {
      navigate('/SchedulePublicationPage')
      return
    }
    if (navigation === 'back') {
      setStep(STEPS.STEP_THIRD_PASS_PUBLICATION)
    }
  }

  const [isModalOpen, setModalOpen] = useState(false)

  const [loading, setLoading] = useState(false)

  const { clientData } = useClient()

  const items = [
    {
      id: 1,
      image:
        'https://i.pinimg.com/564x/db/63/29/db6329626b8a4fa34ef798bfa2d48708.jpg',
      title: 'Quer vender seu carro? Nós compramos ele',
      text: 'Lorem ipsum dolor sit amet consectetur. In quam sed ultrices elementum facilisis. Eu ornare ut lectus porttitor augue. Etiam mattis ut volutpat sem sed sed etiam. Nunc enim semper convallis quis non pellentesque. Tellus et tempor neque in ullamcorper turpis malesuada purus faucibus. Sit accumsan ac arcu arcu ultrices malesuada diam elit. Cras quis at vulputate rhoncus urna augue pulvinar tortor. Turpis fermentum velit proin morbi gravida dapibus lorem. Rhoncus donec at ultrices non.      Mauris placerat eget id ut lorem commodo. Eget consectetur arcu bibendum nisl. Tristique senectus elit aenean sollicitudin.',
      avaliacao: 5,
      // pinterest: {
      //   active: false,
      //   orientation: 2,
      // },
      linkedin: {
        active: true,
        orientation: null,
      },
      facebook: {
        active: true,
        orientation: null,
      },
      instagram: {
        active: true,
        orientation: null,
      },
      tiktok: {
        active: true,
        orientation: null,
      },
      twitter: {
        active: true,
        orientation: null,
      },
    },
  ]

  useEffect(() => {
    if (draft === false && repost === false && schedule === false) {
      legendGenerate()
    }
  }, [])

  const legendGenerate = async () => {
    setLoading(true)

    let businessId
    let descriptionBusines
    let productInfos = ''
    let targetAudiencesInfos = ''

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId = data[0]?.uuid
      descriptionBusines = data[0]?.description
    } else {
      const { data } = await BusinessService.getBusinessByLogin()
      businessId = data[0]?.uuid
      descriptionBusines = data[0]?.description
    }

    // console.log('150', targetAudienceRef?.current)

    if (productRef?.current) {
      productInfos = await ProductService.getProductById(
        productRef?.current?.uuid
      )
    }

    const voiceToneInfos = await VoiceToneService.getVoiceToneByBusiness(
      businessId
    )
    // const businessInfos = await BusinessService.getBusinessByLogin()

    if (targetAudienceRef?.current) {
      targetAudiencesInfos =
        await TargetAudienceService.getAllTargetAudienceById(
          targetAudienceRef?.current?.uuid
        )
    }

    const bodyLegend = {
      headlineSelected: headlineText,
      publicationPurpose: purposeOfPublicationRef?.current
        ? purposeOfPublicationRef?.current
        : '',
      descriptionBusiness: descriptionBusines,
      voiceTone: voiceToneInfos?.data[0]?.tone,
      socialClass: targetAudiencesInfos
        ? targetAudiencesInfos?.data[0]?.educationLevelValue
        : '',
      gender: targetAudiencesInfos
        ? targetAudiencesInfos?.data[0]?.genderValue
        : '',
      profession: targetAudiencesInfos
        ? targetAudiencesInfos?.data[0]?.profession
        : '',
      nameProduct: productInfos ? productInfos?.data?.name : '',
      productType: productInfos ? productInfos?.data?.productTypeValue : '',
      productDescription: productInfos ? productInfos?.data?.description : '',
      value: productInfos ? productInfos?.data?.value : '',
      promotionalValue: productInfos
        ? productInfos?.data?.promotionalValue
        : '',
    }

    const data = await getLegendsService(bodyLegend)

    setLegend(data.message)

    setLoading(false)
  }

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const [currentIndex, setCurrentIndex] = useState(0)

  const stars = [...new Array(5).keys()]

  const [activeIndex, setActiveIndex] = useState()

  const onClickStar = (index) => {
    setActiveIndex((oldState) => (oldState == index ? undefined : index))
  }

  console.log(220, activeIndex)

  const [activeImputLegend, setActiveImputLegend] = useState(false)

  const handleLegendChange = (e) => {
    setLegend(e)
  }

  const legendUpdate = () => {
    setActiveImputLegend(!activeImputLegend)
  }

  return (
    <div className={styles.container_page}>
      <Loading enable={loading} />
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>Nova </p>
        <p className={styles.title_new_posts_title}>Publicação</p>
      </div>
      <div className={styles.title_steps}>
        <div className={styles.previous_pass}>
          <div>
            <NumberOne width={'50px'} height={'50px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>1° Passo</p>
            <p className={styles.first_pass_subtitle}>Configurações</p>
          </div>
        </div>

        <div className={styles.previous_pass}>
          <div className={styles.second_pass_box_number}>
            <NavigateArrowRigth />
            <NumberTwo width={'50px'} height={'50px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>2° Passo</p>
            <p className={styles.first_pass_subtitle}>Imagem</p>
          </div>
        </div>

        <div className={styles.previous_pass}>
          <div className={styles.second_pass_box_number}>
            <NavigateArrowRigth />
            <NumberThree width={'50px'} height={'50px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>3° Passo</p>
            <p className={styles.first_pass_subtitle}>Publicação</p>
          </div>
        </div>

        <div className={styles.current_pass}>
          <div className={styles.second_pass_box_number}>
            <NavigateArrowRigth />
            <NumberFour width={'50px'} height={'50px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>4° Passo</p>
            <p className={styles.first_pass_subtitle}>Legenda</p>
          </div>
        </div>

        <div className={styles.next_pass}>
          <NavigateArrowRigth />
        </div>
      </div>
      <div className={styles.container_content}>
        {items.map((item, index) => (
          <div key={item.id} className={styles.container_image_example}>
            <div className={styles.box_image}>
              <img
                src={imageUrl ?? item.image}
                alt=""
                className={styles.image}
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <div className={styles.box_subtitle}>
              <p className={styles.box_subtitle_title}>
                {headlineList[index] ? headlineList[index] : headlineText}
              </p>
              <div className={styles.box_select}>
                <SelectRound
                  htmlFor="select"
                  placeholder="Selecione"
                  defaultSelected={{
                    label: 'Selecione o tipo de publicação',
                    value: '',
                  }}
                  options={[
                    { label: 'Teste', value: 1 },
                    { label: 'Teste2', value: 2 },
                  ]}
                />
              </div>

              <div className={styles.box_button_subtitle_refresh}>
                <div
                  className={styles.button_subtitle_refresh}
                  onClick={() => legendGenerate()}
                >
                  <WhiteRefresh width={'24px'} height={'24px'} />
                </div>

                <div
                  className={styles.button_subtitle_refresh}
                  onClick={() => legendUpdate()}
                >
                  <Pen2 color="#190027" width={'24px'} height={'24px'} />
                </div>
              </div>

              <div className={styles.box_description_post}>
                {/* <p className={styles.description_text}>{legend}</p> */}

                <textarea
                  className={
                    !activeImputLegend
                      ? styles.input_legend_readOnly
                      : styles.input_legend
                  }
                  readOnly={!activeImputLegend}
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  onChange={(e) => handleLegendChange(e.target.value)}
                  value={legend}
                />
              </div>

              <div className={styles.box_avaliacao}>
                {/* {[...Array(items[currentIndex].avaliacao)].map((_, index) => (
                  ))} */}
                {stars.map((index) => (
                  <ButtonFavoriteStarsPress
                    key={`star_${index}`}
                    color={'#000'}
                    isActive={index <= activeIndex}
                    onClick={() => onClickStar(index)}
                  />
                ))}
              </div>
            </div>

            <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal}>
              <CalendarModal
                handleCloseModal={handleCloseModal}
                item={item}
                purposeOfPublicationRef={purposeOfPublicationRef}
                publicationFormatRef={publicationFormatRef}
                productRef={productRef}
                targetAudienceRef={targetAudienceRef}
                iWantPublicationRef={iWantPublicationRef}
                imageUrl={imageUrl}
                imageLayers={imageLayers}
                setImageUrl={setImageUrl}
                headlineText={headlineText}
                setHeadlineText={setHeadlineText}
                legend={legend}
                setHeadlineList={setHeadlineList}
                headlineList={headlineList}
                setLegendList={setLegendList}
                legendList={legendList}
                //dados da tela anterior
                repost={repost}
                draftModalData={draftData}
              />
            </ModalComponent>
          </div>
        ))}
      </div>
      <div className={styles.button_nav}>
        <button
          className={styles.button_back}
          onClick={() => {
            nextButton('back')
          }}
        >
          <p className={styles.button_title}>Voltar</p>
        </button>

        <div
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          <button
            className={styles.button_next}
            onClick={() => {
              setStep('stepFinish')
            }}
          >
            <p className={styles.button_title}>Novo Finalizar agendamento</p>
          </button>

          <button className={styles.button_next} onClick={handleOpenModal}>
            <p className={styles.button_title}>Finalizar agendamento</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepFourthPass
