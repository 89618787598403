import { motion } from 'framer-motion'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import {
  Clips,
  Folder,
  Pen2,
  Picture,
  Stars,
  Trash,
} from '../../../../assets/svgs/icons'
import ModalComponent from '../../../ModalComponent'
import ModalArtiificialInteligency from '../../../../pages/NewPostFormPage/StepSecondPass/ModalArtificalInteligency'
import ModalUploads from '../../../../pages/NewPostFormPage/StepSecondPass/ModalUploads'
import ModalGalery from '../../../../pages/NewPostFormPage/StepSecondPass/ModalGalery'
import { PythonService } from '../../../../services'
export const ImageStep = ({ imageLayers, setImageLayers }) => {
  const [filesSelectedArray, setFilesSelectedArray] = useState([imageLayers])
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalAIOpen, setModalAIOpen] = useState(false)
  const [isModalUploadsOpen, setModalUploadsOpen] = useState(false)

  function onFileChange(e) {
    var imageLayersTemp = { ...imageLayers }

    const file = e.target.files[0] // Obtenha o primeiro arquivo do array de arquivos

    if (file) {
      const reader = new FileReader()

      reader.onload = (event) => {
        let base64String = event.target.result

        base64String = base64String.replace(/^data:image\/[a-z]+;base64,/, '')

        const lewLayer = {
          srcSemTratativa: base64String,
          isOriginal: true,
          order: 1,
          position: [],
          sequenceEffects: [],
          type: 'image-upload',
        }

        // Altere a propriedade imageId para 'validado'
        imageLayersTemp.camadas[0] = lewLayer
      }

      reader.readAsDataURL(file)
    }
    setImageLayers(imageLayersTemp)
  }

  // const handleRemoveFile = (index) => {
  //   var indice = filesSelectedArray.indexOf(index)
  //   filesSelectedArray.splice(indice, 1)

  //   setFilesSelectedArray((current) => [...current])
  // }

  const handleRemoveLayer = (index) => {
    // Crie uma cópia do array para evitar modificar o estado diretamente
    var imageLayersTemp = { ...imageLayers }

    // Remova o objeto usando splice
    if (index >= 0 && index < imageLayersTemp.camadas.length) {
      imageLayersTemp.camadas.splice(index, 1)

      // Agora, imageLayersTemp não contém mais o objeto na posição especificada
    } else {
      console.log('Índice fora do intervalo do array.')
    }

    setImageLayers(imageLayersTemp)
  }

  const handleUpdateLayer = (e, index) => {
    var imageLayersTemp = { ...imageLayers }

    const file = e.target.files[0] // Obtenha o primeiro arquivo do array de arquivos

    if (file) {
      const reader = new FileReader()

      reader.onload = (event) => {
        let base64String = event.target.result

        base64String = base64String.replace(/^data:image\/[a-z]+;base64,/, '')

        if (index >= 0 && index < imageLayersTemp.camadas.length) {
          // Altere a propriedade imageId para 'validado'
          imageLayersTemp.camadas[index].srcSemTratativa = base64String
        }
      }

      reader.readAsDataURL(file)
    } else {
      console.log('Nenhum arquivo selecionado.')
    }

    setImageLayers(imageLayersTemp)
  }

  const handleOpenModal = (modal) => {
    if (modal === 'galeria') {
      setModalOpen(true)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(true)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(true)
    }
  }

  const handleCloseModal = (modal) => {
    // setModalOpen(false)

    if (modal === 'galeria') {
      setModalOpen(false)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(false)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(false)
    }
  }

  const handleRemoveBackground = async (index, camada) => {
    // return
    const { data } = await PythonService.postRemoveBackground({
      image: `data:image/png;base64,${camada?.srcSemTratativa}`,
    })
    const noBgImage = data.data.images

    // Dividir a string base64 para separar o cabeçalho da imagem
    const parts = noBgImage[1]?.image?.split(',')

    const base64Data = parts[1]

    //return
    filesSelectedArray[0].camadas[index].srcSemTratativa = base64Data
    filesSelectedArray[0].camadas[index].isOriginal = noBgImage[1]?.isOriginal

    setImageLayers(...filesSelectedArray)
  }

  useEffect(() => {
    setImageLayers(imageLayers)
    console.log('RENDER!!', imageLayers)
  }, [imageLayers])

  return (
    <motion.div
      className={styles.animated_container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className={styles.imageUploadComponentContainer}>
        <span className={styles.label}>Imagem</span>

        {imageLayers?.camadas?.length === 0 && (
          <div className={styles.container_box_image}>
            <ModalComponent
              isOpen={isModalOpen}
              onClose={() => handleCloseModal('galeria')}
              className={styles.teste}
            >
              <ModalGalery
                // setImageGaleryArray={setImageGaleryArray}
                closeModal={handleCloseModal}
              />
            </ModalComponent>

            <ModalComponent
              isOpen={isModalAIOpen}
              onClose={() => handleCloseModal('inteligenciaArtificial')}
              className={styles.teste}
            >
              <ModalArtiificialInteligency
                // setImageAIArray={setImageAIArray}
                simpleEditModal={true}
                imageLayers={imageLayers}
                setImageLayers={setImageLayers}
                closeModal={handleCloseModal}
              />
            </ModalComponent>

            <ModalComponent
              isOpen={isModalUploadsOpen}
              onClose={() => handleCloseModal('uploads')}
              className={styles.teste}
            >
              <ModalUploads
                closeModal={handleCloseModal}
                // setImageUploadArray={setImageUploadArray}
                simpleEditModal={true}
                imageLayers={imageLayers}
                setImageLayers={setImageLayers}
              />
            </ModalComponent>

            <label htmlFor="upload">
              <div className={styles.box_image}>
                <div className={styles.box_image_svg}>
                  <Clips width={'20px'} height={'20px'} />
                </div>
                <div className={styles.box_image_text}>
                  <p className={styles.box_image_title}>Galeria</p>
                </div>
              </div>
            </label>
            <input
              style={{ display: 'none' }}
              type="file"
              id="upload"
              onChange={onFileChange}
            />
            <div
              onClick={() => handleOpenModal('galeria')}
              className={styles.box_image}
            >
              <div className={styles.box_image_svg}>
                <Picture width={'25px'} height={'25px'} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>Banco de imagens</p>
              </div>
            </div>

            <div
              className={styles.box_image}
              onClick={() => handleOpenModal('inteligenciaArtificial')}
            >
              <div className={styles.box_image_svg}>
                <Stars width={'25px'} height={'25px'} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>
                  Inteligência Artificial
                </p>
              </div>
            </div>

            {/* <div
              className={styles.box_image}
              onClick={() => handleOpenModal('uploads')}
            >
              <div className={styles.box_image_svg}>
                <Folder width={'25px'} height={'25px'} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>Meus Uploads</p>
              </div>
            </div> */}
          </div>
        )}
        {/* {filesSelectedArray.map((image, index) => (
          <div key={index} className={styles.container_list_image}>
            <div className={styles.item_list_image}>
              <img
                src={image.fileDataBase64}
                alt="file-preview"
                style={{ width: 40, height: 40 }}
              />
              <div className={styles.box_data_info}>
                <p className={styles.box_data_info_title}>{image.name}</p>
                <p className={styles.box_data_info_size}>
                  Tamanho &nbsp;
                  <b> {Math.round(Number(image.size) / (1024 * 1024))} mb</b>
                </p>
              </div>
            </div>
            <div className={styles.item_list_image_buttons}>
              <button
                className={styles.button_cancel}
                onClick={() => handleRemoveFile(index)}
              >
                <Trash />
              </button>
              <button className={styles.button_remove_background}>
                <Pen2 />
              </button>
            </div>
          </div>
        ))} */}

        <div className={styles.container_list_layers}>
          {imageLayers?.camadas?.map((camada, index) => (
            <div key={camada.id} className={styles.container_list_image}>
              <div className={styles.item_list_image}>
                <img
                  src={`data:image/png;base64,${camada?.srcSemTratativa}`}
                  alt="file-preview"
                  style={{ width: 40, height: 40, objectFit: 'cover' }}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className={styles.box_data_info}>
                  <p className={styles.box_data_info_title}>{camada?.type}</p>
                  <p className={styles.box_data_info_size}>Tamanho &nbsp;</p>
                </div>
              </div>
              <div className={styles.item_list_image_buttons}>
                <button
                  className={styles.button_cancel}
                  onClick={() => handleRemoveLayer(index)}
                >
                  <Trash />
                </button>
                <label htmlFor={`uploadLayer-${index}`}>
                  <div
                    className={styles.button_remove_background}
                    //onClick={() => handleUpdateLayer(index)}
                  >
                    <Pen2 />
                  </div>
                </label>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id={`uploadLayer-${index}`}
                  onChange={(e) => handleUpdateLayer(e, index)}
                  //onClick={() => handleUpdateLayer(index)}
                />

                <button
                  className={styles.button_cancel}
                  onClick={() => handleRemoveBackground(index, camada)}
                >
                  <Stars />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  )
}
