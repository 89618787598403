import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import {
  BusinessService,
  EditorialLineService,
  ProductService,
  PythonService,
  TargetAudienceService,
  UserService,
  VoiceToneService,
} from '../../../services'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Loading from '../../../components/Loading'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'
import { FooterNaviBar } from '../../../components/FooterNaviBar'

const STEPS = {
  STEP_PASS_BPS: 'stepBPS',
  STEP_PASS_PUBLICATION_INFO: 'stepPublicationInfo',
}
function StepVoiceTone({ setVoiceTone, voiceTone, setStep }) {
  const [campaign, setCampaign] = useState('')
  const [tones, setTones] = useState([])
  const [toneSelectedId, setToneSelectedId] = useState(undefined)
  const [voiceToneSelected, setVoiceToneSelected] = useState(undefined)
  const [send, setSend] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const storage = window.localStorage

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: true,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: true,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: false,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: false,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: false,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false,
    },
  ]

  const [first, setfirst] = useState('')

  useEffect(() => {
    // carregue os valores de tons vindo do banco de dados caso a resposta seja 200
    ;(async () => {
      const VoiceToneData = await VoiceToneService.getAllVoiceToneByBusiness()

      // console.log('localStorage.setItem', localStorage.i18nextLng)
      if (VoiceToneData.responseStatus === 200) {
        if (VoiceToneData.data) {
          setTones(
            VoiceToneData.data.map((item) => ({
              name: item.tone,
              description: item.description,
            }))
          )

          setSend(true)
        }

        if (VoiceToneData.data.length > 0) {
          setToneSelectedId(VoiceToneData.data[0].uuid)
        }
      }
    })()
  }, [])

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (voiceTone === '') {
        notifyErrorCustom(t('fast_publishing_choose_a_voice_tone'))
        return
      }
      saveEditorialLine()
    } else {
      setStep(STEPS.STEP_PASS_BPS)
    }
  }
  const handleVoiceCampaim = () => {
    if (campaign === '') {
      notifyErrorCustom(t('fast_publishing_enter_your_service_description'))
      return
    }
    loadVoiceCampaim()
  }

  const saveEditorialLine = async () => {
    setLoading(true)

    let retEditorialLine

    let getEditorial = []

    // if (!clientData?.uuid) {
    getEditorial = await EditorialLineService.getEditorialLinebyLogin()

    const business = await BusinessService.getBusinessByLogin()
    const product = await ProductService.getProductByNotBusinessClient()
    const targetAudience =
      await TargetAudienceService.getAllTargetAudienceByNotClientId(
        storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
      )
    const voiceTune = await VoiceToneService.getVoiceToneByBusiness(
      business.data[0].uuid
    )

    const data = {
      flow: 'editorial_line',
      prompt: 'editorial_line',
      business_name: business.data[0].description,
      // niche: business.data[0].BusinessNiche.name,
      niche:
        storage.i18nextLng == 'en'
          ? business.data[0].BusinessNiche.descriptionEn
          : business.data[0].BusinessNiche.description,
      subniche: business.data[0].subNiche,
      business_description: business.data[0].description,
      product: {
        name: product?.data[0]?.name ?? '',
        type: product?.data[0]?.productTypeValue ?? '',
        description: product?.data[0]?.description ?? '',
      },
      target_public: {
        name: targetAudience?.data[0]?.name ?? '',
        gender: targetAudience?.data[0]?.genderValue ?? '',
        social_class:
          targetAudience?.data[0]?.socialClassValue.join(', ') ?? '',
        profession: targetAudience?.data[0]?.profession ?? '',
        region: targetAudience?.data[0]?.stateValue ?? '',
      },
      voice_tone: voiceTune?.data[0]?.tone ?? '',
      voice_tone_description: voiceTune?.data[0]?.description ?? '',
      language:
        localStorage.i18nextLng == 'en' ? localStorage.i18nextLng : 'pt',
    }

    retEditorialLine = await PythonService.postPromptGPT(data)
    // }
    // else {
    //   getEditorial = await EditorialLineService.getEditorialLineByClient(
    //     clientData?.uuid
    //   )

    //   const business = await BusinessService.getBusinessByClientId(
    //     clientData?.uuid
    //   )
    //   const product = await ProductService.getProductByBusinessClientId(
    //     clientData?.uuid
    //   )
    //   const targetAudience =
    //     await TargetAudienceService.getAllTargetAudienceByClientId(
    //       clientData?.uuid
    //     )
    //   const voiceTune = await VoiceToneService.getVoiceToneByBusiness(
    //     business.data[0].uuid
    //   )

    //   const data = {
    //     flow: 'editorial_line',
    //     prompt: 'editorial_line',
    //     business_name: business.data[0].description,
    //     niche: business.data[0].BusinessNiche.name,
    //     subniche: business.data[0].subNiche,
    //     business_description: business.data[0].description,
    //     product: {
    //       name: product.data[0].name,
    //       type: product.data[0].productTypeValue,
    //       description: product.data[0].description,
    //     },
    //     target_public: {
    //       name: targetAudience.data[0].name,
    //       gender: targetAudience.data[0].genderValue,
    //       social_class: targetAudience.data[0].socialClassValue.join(', '),
    //       profession: targetAudience.data[0].profession,
    //       region: targetAudience.data[0].stateValue,
    //     },
    //     voice_tone: voiceTune.data[0].tone,
    //     voice_tone_description: voiceTune.data[0].description,
    //   }
    //   retEditorialLine = await PythonService.postPromptGPT(data)
    // }

    let bodyEditorial

    // if (clientData?.uuid) {
    //   bodyEditorial = {
    //     bodyJson: retEditorialLine.data.data,
    //     description: 'editorial line',
    //     businessClientId: clientData?.uuid,
    //   }
    // } else {
    bodyEditorial = {
      bodyJson: retEditorialLine?.data?.data,
      description: 'editorial line',
    }
    // }

    if (getEditorial?.data?.length > 0) {
      await EditorialLineService.putEditorialLine(
        getEditorial?.data[0]?.uuid,
        bodyEditorial
      )
    } else {
      await EditorialLineService.postEditorialLine(bodyEditorial)
    }

    setLoading(false)
    setStep(STEPS.STEP_PASS_PUBLICATION_INFO)
  }

  const loadVoiceCampaim = async () => {
    setLoading(true)
    // const { responseStatus, data } = await ProductService.getProduct()

    // if (responseStatus !== 200) {
    //   setLoading(false)
    //   return
    // }

    let body = {
      campaign: campaign,
      product: '',
      language:
        localStorage.i18nextLng == 'en' ? localStorage.i18nextLng : 'pt',
    }

    let response = await PythonService.postVoiceCampaign(body)

    if (response) {
      setTones(transform(response?.data?.data))
      //setSend(true)
    }

    setLoading(false)
  }

  function transform(obj) {
    let result = []

    for (let key in obj) {
      result.push({
        name: key,
        description: obj[key],
      })
    }

    return result
  }

  const handleChangeCampaim = (e) => {
    setCampaign(e.target.value)
  }

  const saveVoiceTone = async (data) => {
    let body = {
      tone: data.name,
      description: data.description,
    }

    setVoiceToneSelected(body)

    if (!toneSelectedId) {
      await VoiceToneService.postVoiceTone(body)
    } else {
      const id = toneSelectedId
      await VoiceToneService.putVoiceTone(id, body)
    }

    //setLoading(false)

    //setStep(STEPS.STEP_EFFECTS)
  }
  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          {/* <PreviousPass steps={stepsArray}/> */}

          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>
              {t('fast_publishing_voice_tone_text')}
            </p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
              {t('fast_publishing_voice_tone')}
            </h1>

            <div className={styles.box_input_email}>
              <p>{t('fast_publishing_voice_tone_subtitle')}</p>
              <textarea
                className={styles.input_email}
                type="text"
                value={voiceTone}
                placeholder={t('fast_publishing_voice_tone_placeholder')}
                onChange={handleChangeCampaim}
              />
            </div>
            <div className={styles.box_button_send}>
              <button
                className={styles.button_send}
                onClick={() => {
                  handleVoiceCampaim()
                }}
              >
                {t('fast_publishing_voice_tone_button_send')}
              </button>
            </div>

            <div className={styles.box_opstions_voice_tone}>
              {tones.map((item, index) => (
                <div
                  key={index}
                  className={
                    voiceToneSelected?.description === item?.description
                      ? styles.voice_tone_selected
                      : styles.voice_tone
                  }
                >
                  <p>Tom: {item.name}</p>

                  <p>{item?.description}</p>

                  <div className={styles.box_button_select}>
                    <button
                      className={styles.button_select}
                      onClick={() => {
                        saveVoiceTone(item)
                      }}
                    >
                      {t('fast_publishing_voice_tone_button_select')}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              {t("back_buttom")}
            </button>
            <button
              className={styles.next_button}
              onClick={() => handleNavigate('next')}
            >
              {t('next_buttom')}
            </button>
          </div> */}
          {voiceToneSelected && (
            <FooterNaviBar 
              back={() => handleNavigate('back')} 
              next={() => handleNavigate('next')}
            />
          )}
        </div>
      </motion.div>
    </>
  )
}

export default StepVoiceTone
