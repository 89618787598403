import { useEffect, useState } from 'react'

import styles from './styles.module.css'
import StepEmail from './stepEmail'
import StepVerifyEmail from './stepVerifyEmail'
import StepPassword from './stepPassword'

import xamaLogoazul from '../../assets/logos/xama-logo-azul.png'

const STEPS = {
  STEP_PASS_EMAIL: 'stepEmail',
  STEP_VERIFY_EMAIL: 'stepVerifyEmail',
  STEP_PASS_PASSWORD: 'stepPassword',
}

function ForgotPassword() {
  const [step, setStep] = useState(STEPS.STEP_PASS_EMAIL)
  const [numberStep, setNumberStep] = useState(0)

  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')

  const [pass, setPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  useEffect(() => {
    handleStep()
  }, [numberStep])

  useEffect(() => {
    setStep(STEPS.STEP_PASS_EMAIL)
  }, [])

  const handleStep = () => {
    let newStep = ''

    switch (numberStep) {
      case 1:
        newStep = STEPS.STEP_PASS_EMAIL
        break

      case 2:
        newStep = STEPS.STEP_VERIFY_EMAIL
        break

      case 3:
        newStep = STEPS.STEP_PASS_PASSWORD
        break

      default:
        break
    }

    setStep(newStep)
  }

  return (
    <div className={styles.container_page}>
      <div className={styles.box_logo}>
        <img
          src={xamaLogoazul}
          alt="logo"
          style={{ height: 40 }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      {step === STEPS.STEP_PASS_EMAIL && (
        <StepEmail setEmail={setEmail} email={email} setStep={setStep} />
      )}

      {step === STEPS.STEP_VERIFY_EMAIL && (
        <StepVerifyEmail
          setEmail={setEmail}
          email={email}
          setStep={setStep}
          setCode={setCode}
          code={code}
        />
      )}

      {step === STEPS.STEP_PASS_PASSWORD && (
        <StepPassword
          setPass={setPass}
          setConfirmPass={setConfirmPass}
          pass={pass}
          confirmPass={confirmPass}
          setStep={setStep}
          email={email}
          code={code}
        />
      )}
    </div>
  )
}

export default ForgotPassword
