import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { UserGroup } from '../../../assets/svgs/icons'
import ColorPaletteComponent from './ColorPaletteComponent'
import { notifyErrorCustom } from '../../../utils/notify'
import { BrandingService } from '../../../services'
import { getBusinessByLogin } from '../../../services/business'
import { motion } from 'framer-motion'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'
import { FooterNaviBar } from '../../../components/FooterNaviBar'

const STEPS = {
  STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',

  STEP_PASS_TYPOGRAPHY: 'stepTypography',
}
function StepColorPalette({ setStep, colors, setColors, businessAccountData }) {
  const [branding, setBranding] = useState()

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: false,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: false,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: false,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: false,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: false,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: false,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false,
    },
  ]

  useEffect(() => {
    loadBranding()
  }, [])

  const loadBranding = async () => {
    const { data } = await BrandingService.getAllBrandingByBusinessId(
      businessAccountData.data.uuid
    )
    setBranding(data[0])
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (colors.length < 3) {
        notifyErrorCustom(t('fast_publishing_choose_at_least_3_colors'))
        return
      }

      const responseBusines = await getBusinessByLogin()

      const hasEmptyField = colors.some((item) => !item)

      if (hasEmptyField) {
        notifyErrorCustom(t('fast_publishing_one_field_is_empty'))
        return
      }

      let colorsArray = {
        //businessId: responseBusines?.data[0]?.uuid,
        primaryColor: colors[0],
        secondaryColor: colors[1],
        tertiaryColor: colors[2] ?? '',
        complementaryColor1: colors[3] ?? '',
        complementaryColor2: colors[4] ?? '',

        fontTitleId: '',
        //fontTitleByUploaded: '',

        fontSubtitleId: '',
        //fontSubtitleByUploaded:'',

        fontTextId: '',
        //fontTextByUploaded: '',

        fontTitleByUploaded: false,
        fontSubtitleByUploaded: false,
        fontTextByUploaded: false,
      }

      if (branding) {
        colorsArray = {
          primaryColor: colors[0],
          secondaryColor: colors[1],
          tertiaryColor: colors[2] ?? '',
          complementaryColor1: colors[3] ?? '',
          complementaryColor2: colors[4] ?? '',
          fontTitleId: branding.fontTitleId,
          //fontTitleByUploaded,

          fontSubtitleId: branding.fontSubtitleId,
          //fontSubtitleByUploaded:'',

          fontTextId: branding.fontTextId,
          //fontTextByUploaded,

          fontTitleByUploaded: branding.fontTitleByUploaded,
          fontSubtitleByUploaded: branding.fontSubtitleByUploaded,
          fontTextByUploaded: branding.fontTextByUploaded,
        }

        const { responseStatus } = await BrandingService.putBranding(
          branding.uuid,
          colorsArray
        )
        //return
      } else {
        await BrandingService.postBranding(colorsArray)
      }

      setStep(STEPS.STEP_PASS_TYPOGRAPHY)
    } else {
      setStep(STEPS.STEP_PASS_NICHE_AND_OBJECTIVE)
    }
  }

  const { t } = useTranslation()

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        {/* <PreviousPass steps={stepsArray}/> */}

        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>
            {t('fast_publishing_color_palette_title')}
          </p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
            {t('fast_publishing_color_palette')}
          </h1>
          <ColorPaletteComponent colors={colors} setColors={setColors} />
        </div>
        {/* <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            {t("back_buttom")}
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            {t('next_buttom')}
          </button>

        </div> */}
          <FooterNaviBar 
            back={() => handleNavigate('back')} 
            next={() => handleNavigate('next')}
          />
      </div>
    </motion.div>
  )
}

export default StepColorPalette
