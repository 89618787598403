/* eslint-disable react/prop-types */
import { useState } from 'react'
import styles from './styles.module.css'
import { CircleX, Cross, Interrogation } from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'
import InputIA from '../../../../components/InputIA'

import {
  PythonNodeService,
  PythonService,
  ProductService,
  BrandingService,
  VoiceToneService,
  BusinessService,
  TargetAudienceService,
  FreepikService,
  UserPlanCount,
  SubscriptionService,
} from '../../../../services'

import { Loading } from '../../../../components/Loading'

import {
  notifyErrorCustom,
  notifyWarningCustom,
  // notifySuccessCustom,
} from '../../../../utils/notify'
import { useTranslation } from 'react-i18next'
import ModalWarninFreepik from '../../../../components/ModalWarningFreepik'

const ModalArtiificialInteligency = ({
  setImageAIArray,
  closeModal,
  simpleEditModal,
  setImageLayers,
  imageLayers,
}) => {
  const { t } = useTranslation()

  const [galeyimages, setGaleyimages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])

  const [inputText, setInputText] = useState('')

  const [loading, setLoading] = useState(false)
  const [openModalFreepik, setOpenModalFreepik] = useState(false)

  // const images = [
  //   {
  //     id: 1,
  //     url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
  //     title: 'Comida saudavel',
  //     size: '32mb',
  //     origin: 'imgBank',
  //   },
  // ]

  const handleSearchimage = async () => {
    if (inputText === '') {
      notifyErrorCustom(
        t(
          'new_publication_page_campaign_step_second_pass_modal_ai_error_message'
        )
      )
      setLoading(false)
      return
    }

    const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()
    const mesAtual = new Date(new Date().setDate(1)) // Primeiro dia do mês atual
    mesAtual.setDate(mesAtual.getDate() + 30)
    mesAtual.setHours(0, 0, 0, 0)

    if (!userPlanCount.data) {
      const postUserPlanCount = await UserPlanCount.postUserPlanCount({
        ownScheduleCount: 0,
        postOnDesignCount: 0,
        imageBankCount: 0,
        aiImageCount: 1,
        aiLegendCount: 0,
        aiMessageCount: 0,
        renewalDate:
          userSubscription.data.length > 0
            ? userSubscription.data[0].expiration
            : mesAtual,
      })
    } else {
      const renewalDate = new Date(userPlanCount.data.renewalDate)
      const dataAtual = new Date()

      const expirationPlan =
        userSubscription.data.length > 0
          ? new Date(userSubscription.data[0].expiration)
          : new Date(mesAtual)

      if (
        renewalDate.getTime() < dataAtual.getTime() ||
        renewalDate.getTime() != expirationPlan.getTime()
      ) {
        await UserPlanCount.postUserPlanCount({
          ownScheduleCount: 0,
          postOnDesignCount: 0,
          imageBankCount: 0,
          aiImageCount: 1,
          aiLegendCount: 0,
          aiMessageCount: 0,
          renewalDate:
            userSubscription.data.length > 0
              ? userSubscription.data[0].expiration
              : mesAtual,
        })
      } else {
        if (
          userSubscription.data.length <= 0 &&
          userPlanCount.data.aiImageCount >= 10
        ) {
          notifyWarningCustom(t('plans.user_plan_count_ai_image'))
          setLoading(false)
          return
        } else if (userSubscription.data.length > 0) {
          const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
            (item) => item.type === 'ia_generative_quantity'
          )
          const quantityAiImage =
            userSubscription.data[0].Plan.PlanItems[index]?.quantity

          if (
            userPlanCount.data.aiImageCount >= quantityAiImage &&
            quantityAiImage != null
          ) {
            notifyWarningCustom(t('plans.user_plan_count_ai_image'))
            setLoading(false)
            return
          }
        }
        await UserPlanCount.putUserPlanCount(userPlanCount.data.uuid, {
          ownScheduleCount: userPlanCount.data.ownScheduleCount,
          postOnDesignCount: userPlanCount.data.postOnDesignCount,
          imageBankCount: userPlanCount.data.imageBankCount,
          aiImageCount: userPlanCount.data.aiImageCount + 1,
          aiLegendCount: userPlanCount.data.aiLegendCount,
          aiMessageCount: userPlanCount.data.aiMessageCount,
          renewalDate: userPlanCount.data.renewalDate,
        })
      }
    }

    setGaleyimages(true)

    const body = {
      search: inputText,
      limit: 4,
    }

    const response = await FreepikService.postFreepikImages(body)

    if (response.responseStatus === 200) {
      const images = response.data

      setGaleyimages(images)
    } else {
      setOpenModalFreepik(true)
      // notifyErrorCustom(
      //   t(
      //     'new_publication_page_campaign_step_second_pass_modal_ai_error_message'
      //   )
      // )
    }

    setLoading(false)
  }

  const handleImageClick = (imageUrl) => {
    const imageArray = galeyimages.find(
      (image) => image.imageBase64 === imageUrl.imageBase64
    )

    if (imageArray) {
      const arrayExists = selectedArrayImage.some(
        (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
      )

      if (arrayExists) {
        setSelectedArrayImage(
          selectedArrayImage.filter(
            (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
          )
        )
      } else {
        setSelectedArrayImage([...selectedArrayImage, imageArray])
      }
    }

    if (selectedImages.includes(imageUrl.url)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageUrl.url)
      )
    } else {
      setSelectedImages([...selectedImages, imageUrl.url])
    }
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    if (selectedImages.includes(imageArray.url)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageArray.url)
      )
    } else {
      setSelectedImages([...selectedImages, imageArray.url])
    }
  }

  const addImages = async (selectedArrayImage) => {
    if (simpleEditModal) {
      var imageLayersTemp = { ...imageLayers }

      const newSelectedArrayImage = selectedArrayImage.map((image) => {
        const lewLayer = {
          imageId: image.image,
          isOriginal: true,
          order: 1,
          position: [],
          sequenceEffects: [],
          type: 'image-upload',
        }

        imageLayersTemp.layer[0] = lewLayer
      })

      setImageLayers(imageLayersTemp)
    } else {
      const newSelectedArrayImage = selectedArrayImage.map((image, indice) => {
        return {
          id: indice,
          image: 'data:image/png;base64,' + image.imageBase64,
        }
      })

      setImageAIArray(newSelectedArrayImage)
    }
    closeModal('inteligenciaArtificial')
  }

  const getHeadlineText = async () => {
    const productInfos = await ProductService.getProduct()
    const brandingInfos = await BrandingService.getAllBranding()
    const voiceToneInfos = await VoiceToneService.getAllVoiceTone()
    const businessInfos = await BusinessService.getBusinessByLogin()
    const targetAudiencesInfos =
      await TargetAudienceService.getAllTargetAudience()

    const obj = {
      productInfos: productInfos,
      brandingInfos: brandingInfos,
      voiceToneInfos: voiceToneInfos,
      businessInfos: businessInfos,
      targetAudiencesInfos: targetAudiencesInfos,
    }

    getIaHeadline(obj)
  }

  const getIaHeadline = async (loadbusiness) => {
    let businessData = loadbusiness

    let bodyHeadline = {
      publications_number: 4,
      niche: businessData.businessInfos?.data[0].BusinessNiche?.name,
      subniche: businessData.businessInfos?.data[0].subNiche,
      publication_purpose: 'divulgar',
      description_business: businessData.businessInfos?.data[0].description,
      voice_tone: businessData.voiceToneInfos?.data[0].tone,
      social_class: 'Classe A',
      gender: businessData.targetAudiencesInfos?.data[0]?.genderValue,
      profession: businessData.targetAudiencesInfos?.data[0].profession,
      name_product: businessData.productInfos?.data[0].name,
      product_type: businessData.productInfos?.data[0].productTypeValue,
      product_description: businessData.productInfos?.data[0].description,
      value: businessData.productInfos?.data[0].value,
      promotional_value: businessData.productInfos?.data[0].promotionalValue,
    }

    const headline = await PythonService.postHeadline(bodyHeadline)

    if (headline?.data.status === 201) {
      setInputText(headline?.data?.data[0]?.headline)
      setLoading(false)
    } else {
      setLoading(false)
      notifyErrorCustom(
        t(
          'new_publication_page_campaign_step_second_pass_modal_ai_error_headline'
        )
      )
      return
    }
  }
  const handleCloseModal = () => {
    setOpenModalFreepik(false)
  }

  return (
    <div className={styles.modal}>
      <Loading enable={loading} />
      <ModalWarninFreepik
        isOpen={openModalFreepik}
        onClose={() => handleCloseModal()}
        handleCloseModal={handleCloseModal}
        type={'ia'}
      />

      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('inteligenciaArtificial')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2>
          {t('new_publication_page_campaign_step_second_pass_modal_ai_title')}{' '}
          <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          {t(
            'new_publication_page_campaign_step_second_pass_modal_ai_subtitle'
          )}
        </p>
      </div>
      <div className={styles.container_modal}>
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <InputIA
              placeholder={t(
                'new_publication_page_campaign_step_second_pass_modal_ai_input_placeholder'
              )}
              variant={'light'}
              text={inputText}
              setText={setInputText}
              setLoading={() => {}}
              clickFunction={() => {
                setLoading(true)
                getHeadlineText()
              }}
            />

            <button
              className={styles.button_send}
              onClick={() => {
                setLoading(true)
                handleSearchimage()
              }}
            >
              {t(
                'new_publication_page_campaign_step_second_pass_modal_ai_send_button'
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={styles.container_galeyimages}>
        {galeyimages.length > 0 && (
          <Masonry columnsCount={4} gutter="10px">
            {galeyimages.map((imageUrl, i) => (
              <img
                key={i}
                src={'data:image/png;base64,' + imageUrl?.imageBase64}
                onClick={() => handleImageClick(imageUrl)}
                className={
                  selectedImages.includes(imageUrl?.imageBase64)
                    ? styles.selectedImage
                    : styles.image
                }
                onContextMenu={(e) => e.preventDefault()}
                // alt="file-preview"
              />
            ))}
          </Masonry>
        )}
      </div>

      {selectedArrayImage.length !== 0 && (
        <div className={styles.contaier_caroucel_list_selected_image}>
          <p>
            {t(
              'new_publication_page_campaign_step_second_pass_modal_ai_selected_images'
            )}
          </p>
          <div className={styles.caroucel_list_selected_image}>
            {selectedArrayImage.map((imageArray) => (
              <div
                className={styles.card_lis_selected_image}
                key={imageArray.id}
              >
                <div className={styles.container_card_lis_selected_image}>
                  <div
                    className={styles.delete_card_lis_selected_image}
                    onClick={() => handleRemoveImage(imageArray)}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <CircleX
                        color={'#FF2F00'}
                        width={'15px'}
                        height={'15px'}
                      />
                    </div>
                  </div>

                  <img
                    className={styles.item_selected_image}
                    src={'data:image/png;base64,' + imageArray.imageBase64}
                    onContextMenu={(e) => e.preventDefault()}
                    // alt={imageArray.title}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedArrayImage.length !== 0 && (
        <button
          className={styles.button_add_image}
          onClick={() => {
            addImages(selectedArrayImage)
          }}
        >
          {t(
            'new_publication_page_campaign_step_second_pass_modal_ai_add_images_button'
          )}
        </button>
      )}
    </div>
  )
}

export default ModalArtiificialInteligency
