import styles from './styles.module.css'
import PropTypes from 'prop-types'
import Efect01 from '../../assets/imgs/Effect01.png'
import { Fragment } from 'react'
import Lottie from 'lottie-react'
import lazyLoadingAnimation from '../../assets/lottie/lazy-loading-animation.json'

const NincheCard = ({ values }) => {
  return (
    <div className={styles.ninche_card_container}>
      {!values || Object?.keys(values)?.length ? (
        <Fragment>
          <div className={styles.image_container}>
            <img
              src={`data:image/png;base64,${values.imageUrl}` ?? Efect01}
              alt="Ninche Image"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
          <div className={styles.values_container}>
            <ul>
              <li>
                {values?.category?.length > 30
                  ? values.category.substring(0, 30) + '...'
                  : values.category}
              </li>
              {/* <li>{values?.modelName?.length > 30 ? values.modelName.substring(0, 30) + '...' : values.modelName}</li> */}
              {/* <li>{values?.description?.length > 40 ? values.description.substring(0, 40) + '...' : values.description}</li> */}
              <div className={styles.textContainer}>
                <p>{values.description}</p>
              </div>
            </ul>
          </div>
        </Fragment>
      ) : (
        <span className={styles.ninche_card_loading}>
          <Lottie
            animationData={lazyLoadingAnimation}
            style={{ width: 150, height: 150, margin: 'auto' }}
            loop={true}
            autoplay={true}
          />
        </span>
      )}
    </div>
  )
}

export default NincheCard

NincheCard.propTypes = {
  values: PropTypes.shape({
    id: PropTypes.string,
    category: PropTypes.string,
    modelName: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
}
