/* eslint-disable react/prop-types */

import { useRef, useState, useEffect } from 'react'
import styles from './styles.module.css'

import lazyLoadingAnimation from '../../assets/lottie/lazy-loading-animation.json'
import Lottie from 'lottie-react'

import SimpleEditionModal from '../../components/SimpleEditionModalTemplate'
import {
  BusinessClient,
  BusinessUser,
  ProductService,
  SubscriptionService,
  TargetAudienceService,
} from '../../services'

const CarouselDashboard = ({
  items,
  setIsModalOpen,
  setImageUrl,
  setImageLayers,
  setIndexModalSelected,
  setBlockDowngrade,
}) => {
  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [dragThreshold, setDragThreshold] = useState(5) // Threshold to differentiate between drag and click
  const [isClick, setIsClick] = useState(true) // Track if the action is click or drag

  useEffect(() => {}, [])

  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
    setIsClick(true) // Assume it's a click initially
  }

  const handleMouseMove = (event) => {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset

    // If the mouse moves more than the drag threshold, it's a drag, not a click
    if (Math.abs(event.clientX - startX) > dragThreshold) {
      setIsClick(false)
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
  }
  const handleValidBlockDowngrade = async (userSubscription) => {
    let blockDowngrade = false
    const productCount = await ProductService.getAllproductCount()

    if (userSubscription.data.length <= 0 && productCount.data.count > 30) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'product_quantity'
      )
      const quantityProduct =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        productCount.data.count > quantityProduct &&
        quantityProduct != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessTargetAudienceCount =
      await TargetAudienceService.getAllTargetAudienceCount()

    if (
      userSubscription.data.length <= 0 &&
      businessTargetAudienceCount.data.count > 30
    ) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'target_audience_quantity'
      )
      const quantityTargetAudience =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        businessTargetAudienceCount.data.count > quantityTargetAudience &&
        quantityTargetAudience != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessUserCount = await BusinessUser.getAllBusinessUserCount()
    if (userSubscription.data.length <= 0 && businessUserCount.data.count > 1) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const indexUser = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'user_quantity'
      )
      const quantityItemUser =
        userSubscription.data[0].Plan.PlanItems[indexUser].quantity

      if (businessUserCount.data.count > quantityItemUser) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessClientCount = await BusinessClient.getAllBusinessClientCount()

    if (
      userSubscription.data.length <= 0 &&
      businessClientCount.data.count > 1
    ) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'client_quantity'
      )
      const quantityClient =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        businessClientCount.data.count > quantityClient &&
        quantityClient != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    return blockDowngrade
  }

  const handleItemClick = async (item, index) => {
    const userSubscription = await SubscriptionService.getSubscriptionByUser()
    const blockDowngrade = await handleValidBlockDowngrade(userSubscription)

    if (blockDowngrade) {
      setBlockDowngrade(true)
      return
    }

    if (isClick) {
      setIsModalOpen(true)
      setImageUrl('data:image/png;base64,' + item?.srcComTratativa)
      setImageLayers(item)
      setIndexModalSelected(index)
    }
  }

  const renderItems = () => {
    return (
      <>
        {items?.map((item, index) => (
          <div className={styles.carousel_items} key={index} draggable="false">
            <div className={styles.carousel_container_image} draggable="false">
              <div className={styles.slide_container} draggable="false">
                {!item?.srcComTratativa && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                      }}
                    >
                      <Lottie
                        animationData={lazyLoadingAnimation}
                        style={{ width: 100, height: 100 }}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  </div>
                )}
              </div>

              {item?.srcComTratativa && (
                <div
                  style={{
                    display: 'flex',
                    cursor: 'pointer',

                    // backgroundColor: 'red',

                    width: '100%',
                    height: '100%',
                  }}
                  onClick={() => handleItemClick(item, index)}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '20px',
                    }}
                    src={'data:image/png;base64,' + item?.srcComTratativa}
                    alt={item?.alt}
                    draggable="false"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </div>
              )}
            </div>

            <div className={styles.box_button}></div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div
      className={styles.carousel_container}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      draggable="false"
    >
      <div className={styles.carousel} ref={carouselRef}>
        {renderItems()}
      </div>
    </div>
  )
}

export default CarouselDashboard
