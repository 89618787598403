/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import {
  CircleFacebook,
  CalendarIcon,
  DatepikerWhite,
  Hamburger,
  Instagram,
  Linkedin,
  NumberOne,
  NumberThree,
  NumberTwo,
  //Pinterest,
  Refresh,
  Twitter,
  Cross,
  CircleX,
  ArrowUp,
  ChevronDown,
  NumberFour,
  BoxChecket,
  BoxNotChecket,
  Facebook,
  XTwitter,
  EditPencil, Trash,
} from '../../../assets/svgs/icons'
import { useState, useEffect } from 'react'
import { TikTok } from '../../../assets/svgs/icons'
import ModalComponent from '../../../components/ModalComponent'
import CalendarModal from '../../../components/CalendarModal'

import { Loading } from '../../../components/Loading'
import { motion } from 'framer-motion'

import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import {
  BusinessService,
  LogosService,
  PublicationService,
  PythonService,
  PlanService,
  TiktokService,
  UserPlanCount,
  SubscriptionService,
} from '../../../services'

import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'
import { MyUploadsService } from '../../../services'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
}

function StepFifthPass({
  setStep,
  selectedArrayImage,
  setSelectedArrayImage,
  selectedArrayImageObject,

  selectedCampaignOption,
  legendList,
  headlineList,
  stateLegend,
  selectedImagesLogo,
  setSelectedImagesLogo,

  //---
  imagesSelectedsArrayObj,
  setImagesSelectedsArrayObj,
  imagesSelectedId,
  editSelectedId,
  imageLayers,
  publicationIdRef,
  headlineGpt,
  publicationTypePost,
  facebookPageToken,

  // setTiktokMusic,
  // tiktokMusic,
  // setTiktokPrivacyLevel,
  // tiktokPrivacyLevel,
  // setTiktokComment,
  // tiktokComment,

  // selectedObjectItem,
}) {
  const { clientData } = useClient()
  const { t } = useTranslation()
  const navigate = useNavigate()

  // const [imagesSelectedsArrayObj, setImagesSelectedsArrayObj] = useState([])
  const [draggingIndex, setDraggingIndex] = useState(null)

  const [isModalOpen, setModalOpen] = useState(false)
  const [itemModal, setItemModal] = useState()

  const [loading, setLoading] = useState(false)

  const [socialMediaEvent, setSocialMediaEvent] = useState([])

  const [addLogo, setAddLogo] = useState(false)

  //const [selectedImagesLogo, setSelectedImagesLogo] = useState([])

  const [selectedValues, setSelectedValues] = useState({})
  const selectedValuesString = Object.keys(selectedValues).toString() || ''
  const [uploadProgress, setUploadProgress] = useState(0)


  const socialMediaVerification = (socialTypes = '', item) => {
    const socialTypesArray = socialTypes ? socialTypes.split(',') : []

    // Mapeia cada tipo para o componente correspondente
    const components = socialTypesArray.map((type) => {
      if (type === '1') {
        return (
          <div onClick={() => handleClick(1)}>
            <Linkedin
              key="linkedin" // Adicione uma chave única
              width={25}
              height={25}
              color={item.linkedin.active ? '#00B828' : '#E6E6E6'}
            />
          </div>
        )
      }
      if (type === '2') {
        return (
          <XTwitter
            key="twitter" // Adicione uma chave única
            width={25}
            height={25}
            color={item.linkedin.active ? '#00B828' : '#E6E6E6'}
          />
        )
      }
      if (type === '3') {
        return (
          <TikTok
            key="tiktok" // Adicione uma chave única
            width={25}
            height={25}
            color={item.tiktok.active ? '#00B828' : '#E6E6E6'}
          />
        )
      }
      if (type === '4') {
        return (
          <Instagram
            key="instagram" // Adicione uma chave única
            width={25}
            height={25}
            color={item.instagram.active ? '#00B828' : '#E6E6E6'}
          />
        )
      }
      if (type === '5') {
        return (
          <Facebook
            key="instagram" // Adicione uma chave única
            width={25}
            height={25}
            color={item.facebook.active ? '#00B828' : '#E6E6E6'}
          />
        )
      }

      return null // Retorna null para tipos desconhecidos
    })

    return components // Retorna o array de componentes
  }

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    console.log("Aquiiii hehe =>", editSelectedId.current)
    editSelectedId.current = undefined
    // let imagesSelectedsArrayObj

    if (imagesSelectedsArrayObj.length !== 0) {
      setImagesSelectedsArrayObj(imagesSelectedsArrayObj)
      return
    }

    if (selectedArrayImage?.length != 0 && selectedImagesLogo?.length === 0) {
      imagesSelectedsArrayObj = selectedArrayImage.map((item, index) => {
        if (item?.schedule) {
          return {
            ...item,
          }
        }

        const headline = headlineGpt.data.data.headlines.find(
          (h) =>
            h.id ===
            selectedArrayImageObject[index].tempImageLayers.VariantSelected
        )

        return {
          image: item,
          title: legendList[index],
          id: index,
          linkedin: {
            active: true,
            orientation: null,
          },
          facebook: {
            active: true,
            orientation: null,
          },
          instagram: {
            active: true,
            orientation: null,
          },
          tiktok: {
            active: true,
            orientation: null,
          },
          twitter: {
            active: true,
            orientation: null,
          },
          date: '00/00/0000',
          hour: '00:00',
          funnel: headline.funil,
        }
      })

      setImagesSelectedsArrayObj(imagesSelectedsArrayObj)
    } else {
      imagesSelectedsArrayObj = selectedImagesLogo.map((item, index) => {
        if (item?.schedule) {
          return {
            ...item,
          }
        }

        const headline = headlineGpt.data.data.headlines.find(
          (h) =>
            h.id ===
            selectedArrayImageObject[index].tempImageLayers.VariantSelected
        )

        return {
          image: `data:image/png;base64,${item?.src}`,
          title: legendList[index],
          id: index,
          linkedin: {
            active: true,
            orientation: null,
          },
          facebook: {
            active: true,
            orientation: null,
          },
          instagram: {
            active: true,
            orientation: null,
          },
          tiktok: {
            active: true,
            orientation: null,
          },
          twitter: {
            active: true,
            orientation: null,
          },
          date: '00/00/0000',
          hour: '00:00',
          funnel: headline.funil,
        }
      })

      setImagesSelectedsArrayObj(imagesSelectedsArrayObj)
    }
  }, [selectedImagesLogo])

  const handleClick = (value) => {
    // Verifica se o valor já está no array
    if (selectedValues[value]) {
      // Se já estiver no objeto, remove o valor
      const newValues = { ...selectedValues }
      delete newValues[value]
      setSelectedValues(newValues)
    } else {
      // Se não estiver no objeto, adiciona o valor
      setSelectedValues({ ...selectedValues, [value]: true })
    }
  }

  // const moverParaCima = (index) => {
  //   if (index === 0) return
  //   const novaLista = [...imagesSelectedsArrayObj]
  //   const item = novaLista[index]
  //   novaLista[index] = novaLista[index - 1]
  //   novaLista[index - 1] = item
  //   setImagesSelectedsArrayObj(novaLista)
  // }

  // const moverParaBaixo = (index) => {
  //   if (index === imagesSelectedsArrayObj.length - 1) return
  //   const novaLista = [...imagesSelectedsArrayObj]
  //   const item = novaLista[index]
  //   novaLista[index] = novaLista[index + 1]
  //   novaLista[index + 1] = item
  //   setImagesSelectedsArrayObj(novaLista)
  // }

  // const handleOpenModal = (item) => {
  //   setItemModal(item)
  //   setModalOpen(true)
  // }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)
    }
    if (navigation === 'next') {
      // setStep(STEPS.STEP_FOURTH_PASS_IMAGE)
    }
  }

  // const handleSelectDate = (data) => {
  //   const newImagesSelectedsArrayObj = imagesSelectedsArrayObj.map((item) => {
  //     if (item.id === data.id) {
  //       return {
  //         ...item,
  //         date: data.formatDate,
  //         hour: data.valueHour + ':' + data.valueMinute,
  //         valueHour: data.valueHour,
  //         valueMinute: data.valueMinute,
  //         selectedDate: data.selectedDate,
  //         sketch: data.sketch,
  //         socialTypes: data.socialTypes,
  //       }
  //     }
  //     return item
  //   })

  //   setImagesSelectedsArrayObj(newImagesSelectedsArrayObj)
  // }

  const handleCancelSelectDate = (data) => {
    const newImagesSelectedsArrayObj = imagesSelectedsArrayObj.map((item) => {
      if (item.id === data.id) {
        return {
          ...item,
          date: '00/00/0000',
          hour: '00:00',
          socialTypes: '',
        }
      }
      return item
    })

    setImagesSelectedsArrayObj(newImagesSelectedsArrayObj)
  }

  const returnUploadImageBucket = async (data) => {
    function extractImageData(base64String) {
      // Dividir a string base64 para separar o cabeçalho da imagem
      const parts = base64String.split(',')
      const header = parts[0]
      const base64Data = parts[1]

      // Extrair o formato do arquivo e o tipo de conteúdo
      const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
      const fileContentType = matches[0]
      const fileFormat = fileContentType.split('/')[1]

      // Retornar os dados no formato desejado
      return {
        fileName: 'publication',
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    }

    let body = extractImageData(data)

    // Adicione um log para verificar o corpo

    const publicationImageBucket =
      await PublicationService.postPublicationImage(body)

    if (publicationImageBucket?.responseStatus === 200) {
      return publicationImageBucket?.data
    }
  }

  const handleSetProgress = async (value, stage, numberOfpublications) => {
    setUploadProgress((prevProgress) => {
      const newProgress = prevProgress + value
      console.log('Progress:', {
        previousValue: prevProgress,
        increment: value,
        newTotal: newProgress,
        stage
      })
      return newProgress
    })
  }

  const handleSavePublication = async () => {

    // const [imageIndex, setImageIndex] = useState()
    // const [percent, setPercent] = useState()

    for (const [index, element] of imagesSelectedsArrayObj.entries()) {
      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()
        businessId = data[0]?.uuid
      }

      // const dateSelected = new Date(element.selectedDate)
      // const date = new Date(dateSelected)

      // dateSelected.setHours(element.valueHour)
      // dateSelected.setMinutes(element.valueMinute)

      const dateSelected = dayjs(element.selectedDate)
        .hour(element.valueHour)
        .minute(element.valueMinute)

      let publicationImageBucket = await returnUploadImageBucket(element.image)


      if (publicationImageBucket) {
        let publicationBody = {
          // headline: selectedObjectItem[index]?.params?.filter(
          //   (item) => item.name === 'titulo'
          // )[0].value,
          headline: selectedArrayImageObject[index]?.tempImageLayers?.params
            ? selectedArrayImageObject[index]?.tempImageLayers?.params.filter(
              (item) => item?.name === 'titulo'
            )[0]?.value
            : '',
          legend: legendList[index],
          publicationImageUrl: publicationImageBucket?.fileUrl,
          publicationImageUrlName: publicationImageBucket?.fileName,
          sketch: element?.sketch,
          scheduleDate: dateSelected,
          publicationDate: dateSelected,
          campaignId: selectedCampaignOption?.value,
          objective: selectedCampaignOption?.objective,
          businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
          socialTypes: imagesSelectedsArrayObj[index].socialTypes,
          generateArtJson:
            selectedArrayImageObject[index]?.tempImageLayers || {},
          guestId: JSON.parse(window.localStorage.getItem('userData'))
            .guestUserId,
          publicationMode: publicationTypePost
            ? JSON.stringify(publicationTypePost)
            : 'FEED',
          facebookPageToken: facebookPageToken,
        }


        const moreConfig2 = {
          onUploadProgress: (progressEvent) => {
            console.log('Upload Progress:', progressEvent); // Debug log
            console.log('imagesSelectedsArrayObj.length:', imagesSelectedsArrayObj.length); // Debug log
            const percentCompleted = Math.round(
              ((progressEvent.loaded * 50) / progressEvent.total) / imagesSelectedsArrayObj.length
            )
            console.log('Percent Completed:', percentCompleted); // Debug log
            handleSetProgress(percentCompleted, 'teste1', imagesSelectedsArrayObj.length) // Adicione este estado
          },
        }

        let publicationResponse = await PublicationService.postPublication(
          businessId,
          element.socialTypes,
          publicationBody,
          moreConfig2
        )


        if (publicationResponse?.responseStatus === 200) {
          if (imagesSelectedsArrayObj[index].socialTypes.includes('3')) {
            let bodyTiktokPublication = {
              publicationId: publicationResponse.data.uuid,
              privacyLevel: element.tiktokPrivacyLevel,
              disableComment: !element.tiktokComment,
              musicImage: element.tiktokMusic,
              brandContentToggle: element.tiktokBrandedContent,
              brandOrganicToggle: element.tiktokYourBrand,
            }

            const tiktokPublicationReturn =
              await TiktokService.postTiktokPublication(bodyTiktokPublication)
          }


          publicationIdRef.current = [
            ...publicationIdRef.current,
            { uuid: publicationResponse?.data?.uuid, classification: null },
          ]

          const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
          const userSubscription =
            await SubscriptionService.getSubscriptionByUser()
          const mesAtual = new Date(new Date().setDate(1)) // Primeiro dia do mês atual
          mesAtual.setDate(mesAtual.getDate() + 30)
          mesAtual.setHours(0, 0, 0, 0)


          if (!userPlanCount.data) {
            const postUserPlanCount = await UserPlanCount.postUserPlanCount({
              ownScheduleCount: 0,
              postOnDesignCount: 1,
              imageBankCount: 0,
              aiImageCount: 0,
              aiLegendCount: 0,
              aiMessageCount: 0,
              renewalDate:
                userSubscription.data.length > 0
                  ? userSubscription.data[0].expiration
                  : mesAtual,
            })


          } else {
            const renewalDate = new Date(userPlanCount.data.renewalDate)
            const dataAtual = new Date()
            const expirationPlan =
              userSubscription.data.length > 0
                ? new Date(userSubscription.data[0].expiration)
                : new Date(mesAtual)

            const moreConfig2 = {
              onUploadProgress: (progressEvent) => {
                console.log('Upload Progress:', progressEvent); // Debug log
                const percentCompleted = Math.round(
                  ((progressEvent.loaded * 50) / progressEvent.total / imagesSelectedsArrayObj.length)
                )
                console.log('Percent Completed:', percentCompleted); // Debug log
                handleSetProgress(percentCompleted, 'teste1', imagesSelectedsArrayObj.length) // Adicione este estado
              },
            }
            if (
              renewalDate.getTime() < dataAtual.getTime() ||
              renewalDate.getTime() != expirationPlan.getTime()
            ) {


              await UserPlanCount.postUserPlanCount({
                ownScheduleCount: 0,
                postOnDesignCount: 1,
                imageBankCount: 0,
                aiImageCount: 0,
                aiLegendCount: 0,
                aiMessageCount: 0,
                renewalDate:
                  userSubscription.data.length > 0
                    ? userSubscription.data[0].expiration
                    : mesAtual,
              },
                moreConfig2)


            } else {
              await UserPlanCount.putUserPlanCount(userPlanCount.data.uuid, {
                ownScheduleCount: userPlanCount.data.ownScheduleCount,
                postOnDesignCount: userPlanCount.data.postOnDesignCount + 1,
                imageBankCount: userPlanCount.data.imageBankCount,
                aiImageCount: userPlanCount.data.aiImageCount,
                aiLegendCount: userPlanCount.data.aiLegendCount,
                aiMessageCount: userPlanCount.data.aiMessageCount,
                renewalDate: userPlanCount.data.renewalDate,
              },
                moreConfig2)
            }
          }


        } else {
          notifyErrorCustom(t('stepFifthPass.error.schedulePublication'))
        }
      } else {
        notifyErrorCustom(t('stepFifthPass.error.saveArt'))
      }
    }

    notifySuccessCustom(t('stepFifthPass.success.schedulePublication'))


    setTimeout(() => {
      setStep('stepPublicationSuccess')

    }, 1000)

    setTimeout(() => {

      setLoading(false)

    }, 1000)

  }

  const handleAddLogo = async () => {
    setAddLogo(!addLogo)

    let businessId

    if (addLogo === false) {
      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)

        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()
        businessId = data[0]?.uuid
      }

      const { data: dataLogo, responseStatus: responseStatusLogo } =
        await LogosService.getLogosById(businessId)

      const { data: dataLogoBase64, responseStatus: responseStatusLogoBase64 } =
        await LogosService.getLogosByBase64(dataLogo[0].uuid)

      let parts
      var base64Data = []
      for (let i = 0; i < selectedArrayImage?.length; i++) {
        parts = selectedArrayImage[i].split(',')
        base64Data = [...base64Data, parts[1]]
      }

      const bodyToLogo = {
        art_width: 1080,
        art_height: 1350,
        logo_base64: dataLogoBase64,
        images: base64Data,
      }

      let generateLogoToImagesReturn = await PythonService.postAddLogoToImage(
        bodyToLogo
      )

      setSelectedImagesLogo(generateLogoToImagesReturn?.data?.data)
    } else {
      setSelectedImagesLogo([])
    }
  }

  const handleDragStart = (e, index) => {
    // Inicia o arrastar
    e.dataTransfer.setData('index', index)

    setDraggingIndex(index)
  }

  const handleDragOver = (e, index) => {
    // Permite a soltura
    e.preventDefault()
  }

  const handleDrop = (e, dropIndex) => {
    // Finaliza o arrastar e soltar
    const dragIndex = parseInt(e.dataTransfer.getData('index'))

    // Cria uma cópia da lista atual
    const newList = [...imagesSelectedsArrayObj]

    // Remove o item arrastado da lista
    const draggedItem = newList.splice(dragIndex, 1)[0]

    // Insere o item arrastado na nova posição
    newList.splice(dropIndex, 0, draggedItem)

    // Atualiza o estado com a nova lista ordenada
    setImagesSelectedsArrayObj(newList)

    // Remove a classe de arrastar
    setDraggingIndex(null)
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.container_header}
    >
      <Loading enable={loading}
      //type="circular" progress={uploadProgress}  
      />

      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>
          {t('stepFifthPass.title')}
        </p>
        <p className={styles.title_new_posts_title}>
          {t('stepFifthPass.subtitle')}
        </p>
      </div>
      <div className={styles.title_steps}>
        <div className={styles.first_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>
              {t('stepFifthPass.pass1')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFifthPass.pass_subtitle1')}
            </p>
          </div>
        </div>

        <div className={styles.second_pass}>
          <div>
            <NumberTwo width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>
              {t('stepFifthPass.pass2')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFifthPass.pass_subtitle2')}
            </p>
          </div>
        </div>

        <div className={styles.third_pass}>
          <div>
            <NumberThree width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>
              {t('stepFifthPass.pass3')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFifthPass.pass_subtitle3')}
            </p>
          </div>
        </div>

        <div className={styles.fourth_pass}>
          <div>
            <NumberFour width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>
              {t('stepFifthPass.pass4')}
            </p>
            <p className={styles.first_pass_subtitle}>
              {t('stepFifthPass.pass_subtitle4')}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.container_content}>
        {imagesSelectedsArrayObj.map((item, index) => (
          <div
            key={item.id}
            //className={styles.card_schedule}
            className={`${styles.card_schedule} ${draggingIndex === index ? styles.dragging : ''
              }`}
            draggable="true"
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={(e) => handleDrop(e, index)}
          >
            <div className={styles.box_hamburger}>
              {/* <Hamburger color={'#000'} /> */}
              <div
                style={{
                  padding: '0px 10px',
                  cursor: 'grabbing',
                }}
              >
                <Hamburger color={'#000'} />
              </div>
            </div>
            <div className={styles.box_image_publication}>
              <img
                src={item.image}
                alt={item.id}
                className={styles.image_publication}
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <div className={styles.box_title_publication}>
              <p className={styles.title_publication}>
                {
                  // selectedObjectItem[index]?.params?.filter(
                  //   (item) => item.name === 'titulo'
                  // )[0].value

                  selectedArrayImageObject[
                    index
                  ]?.tempImageLayers?.params.filter(
                    (item) => item?.name === 'titulo'
                  )[0]?.value || ''
                }
              </p>
            </div>
            <div className={styles.box_date}>
              <p className={styles.date_label}>{t('stepFifthPass.date')}</p>
              <p className={styles.date_text}>{item.date}</p>
            </div>
            <div className={styles.box_hour}>
              <p className={styles.hour_label}>{t('stepFifthPass.hour')}</p>
              <p className={styles.hour_text}>{item.hour}</p>
            </div>
            <div className={styles.box_funnel_stage}>
              <p className={styles.funnel_label}>
                {t('stepFifthPass.funnelStage')}
              </p>
              <p className={styles.funnel_text}>{item.funnel}</p>
            </div>
            <div className={styles.box_platforms}>
              <p className={styles.platforms_label}>
                {t('stepFifthPass.platforms')}
              </p>
              <div className={styles.box_platforms_icons}>
                {socialMediaVerification(
                  imagesSelectedsArrayObj[index]?.socialTypes,
                  item
                )}
                {/* {socialMediaEvent.pinterest && (
                  <Pinterest
                    width={25}
                    height={25}
                    color={item.pinterest.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.linkedin && (
                  <Linkedin
                    width={25}
                    height={25}
                    color={item.linkedin.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                
                {socialMediaEvent.facebook && (
                  <CircleFacebook
                    width={25}
                    height={25}
                    color={item.facebook.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.instagram && (
                  <Instagram
                    width={25}
                    height={25}
                    color={item.instagram.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.tiktok && (
                  <TikTok
                    width={25}
                    height={25}
                    color={item.tiktok.active ? '#00B828' : '#E6E6E6'}
                  />
                )}
                {socialMediaEvent.twitter && (
                  <Twitter
                    width={25}
                    height={25}
                    color={item.twitter.active ? '#00B828' : '#E6E6E6'}
                  />
                )} */}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                {item.date !== '00/00/0000' && <div
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    console.log("item =======>", item)
                    console.log("selectedArrayImage ======>", selectedArrayImage)
                    console.log("imagesSelectedsArrayObj ======>", imagesSelectedsArrayObj)

                    imagesSelectedId.current = item.id
                    editSelectedId.current = item
                    setStep('stepFinish')
                  }}
                >
                  <EditPencil width={20} height={20} color={'#000'} />
                </div>}
                <div
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setImagesSelectedsArrayObj(imagesSelectedsArrayObj.filter((item2, index2) => item2.id !== item.id))
                    setSelectedArrayImage(selectedArrayImage.filter((item2, index2) => item2 !== item.image))

                    
                  }}
                >
                  <Trash width={20} height={20} color={'#000'} />
                </div>
              </div>
              <div className={styles.box_button_schedule}>
                {item.date === '00/00/0000' ? (
                  <button
                    className={styles.button_schedule}
                    onClick={() => {
                      imagesSelectedId.current = item.id
                      setStep('stepFinish')
                    }}
                  >
                    {t('stepFifthPass.scheduleButton')} <CalendarIcon />
                  </button>
                ) : (
                  <button
                    className={styles.button_cancel}
                    onClick={() => {
                      handleCancelSelectDate(item)
                    }}
                  >
                    {t('stepFifthPass.cancelButton')}{' '}
                    <CircleX width={20} height={20} color={'#fff'} />
                  </button>
                )}
              </div>
            </div>

          </div>
        ))}

        {/* <div className={styles.box_add_logo} onClick={handleAddLogo}>
          {addLogo ? <BoxChecket /> : <BoxNotChecket borderColor={'#000'} />}
          <p className={styles.box_add_logo_label}>
            Adicionar logo em todas as artes
          </p>
        </div> */}
      </div>

      {/* <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal}>
        <CalendarModal
          handleCloseModal={handleCloseModal}
          item={itemModal}
          imageUrl={itemModal?.image}
          multiplePublication={true}
          handleSelectDate={handleSelectDate}
          socialMediaEvent={socialMediaEvent}
          setSocialMediaEvent={setSocialMediaEvent}
        />
      </ModalComponent> */}

      <div className={styles.button_nav}>
        <button
          className={styles.button_back}
          onClick={() => {
            nextButton('back')
          }}
        >
          <p className={styles.button_title}>
            {t('stepFifthPass.button.back')}
          </p>
        </button>

        <button
          className={styles.button_next}
          onClick={() => {
            let verifyDate = imagesSelectedsArrayObj.find((item) => {
              return item.date === '00/00/0000'
            })

            if (verifyDate) {
              notifyErrorCustom(t('stepFifthPass.error.selectDate'))
              return
            }
            setLoading(true)
            handleSavePublication()
            setUploadProgress(0)

          }}
        >
          <p className={styles.button_title}>
            {t('stepFifthPass.button.next')}
          </p>
        </button>
      </div>
    </motion.div>
  )
}

export default StepFifthPass
