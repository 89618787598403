import { array, func, object } from 'prop-types'
import { CloseIcon, CloundArrowUp } from '../../../assets/svgs/icons'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const DragContainer = ({
  filesSelectedArray,
  onFileChange,
  selectedImage,
  onCloseSelectedImage,
}) => {
  const { t } = useTranslation()
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      if (percentage < 100 && selectedImage) {
        setPercentage((prevState) => prevState + 1)
      }
    }, 1)

    return () => clearInterval(timer)
  }, [selectedImage, percentage])

  const handleClose = () => {
    setPercentage(0)
    onCloseSelectedImage()
  }

  return (
    <div className={styles.modal_container_drag}>
      <div className={styles.modal_drag}>
        {!selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={styles.modal_drag_drop}
          >
            <label htmlFor="arquivo">
              <CloundArrowUp color={'#B900FE'} />
              <h2
                className={
                  filesSelectedArray.length !== 0
                    ? styles.modal_drag_drop_title_short
                    : styles.modal_drag_drop_title
                }
              >
                {t('drag_container.drag_and_drop')}
              </h2>
              <p
                className={
                  filesSelectedArray.length !== 0
                    ? styles.modal_drag_drop_sub_title_short
                    : styles.modal_drag_drop_sub_title
                }
              >
                {t('drag_container.or_select_file')}
              </p>
            </label>
            <input
              type="file"
              name="arquivo"
              id="arquivo"
              onChange={(e) => onFileChange(e)}
            ></input>
          </motion.div>
        )}
        {selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={styles.image_preview_container}
          >
            <img
              width={150}
              height={80}
              src={selectedImage?.fileUrl}
              alt="selected image"
              onContextMenu={(e) => e.preventDefault()}
            />

            <div className={styles.progressbar_container}>
              <div className={styles.label_icon_container}>
                <div className={styles.progressbar_container_text}>
                  <p className={styles.progressbar_label}>
                    {t('drag_container.uploading')}
                  </p>
                  <p className={styles.progressbar_percentage}>{percentage}%</p>
                </div>
                <div onClick={handleClose} className={styles.close_button}>
                  <CloseIcon color="red" />
                </div>
              </div>
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: '100%' }}
                className={styles.progressbar}
              />
            </div>
          </motion.div>
        )}
      </div>
    </div>
  )
}

DragContainer.propTypes = {
  filesSelectedArray: array,
  onFileChange: func,
  selectedImage: object,
  onCloseSelectedImage: func,
}
