/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import SchedulerCalendar from '../../../components/SchedulerCalendar'
import CarouselHours from '../../../components/CarouselHours'

// import { useNavigate } from 'react-router-dom'

import { notifyErrorCustom, notifySuccessCustom } from '../../../utils/notify'
import { useClient } from '../../../hooks/useClient'
import { getBusinessByClientId } from '../../../services/business'

import {
  BusinessService,
  PublicationService,
  // HeadlineHasPublicationService,
} from '../../../services'

import {
  Picture,
  Instagram,
  Linkedin,
  //Pinterest,
  TikTok,
  Twitter,
  Calendar2,
  Subtract,
  PinMapsIcon,
  MorePersonsIcon,
  LinksIcon,
  HashtagIcon,
  FacebookChatIcon,
  Cross,
  Clips,
  XTwitter,
} from '../../../assets/svgs/icons'

import { useState, useEffect } from 'react'

// import { imgBase64Mock } from '../../../assets/mocks/imgMock'

import { ModalComponent } from '../../../components/ModalComponent'
import { ImageRender } from '../../../components/ImageRender'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import dayjs from 'dayjs'

function StepMyPublication({
  setStep,
  publicationIdRef,
  imageUrl,
  setImageUrl,
}) {
  const { clientData } = useClient()
  // const navigate = useNavigate()

  // const [loading, setLoading] = useState(false)
  const [oppenedModalLocale, setOppenedModalLocale] = useState(false)
  const [oppenedModalPersons, setOppenedModalPersons] = useState(false)

  const [oppennedModalLinks, setOppennedModalLinks] = useState(false)
  const [oppennedModalHashtags, setOppennedModalHashtags] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())
  const [valueHour, setValueHour] = useState('')
  const [valueMinute, setValueMinute] = useState('')

  const [selectedValues, setSelectedValues] = useState({})
  const selectedValuesString = Object.keys(selectedValues).toString() || ''

  // const [ImageLogoSrc, setImageLogoSrc] = useState({})

  const [publicationLegend, setPublicationLegend] = useState('')
  // const [selectedArrayImage, setSelectedArrayImage] = useState([])
  // const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {}, [])

  const returnUploadImageBucket = async (data) => {
    function extractImageData(base64String) {
      // Dividir a string base64 para separar o cabeçalho da imagem
      const parts = base64String.split(',')
      const header = parts[0]
      const base64Data = parts[1]

      // Extrair o formato do arquivo e o tipo de conteúdo
      const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
      const fileContentType = matches[0]
      const fileFormat = fileContentType.split('/')[1]

      // Retornar os dados no formato desejado
      return {
        fileName: 'publication',
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      }
    }

    let body = extractImageData(data)

    const publicationImageBucket =
      await PublicationService.postPublicationImage(body, selectedValuesString)

    if (publicationImageBucket?.responseStatus === 200) {
      return publicationImageBucket?.data
    } else {
      console.log(publicationImageBucket?.responseStatus)
    }
  }

  const handleClick = (value) => {
    // Verifica se o valor já está no array
    if (selectedValues[value]) {
      // Se já estiver no objeto, remove o valor
      const newValues = { ...selectedValues }
      delete newValues[value]
      setSelectedValues(newValues)
    } else {
      // Se não estiver no objeto, adiciona o valor
      setSelectedValues({ ...selectedValues, [value]: true })
    }
  }

  const handleSavePublication = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')
      return
    }

    // setLoading(true)

    // const dateSelected = new Date(selectedDate)

    // dateSelected.setUTCHours(valueHour)
    // dateSelected.setUTCMinutes(valueMinute)

    const dateSelected = dayjs(selectedDate).hour(valueHour).minute(valueMinute)

    // const imageUrlBuket = ImageLogoSrc?.src
    //   ? `data:image/png;base64,${ImageLogoSrc?.src}`
    //   : imageUrl

    const imageUrlBuket = imageUrl

    // return

    let publicationImageBucket = await returnUploadImageBucket(imageUrlBuket)

    if (publicationImageBucket) {
      let businessId

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid)
        businessId = data[0]?.uuid
      } else {
        const { data } = await BusinessService.getBusinessByLogin()

        businessId = data[0]?.uuid
      }

      let publicationBody = {
        headline: '',
        legend: publicationLegend,
        publicationImageUrl: publicationImageBucket?.fileUrl,
        publicationImageUrlName: publicationImageBucket?.fileName,
        sketch: false,
        publicationDate: dateSelected,
        scheduleDate: dateSelected,
        objective: '',
        businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
        socialTypes: selectedValuesString,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
      }

      const dataTest = new Date()
      console.log('dataTest', dataTest)

      const publicationResponse = await PublicationService.postPublication(
        businessId,
        selectedValuesString,
        publicationBody
      )

      publicationIdRef.current = [
        { uuid: publicationResponse?.data?.uuid, classification: null },
      ]

      // let headlineHasPublicationBody = {
      //   publicationId: publicationResponse.data.uuid,
      //   purposePublication: '',
      //   publicationLegend: '',
      //   productId: undefined,
      //   targetAudience: targetAudienceRef.current,
      // }

      // const headlinePublicationRetur =
      // await HeadlineHasPublicationService.postHeadlineHasPublication(
      //   headlineHasPublicationBody
      // )

      if (
        publicationResponse?.responseStatus === 200 ||
        publicationResponse?.responseStatus === 204
      ) {
        notifySuccessCustom('Publicação agendada com sucesso!')
        setStep('stepPublicationSuccess')
      } else {
        notifyErrorCustom('Erro ao agendar publicação!')
      }
    } else {
      notifyErrorCustom('Erro ao salvar arte!')
      return
    }

    // setLoading(false)
  }

  const [valueItem, setValueItem] = useState(1)

  const handleSelectedIntem = (item) => {
    // campaignRef.current = item
    setValueItem(item.id)
  }

  const ScheduleInfoField = () => {
    const hours = [
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00 ',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00 ',
      '00:00',
    ]

    return (
      <div
        style={{
          flex: 1,
          flexDirection: 'column',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          //maxHeight: '800px',
          height: 'calc(100vh - 176px)',
          // backgroundColor: 'red',
        }}
      >
        <div className={styles.card}>
          <div className={styles.showPath}>
            <Picture color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Publicar em'}</p>
          </div>

          <div className={styles.iconsField}>
            {/* <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div> */}

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(1)}
            >
              <Linkedin
                color={selectedValues[1] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Instagram color={'#AAAAAA'} width={24} height={24} />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <TikTok color={'#AAAAAA'} width={24} height={24} />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(2)}
            >
              <XTwitter
                color={selectedValues[2] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            {/* <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div> */}
          </div>
        </div>

        <div
          style={{
            marginTop: 30,

            display: 'flex',
            alignItems: 'center',
            gap: 20,
            cursor: 'default',
          }}
          className={styles.selectedModalCard}
        >
          <div
            className={styles.form_container_input}
            style={{
              cursor: 'default',
            }}
          >
            <label
              style={{
                fontSize: 14,
                color: '#190027',
                fontWeight: 500,
              }}
            >
              Legenda
            </label>
            <textarea
              className={styles.textarea}
              placeholder="Digite aqui a legenda da publicação..."
              value={publicationLegend}
              onChange={(e) => {
                setPublicationLegend(e.target.value)
              }}
            ></textarea>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Calendar2 color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Data e Horário'}</p>
          </div>

          <div className={styles.box_calendar}>
            <div
              style={{
                flex: 1,
              }}
            >
              <SchedulerCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <div className={styles.container_hour_carousel}>
              <p className={styles.container_hour_carousel_title}>Horário</p>
              <CarouselHours
                items={hours}
                setValueMinute={setValueMinute}
                valueMinute={valueMinute}
                setValueHour={setValueHour}
                valueHour={valueHour}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Configurações Adicionais'}</p>
          </div>

          <div
            onClick={() => setOppenedModalLocale(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <PinMapsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>Localização</div>
              <div className={styles.selectedModalTextSubtitle}>
                Adicione uma localização
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppenedModalPersons(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <MorePersonsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Marcar pessoas
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Marque pessoas ou empresas a esta publicação
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <FacebookChatIcon color={'#610f92'} size={'24'} />

            <div>
              <div className={styles.selectedModalTextTitle}>
                Receber Mensagens
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Permitam que pessoas te enviem mensagens a partir desta
                publicação (Apenas Facebook)
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalLinks(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <LinksIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Adicionar Links
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Adicione links externos a sua publicação (Apenas Facebook)
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalHashtags(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <HashtagIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Adicionar Hashtags
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Segmente sua publicação para os melhores públicos
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>
              {'Configurações de Privacidade'}
            </p>
          </div>

          <div
            style={{
              marginTop: 30,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>Público</div>
              <div className={styles.selectedModalTextSubtitle}>
                Qualquer pessoa dentro ou fora do Facebook poderá ver sua
                publicação.
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>Restrito</div>
              <div className={styles.selectedModalTextSubtitle}>
                Escolha certas pessoas no Facebook que podem ver sua publicação.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SchedulePhotosField = () => {
    // const carouselRef = useRef(null)
    // const [isDragging, setIsDragging] = useState(false)
    // const [startX, setStartX] = useState(null)
    // const [scrollLeft, setScrollLeft] = useState(0)

    // const CarrouselComponent = () => {
    //   const handleMouseDown = (event) => {
    //     setIsDragging(true)
    //     setStartX(event.clientX - carouselRef.current.offsetLeft)
    //     setScrollLeft(carouselRef.current.scrollLeft)
    //     carouselRef.current.style.scrollBehavior = 'unset'
    //   }

    //   const handleMouseMove = (event) => {
    //     if (!isDragging) return
    //     const x = event.clientX - carouselRef.current.offsetLeft
    //     const scrollOffset = (x - startX) * 1.0
    //     carouselRef.current.scrollLeft = scrollLeft - scrollOffset
    //   }

    //   const handleMouseUp = () => {
    //     setIsDragging(false)
    //     carouselRef.current.style.scrollBehavior = 'smooth'
    //   }

    //   const handleRemoveImage = (imageArray) => {
    //     // setSelectedArrayImage(
    //     //   selectedArrayImage.filter((arr) => arr !== imageArray)
    //     // )
    //     // setSelectedArrayImageObject(
    //     //   selectedArrayImageObject.filter((arr) => arr?.mergedImage !== imageArray)
    //     // )
    //   }

    //   const handleFileSelect = (event) => {
    //     const file = event.target.files[0]
    //     if (file) {
    //       const reader = new FileReader()
    //       reader.onload = (e) => {
    //         // Adicione a lógica para atualizar o selectedArrayImage com a nova imagem
    //         console.log(e.target.result) // Exemplo: console.log da imagem carregada

    //         // setSelectedArrayImage([...selectedArrayImage, e.target.result])
    //         setImageUrl(e.target.result)
    //       }
    //       reader.readAsDataURL(file)
    //     }
    //   }

    //   const handleClick = () => {
    //     const input = document.createElement('input')
    //     input.type = 'file'
    //     input.accept = 'image/*'
    //     input.onchange = handleFileSelect
    //     input.click()
    //   }

    //   return (
    //     <div
    //       style={{
    //         // background: 'red',
    //         height: 90,
    //       }}
    //     >
    //       <div>
    //         <div
    //           className={styles.content_list_selected_image}
    //           // onMouseDown={handleMouseDown}
    //           // onMouseMove={handleMouseMove}
    //           // onMouseUp={handleMouseUp}
    //           // onMouseLeave={handleMouseUp}
    //         >
    //           <div
    //             className={styles.caroucel_list_selected_image}
    //             ref={carouselRef}
    //           >
    //             <div
    //               onClick={handleClick}
    //               style={{
    //                 backgroundColor: '#eae5ee',
    //                 border: '1px solid #440063',
    //                 padding: '20px 28px',

    //                 marginBottom: 6,
    //                 borderRadius: 10,
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',

    //                 cursor: 'pointer',
    //                 height: 90,
    //               }}
    //             >
    //               <div
    //                 style={{
    //                   display: 'flex',
    //                   height: 30,
    //                   width: 30,
    //                   borderRadius: 200,
    //                   backgroundColor: '#a922f5',
    //                   justifyContent: 'center',
    //                   alignItems: 'center',
    //                 }}
    //               >
    //                 <More color={'#fff'} size={24} />
    //               </div>
    //             </div>

    //             {selectedArrayImage?.map((imageArray, index) => (
    //               <div className={styles.card_lis_selected_image} key={index}>
    //                 <div className={styles.container_card_lis_selected_image}>
    //                   <div
    //                     className={styles.delete_card_lis_selected_image}
    //                     onClick={() => handleRemoveImage(imageArray)}
    //                   >
    //                     <div style={{ cursor: 'pointer' }}>
    //                       <CircleX
    //                         color={'#FF2F00'}
    //                         width={'20px'}
    //                         height={'20px'}
    //                       />
    //                     </div>
    //                   </div>
    //                   <img
    //                     className={styles.item_selected_image}
    //                     src={imageArray}
    //                   />
    //                 </div>
    //               </div>
    //             ))}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }

    const handleFileSelect = (event) => {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          // Adicione a lógica para atualizar o selectedArrayImage com a nova imagem
          console.log(e.target.result) // Exemplo: console.log da imagem carregada

          // setSelectedArrayImage([...selectedArrayImage, e.target.result])
          setImageUrl(e.target.result)
        }
        reader.readAsDataURL(file)
      }
    }

    const handleClick = () => {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'image/*'
      input.onchange = handleFileSelect
      input.click()
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: 480,
          marginLeft: 20,
          gap: 20,
        }}
      >
        <div
          style={{
            backgroundColor: imageUrl ? '#fbfbfb' : '#eeeeee',
            border: imageUrl ? 'none' : '0.5px solid #190027',
            borderRadius: imageUrl ? 0 : 30,
            flex: 1,
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {imageUrl ? (
            // <img
            //   src={imageUrl}
            //   style={{
            //     // width: '100%',
            //     // height: '100%',
            //     width: 480,
            //     height: 480,
            //     objectFit: 'contain',
            //     // borderRadius: 10,
            //   }}
            // />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <SelectSquare
                backgroundColor={'#EDEDED'}
                border={true}
                borderColor={'#dadada'}
                placeholder={'Selecione'}
                arrowDirection="down"
                options={[
                  {
                    id: 1,
                    label: 'Previa Feed Do Facebook',
                  },
                  {
                    id: 2,
                    label: 'Previa Feed Do Instagram',
                  },
                  {
                    id: 3,
                    label: 'Previa Stories Do Facebook',
                  },
                  {
                    id: 4,
                    label: 'Previa Stories Do Instagram',
                  },
                ]}
                fontSize={12}
                //  defaultSelected={locationsStatesOptions.find(
                //    (e) => e.value === locationsStatesOptions?.value
                //  )}
                onClickItem={handleSelectedIntem}
              />

              <ImageRender
                image={imageUrl}
                type={valueItem}
                legend={publicationLegend}
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',

                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 20,
              }}
            >
              <div
                className={styles.modal_title}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                }}
              >
                Adicionar nova imagem
              </div>

              <div
                onClick={handleClick}
                style={{
                  padding: 54,
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 20,
                  gap: 4,
                }}
              >
                <Clips color={'#600f93'} width={24} height={24} />
                <div
                  className={styles.modal_title}
                  style={{
                    fontSize: 12,
                    // fontWeight: 500,
                    textAlign: 'center',
                  }}
                >
                  Galeria
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div>{<CarrouselComponent /> }</div> */}
      </div>
    )
  }

  const ButtonsBottomField = () => {
    return (
      <div
        style={{
          height: 70,
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              setImageUrl('')
              setStep('stepFirstPass')
            }}
          >
            <p className={styles.button_title}>Voltar</p>
          </button>

          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <button
              className={styles.button_next}
              onClick={handleSavePublication}
            >
              <p className={styles.button_title}>Finalizar</p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.container_page}>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {ScheduleInfoField()}
          {SchedulePhotosField()}
        </div>

        <ButtonsBottomField />

        <ModalComponent
          isOpen={oppenedModalLocale}
          onClose={() => setOppenedModalLocale(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Localização</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Digite aqui..."
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppenedModalPersons}
          onClose={() => setOppenedModalPersons(false)}
          style={{
            width: 600,
            height: 280,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Marcar Pessoas</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <div className={styles.iconsField} style={{ marginTop: 20 }}>
              {/* <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div> */}

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Linkedin color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Instagram color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <TikTok color={'#AAAAAA'} width={24} height={24} />
              </div>

              {/* <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <XTwitter color={'#AAAAAA'} width={24} height={24} />
              </div> */}
            </div>

            <input
              type="text"
              placeholder="Quem Você gostaria de Marcar?"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalLinks}
          onClose={() => setOppennedModalLinks(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Link</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira um link público e válido aqui"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalHashtags}
          onClose={() => setOppennedModalHashtags(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Hashtags</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira as hashtags e separe as mesmas com uma vírgula (,)"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  )
}

export default StepMyPublication

// StepMyPublication.propTypes = {}
