import { useNavigate } from 'react-router-dom'
import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import React, { useEffect, useState } from 'react'
import {
  BusinessService,
  EditorialLineService,
  NotificationService,
  ProductService,
  PythonService,
  SubscriptionService,
  TargetAudienceService,
  UserAccountsService,
  VoiceToneService,
} from '../../services'

import { Loading } from '../../components/Loading'
import { useClient } from '../../hooks/useClient'
import { useTranslation } from 'react-i18next'

function LoginCompletion() {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const [showPlans, setShowPlans] = useState(false)
  const [loading, setLoading] = useState(false)
  const { clientData } = useClient()
  const [firstLogin, setFirstLogin] = useState()
  useEffect(() => {
    console.log('TAG DATA LAYER ===>')

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'all_settings_completed_screen',
    })

    // loadPlans()
    saveEditorialLine()
    loadFirstLogin()
  }, [])

  const saveEditorialLine = async () => {
    setLoading(true)

    const storage = window.localStorage

    let retEditorialLine
    let getEditorial = []

    if (!clientData?.uuid) {
      getEditorial = await EditorialLineService.getEditorialLinebyLogin()

      const business = await BusinessService.getBusinessByLogin()
      const product = await ProductService.getProductDefault(
        storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
      )
      const targetAudience =
        await TargetAudienceService.getTargetAudiencesDefault(
          storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
        )
      const voiceTune = await VoiceToneService.getVoiceToneByBusiness(
        business.data[0].uuid
      )

      const data = {
        flow: 'editorial_line',
        prompt: 'editorial_line',
        business_name: business.data[0].description,
        niche:
          storage.i18nextLng == 'ptBR'
            ? business.data[0].BusinessNiche.description
            : business.data[0].BusinessNiche.descriptionEn,
        subniche: business.data[0].subNiche,
        business_description: business.data[0].description,
        product: {
          name: product.data.name,
          type: product.data.productTypeValue,
          description: product.data.description,
        },
        target_public: {
          name: targetAudience.data?.name,
          gender: targetAudience.data?.genderValue,
          social_class: targetAudience.data?.socialClassValue.join(', '),
          profession: targetAudience.data?.profession,
          region: targetAudience.data?.stateValue,
        },
        voice_tone: voiceTune.data[0]?.tone,
        voice_tone_description: voiceTune.data[0]?.description,
        language:
          localStorage.i18nextLng == 'ptBR' ? 'pt' : localStorage.i18nextLng,
      }

      retEditorialLine = await PythonService.postPromptGPT(data)
    } else {
      getEditorial = await EditorialLineService.getEditorialLineByClient(
        clientData?.uuid
      )

      const business = await BusinessService.getBusinessByClientId(
        clientData?.uuid
      )
      const product = await ProductService.getProductByBusinessClientId(
        clientData?.uuid
      )
      const targetAudience =
        await TargetAudienceService.getAllTargetAudienceByClientId(
          clientData?.uuid
        )
      const voiceTune = await VoiceToneService.getVoiceToneByBusiness(
        business.data[0].uuid
      )

      const data = {
        flow: 'editorial_line',
        prompt: 'editorial_line',
        business_name: business.data[0].description,
        niche: business.data[0].BusinessNiche.name,
        subniche: business.data[0].subNiche,
        business_description: business.data[0].description,
        product: {
          name: product.data[0].name,
          type: product.data[0].productTypeValue,
          description: product.data[0].description,
        },
        target_public: {
          name: targetAudience.data[0].name,
          gender: targetAudience.data[0].genderValue,
          social_class: targetAudience.data[0].socialClassValue.join(', '),
          profession: targetAudience.data[0].profession,
          region: targetAudience.data[0].stateValue,
        },
        voice_tone: voiceTune.data[0].tone,
        voice_tone_description: voiceTune.data[0].description,
      }
      retEditorialLine = await PythonService.postPromptGPT(data)
    }

    let bodyEditorial

    if (clientData?.uuid) {
      bodyEditorial = {
        bodyJson: retEditorialLine.data.data,
        description: 'editorial line',
        businessClientId: clientData?.uuid,
      }
    } else {
      bodyEditorial = {
        bodyJson: retEditorialLine?.data?.data,
        description: 'editorial line',
      }
    }

    if (getEditorial?.data?.length > 0) {
      await EditorialLineService.putEditorialLine(
        getEditorial?.data[0]?.uuid,
        bodyEditorial
      )
    } else {
      await EditorialLineService.postEditorialLine(bodyEditorial)
    }

    setLoading(false)
  }
  const loadFirstLogin = async () => {
    const { data: dataFirstLogin, responseStatus } =
      await UserAccountsService.getUserAccountsByLogin()

    setFirstLogin(dataFirstLogin)
  }

  const loadPlans = async () => {
    setLoading(true)
    const { data, responseStatus } =
      await SubscriptionService.getSubscriptionByUser()

    if (data.length <= 0) {
      setShowPlans(true)
    }
    setLoading(false)
  }

  const updateFirstLogin = async () => {
    const { ...rest } = firstLogin

    delete rest.uuid
    delete rest.createdAt
    delete rest.updatedAt
    delete rest.deletedAt
    delete rest.PaymentHistory

    rest.firstLogin = false
    rest.photoName = ''
    rest.photoUrl = ''

    const { data, responseStatus } =
      await UserAccountsService.putUserAccountsByLogin(rest)

    await NotificationService.postNotification({
      appointmentNotification: true,
      passwordReset: true,
      notifyUpdate: true,
      PublishedAppointmentNotifications: true,
      NewsSuggestionsUpdates: true,
    })
  }

  async function handleNavigate(destiny) {
    updateFirstLogin()
    if (destiny === 'home') {
      navigation('/HomePage')
    }
    if (destiny === 'publication') {
      navigation('/NewPostPage')
    }
  }

  return (
    <div className={styles.page}>
      <Loading enable={loading} />
      <div className={styles.logo}>
        {/* <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} /> */}
        <img
          src={posteiLogoText}
          alt="posteiLogoText"
          style={{ height: 34 }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div className={styles.divider} />

      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content_title}>
            <h1 style={{ fontSize: 32 }}>{t('login_completion.title')}</h1>
          </div>
          <div className={styles.content_subtitle}>
            <h2 style={{ fontSize: 18 }}>{t('login_completion.subtitle')}</h2>
          </div>
          <div style={{ maxWidth: 580 }}>
            <p style={{ fontSize: 14, marginTop: 26, fontWeight: 500 }}>
              {t('login_completion.description')}
            </p>
          </div>
        </div>
        <div className={styles.container_buttons}>
          <button
            style={{
              backgroundColor: '#fff',
              color: '#190027',
              borderRadius: 4,
              padding: '12px 24px',
              fontSize: 14,
              fontWeight: 500,
            }}
            onClick={() => handleNavigate('home')}
          >
            {t('login_completion.button_start_tour')}
          </button>
          <button
            style={{
              backgroundColor: '#190027',
              border: '1px solid #fff',
              borderRadius: 4,
              padding: '14px 24px',
              fontSize: 12,
            }}
            onClick={() => handleNavigate('publication')}
          >
            {t('login_completion.button_first_publication')}
          </button>
        </div>
      </div>
      {/* )} */}
    </div>
  )
}

export default LoginCompletion
