/* eslint-disable react/prop-types */
import { useState } from 'react'
import styles from './styles.module.css'
// import { CircleX, Cross, Interrogation } from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'

import { CircleX, Cross, Interrogation } from '../../../assets/svgs/icons'
import {
  BrandingService,
  BusinessService,
  ProductService,
  PythonNodeService,
  PythonService,
  TargetAudienceService,
  UserService,
  VoiceToneService,
} from '../../../services'
import Loading from '../../../components/Loading'
import { notifyErrorCustom } from '../../../utils/notify'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'

const ModalCancelPlan = ({
  setImageAIArray,
  closeModal,
  onClose,
  simpleEditModal,
  setImageLayers,
  imageLayers,
}) => {
  const [galeyimages, setGaleyimages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])
  const { logout, userData } = useAuth()

  const [inputText, setInputText] = useState('')

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  // const images = [
  //   {
  //     id: 1,
  //     url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
  //     title: 'Comida saudavel',
  //     size: '32mb',
  //     origin: 'imgBank',
  //   },
  // ]

  const handleCancelPlan = async () => {
    const user = await UserService.getUserByLogin()

    const body = user.data

    delete body.createdAt
    delete body.updatedAt
    delete body.deletedAt

    await UserService.sendEmailcancel(body)
    logout()
    navigate('/CancelPlanPage')

    setLoading(false)
  }

  return (
    <div className={styles.modal}>
      <Loading enable={loading} />

      <div className={styles.header_close}>
        <div className={styles.close_modal} onClick={() => onClose()}>
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2>Cancelamento </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          Ao clicar em enviar, será enviado um e-mail de confirmação para
          cancelamento.
        </p>
        <p className={styles.modal_sub_title_text}>
          Ao prosseguir com o cancelamento, a conta será inativada no sistema.
        </p>
      </div>
      <div className={styles.container_modal}>
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <button className={styles.button_back} onClick={onClose}>
              Voltar
            </button>

            <button
              className={styles.button_send}
              onClick={() => {
                setLoading(true)
                handleCancelPlan()
              }}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalCancelPlan
