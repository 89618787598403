import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { Pen } from '../../assets/svgs/icons'
import { useNavigate } from 'react-router-dom'
import SimpleEditionModal from '../SimpleEditionModal'
import SimpleEditionModalTemplate from '../SimpleEditionModalTemplate'
import {
  PublicationService,
  SubscriptionService,
  TimelineActionsService,
} from '../../services'
import { notifyWarningCustom } from '../../utils/notify'

const CAROUSEL_PROPS = {
  CLICK: 'click',
  SCROLL: 'scroll',
}

// eslint-disable-next-line react/prop-types
const CarouselSchedule = ({ items, type = 'click' | 'scroll' }) => {
  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  const [isSimplesEditModalOpen, setIsSimplesEditModalOpen] = useState(false)

  const [simplesEditModalData, setSimplesEditModalData] = useState({})
  const [simplesEditModalImageData, setSimplesEditModalImageData] = useState({})
  const [imageLayers, setImageLayers] = useState([])

  const [selectedImage, setSelectedImage] = useState([])
  const [fontToRender, setFontToRender] = useState('')
  const [imageUrl, setImageUrl] = useState(undefined)
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [imageLayersEdit, setImageLayersEdit] = useState([])

  const navigation = useNavigate()
  useEffect(() => {
    //setTempImageLayers(imageLayers)
  }, [])

  const handleMouseDown = (event) => {
    if (type === CAROUSEL_PROPS.SCROLL) {
      setIsDragging(true)
      setStartX(event.clientX - carouselRef.current.offsetLeft)
      setScrollLeft(carouselRef.current.scrollLeft)
      carouselRef.current.style.scrollBehavior = 'unset'
    }
  }

  const handleMouseMove = (event) => {
    if (type === CAROUSEL_PROPS.SCROLL) {
      if (!isDragging) return
      const x = event.clientX - carouselRef.current.offsetLeft
      const scrollOffset = (x - startX) * 1.0 // Ajuste a velocidade de rolagem conforme necessário
      carouselRef.current.scrollLeft = scrollLeft - scrollOffset
    }
  }

  const handleMouseUp = () => {
    if (type === CAROUSEL_PROPS.SCROLL) {
      setIsDragging(false)
      carouselRef.current.style.scrollBehavior = 'smooth'
    }
  }

  const handleScrollLeft = () => {
    carouselRef.current.scrollBy({
      left: -carouselRef.current.offsetWidth,
      behavior: 'smooth',
    })
  }

  const hadleScrollRight = () => {
    carouselRef.current.scrollBy({
      left: carouselRef.current.offsetWidth,
      behavior: 'smooth',
    })
  }

  const handleNavigate = (route) => {
    navigation(route)
  }

  const handleNavigateStep = () => {
    //return
    navigation('/NewPostFormPage', {
      state: { schedule: true, dataPublication: simplesEditModalData },
    })
  }

  const handleOpenSimpleEditionModal = async (data) => {
    const publicationCount = await PublicationService.getAllPublicationCount()
    const schedulingCount =
      await TimelineActionsService.getAllTimelineCountPublication()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    if (
      userSubscription.data.length <= 0 &&
      (publicationCount.data.count >= 8 || schedulingCount.data.count >= 60)
    ) {
      notifyWarningCustom(
        'Você atingiu o limite para realizar reagendamentos, consulte nossos planos para mais informações!'
      )
      return
    } else if (userSubscription.data.length > 0) {
      const indexSchedulling =
        await userSubscription.data[0].Plan.PlanItems.findIndex(
          (item) => item.type === 'scheduling_quantity'
        )
      const quantityItemSchedulling =
        userSubscription.data[0].Plan.PlanItems[indexSchedulling]?.quantity

      if (
        schedulingCount.data.count >= quantityItemSchedulling &&
        quantityItemSchedulling != null
      ) {
        notifyWarningCustom(
          'Você atingiu o limite para realizar reagendamentos, consulte nossos planos para mais informações!'
        )
        return
      }
    }
    const publication = await PublicationService.getPublication(data.id)
    setImageLayers(publication.data[0])
    // setSimplesEditModalData(publication.data[0].generateArtJson)
    setSimplesEditModalImageData(data.images[0])
    setIsSimplesEditModalOpen(true)
  }

  const handleCloseSimpleEditionModal = () => {
    setIsSimplesEditModalOpen(false)
  }

  const renderItems = () => {
    return items?.images?.map((item) => (
      <div key={item.id} className={styles.carousel_item}>
        <div
          className={styles.edit_image}
          // onClick={() => handleNavigate('/HomePage')}
          onClick={() => handleOpenSimpleEditionModal(items)}
        >
          <Pen color={'#000'} />
        </div>
        <img
          draggable="true"
          onDragStart={handleDragStart}
          className={
            selectedImage.includes(item.id)
              ? styles.carousel_selectedImage
              : styles.carousel_img
          }
          src={item.url}
          alt="Efects"
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    ))
  }

  const handleDragStart = (event) => {
    event.preventDefault()
    // Lógica para manipular o evento de arrastar
    // ...
  }

  return (
    <div
      className={styles.carousel_container}
      // onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {type === CAROUSEL_PROPS.CLICK ? (
        <button className={styles.carousel_button} onClick={handleScrollLeft}>
          &lt;
        </button>
      ) : null}

      <div className={styles.carousel} ref={carouselRef}>
        {renderItems()}
      </div>

      <SimpleEditionModalTemplate
        isOpen={isSimplesEditModalOpen}
        setImageLayersEdit={setImageLayersEdit}
        setNextStep={(teste1, teste2) => {
          navigation('/NewPublicationPage', {
            state: {
              edit: true,
              dataPublication: {
                ...imageLayers,
                generateArtJson: {
                  ...teste2?.tempImageLayers,
                  srcComTratativa: teste1.replace(
                    /^data:image\/png;base64,/,
                    ''
                  ),
                },
              },
            },
          })
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        imageLayers={imageLayers?.generateArtJson}
        setImageLayers={setImageLayers}
        onClose={handleCloseSimpleEditionModal}
        title={title ? title : ''}
        subtitle={subTitle ? subTitle : ''}
        publicTargetOptions={[]}
        productOptions={[]}
        // onChangeColorPalleteSubtittle={(e) => {}}
        //onChangeColorPalleteTittle={(e) => {}}
        onChangeColorPalleteTittle={(e) => setTitle(e.target.value)}
        onChangeColorPalleteSubtittle={(e) => setSubTitle(e.target.value)}

        // isOpen={isSimplesEditModalOpen}
        // setNextStep={() => handleNavigateStep(simplesEditModalData)}
        // imageUrl={simplesEditModalImageData?.url}
        // onClose={handleCloseSimpleEditionModal}
        // fontName={fontToRender}
        // title={simplesEditModalData?.title}
        // subtitle={simplesEditModalData?.copy}
        // // publicTargetOptions={productTargetAudienceOptions}
        // // productOptions={productTypeOptions}
        // imageLayers={imageLayers}
        // setImageLayers={setImageLayers}
        // isCampaign={simplesEditModalData.campaignId === null ? false : true}
        // onChangeColorPalleteSubtittle={(e) =>
        //   setHeadlineSubtitle(e.target.value)
        // }
        // onChangeColorPalleteTittle={(e) => setHeadlineText(e.target.value)}
      />

      {/* <SimpleEditionModal
          isOpen={isSimplesEditModalOpen}
          setNextStep={() => handleNavigateStep(simplesEditModalData)}
          imageUrl={simplesEditModalData?.publicationImageUrl}
          onClose={handleCloseSimpleEditionModal}
          fontName={fontToRender}
          title={simplesEditModalData?.headline}
          subtitle={simplesEditModalData?.legend}
          setImageLayers={setImageLayers}
          // publicTargetOptions={productTargetAudienceOptions}
          // productOptions={productTypeOptions}

          isCampaign={simplesEditModalData.campaignId === null ? false : true}

          // onChangeColorPalleteSubtittle={(e) =>
          //   setHeadlineSubtitle(e.target.value)
          // }
          // onChangeColorPalleteTittle={(e) => setHeadlineText(e.target.value)}
        /> */}

      {type === CAROUSEL_PROPS.CLICK ? (
        <button className={styles.carousel_button} onClick={hadleScrollRight}>
          &gt;
        </button>
      ) : null}
    </div>
  )
}

export default CarouselSchedule
