import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

function DefaulterPage({ userDefaulter, guestUser, setUserDefaulter }) {
  const navigation = useNavigate()

  // useEffect(() => {
  //   console.log('userDefaulter', userDefaulter)
  //   console.log('guestUser', guestUser)
  // }, [])

  const handleNavigate = (route) => {
    event.preventDefault()
    navigation(`${route}`)
  }

  return (
    <div className={styles.page}>
      <div className={styles.container_form}>
        <form>
          <div className={styles.form_header}>
            <div className={styles.logo}>
              {/* <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} /> */}
              <img
                src={posteiLogoText}
                alt="posteiLogoText"
                style={{ height: 34 }}
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.form_body}>
            <h1>Conta Bloqueada Temporariamente.</h1>
            <div className={styles.form_body_title}>
              <p>
                Olá,{' '}
                {userDefaulter.userDefaulter
                  ? userDefaulter.userDefaulter.name
                  : userDefaulter.name}
                !
              </p>
              {userDefaulter.isGuest ? (
                <p className={styles.form_info}>
                  Infelizmente, a conta que você está tentando acessar foi
                  bloqueada pelo usuário principal, proprietário desta conta.
                  <br /> <br />
                  Devido a essa ação, seu acesso aos serviços do sistema foi
                  temporariamente suspenso. Para mais informações sobre o
                  bloqueio ou para verificar se é possível restabelecer o
                  acesso, sugerimos que entre em contato diretamente com o
                  proprietário da conta. <br />
                  <br />
                  Se houver algum outro problema ou dúvida, nosso suporte está à
                  disposição para ajudar.
                </p>
              ) : (
                <p className={styles.form_info}>
                  infelizmente não conseguimos identificamos o pagamento
                  referente à sua assinatura e devido a isso, o acesso à sua
                  conta foi temporariamente bloqueado.
                  <br /> <br />
                  Para regularizar sua situação, pedimos que você realize o
                  pagamento o mais rápido possível junto à nossa parceira de
                  pagamentos, o Pagar.me. <br />
                  <br />
                  Caso o pagamento não seja efetuado, sua conta permanecerá
                  bloqueada, impossibilitando o acesso aos serviços do sistema.
                  Assim que o pagamento for confirmado, seu acesso será
                  restabelecido automaticamente. <br />
                  <br />
                  Se você já realizou o pagamento ou acredita que houve um erro,
                  por favor, entre em contato com o nosso suporte. <br />
                  <br />
                  Estamos à disposição para ajudar a resolver essa questão o
                  mais rápido possível.
                </p>
              )}

              <p className={styles.form_info}>
                Agradecemos a sua compreensão.
                <br /> Atenciosamente,
                <br />{' '}
                <a href="https://xama.ai/">
                  <strong>xama.ai</strong>
                </a>
              </p>
            </div>
          </div>

          <div className={styles.form_footer}>
            <button
              className={styles.button_cancel}
              onClick={() => handleNavigate('/')}
            >
              Sair
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DefaulterPage
