import axios from 'axios'

const BASE_URL = import.meta.env.VITE_API_BASE_URL

export const getPublication = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publications/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error getProduct', error.response.data)
      return error
    })
}

export const getPublicationMovie = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publications-movie/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error getProduct', error.response.data)
      return error
    })
}

export const postPublication = async (id, socialTypes, body,moreConfig) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
    ...moreConfig
  }

  return await axios
    .post(
      `${BASE_URL}publications/${id}/?socialTypes=${socialTypes}`,
      body,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const postMovieBucket = async (body, moreConfig) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
    ...moreConfig,
  }

  return await axios
    .post(`${BASE_URL}movies-bucket`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postMovieBucket', error.response.data)
      return error
    })
}

export const postPublicationMovie = async (id, socialTypes, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  console.log('socialTypes', socialTypes)

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .post(
      `${BASE_URL}publication-movies/${id}/?socialTypes=${socialTypes}`,
      body,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postPublicationMovie', error.response.data)
      return error
    })
}

export const putPublication = async (id, business, socialType, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(
      `${BASE_URL}publications/${id}/${business}/?socialTypes=${socialType}`,
      body,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const putPublicationMovie = async (id, business, socialType, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(
      `${BASE_URL}publications-movie/${id}/${business}/?socialTypes=${socialType}`,
      body,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const putCancelPublicationsMovie = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}cancel-publications-movie/${id}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const cancelPublication = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}cancel-publications/${id}`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const deletePublication = async (id, body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}publications/${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const postPublicationImage = async (body,moreConfig) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
    ...moreConfig
  }

  return await axios
    .post(`${BASE_URL}publications-image`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error postProduct', error.response.data)
      return error
    })
}

export const getAllPublicationByLogin = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publication-by-user`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getAllPublicationByUsersByDate = async (day, month, year) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}publication-by-users-by-date/${day}/${month}/${year}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByUsersByDate', error.response.data)
      return error
    })
}

export const getAllPublicationByLoginByBusinessClientId = async (
  id,
  page,
  limit
) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}publication-by-business-client/${id}?page=${page}&limit=${limit}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log(
        'error getAllPublicationByLoginByBusinessClientId',
        error.response.data
      )
      return error
    })
}

export const getAllPublicationByLoginByNotBusinessClient = async (
  page,
  limit
) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}publication-by-not-business-client?page=${page}&limit=${limit}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getAllPublicationByLoginSketch = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publication-by-user-sketch`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getAllPublicationByLoginSketchByBusinessClientId = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}publication-by-user-sketch-by-business-client/${id}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getAllPublicationByLoginSketchNotBusinessClient = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publication-by-user-sketch-by-not-business-client`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getAllPublicationByLoginByDate = async (month, year) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publication-by-user-by-date/${month}/${year}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getAllPublicationByLoginByDateByBusinessClientId = async (
  id,
  month,
  year
) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}publication-by-user-by-dateby-business-client/${id}/${month}/${year}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getAllPublicationByLoginByDateNotBusinessClient = async (
  month,
  year
) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(
      `${BASE_URL}publication-by-user-by-date-by-not-business-client/${month}/${year}`,
      config
    )
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationByLogin', error.response.data)
      return error
    })
}

export const getPublicationBase64ById = async (id) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publication-base64-by-id${id}`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getPublicationBase64ById', error.response.data)
      return error
    })
}

export const getAllPublicationCount = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .get(`${BASE_URL}publication-user-count`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error getAllPublicationCount', error.response.data)
      return error
    })
}
export const deletePublicationByUser = async () => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .delete(`${BASE_URL}publications-by-user`, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        window.localStorage.clear()
        window.location.reload()
      }
      console.log('error deletePublicationByUser', error.response.data)
      return error
    })
}

export const putPublicationClassification = async (body) => {
  const STORAGE_TOKEN = JSON.parse(window.localStorage.getItem('token'))

  const config = {
    headers: {
      Authorization: 'Bearer ' + STORAGE_TOKEN,
    },
    withCredentials: true,
  }

  return await axios
    .put(`${BASE_URL}publications/update-classification`, body, config)
    .then((response) => {
      return {
        data: response.data,
        responseStatus: response.status,
      }
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        // window.localStorage.clear()
        // window.location.reload()
      }
      console.log('error putPublicationClassification', error.response.data)
      return error
    })
}
