import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import {
  InterrogationCircleIcon,
  Trash,
  CloundArrowUp,
  BoxNotChecket,
  BoxChecket,
  Stars,
} from '../../assets/svgs/icons'
import { ButtonNavigateStep } from '../../components/ButtonNavigateStep'

import { motion } from 'framer-motion'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepVisualReferences({ setStep }) {
  const navigation = useNavigate()
  function handleNavigate() {
    navigation('/LoginCompletion')
  }

  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [confirmchecket, setConfirmchecket] = useState(false)

  const [removeBackgroundMode, setRemoveBackgroundMode] = useState([])
  const [removedImageBackground, setRemovedImageBackground] = useState({})

  const [loading, setLoading] = useState(false)
  const [isCopyright, setIsCopyright] = useState(false)

  const onDragOver = (event) => {
    event.preventDefault()
    // setDropzoneHover(true)
  }

  const onDragLeave = () => {
    // setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
        }

        setFilesSelectedArray((current) => [...current, fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const handleOpenRemoveBackgroundMode = (index) => {
    if (removeBackgroundMode.some((e) => e === index)) return
    removeBackgroundMode.push(index)
    setRemoveBackgroundMode([...removeBackgroundMode])
  }

  const handleRemoveBackground = async (index, image) => {
    const { data } = await PythonService.postRemoveBackground({ image })
    filesSelectedArray[index].fileDataBase64 = data?.data?.images[1].image
    setFilesSelectedArray([...filesSelectedArray])
    setRemovedImageBackground(data?.data?.images)
  }

  const handleStopRemoveBackgroundMode = (index) => {
    const aux = removeBackgroundMode.filter((e) => e !== index)
    setRemoveBackgroundMode([...aux])
  }

  const checkCopyright = () => {
    setIsCopyright(!isCopyright)
  }

  const handleRemoveFile = (index) => {
    var indice = filesSelectedArray.indexOf(index)
    filesSelectedArray.splice(indice, 1)

    setFilesSelectedArray((current) => [...current])
  }

  const hadleChecket = () => {
    setConfirmchecket(!confirmchecket)
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>Minhas Referências</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        Agora você tem a oportunidade de orientar a IA não apenas com os dados
        fornecidos anteriormente, mas também com uma referência visual alinhada
        aos seus gostos. A partir dessa referência, os posts serão criados
        seguindo essa mesma linha de estilo.
      </p>

      <div className={styles.container_modal}>
        <div className={styles.content_up_image}>
          <div className={styles.modal_container_drag}>
            <div className={styles.modal_drag}>
              {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
              </label> */}

              <div
                className={styles.modal_drag_drop}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                // onClick={handleShowListImages}

                // style={{
                //   height: '100px',
                //   width: '100%',
                //   border: '2px dashed black',
                //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                // }}
                // className={styles.modal_drag}
              >
                <label htmlFor="arquivo">
                  <CloundArrowUp color={'#B900FE'} />
                  <h2
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_title_short
                        : styles.modal_drag_drop_title
                    }
                  >
                    Arraste & Solte o arquivo
                  </h2>
                  <p
                    className={
                      filesSelectedArray.length !== 0
                        ? styles.modal_drag_drop_sub_title_short
                        : styles.modal_drag_drop_sub_title
                    }
                  >
                    Ou selecione o arquivo no explorador
                  </p>
                </label>
                <input
                  type="file"
                  name="arquivo"
                  id="arquivo"
                  onChange={(e) => onFileChange(e)}
                ></input>
              </div>
            </div>
          </div>
          <div className={styles.modal_button_save}>
            <button className={styles.button_add}>
              <label className={styles.button_add_label} htmlFor="arquivo">
                Adicionar Imagem
              </label>
            </button>
          </div>
        </div>
        {filesSelectedArray.length !== 0 && (
          <div className={styles.content_list_image}>
            <div className={styles.container_box_list_image}>
              {filesSelectedArray.map((image, index) => (
                <div
                  key={index}
                  className={
                    styles[
                      !removeBackgroundMode.some((e) => e === index)
                        ? 'container_list_image'
                        : 'container_list_image_remove_background_mode'
                    ]
                  }
                >
                  <div className={styles.item_list_image}>
                    <img
                      src={image.fileDataBase64}
                      alt="file-preview"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    {!removeBackgroundMode.some((e) => e === index) && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.8 }}
                        className={styles.box_data_info}
                      >
                        <p className={styles.box_data_info_title}>
                          {image.name}
                        </p>
                        <p className={styles.box_data_info_size}>
                          Tamanho &nbsp;<b> {image.size} mb</b>
                        </p>
                      </motion.div>
                    )}
                  </div>

                  {!removeBackgroundMode.some((e) => e === index) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.8 }}
                      className={styles.item_list_image_buttons}
                    >
                      <button
                        className={styles.button_cancel}
                        onClick={() => handleRemoveFile(index)}
                      >
                        <p className={styles.button_cancel_title}>Excluir</p>
                        <Trash />
                      </button>
                      <button
                        onClick={() => handleOpenRemoveBackgroundMode(index)}
                        className={styles.button_remove_background}
                      >
                        <p className={styles.button_remove_background_title}>
                          Remover Fundo
                        </p>
                        <Stars />
                      </button>
                    </motion.div>
                  )}

                  {removeBackgroundMode.some((e) => e === index) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 1 }}
                      className={styles.item_list_image_buttons}
                    >
                      <button
                        onClick={() =>
                          handleRemoveBackground(index, image.fileDataBase64)
                        }
                        className={styles.button_remove_background}
                      >
                        <p className={styles.button_remove_background_title}>
                          Remover Fundo
                        </p>
                        <Stars />
                      </button>
                      <button
                        className={
                          styles[
                            !removeBackgroundMode.some((e) => e === index)
                              ? 'button_cancel'
                              : 'button_cancel_remove_background'
                          ]
                        }
                        onClick={() => handleStopRemoveBackgroundMode(index)}
                      >
                        <p
                          className={styles.button_cancel_title}
                          style={{
                            color: removeBackgroundMode.some((e) => e === index)
                              ? 'black'
                              : '',
                          }}
                        >
                          Cancelar
                        </p>
                      </button>
                    </motion.div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={styles.box_copyright}>
        <div onClick={() => hadleChecket()}>
          {confirmchecket ? <BoxChecket /> : <BoxNotChecket />}
        </div>
        <p className={styles.box_copyright_text}>
          Confirmo que possuo os direitos autorais destas imagens
        </p>
      </div>

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_VISUAL_PREFERENCES)}
          variant={true}
        >
          Voltar
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => handleNavigate()} variant={'next'}>
          Prosseguir
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepVisualReferences
