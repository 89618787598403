import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { useEffect, useRef, useState } from 'react'
import { NavigateArrowRigth, ChevronDown, SearchIcon } from '../../../assets/svgs/icons'

export const CustomSearchSelect = ({
  options,
  label,
  htmlFor,
  placeholder,
  defaultSelected,
  arrowDirection = 'right',
  backgroundColor,
  borderRadius,
  fontSize,
  onClickItem,
  iconSize = 24,
  disabled,
  fontUrls,
}) => {
  const [containerVisible, setContainerVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    label: '',
    value: '',
  })

  const [textExemple, setTextExemple] = useState('')
  const [visibleItems, setVisibleItems] = useState(20);
  const observerTarget = useRef(null);

  const handleSelect = (e) => {
    setContainerVisible(false)
    setSelectedItem(e)
    onClickItem(e)
  }
  useEffect(() => {
    setSelectedItem(defaultSelected)
    // console.log('fontUrls', fontUrls)
  }, [defaultSelected])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setContainerVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerVisible])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setVisibleItems(prev => {
            const filteredLength = options
              .filter(font => font?.label?.toLowerCase().includes(textExemple.toLowerCase()))
              .length;
            
            return prev < filteredLength ? prev + 20 : prev;
          });
        }
      },
      { 
        threshold: 0.1,
        rootMargin: '20px'
      }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, options, textExemple, containerVisible]);

  const ref = useRef(null)

  return (
    <>
      <style>{`${fontUrls}`}</style>
     
      <div ref={ref} className={styles.Container_select}>
        <div className={styles.inputSelectContainer}>
          <label htmlFor={htmlFor}>{label}</label>
          <div
            className={styles.buttonSelect}
            onClick={() =>
              disabled ? null : setContainerVisible((prev) => !prev)
            }
            type="text"
            style={{
              backgroundColor,
              borderRadius,
              fontSize,
              cursor: disabled ? 'initial' : 'pointer',
            }}
          >
            <p style={{ fontSize }} className={styles.placeholder_select}>
              {selectedItem?.label || placeholder}
            </p>
            <div className={styles.box_svg}>
              <div
                className={`${styles[arrowDirection]} ${
                  containerVisible ? styles.rotate : styles.containerVisible
                }`}
              >
                {arrowDirection == 'right' && (
                  <NavigateArrowRigth size={iconSize} />
                )}
                {arrowDirection == 'down' && <ChevronDown size={iconSize} />}
              </div>
            </div>
          </div>
          {containerVisible && !disabled && (
            <div className={styles.selectContainer}>
              <div className={styles.box_search}>
                <input type="text"
                  value={textExemple}
                  onChange={(e) => setTextExemple(e.target.value)}
                  placeholder="Pesquisar..."
                  className={styles.search}
                />
                <SearchIcon/>
              </div>
              <div className={styles.optionsContainer}>
                {options
                  .filter((option) =>
                    option?.label?.toLowerCase().includes(textExemple.toLowerCase())
                  )
                  .slice(0, visibleItems)
                  .map((option, index) => (
                    <button
                      key={index}
                      className={styles.selectContainerItem}
                      onClick={() => handleSelect(option)}
                    >
                      <span style={{ fontSize, fontFamily: option?.value ? `${option?.value}` : '' }}>{option.label}</span>
                    </button>
                  ))}
                <div ref={observerTarget} style={{ height: '20px', width: '100%' }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
