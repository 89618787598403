import PlanCard from '../../components/PlanCard'
import styles from './styles.module.css'

import posteiLogo from '../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../assets/logos/postei-logo-text.svg'
import xamaLogoBranco from '../../assets/logos/xama-logo-branco.png'

import { useTranslation } from 'react-i18next'

function TermsOfUse() {
  const { t } = useTranslation()
  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        <img
          src={posteiLogo}
          alt="XamaLogo"
          style={{ height: 34 }}
          onContextMenu={(e) => e.preventDefault()}
        />
        <img
          src={xamaLogoBranco}
          alt="XamaLogoText"
          style={{ height: 34 }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div className={styles.container}>
        {/* <div className={styles.termsContainer}> */}
        <h1 className={styles.header1}>{t('termsOfUse.title')}</h1>

        <p className={styles.paragraph}>
          {t('termsOfUse.welcome')} <strong>{t('termsOfUse.xamaAI')}</strong>{' '}
          {t('termsOfUse.theseAreTheTermsOfUse')}
        </p>

        <h2 className={styles.header2}>{t('termsOfUse.terms')}</h2>

        <p className={styles.highlight}>{t('termsOfUse.knowThat')}</p>

        <p className={styles.important}>{t('termsOfUse.ifNotAgree')}</p>

        <p className={styles.paragraph}>{t('termsOfUse.termsCanBeChanged')}</p>

        <br />
        <p className={styles.paragraph}>{t('termsOfUse.termsAditionals')}</p>
        <br />
        <p className={styles.paragraph}>{t('termsOfUse.termsAditionals_2')}</p>
        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.initialConsiderations')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.initialConsiderations_1')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.initialConsiderations_2')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.initialConsiderations_3')}
        </p>
        <br />
        <h3 className={styles.sectionNumber}>{t('termsOfUse.eligibility')}</h3>
        <p className={styles.paragraph}>{t('termsOfUse.eligibility_1')}</p>
        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.howToBecomeAUser')}
        </h3>
        <p className={styles.paragraph}>{t('termsOfUse.howToBecomeAUser_1')}</p>
        <p className={styles.paragraph}>{t('termsOfUse.howToBecomeAUser_2')}</p>
        <p className={styles.paragraph}>{t('termsOfUse.howToBecomeAUser_3')}</p>
        <p className={styles.paragraph}>{t('termsOfUse.howToBecomeAUser_4')}</p>
        <p className={styles.paragraph}>{t('termsOfUse.howToBecomeAUser_5')}</p>
        <br />
        <h3 className={styles.sectionNumber}>{t('termsOfUse.plans')}</h3>
        <h3 className={styles.sectionNumber}>{t('termsOfUse.plansOptions')}</h3>
        <p className={styles.paragraph}>{t('termsOfUse.plansOptions_1')}</p>
        <ul>
          <li>
            <strong>{t('termsOfUse.standard')}</strong>{' '}
            {t('termsOfUse.standard_1')}
          </li>
          <li>
            <strong>{t('termsOfUse.premium')}</strong>{' '}
            {t('termsOfUse.premium_1')}
          </li>
        </ul>
        <h3 className={styles.sectionNumber}>{t('termsOfUse.changePlan')}</h3>
        <p className={styles.paragraph}>{t('termsOfUse.changePlan_1')}</p>
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.servicesOfferedByThePlan')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.servicesOfferedByThePlan_1')}
        </p>
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.changePlanValues')}
        </h3>
        <p className={styles.paragraph}>{t('termsOfUse.changePlanValues_1')}</p>
        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.paymentOrCancellationOfServices')}
        </h3>
        <h3 className={styles.sectionNumber}>{t('termsOfUse.payment')}</h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.payment_1')}{' '}
          <strong>{t('termsOfUse.boletoBancario')}</strong>{' '}
          {t('termsOfUse.payment_2')}
        </p>

        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.cancelamentoSolicitadoPeloUsuario')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.cancelamentoSolicitadoPeloUsuario_1')}
        </p>
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.cancelamentoDeAssinatura')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.cancelamentoDeAssinatura_1')}
        </p>
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.ausenciaDePagamento')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.ausenciaDePagamento_1')}
        </p>

        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.inatividadeDoCadastro')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.inatividadeDoCadastro_1')}
        </p>

        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.cancelamentoDoCadastro')}
        </h3>

        <p className={styles.paragraph}>
          {t('termsOfUse.cancelamentoDoCadastro_1')}
        </p>

        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.restricoesNoUso')}
        </h3>

        <ul>
          <li>{t('termsOfUse.restricoesNoUso_1')}</li>
          <li>{t('termsOfUse.restricoesNoUso_2')}</li>
          <li>{t('termsOfUse.restricoesNoUso_3')}</li>
          <li>{t('termsOfUse.restricoesNoUso_4')}</li>
          <li>{t('termsOfUse.restricoesNoUso_5')}</li>
          <li>{t('termsOfUse.restricoesNoUso_6')}</li>
          <li>{t('termsOfUse.restricoesNoUso_7')}</li>
          <li>{t('termsOfUse.restricoesNoUso_8')}</li>
          <li>{t('termsOfUse.restricoesNoUso_9')}</li>
          <li>{t('termsOfUse.restricoesNoUso_10')}</li>
          <li>{t('termsOfUse.restricoesNoUso_11')}</li>
          <li>{t('termsOfUse.restricoesNoUso_12')}</li>
          <li>{t('termsOfUse.restricoesNoUso_13')}</li>
        </ul>
        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.dadosDoUsuarioEPrivacidade')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.dadosDoUsuarioEPrivacidade_1')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.dadosDoUsuarioEPrivacidade_2')}
        </p>
        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.accessToTheNetworkAndEquipament')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.accessToTheNetworkAndEquipament_1')}
        </p>

        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.ausenciaDeGarantia')}
        </h3>

        <p className={styles.paragraph}>
          {t('termsOfUse.ausenciaDeGarantia_1')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.ausenciaDeGarantia_2')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.ausenciaDeGarantia_3')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.ausenciaDeGarantia_4')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.ausenciaDeGarantia_5')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.ausenciaDeGarantia_6')}
        </p>

        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.limiteDeResponsabilidade')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.limiteDeResponsabilidade_1')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.limiteDeResponsabilidade_2')}
        </p>

        <br />
        <h3 className={styles.sectionNumber}>{t('termsOfUse.licenca')}</h3>
        <p className={styles.paragraph}>{t('termsOfUse.licenca_1')}</p>

        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.propriedadeInteligente')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.propriedadeInteligente_1')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.propriedadeInteligente_2')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.propriedadeInteligente_3')}
        </p>
        <p className={styles.paragraph}>
          {t('termsOfUse.propriedadeInteligente_4')}
        </p>

        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.comunicacaoComOUsuario')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.comunicacaoComOUsuario_1')}
        </p>

        <br />
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.disposicoesFinais')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.disposicoesFinais_1')}
        </p>
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.legislacaoAplicavel')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.legislacaoAplicavel_1')}
        </p>

        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.renunciaDeDireitos')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.renunciaDeDireitos_1')}
        </p>

        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.cessaoDeDireitos')}
        </h3>
        <p className={styles.paragraph}>{t('termsOfUse.cessaoDeDireitos_1')}</p>

        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.independenciaDasPartes')}
        </h3>
        <p className={styles.paragraph}>
          {t('termsOfUse.independenciaDasPartes_1')}
        </p>
        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.indivisibilidade')}
        </h3>
        <p className={styles.paragraph}>{t('termsOfUse.indivisibilidade_1')}</p>

        <h3 className={styles.sectionNumber}>
          {t('termsOfUse.foroAplicavel')}
        </h3>
        <p className={styles.paragraph}>{t('termsOfUse.foroAplicavel_1')}</p>

        <div className={styles.footer}>
          <p className={styles.paragraph}>{t('termsOfUse.copyright')}</p>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default TermsOfUse
