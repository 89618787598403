import { useEffect, useRef, useState } from 'react'
import { NavigateArrowRigth } from '../../assets/svgs/icons'
import StepName from './stepName'
import styles from './styles.module.css'
import StepEmail from './stepEmail'
import StepPhone from './stepPhone'
import StepPassword from './stepPassword'
import StepImage from './stepImage'
import StepVoiceTone from './stepVoiceTone'
import StepLogo from './stepLogo'
import StepBPS from './stepBPS'
import StepTypography from './stepTypography'

import StepNIcheAndObjective from './stepNicheAndObjective'
import StepPublication from './stepPublication'
import StepPublicationDownload from './stepPublicationDownload'
import Password from 'antd/es/input/Password'
import { BusinessUser, UserAccountsService, UserService } from '../../services'

import { notifyErrorCustom } from '../../utils/notify'
import StepCPF from './stepCPF'
import StepAddress from './stepAddress'
import StepColorPalette from './stepColorPalette'
import { useAuth } from '../../hooks/useAuth'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import StepSubject from './stepSubject'
import StepBusinessInfo from './stepBusnessInfo'
import StepPublicationInfo from './stepPublicationInfo'

import xamaLogoazul from '../../assets/logos/xama-logo-azul.png'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_PASS_NAME: 'stepName',
  STEP_PASS_EMAIL: 'stepEmail',
  STEP_PASS_PHONE: 'stepPhone',
  STEP_PASS_PASSWORD: 'stepPassword',
  STEP_PASS_CPF: 'stepCPF',
  STEP_PASS_ADDRESS: 'stepAddress',
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_SUBJECT: 'StepSubject',
  STEP_PASS_LOGO: 'stepLogo',
  STEP_PASS_IMAGE: 'stepImage',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_PASS_BPS: 'stepBPS',
  STEP_PASS_TYPOGRAPHY: 'stepTypography',
  STEP_PASS_NICHE_AND_OBJECTIVE: 'stepNIcheAndObjective',
  STEP_PASS_PUBLICATION: 'stepPublication',
  STEP_PASS_PUBLICATION_DOWNLOAD: 'stepPublicationDownload',
  STEP_PASS_BUSINESS_INFO: 'stepBusinessInfo',
  STEP_PASS_PUBLICATION_INFO: 'stepPublicationInfo',
}

function FastPublishing() {
  const { logout } = useAuth()
  const [step, setStep] = useState(STEPS.STEP_FIRST_PASS_NAME)
  const [numberStep, setNumberStep] = useState(0)
  const { setQuickRegistration, quickRegistration } = useAuth()
  const location = useLocation()

  const [name, setName] = useState('')
  const [arrayName, setArrayName] = useState([])
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [pass, setPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [fantasyName, setFantasyName] = useState('')
  const [corporateReason, setCorporateReason] = useState('')
  const [subject, setSubject] = useState('')

  const [userData, setUserData] = useState('')
  const [userAccountData, setUserAccountData] = useState('')
  const [businessAccountData, setBusinessAccountData] = useState('')

  const wordsArray = name.trim().split(/\s+/)

  const [filesSelectedArray, setFilesSelectedArray] = useState([])
  const [logoSelectedArray, setLogoSelectedArray] = useState([])

  const [objectiveSelected, setObjectiveSelected] = useState('')

  const [brandingArchetypeData, setBrandingArchetypeData] = useState({})

  const [colors, setColors] = useState([])

  const [font, setFont] = useState('')

  const [headlineList, setHeadlineList] = useState([])
  const [legendList, setLegendList] = useState([])
  const [headlineText, setHeadlineText] = useState('')
  const [postSelected, setPostSelected] = useState({})
  const { t } = useTranslation()

  const [iaData, setIaData] = useState([
    {},
    {},
    {},
    {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
    // {},
  ])

  const [headlineGpt, setHeadlineGpt] = useState(null)

  const [legend, setLegend] = useState(false)

  const navigation = useNavigate()
  const navigate = useNavigate()

  const [qtdLoadTemplates, setQtdLoadTemplates] = useState(0)
  const qtdTemplatesLimit = 3
  useEffect(() => {
    handleStep()
    // const [first, ...rest] = wordsArray
  }, [numberStep])

  useEffect(() => {
    handleFirstLogin()

    if (location.state?.loginRedirect) {
      setStep(STEPS.STEP_PASS_BUSINESS_INFO)
    } else {
      console.log('TAG DATA LAYER ===>')

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'filling_out_registration',
        // form_nome: formData.nome,
        // form_email: formData.email,
        // timestamp: new Date().toISOString()
      })
    }
  }, [])

  const handleFirstLogin = async () => {
    const { data: dataFirstLogin } =
      await UserAccountsService.getUserAccountsByLogin()

    if (!dataFirstLogin.firstLogin) {
      navigation('/HomePage')
    }
  }

  window.history.pushState(null, null, window.location.href)

  window.onpopstate = function () {
    redirectLogOut() // Chama a função quando o usuário clicar em "Voltar"
  }

  function redirectLogOut() {
    if (window.location.pathname === '/FastPublishing1') {
      //logout()
      //navigate('/FastPublishing1')
      window.location.reload()
    }
    if (window.location.pathname === '/FastPublishing') {
      //logout()
      navigate('/signIn')
    }
  }
  const handleStep = () => {
    let newStep = ''

    switch (numberStep) {
      case 0:
        newStep = STEPS.STEP_PASS_NAME
        break
      case 1:
        newStep = STEPS.STEP_PASS_EMAIL
        break
      case 2:
        newStep = STEPS.STEP_PASS_PHONE
        break
      case 3:
        newStep = STEPS.STEP_PASS_PASSWORD
        break
      case 4:
        newStep = STEPS.STEP_PASS_CPF
        break
      case 5:
        newStep = STEPS.STEP_PASS_ADDRESS
        break
      case 6:
        newStep = STEPS.STEP_PASS_NICHE_AND_OBJECTIVE
        break
      case 7:
        newStep = STEPS.STEP_PASS_IMAGE
        break
      case 8:
        newStep = STEPS.STEP_PASS_LOGO
        break
      case 9:
        newStep = STEPS.STEP_PASS_BPS
        break
      case 10:
        newStep = STEPS.STEP_PASS_TYPOGRAPHY
        break
      case 11:
        newStep = STEPS.STEP_PASS_PUBLICATION
        break
      case 12:
        newStep = STEPS.STEP_PASS_PUBLICATION_DOWNLOAD
        break
      default:
        break
    }

    setStep(newStep)
  }

  const ProgressBar = () => {
    let stepNumber = 0
    let stepName = ''

    let backStep = null

    switch (step) {
      case STEPS.STEP_PASS_NICHE_AND_OBJECTIVE:
        stepNumber = 1
        stepName = t('fast_publishing_brand_information')
        backStep = STEPS.STEP_PASS_BUSINESS_INFO
        break
      case STEPS.STEP_PASS_COLOR_PALETTE:
        stepNumber = 2
        stepName = t('fast_publishing_brand_information')
        backStep = STEPS.STEP_PASS_NICHE_AND_OBJECTIVE
        break
      case STEPS.STEP_PASS_TYPOGRAPHY:
        stepNumber = 3
        stepName = 'Informações da marca'
        backStep = STEPS.STEP_PASS_COLOR_PALETTE
        break
      case STEPS.STEP_PASS_BPS:
        stepNumber = 4
        stepName = 'Informações da marca'
        backStep = STEPS.STEP_PASS_TYPOGRAPHY
        break
      case STEPS.STEP_VOICE_TONE:
        stepNumber = 5
        stepName = 'Informações da marca'
        backStep = STEPS.STEP_PASS_BPS
        break
      case STEPS.STEP_PASS_SUBJECT:
        stepNumber = 6
        stepName = 'Assunto'
        backStep = STEPS.STEP_VOICE_TONE
        break
      case STEPS.STEP_PASS_LOGO:
        stepNumber = 7
        stepName = 'Logotipo'
        backStep = STEPS.STEP_PASS_SUBJECT
        break
      case STEPS.STEP_PASS_IMAGE:
        stepNumber = 8
        stepName = 'Imagem'
        backStep = STEPS.STEP_PASS_LOGO
        break
      case STEPS.STEP_PASS_PUBLICATION:
        stepNumber = 9
        stepName = 'Publicação'
        backStep = STEPS.STEP_PASS_IMAGE
        break

      default:
        break
    }

    if (stepNumber === 0) {
      return null
    }

    return (
      <div
        style={{
          width: '100%',
          maxWidth: '600px',
          margin: '20px auto',
          padding: '0 20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
            position: 'relative',
          }}
        >
          {/* <button
            onClick={() => setStep(backStep)}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: '#857c8c',
              fontSize: '14px',
              fontWeight: '400',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              position: 'absolute',
              left: 0,
            }}
          >
            <div style={{ transform: 'rotate(180deg)' }}>
              <NavigateArrowRigth color="#857c8c" width="12" height="12" />
            </div>
            <span style={{ fontSize: '12px', marginTop: 2 }}>Voltar</span>
            
          </button> */}

          <span
            style={{
              color: '#857c8c',
              fontSize: '16px',
              fontWeight: '400',
              width: '100%',
              textAlign: 'center',
            }}
          >
            {stepName}
          </span>
        </div>

        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '8px',
            color: '#6B7280',
            fontSize: '14px',
          }}
        >
          <span>Passo {stepNumber}</span>
          <span>8 passos</span>
        </div> */}

        <div
          style={{
            width: '100%',
            height: '8px',
            backgroundColor: '#E5E7EB',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              width: `${(stepNumber / 8) * 100}%`,
              height: '100%',
              backgroundColor: '#6A0098',
              borderRadius: '4px',
              transition: 'width 0.3s ease-in-out',
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container_page}>
      <div className={styles.box_logo}>
        <img
          src={xamaLogoazul}
          alt="logo"
          style={{ height: 40 }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <ProgressBar />

      {step === STEPS.STEP_PASS_NAME && (
        <StepName
          setName={setName}
          name={name}
          setArrayName={setArrayName}
          setStep={setStep}
        />
      )}
      {step === STEPS.STEP_PASS_EMAIL && (
        <StepEmail setEmail={setEmail} email={email} setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PHONE && (
        <StepPhone phone={phone} setPhone={setPhone} setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PASSWORD && (
        <StepPassword
          setPass={setPass}
          setConfirmPass={setConfirmPass}
          pass={pass}
          confirmPass={confirmPass}
          setStep={setStep}
          wordsArray={wordsArray}
          phone={phone}
          email={email}
          setUserData={setUserData}
        />
      )}
      {step === STEPS.STEP_PASS_CPF && (
        <StepCPF
          cpf={cpf}
          setCpf={setCpf}
          setCnpj={setCnpj}
          cnpj={cnpj}
          setFantasyName={setFantasyName}
          fantasyName={fantasyName}
          setCorporateReason={setCorporateReason}
          corporateReason={corporateReason}
          setNumberStep={setNumberStep}
          numberStep={numberStep}
          setStep={setStep}
        />
      )}
      {step === STEPS.STEP_PASS_ADDRESS && (
        <StepAddress
          setStep={setStep}
          cpf={cpf}
          corporateReason={corporateReason}
          userData={userData}
          fantasyName={fantasyName}
          cnpj={cnpj}
          setUserAccountData={setUserAccountData}
          pass={pass}
          email={email}
          wordsArray={wordsArray}
          phone={phone}
          setUserData={setUserData}
          setQuickRegistration={setQuickRegistration}
        />
      )}
      {step === STEPS.STEP_PASS_BUSINESS_INFO && (
        <StepBusinessInfo setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_PUBLICATION_INFO && (
        <StepPublicationInfo setStep={setStep} />
      )}
      {step === STEPS.STEP_PASS_SUBJECT && (
        <StepSubject
          setStep={setStep}
          subject={subject}
          setSubject={setSubject}
        />
      )}
      {step === STEPS.STEP_PASS_COLOR_PALETTE && (
        <StepColorPalette
          setStep={setStep}
          colors={colors}
          setColors={setColors}
          businessAccountData={businessAccountData}
        />
      )}
      {step === STEPS.STEP_PASS_LOGO && (
        <StepLogo
          setStep={setStep}
          logoSelectedArray={logoSelectedArray}
          setLogoSelectedArray={setLogoSelectedArray}
        />
      )}
      {step === STEPS.STEP_PASS_IMAGE && (
        <StepImage
          setStep={setStep}
          filesSelectedArray={filesSelectedArray}
          setFilesSelectedArray={setFilesSelectedArray}
        />
      )}
      {step === STEPS.STEP_VOICE_TONE && <StepVoiceTone setStep={setStep} />}
      {step === STEPS.STEP_PASS_NICHE_AND_OBJECTIVE && (
        <StepNIcheAndObjective
          setStep={setStep}
          setBusinessAccountData={setBusinessAccountData}
          objectiveSelected={objectiveSelected}
          setObjectiveSelected={setObjectiveSelected}
        />
      )}
      {step === STEPS.STEP_PASS_BPS && (
        <StepBPS
          setStep={setStep}
          setBrandingArchetypeData={setBrandingArchetypeData}
          brandingArchetypeData={brandingArchetypeData}
        />
      )}
      {step === STEPS.STEP_PASS_TYPOGRAPHY && (
        <StepTypography
          setStep={setStep}
          businessAccountData={businessAccountData}
          font={font}
          setFont={setFont}
        />
      )}

      {step === STEPS.STEP_PASS_PUBLICATION && (
        <StepPublication
          setStep={setStep}
          filesSelectedArray={filesSelectedArray}
          setHeadlineList={setHeadlineList}
          headlineList={headlineList}
          setLegendList={setLegendList}
          legend={legend}
          subject={subject}
          setLegend={setLegend}
          legendList={legendList}
          setHeadlineText={setHeadlineText}
          headlineText={headlineText}
          iaData={iaData}
          setIaData={setIaData}
          qtdLoadTemplates={qtdLoadTemplates}
          setQtdLoadTemplates={setQtdLoadTemplates}
          qtdTemplatesLimit={qtdTemplatesLimit}
          setPostSelected={setPostSelected}
          brandingArchetypeData={brandingArchetypeData}
          businessAccountData={businessAccountData}
          font={font}
        />
      )}
      {step === STEPS.STEP_PASS_PUBLICATION_DOWNLOAD && (
        <StepPublicationDownload
          setStep={setStep}
          postSelected={postSelected}
          setQuickRegistration={setQuickRegistration}
          quickRegistration={quickRegistration}
        />
      )}
    </div>
  )
}

export default FastPublishing
