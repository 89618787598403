import { useState, useEffect, useRef } from 'react'
import { ListTable } from '../../components/ListTable'
import { Row } from '../../components/ListTable/Row'
import { TableItem } from '../../components/ListTable/TableItem'

import { EditPencil, Trash, Calendar } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { HistoryModal } from '../../components'
import SimpleEditionModalTemplate from '../../components/SimpleEditionModalTemplate'

import {
  BusinessClient,
  BusinessUser,
  ProductService,
  PublicationService,
  SubscriptionService,
  TargetAudienceService,
  TimelineActionsService,
  UserPlanCount,
} from '../../services'
import { Loading } from '../../components/Loading'
import { useNavigate } from 'react-router-dom'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'

import { useClient } from '../../hooks/useClient'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import ModalValidDowngrade from '../../components/ModalValidDowngrade'

export const HistoryPage = () => {
  const { t } = useTranslation()
  const { clientData } = useClient()

  const [isSimplesEditModalOpen, setIsSimplesEditModalOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [imageUrl, setImageUrl] = useState(undefined)
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const navigation = useNavigate()

  const [imageLayers, setImageLayers] = useState([])

  const [publications, setPublications] = useState([])

  const [loading, setLoading] = useState(true)

  const [simplesEditModalData, setSimplesEditModalData] = useState({})

  const pageRef = useRef(1)
  const limitRef = useRef(10)
  const offsetRef = useRef(0)

  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [totalPages, setTotalPages] = useState(1)

  const navigate = useNavigate()

  const [headlineText, setHeadlineText] = useState('') //simplesEditModalData?.headline
  const [headlineSubtitle, setHeadlineSubtitle] = useState('') //simplesEditModalData?.legend
  const [blockDowngrade, setBlockDowngrade] = useState(false)

  const headers = [
    // 'Código',
    t('historyPage.publication'),
    t('historyPage.campaign'),
    t('historyPage.creation_date'),
    t('historyPage.publication_date'),
    t('historyPage.actions'),
  ]

  useEffect(() => {
    pageRef.current = 1
    offsetRef.current = 0
    loadData()
    setHeadlineText(simplesEditModalData?.headline ?? '')
    setHeadlineSubtitle(simplesEditModalData?.legend ?? '')
  }, [clientData])

  const loadData = async (isLoadingMore = false) => {
    if (!isLoadingMore) {
      setLoading(true)
      offsetRef.current = 0
      pageRef.current = 1
    }

    const currentPage = isLoadingMore ? pageRef.current : 1

    if (clientData?.uuid === undefined) {
      const { data, responseStatus } =
        await PublicationService.getAllPublicationByLoginByNotBusinessClient(
          currentPage,
          limitRef.current,
          offsetRef.current
        )

      if (responseStatus === 200) {
        if (isLoadingMore) {
          setPublications((prev) => [...prev, ...data.data])

          console.log('data', data.data)
        } else {
          console.log('data', data.data)
          setPublications(data.data)
        }
        setHasMore(data.meta.currentPage < data.meta.totalPages)
        setTotalPages(data.meta.totalPages)
      }

      setLoading(false)
      setLoadingMore(false)
      return
    }

    const { data, responseStatus } =
      await PublicationService.getAllPublicationByLoginByBusinessClientId(
        clientData?.uuid,
        currentPage,
        limitRef.current,
        offsetRef.current
      )

    if (responseStatus === 200) {
      if (isLoadingMore) {
        setPublications((prev) => [...prev, ...data.data])
      } else {
        setPublications(data.data)
      }
      setHasMore(data.meta.currentPage < data.meta.totalPages)
      setTotalPages(data.meta.totalPages)
    }

    setLoading(false)
    setLoadingMore(false)
  }

  const handleValidBlockDowngrade = async (userSubscription) => {
    let blockDowngrade = false
    const productCount = await ProductService.getAllproductCount()
    console.log('userSubscription', userSubscription)
    if (userSubscription.data.length <= 0 && productCount.data.count > 30) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'product_quantity'
      )
      const quantityProduct =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        productCount.data.count > quantityProduct &&
        quantityProduct != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessTargetAudienceCount =
      await TargetAudienceService.getAllTargetAudienceCount()

    if (
      userSubscription.data.length <= 0 &&
      businessTargetAudienceCount.data.count > 30
    ) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'target_audience_quantity'
      )
      const quantityTargetAudience =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        businessTargetAudienceCount.data.count > quantityTargetAudience &&
        quantityTargetAudience != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessUserCount = await BusinessUser.getAllBusinessUserCount()
    if (userSubscription.data.length <= 0 && businessUserCount.data.count > 1) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const indexUser = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'user_quantity'
      )
      const quantityItemUser =
        userSubscription.data[0].Plan.PlanItems[indexUser].quantity

      if (businessUserCount.data.count > quantityItemUser) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessClientCount = await BusinessClient.getAllBusinessClientCount()

    if (
      userSubscription.data.length <= 0 &&
      businessClientCount.data.count > 1
    ) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'client_quantity'
      )
      const quantityClient =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        businessClientCount.data.count > quantityClient &&
        quantityClient != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    return blockDowngrade
  }

  const handleOpenSimpleEditionModal = async (data) => {
    const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    const blockDowngrade = await handleValidBlockDowngrade(userSubscription)

    if (blockDowngrade) {
      setBlockDowngrade(true)
      setLoading(false)
      return
    }

    if (
      userSubscription.data.length <= 0 &&
      userPlanCount.data.ownScheduleCount >= 20
    ) {
      notifyWarningCustom(t('plans.user_plan_count_scheduling'))
      setLoading(false)
      return
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'scheduling_quantity'
      )
      const quantityAiImage =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        userPlanCount.data.ownScheduleCount >= quantityAiImage &&
        quantityAiImage != null
      ) {
        notifyWarningCustom(t('plans.user_plan_count_scheduling'))
        setLoading(false)
        return
      }
    }

    // if (
    //   userSubscription.data.length <= 0 &&
    //   (publicationCount.data.count >= 8 || schedulingCount.data.count >= 60)
    // ) {
    //   notifyWarningCustom(
    //     'Você atingiu o limite para realizar reagendamentos, consulte nossos planos para mais informações!'
    //   )
    //   return
    // } else if (userSubscription.data.length > 0) {
    //   const indexSchedulling =
    //     await userSubscription.data[0].Plan.PlanItems.findIndex(
    //       (item) => item.type === 'scheduling_quantity'
    //     )
    //   const quantityItemSchedulling =
    //     userSubscription.data[0].Plan.PlanItems[indexSchedulling].quantity

    //   if (
    //     schedulingCount.data.count >= quantityItemSchedulling &&
    //     quantityItemSchedulling != null
    //   ) {
    //     notifyWarningCustom(
    //       'Você atingiu o limite para realizar reagendamentos, consulte nossos planos para mais informações!'
    //     )
    //     return
    //   }
    // }
    const publication = await PublicationService.getPublication(data.uuid)

    setImageLayers(publication.data[0])

    setIsSimplesEditModalOpen(true)
  }

  const handleOpenSimpleEditionModalSelfPublication = async (publication) => {
    const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    const blockDowngrade = await handleValidBlockDowngrade(userSubscription)

    if (blockDowngrade) {
      setBlockDowngrade(true)
      setLoading(false)
      return
    }

    if (
      userSubscription.data.length <= 0 &&
      userPlanCount.data.ownScheduleCount >= 20
    ) {
      notifyWarningCustom(t('plans.user_plan_count_scheduling'))
      setLoading(false)
      return
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'scheduling_quantity'
      )
      const scheduling =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        userPlanCount.data.ownScheduleCount >= scheduling &&
        scheduling != null
      ) {
        notifyWarningCustom(t('plans.user_plan_count_scheduling'))
        setLoading(false)
        return
      }
    }
    navigate('/NewPublicationPageMyPublication', {
      state: {
        data: publication,
        movie: true,
      },
    })
  }

  const handleOpenSimpleEditionModalSelfImage = async (publication) => {
    const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    const blockDowngrade = await handleValidBlockDowngrade(userSubscription)

    if (blockDowngrade) {
      setBlockDowngrade(true)
      setLoading(false)
      return
    }

    if (
      userSubscription.data.length <= 0 &&
      userPlanCount.data.ownScheduleCount >= 20
    ) {
      notifyWarningCustom(t('plans.user_plan_count_scheduling'))
      setLoading(false)
      return
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'scheduling_quantity'
      )
      const quantityAiImage =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        userPlanCount.data.ownScheduleCount >= quantityAiImage &&
        quantityAiImage != null
      ) {
        notifyWarningCustom(t('plans.user_plan_count_scheduling'))
        setLoading(false)
        return
      }
    }
    navigate('/NewPublicationPage', {
      state: {
        edit: true,
        dataPublication: publication?.data[0],
      },
    })
  }

  const handleCloseSimpleEditionModal = () => {
    setIsSimplesEditModalOpen(false)
  }

  const handleDeletePost = async (id) => {
    setLoading(true)

    const publicationResponse = await PublicationService.deletePublication(id)

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom('Rascunho removido com sucesso!')
    } else {
      notifyErrorCustom('Erro ao remover rascunho!')
    }
    setLoading(false)
    loadData()
  }

  function formatDate(data) {
    return dayjs(data).format('DD/MM/YYYY HH:mm')
  }

  const VideoThumbnail = ({ url }) => {
    return (
      <video
        src={url}
        preload="metadata"
        style={{
          height: '60px',
          width: '60px',
          objectFit: 'cover',
          borderRadius: '5px',
        }}
      />
    )
  }

  const handleLoadMore = () => {
    if (pageRef.current >= totalPages) {
      setHasMore(false)
      return
    }

    setLoadingMore(true)
    offsetRef.current += limitRef.current
    pageRef.current += 1
    loadData(true)
  }

  const handleCloseModal = () => {
    setBlockDowngrade(false)
  }

  return (
    <>
      <Loading enable={loading} />
      <ModalValidDowngrade
        // setImageAIArray={setImageAIArray}
        // setDowngradeNow={setDowngradeNow}
        isOpen={blockDowngrade}
        onClose={() => handleCloseModal()}
        handleCloseModal={handleCloseModal}
      />

      <HistoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalData={modalData}
        setModalData={setModalData}
        scheduling={true}
      />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ListTable
          headers={headers}
          label={t('historyPage.history')}
          title={t('historyPage.historyTitle')}
          disabledButton={true}
        >
          {publications.map((publication, index) => (
            <Row key={index}>
              <TableItem style={{}}>
                <div
                  className={`${styles.table_item_gap} ${styles.secondColumn}`}
                >
                  <div>
                    {!publication?.publicationMovieUrl && (
                      <img
                        src={publication?.publicationImageUrl}
                        alt=""
                        style={{
                          height: '60px',
                          width: '45px',
                          objectFit: 'cover',
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    )}

                    {publication?.publicationThumbnailUrl && (
                      <img
                        src={publication?.publicationThumbnailUrl}
                        alt=""
                        style={{
                          height: '60px',
                          width: '45px',
                          objectFit: 'cover',
                        }}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    )}

                    {publication?.publicationMovieUrl &&
                      !publication?.publicationThumbnailUrl && (
                        <VideoThumbnail
                          url={publication?.publicationMovieUrl}
                        />
                      )}

                    {/* <VideoThumbnail url={publication?.publicationMovieUrl} /> */}
                  </div>

                  <p className={styles.textLineRender}>
                    {publication?.headline || 'Post Próprio'}
                  </p>
                </div>
              </TableItem>

              <TableItem>
                <p className={styles.textLineRender}>
                  {publication?.Campaign?.name && publication?.headline
                    ? publication?.Campaign?.name
                    : !publication?.Campaign?.name && publication?.headline
                    ? 'Publicação Única'
                    : 'Post Próprio'}
                </p>
              </TableItem>
              <TableItem>
                <span className={styles.bold}>
                  {formatDate(publication?.createdAt)}
                </span>
              </TableItem>
              <TableItem>
                <span className={styles.bold}>
                  {formatDate(publication?.publicationDate)}
                </span>
              </TableItem>
              <TableItem style={{ maxWidth: 170 }}>
                <div className={styles.last_column}>
                  <div className={styles.icons_container}>
                    {!publication?.publicationMovieUrl && (
                      <div
                        onClick={async () => {
                          if (publication?.headline) {
                            handleOpenSimpleEditionModal(publication)
                          } else {
                            const publicationInfo =
                              await PublicationService.getPublication(
                                publication?.uuid
                              )
                            handleOpenSimpleEditionModalSelfImage(
                              publicationInfo
                            )
                            // navigate('/NewPublicationPage', {
                            //   state: {
                            //     edit: true,
                            //     dataPublication: publicationInfo?.data[0],
                            //   },
                            // })
                          }
                        }}
                        className={styles.icon}
                      >
                        {publication?.headline ? (
                          <EditPencil color={'#0F172A'} />
                        ) : (
                          <Calendar color={'#0F172A'} />
                        )}
                      </div>
                    )}

                    {publication?.publicationMovieUrl && (
                      <div
                        onClick={async () => {
                          handleOpenSimpleEditionModalSelfPublication(
                            publication
                          )
                        }}
                        className={styles.icon}
                      >
                        <Calendar color={'#0F172A'} />
                      </div>
                    )}

                    <div
                      className={styles.icon}
                      onClick={() => {
                        if (publication?.publicationMovieUrl) {
                          return
                        }

                        handleDeletePost(publication?.uuid)
                      }}
                    >
                      <Trash color={'#000000'} />
                    </div>
                  </div>
                </div>
              </TableItem>
            </Row>
          ))}
        </ListTable>

        {hasMore && (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <label
              onClick={handleLoadMore}
              style={{
                padding: '10px 20px',
                cursor: loadingMore ? 'default' : 'pointer',
                backgroundColor: '#0000',
                borderRadius: '4px',
                display: 'inline-block',
                userSelect: 'none',
              }}
            >
              {loadingMore ? 'Carregando...' : 'Carregar mais'}
            </label>
          </div>
        )}
      </div>

      <SimpleEditionModalTemplate
        isOpen={isSimplesEditModalOpen}
        setNextStep={(teste1, teste2) => {
          navigation('/NewPublicationPage', {
            state: {
              edit: true,
              dataPublication: {
                ...imageLayers,
                generateArtJson: {
                  ...teste2?.tempImageLayers,
                  srcComTratativa: teste1.replace(
                    /^data:image\/png;base64,/,
                    ''
                  ),
                },
              },
            },
          })
        }}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        imageLayers={imageLayers?.generateArtJson}
        setImageLayers={setImageLayers}
        onClose={handleCloseSimpleEditionModal}
        title={headlineText}
        subtitle={headlineSubtitle}
        publicTargetOptions={[]}
        productOptions={[]}
        // onChangeColorPalleteSubtittle={(e) => {}}
        //onChangeColorPalleteTittle={(e) => {}}
        onChangeColorPalleteTittle={(e) => setTitle(e.target.value)}
        onChangeColorPalleteSubtittle={(e) => setSubTitle(e.target.value)}
      />
    </>
  )
}
