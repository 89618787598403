import { useNavigate } from 'react-router-dom'
import styles from './styles.module.css'

import posteiLogo from '../../../../assets/logos/postei-logo.svg'
import posteiLogoText from '../../../../assets/logos/postei-logo-text.svg'
import { UserAccountsService } from '../../../../services'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',

  STEP_NOTICE: 'NoticeStep',

  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepNotice({ setStep, firstLogin }) {
  const navigation = useNavigate()

  function handleNavigate(route) {
    if (firstLogin) {
      updateFirstLogin()
      //return
    }
    navigation(route)
  }

  const handleSteps = () => {
    if (firstLogin) {
      updateFirstLogin()
      //return
    }
    setStep(STEPS.STEP_SOCIAL_MEDIA)
  }

  const updateFirstLogin = async () => {
    const { ...rest } = firstLogin

    delete rest.uuid
    delete rest.createdAt
    delete rest.updatedAt
    delete rest.deletedAt

    rest.firstLogin = false
    rest.photoName = ''
    rest.photoUrl = ''

    const { data, responseStatus } =
      await UserAccountsService.putUserAccountsByLogin(rest)
  }

  return (
    <div className={styles.page}>
      <div className={styles.logo}>
        {/* <img src={posteiLogo} alt="posteiLogo" style={{ height: 34 }} /> */}
        <img
          src={posteiLogoText}
          alt="posteiLogoText"
          style={{ height: 34 }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content_title}>
            <h1 style={{ fontSize: 32 }}>Atenção!</h1>
          </div>
          <div className={styles.content_subtitle}>
            <h2 style={{ fontSize: 18 }}>
              Você pode optar por parar aqui e prosseguir para criar sua
              primeira publicação. Porém a assertividade da nossa IA será
              reduzida consideravelmente.
            </h2>

            <h2 style={{ fontSize: 18, marginTop: 26 }}>
              Completando os passos a seguir poderemos coletar mais dados
              preciosos sobre suas preferências e seu negócio!
            </h2>
          </div>
        </div>

        <div className={styles.container_buttons}>
          <button
            style={{
              backgroundColor: '#fff',
              color: '#190027',
              borderRadius: 4,
              padding: '12px 24px',
              fontSize: 14,
              fontWeight: 500,
            }}
            onClick={() => handleSteps()}
          >
            Continuar configurações
          </button>

          <button
            style={{
              backgroundColor: '#190027',
              border: '1px solid #fff',
              borderRadius: 4,
              padding: '14px 24px',
              fontSize: 12,
            }}
            onClick={() => handleNavigate('/HomePage')}
          >
            Quero concluir as configurações depois
          </button>
        </div>
      </div>
    </div>
  )
}

export default StepNotice
