/* eslint-disable react-refresh/only-export-components */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */

import { createContext, useContext, useState } from 'react'
import { useLocalStorage } from './useLocalStorage'

const NavigationContext = createContext()

export const NavigationProvider = ({ children }) => {
  const [updateCampaing, setUpdateCampaing] = useState(false)

  const value = {updateCampaing, setUpdateCampaing}
  return (
    <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
  )
}

export const useNavigationHook = () => {
  return useContext(NavigationContext)
}
