/* eslint-disable react/prop-types */
import { motion } from 'framer-motion'
import styles from './styles.module.css'
import { useEffect, useState } from 'react'
import {
  Clips,
  Folder,
  Pen2,
  Picture,
  // Picture,
  Stars,
  // Trash,
  SupermarketCart,
} from '../../../../assets/svgs/icons'
import ModalComponent from '../../../ModalComponent'
import ModalArtiificialInteligency from '../../../../pages/NewPostFormPage/StepSecondPass/ModalArtificalInteligency'
import ModalImageBank from '../../../../pages/NewPostFormPage/StepSecondPass/ModalImageBank'
import ModalUploads from '../../../../pages/NewPostFormPage/StepSecondPass/ModalUploads'
import ModalGalery from '../../../../pages/NewPostFormPage/StepSecondPass/ModalGalery'
import ModalProducts from '../../../../pages/NewPublicationPage/StepSecondPass/ModalProducts'

import {
  FreepikService,
  PythonService,
  SubscriptionService,
} from '../../../../services'
export const ImageStep = ({
  imageLayers,
  setImageLayers,
  editTemplate,
  setLoading,
}) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalAIOpen, setModalAIOpen] = useState(false)
  const [isModalImageBankOpen, setModalImageBankOpen] = useState(false)
  const [isModalUploadsOpen, setModalUploadsOpen] = useState(false)
  const [isModalProductsOpen, setModalProductsOpen] = useState(false)

  const [editImageEdit, setEditImageEdit] = useState(undefined)

  const newHandleUpdateLayer = async (base64String, imageBank = false) => {
    if (imageBank) {
      const userSubscription = await SubscriptionService.getSubscriptionByUser()

      if (userSubscription.data.length > 0) {
        const imageDownloaded = await FreepikService.getFreepikDownload(
          base64String
        )
        console.log('imageDownloaded', imageDownloaded)

        editTemplate(editImageEdit, imageDownloaded.data)
      } else {
        editTemplate(editImageEdit, base64String)
      }
    } else {
      editTemplate(editImageEdit, base64String)

      console.log('39 base64String ', base64String)
    }

    // if (base64String && !imageDownloaded) {
    //   editTemplate(editImageEdit, base64String)
    // }

    // const imageDownloaded = await FreepikService.getFreepikDownload(
    //   base64String.idFreepik
    // )

    // console.log('imageDownloaded', imageDownloaded)
    // //return

    // if (imageDownloaded.data) {
    //   editTemplate(editImageEdit, imageDownloaded.data)

    if (editImageEdit) {
      setEditImageEdit(undefined)
    }
    // } else {
    //   console.log('Nenhum arquivo selecionado.')
    // }
  }

  const handleOpenModal = (modal) => {
    if (modal === 'galeria') {
      setModalOpen(true)
    }
    if (modal === 'imageBank') {
      setModalImageBankOpen(true)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(true)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(true)
    }
    if (modal === 'produtos') {
      setModalProductsOpen(true)
    }
  }

  const handleCloseModal = (modal) => {
    //

    if (modal === 'galeria') {
      setModalOpen(false)
    }
    if (modal === 'imageBank') {
      setModalImageBankOpen(false)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(false)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(false)
    }
    if (modal === 'produtos') {
      setModalProductsOpen(false)
    }
  }

  const handleRemoveBackground = async (index, camada) => {
    const { data } = await PythonService.postRemoveBackground({
      image: 'data:image/png;base64,' + camada?.value,
    })

    if (data?.status === 200) {
      const newData = data?.data?.images[1]?.image.replace(
        /^data:image\/[a-z]+;base64,/,
        ''
      )

      editTemplate(index, newData)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    setImageLayers(imageLayers)
  }, [imageLayers])

  const SelectedImageField = () => {
    return (
      <div className={styles.container_box_image}>
        <ModalComponent
          isOpen={isModalOpen}
          onClose={() => handleCloseModal('galeria')}
          className={styles.teste}
        >
          <ModalGalery
            closeModal={handleCloseModal}
            updateImage={(value) => {
              setLoading(true)
              handleCloseModal('galeria')

              if (value && value?.length > 0) {
                let base64String = event.target.result
                base64String = value[0].fileDataBase64.replace(
                  /^data:image\/[a-z]+;base64,/,
                  ''
                )

                newHandleUpdateLayer(base64String)

                if (editImageEdit) {
                  setEditImageEdit(undefined)
                }
              }
            }}
          />
        </ModalComponent>

        <ModalComponent
          isOpen={isModalImageBankOpen}
          onClose={() => handleCloseModal('imageBank')}
          className={styles.teste}
        >
          <ModalImageBank
            closeModal={handleCloseModal}
            updateImage={(value) => {
              setLoading(true)
              handleCloseModal('imageBank')

              console.log('ValueModalImageBank', value)

              if (value && value?.length > 0) {
                newHandleUpdateLayer(
                  value[0]?.base64 ? value[0]?.base64 : value[0]?.idFreepik,
                  true
                )
              }
            }}
          />
        </ModalComponent>

        <ModalComponent
          isOpen={isModalAIOpen}
          onClose={() => handleCloseModal('inteligenciaArtificial')}
          className={styles.teste}
        >
          <ModalArtiificialInteligency
            closeModal={handleCloseModal}
            updateImage={(value) => {
              setLoading(true)
              handleCloseModal('inteligenciaArtificial')

              console.log('valueIa', value)

              if (value && value?.length > 0) {
                newHandleUpdateLayer(value[0]?.image)
              }
            }}
          />
        </ModalComponent>

        <ModalComponent
          isOpen={isModalUploadsOpen}
          onClose={() => handleCloseModal('uploads')}
          className={styles.teste}
        >
          <ModalUploads
            closeModal={handleCloseModal}
            updateImage={(value) => {
              setLoading(true)
              handleCloseModal('uploads')

              if (value && value?.length > 0) {
                newHandleUpdateLayer(value[0]?.fileBase64)
              }
            }}
          />
        </ModalComponent>

        <ModalComponent
          isOpen={isModalProductsOpen}
          onClose={() => handleCloseModal('produtos')}
          className={styles.teste}
        >
          <ModalProducts
            closeModal={handleCloseModal}
            setImageProductsArray={(value) => {
              setLoading(true)

              if (value && value?.length > 0) {
                newHandleUpdateLayer(
                  value[0]?.fileDataBase64.replace('data:image/png;base64,', '')
                )
              }
            }}
          />
        </ModalComponent>

        <div
          className={styles.box_image}
          onClick={() => handleOpenModal('galeria')}
        >
          <div className={styles.box_image_svg}>
            <Clips width={'20px'} height={'20px'} />
          </div>
          <div className={styles.box_image_text}>
            <p className={styles.box_image_title}>Galeria</p>
          </div>
        </div>

        {/* <div
          onClick={() => handleOpenModal('galeria')}
          className={styles.box_image}
        >
          <div className={styles.box_image_svg}>
            <Picture width={'25px'} height={'25px'} />
          </div>
          <div className={styles.box_image_text}>
            <p className={styles.box_image_title}>Banco de imagens</p>
          </div>
        </div> */}

        <div
          className={styles.box_image}
          onClick={() => handleOpenModal('imageBank')}
        >
          <div className={styles.box_image_svg}>
            <Picture width={'25px'} height={'25px'} />
          </div>
          <div className={styles.box_image_text}>
            <p className={styles.box_image_title}>Banco de imagens</p>
          </div>
        </div>

        <div
          className={styles.box_image}
          onClick={() => handleOpenModal('inteligenciaArtificial')}
        >
          <div className={styles.box_image_svg}>
            <Stars width={'25px'} height={'25px'} />
          </div>
          <div className={styles.box_image_text}>
            <p className={styles.box_image_title}>Inteligência Artificial</p>
          </div>
        </div>

        {/* <div
          className={styles.box_image}
          onClick={() => handleOpenModal('uploads')}
        >
          <div className={styles.box_image_svg}>
            <Folder width={'25px'} height={'25px'} />
          </div>
          <div className={styles.box_image_text}>
            <p className={styles.box_image_title}>Meus Uploads</p>
          </div>
        </div> */}

        <div
          className={styles.box_image}
          onClick={() => handleOpenModal('produtos')}
        >
          <div className={styles.box_image_svg}>
            <SupermarketCart width={30} height={30} />
          </div>
          <div className={styles.box_image_text}>
            <p className={styles.box_image_title}>Produtos</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <motion.div
      className={styles.animated_container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className={styles.imageUploadComponentContainer}>
        <span className={styles.label}>Imagem</span>

        <div className={styles.container_list_layers}>
          {imageLayers?.params?.map((camada, index) => {
            if (camada?.type !== 'imagem') return null

            return (
              <div key={index}>
                {editImageEdit === index && SelectedImageField()}

                <div className={styles.container_list_image}>
                  <div className={styles.item_list_image}>
                    <img
                      src={`data:image/png;base64,${camada?.value}`}
                      alt="file-preview"
                      style={{ width: 40, height: 40, objectFit: 'cover' }}
                      onContextMenu={(e) => e.preventDefault()}
                    />
                    <div className={styles.box_data_info}>
                      <p className={styles.box_data_info_title}>
                        {camada?.type}
                      </p>
                      <p className={styles.box_data_info_size}>
                        Tamanho &nbsp;
                      </p>
                    </div>
                  </div>
                  <div className={styles.item_list_image_buttons}>
                    <button
                      className={styles.button_remove_background}
                      onClick={() => {
                        if (editImageEdit === index) {
                          setEditImageEdit(undefined)
                        } else {
                          setEditImageEdit(index)
                        }
                      }}
                    >
                      <Pen2 />
                    </button>

                    <button
                      className={styles.button_cancel}
                      onClick={() => {
                        setLoading(true)
                        handleRemoveBackground(index, camada)
                      }}
                    >
                      <Stars />
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </motion.div>
  )
}
