import styles from './styles.module.css'

import { useNavigate } from 'react-router-dom'
import {
  Accept,
  Interrogation,
  MultiplePhotos,
  Picture,
  UploadIcon,
} from '../../assets/svgs/icons'
import {
  BusinessClient,
  BusinessUser,
  ProductService,
  PublicationService,
  SubscriptionService,
  TargetAudienceService,
  TimelineActionsService,
  UserPlanCount,
} from '../../services'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'
import { useState } from 'react'
import Loading from '../../components/Loading'
import { motion } from 'framer-motion'
import ModalComponent from '../../components/ModalValidDowngrade'

// import { ProductFruits } from 'react-product-fruits'
import { useTranslation } from 'react-i18next'
import ModalValidDowngrade from '../../components/ModalValidDowngrade'

function NewPostPage() {
  const navigation = useNavigate()
  const [loading, setLoading] = useState(false)
  const [blockDowngrade, setBlockDowngrade] = useState(false)

  const handleValidBlockDowngrade = async (userSubscription) => {
    let blockDowngrade = false
    const productCount = await ProductService.getAllproductCount()

    if (userSubscription.data.length <= 0 && productCount.data.count > 30) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'product_quantity'
      )
      const quantityProduct =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        productCount.data.count > quantityProduct &&
        quantityProduct != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessTargetAudienceCount =
      await TargetAudienceService.getAllTargetAudienceCount()

    if (
      userSubscription.data.length <= 0 &&
      businessTargetAudienceCount.data.count > 30
    ) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'target_audience_quantity'
      )
      const quantityTargetAudience =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        businessTargetAudienceCount.data.count > quantityTargetAudience &&
        quantityTargetAudience != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessUserCount = await BusinessUser.getAllBusinessUserCount()
    if (userSubscription.data.length <= 0 && businessUserCount.data.count > 1) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const indexUser = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'user_quantity'
      )
      const quantityItemUser =
        userSubscription.data[0].Plan.PlanItems[indexUser].quantity

      if (businessUserCount.data.count > quantityItemUser) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    const businessClientCount = await BusinessClient.getAllBusinessClientCount()

    if (
      userSubscription.data.length <= 0 &&
      businessClientCount.data.count > 1
    ) {
      blockDowngrade = true
      return blockDowngrade
    } else if (userSubscription.data.length > 0) {
      const index = await userSubscription.data[0].Plan.PlanItems.findIndex(
        (item) => item.type === 'client_quantity'
      )
      const quantityClient =
        userSubscription.data[0].Plan.PlanItems[index]?.quantity

      if (
        businessClientCount.data.count > quantityClient &&
        quantityClient != null
      ) {
        blockDowngrade = true
        return blockDowngrade
      }
    }

    return blockDowngrade
  }

  const handleNavigate = async (route) => {
    setLoading(true)

    const userPlanCount = await UserPlanCount.getUserPlanCountByUser()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()
    const mesAtual = new Date(new Date().setDate(1)) // Primeiro dia do mês atual
    mesAtual.setDate(mesAtual.getDate() + 30)
    mesAtual.setHours(0, 0, 0, 0)

    const blockDowngrade = await handleValidBlockDowngrade(userSubscription)

    if (blockDowngrade) {
      setBlockDowngrade(true)
      setLoading(false)
      return
    }

    if (!userPlanCount.data) {
      const postUserPlanCount = await UserPlanCount.postUserPlanCount({
        ownScheduleCount: 0,
        postOnDesignCount: 0,
        imageBankCount: 0,
        aiImageCount: 0,
        aiLegendCount: 0,
        aiMessageCount: 0,
        renewalDate:
          userSubscription.data.length > 0
            ? userSubscription.data[0].expiration
            : mesAtual,
      })
    } else {
      const renewalDate = new Date(userPlanCount.data.renewalDate)
      const dataAtual = new Date()

      const expirationPlan =
        userSubscription.data.length > 0
          ? new Date(userSubscription.data[0].expiration)
          : new Date(mesAtual)

      if (
        renewalDate.getTime() < dataAtual.getTime() ||
        renewalDate.getTime() != expirationPlan.getTime()
      ) {
        await UserPlanCount.postUserPlanCount({
          ownScheduleCount: 0,
          postOnDesignCount: 0,
          imageBankCount: 0,
          aiImageCount: 0,
          aiLegendCount: 0,
          aiMessageCount: 0,
          renewalDate:
            userSubscription.data.length > 0
              ? userSubscription.data[0].expiration
              : mesAtual,
        })
      } else {
        if (route == '/NewPublicationPageMyPublication') {
          if (
            userSubscription.data.length <= 0 &&
            userPlanCount.data.ownScheduleCount >= 20
          ) {
            notifyWarningCustom(t('plans.user_plan_count_scheduling'))
            setLoading(false)
            return
          } else if (userSubscription.data.length > 0) {
            const index =
              await userSubscription.data[0].Plan.PlanItems.findIndex(
                (item) => item.type === 'scheduling_quantity'
              )
            const quantityAiImage =
              userSubscription.data[0].Plan.PlanItems[index]?.quantity

            if (
              userPlanCount.data.ownScheduleCount >= quantityAiImage &&
              quantityAiImage != null
            ) {
              notifyWarningCustom(t('plans.user_plan_count_scheduling'))
              setLoading(false)
              return
            }
          }
        } else {
          if (
            userSubscription.data.length <= 0 &&
            userPlanCount.data.postOnDesignCount >= 8
          ) {
            notifyWarningCustom(t('plans.user_plan_count_post_design'))
            setLoading(false)
            return
          } else if (userSubscription.data.length > 0) {
            const index =
              await userSubscription.data[0].Plan.PlanItems.findIndex(
                (item) => item.type === 'post_quantity'
              )
            const quantityAiImage =
              userSubscription.data[0].Plan.PlanItems[index]?.quantity

            if (
              userPlanCount.data.postOnDesignCount >= quantityAiImage &&
              quantityAiImage != null
            ) {
              notifyWarningCustom(t('plans.user_plan_count_post_design'))
              setLoading(false)
              return
            }
          }
        }
      }
    }

    navigation(`${route}`)
    setLoading(false)
  }
  const storage = JSON.parse(window.localStorage.getItem('userData')).userEmail
  const userInfo = {
    username: storage, // REQUIRED, must be unique
  }

  const { t } = useTranslation()

  const handleCloseModal = () => {
    setBlockDowngrade(false)
  }

  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%' }}
    >
      <ModalValidDowngrade
        // setImageAIArray={setImageAIArray}
        // setDowngradeNow={setDowngradeNow}
        isOpen={blockDowngrade}
        onClose={() => handleCloseModal()}
        handleCloseModal={handleCloseModal}
      />
      <div className={styles.page}>
        <Loading enable={loading} />
        <div className={styles.container_new_posts}>
          <div className={styles.title_new_posts}>
            <p className={styles.label_page}>{t('new_post_page_new')}</p>
            <p className={styles.title_page}>{t('new_post_page_post')}</p>
          </div>
          <div className={styles.container_card}>
            <div
              className={styles.card_post}
              style={{
                backgroundImage:
                  'linear-gradient(to bottom right, #A6A6A6,#808080 , #595959)',
              }}
            >
              <div className={styles.card_post_header}>
                <div
                  className={styles.card_post_icon}
                  style={{ background: '#a1a1a1' }}
                >
                  <UploadIcon />
                </div>
                <Interrogation color="#fff" />
              </div>
              <div className={styles.card_post_body}>
                <h2>{t('new_post_page_first_card_title')}</h2>
                <p
                  style={{
                    width: '100%',
                  }}
                >
                  {t('new_post_page_first_card_subtitle')}
                </p>
              </div>
              <div className={styles.card_post_footer}>
                <button
                  className={styles.button_select}
                  style={{ borderRadius: 4 }}
                  onClick={() => {
                    // handleNavigate('/NewPostFormPage')
                    handleNavigate('/NewPublicationPageMyPublication')
                  }}
                >
                  {t('new_post_page_button_select')}{' '}
                  <Accept color={'#00B828'} />
                </button>
              </div>
            </div>

            <div className={styles.card_post}>
              <div className={styles.card_post_header}>
                <div
                  className={styles.card_post_icon}
                  style={{ background: '#841bb2' }}
                >
                  <Picture />
                </div>
                <Interrogation color="#fff" />
              </div>
              <div className={styles.card_post_body}>
                <h2>{t('new_post_page_second_card_title')}</h2>
                <p>{t('new_post_page_second_card_subtitle')}</p>
              </div>
              <div className={styles.card_post_footer}>
                <button
                  className={styles.button_select}
                  style={{ borderRadius: 4 }}
                  onClick={() => {
                    // handleNavigate('/NewPostFormPage')
                    handleNavigate('/NewPublicationPage')
                  }}
                >
                  {t('new_post_page_button_select')}{' '}
                  <Accept color={'#00B828'} />
                </button>
              </div>
            </div>

            <div className={styles.card_campaigns}>
              <div className={styles.card_post_header}>
                <div
                  className={styles.card_post_icon}
                  style={{ background: '#EE951A' }}
                >
                  <MultiplePhotos color="#fff" size={4000} />
                </div>
                <Interrogation color="#fff" />
              </div>
              <div className={styles.card_post_body}>
                <h2>{t('new_post_page_third_card_title')}</h2>
                <p>{t('new_post_page_third_card_subtitle')} </p>
              </div>
              <div className={styles.card_post_footer}>
                <button
                  className={styles.button_select}
                  style={{ borderRadius: 4 }}
                  onClick={() => {
                    // handleNavigate('/NewCampaingFormPage')
                    handleNavigate('/NewPublicationPageCampaign')
                  }}
                >
                  {t('new_post_page_button_select')}{' '}
                  <Accept color={'#00B828'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default NewPostPage
