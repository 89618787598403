import { ArrowRigth, NavigateArrowLeft, NavigateArrowRigth } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { useTranslation } from 'react-i18next'

export const FooterNaviBar = ({
  back,
  next
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container_nav_bar}>
      <div className={styles.box_nav_bar}>
        <button 
          className={styles.button_left} 
          onClick={back}
        >
          <NavigateArrowLeft color={'#8E8E8E'} />
          {t('fotter_bar.back')}
        </button>
        <button 
          className={styles.button_right} 
          onClick={next}
        >
          {t('fotter_bar.next')}
          <NavigateArrowRigth color={'#ffffff'}/>
        </button>
      </div>
    </div>
  )
}
