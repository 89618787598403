/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react'
import styles from './styles.module.css'

import posteiLogoColor from '../../assets/logos/postei-logo.svg'
import xamaLogoBranco from '../../assets/logos/xama-logo-branco.png'


import { InterrogationCircleIcon } from '../../assets/svgs/icons'
import {
  UserIcon,
  InformationIcon,
  BrushIcon,
  PhotoIcon,
  SocialMediaIcon,
  TargetIcon,
  CarIcon,
  ChatIcon,
  ImageListIcon,
  ArchetypeIcon,
  PreferencesIcon,
} from '../../assets/svgs/iconsSteps'

import StepRegistrationData from '../../containers/stepRegistrationData'
import StepBusinessInformation from '../../containers/stepBusinessInformation'
import StepBranding from '../../containers/stepBranding'
import StepLogos from '../../containers/stepLogos'

import NoticeStep from '../../containers/stepNotice'

import StepVoiceTone from '../../containers/stepVoiceTone'
import StepVisualPreferences from '../../containers/stepVisualPreferences'
import StepEffects from '../../containers/stepEffects'
import StepVisualReferences from '../../containers/stepVisualReferences'
import StepSocialMedia from '../../containers/stepSocialMedia'
import StepTargetAudience from '../../containers/stepTargetAudience'
import StepProduct from '../../containers/stepProduct'
import StepBrandArchetype from '../../containers/stepBrandArchetype'

import { useLocation } from 'react-router-dom'

import { useClient } from '../../hooks/useClient'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',

  STEP_NOTICE: 'NoticeStep',

  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
}

function StepsPage() {
  const { clientData } = useClient()
  const { t } = useTranslation()
  const location = useLocation()

  const [step, setStep] = useState(STEPS.STEP_REGISTRATION_DATA)
  const [iaData, setIaData] = useState(undefined)

  const [firstLoginParam, setfirstLoginParam] = useState({})

  useEffect(() => {
    if (location?.state?.queryCode || location?.state?.redirect) {
      setStep(STEPS.STEP_SOCIAL_MEDIA)
    }
    if (location?.state?.param) {
      setStep(STEPS.STEP_SOCIAL_MEDIA)
    }
    if (clientData?.uuid) {
      setStep(STEPS.STEP_BUSINESS_INFORMATION)
    }
  }, [])

  useEffect(() => {
    const uncompletedSteps = location.state?.uncompletedSteps
    setfirstLoginParam(location.state?.firstLogin)

    if (location.state?.fastPublication) {
      setfirstLoginParam(false)
    }

    if (location.state?.redirect === true) {
      setStep(STEPS.STEP_SOCIAL_MEDIA)
    }

    if (location.state?.fastPublication === true) {
      setStep(STEPS.STEP_NOTICE)
    }

    if (uncompletedSteps?.length > 0) {
      setStep(uncompletedSteps[0])
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [step])

  const StepRender = ({
    selectedPage,
    title,
    stage,
    Icon,
    selectedStep,
    setStep,
  }) => {
    let textColor = '#145f13'
    let iconColor = '#0FBE00'
    let circleColor = '#17261e'
    let minCircleColor = '#0FBE00'

    if (selectedPage) {
      textColor = '#FFFFFF'
      circleColor = '#FF8B00'
      iconColor = '#FFFFFF'
      minCircleColor = '#FF8B00'
    }

    return (
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-end',
          // background: 'blue',
        }}
        onClick={() => setStep(selectedStep)}
      >
        <div style={{ marginRight: 10, marginTop: 20 }}>
          <p
            style={{
              color: textColor,
              margin: 0,
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            {title}
          </p>
          <p
            style={{
              color: '#747474',
              textAlign: 'end',
              margin: 0,
              fontWeight: 300,
              fontSize: 10,
            }}
          >
            {stage}
          </p>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 20,
          }}
        >
          <div
            style={{
              background: circleColor,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 1000,
              padding: 8,
            }}
          >
            <Icon color={iconColor} />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ background: '#424242', flex: 1, width: 0.5 }} />
          <div
            style={{
              background: minCircleColor,
              padding: 3,
              borderRadius: 100,
            }}
          />
          <div style={{ background: '#424242', flex: 1, width: 0.5 }} />
        </div>
      </div>
    )
  }

  if (step === STEPS.STEP_NOTICE)
    return <NoticeStep setStep={setStep} firstLogin={firstLoginParam} />

  return (
    <div className={styles.page}>
      <div className={styles.postei_logo_field}>
        <img
          src={xamaLogoBranco}
          alt="posteiLogoText"
          style={{ height: 34 }}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>

      <div className={styles.data_field}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <p
              style={{
                fontSize: 20,
                color: '#fff',
                margin: 0,
                fontWeight: 500,
              }}
            >
              {t('step_registration_data.title')}
            </p>
            <InterrogationCircleIcon width={22} height={22} />
          </div>

          <p
            style={{ fontSize: 12, color: '#fff', margin: 0, fontWeight: 300 }}
          >
            {t('step_registration_data.subtitle')}
          </p>

          <div className={styles.divider} style={{ marginTop: 40 }} />
        </div>
      </div>

      {/* STEP_REGISTRATION_DATA */}

      <div>
        {step === STEPS.STEP_REGISTRATION_DATA && !clientData?.uuid && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              <StepRender
                title={t('step_registration_data.references')}
                stage={t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                Icon={UserIcon}
                selectedStep={STEPS.STEP_REGISTRATION_DATA}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepRegistrationData setStep={setStep} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_BUSINESS_INFORMATION */}

      <div>
        {step === STEPS.STEP_BUSINESS_INFORMATION && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepBusinessInformation
                setStep={setStep}
                clientData={clientData}
              />
            </div>
          </div>
        )}
      </div>

      {/* STEP_BRANDING */}

      <div>
        {step === STEPS.STEP_BRANDING && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepBranding setStep={setStep} clientData={clientData} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_LOGOS */}

      <div>
        {step === STEPS.STEP_LOGOS && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepLogos setStep={setStep} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_SOCIAL_MEDIA */}

      <div>
        {step === STEPS.STEP_SOCIAL_MEDIA && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {t('step_registration_data.optional_steps')}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {t('step_registration_data.optional_stage')}
                  </p>
                </div>
              </div>

              <StepRender
                title={t('step_registration_data.social_media')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_6') : t('step_registration_data.stage_5')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepSocialMedia setStep={setStep} clientData={clientData} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_TARGET_AUDIENCE */}

      <div>
        {step === STEPS.STEP_TARGET_AUDIENCE && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {t('step_registration_data.optional_steps')}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {t('step_registration_data.optional_stage')}
                  </p>
                </div>
              </div>

              <StepRender
                title={t('step_registration_data.social_media')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_6') : t('step_registration_data.stage_5')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.target_audience')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_7') : t('step_registration_data.stage_6')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                Icon={TargetIcon}
                selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepTargetAudience setStep={setStep} clientData={clientData} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_PRODUCT */}

      <div>
        {step === STEPS.STEP_PRODUCT && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {t('step_registration_data.optional_steps')}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {t('step_registration_data.optional_stage')}
                  </p>
                </div>
              </div>

              <StepRender
                title={t('step_registration_data.social_media')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_6') : t('step_registration_data.stage_5')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.target_audience')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_7') : t('step_registration_data.stage_6')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                Icon={TargetIcon}
                selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                setStep={setStep}
              />

              <StepRender
                title={t('step_product.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_8') : t('step_registration_data.stage_7')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_PRODUCT}
                Icon={CarIcon}
                selectedStep={STEPS.STEP_PRODUCT}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepProduct setStep={setStep} clientData={clientData} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_VOICE_TONE */}

      <div>
        {step === STEPS.STEP_VOICE_TONE && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              {/* <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {'Etapas Opcionais'}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {'ETAPAS NÃO OBRIGATÓRIAS'}
                  </p>
                </div>
              </div>

              <StepRender
                title={'Redes Sociais'}
                stage={!clientData?.uuid ? 'ETAPA 5' : 'ETAPA 4'}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />

              <StepRender
                title={'Público-Alvo'}
                stage={!clientData?.uuid ? 'ETAPA 6' : 'ETAPA 5'}
                status={'complete'}
                selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                Icon={TargetIcon}
                selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                setStep={setStep}
              />

              <StepRender
                title={'Produtos'}
                stage={!clientData?.uuid ? 'ETAPA 7' : 'ETAPA 6'}
                status={'complete'}
                selectedPage={step === STEPS.STEP_PRODUCT}
                Icon={CarIcon}
                selectedStep={STEPS.STEP_PRODUCT}
                setStep={setStep}
              /> */}
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepVoiceTone setStep={setStep} clientData={clientData} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_EFFECTS */}

      <div>
        {step === STEPS.STEP_EFFECTS && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {t('step_registration_data.optional_steps')}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {t('step_registration_data.optional_stage')}
                  </p>
                </div>
              </div>

              <StepRender
                title={t('step_registration_data.social_media')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_6') : t('step_registration_data.stage_5')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.target_audience')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_7') : t('step_registration_data.stage_6')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                Icon={TargetIcon}
                selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                setStep={setStep}
              />

              <StepRender
                title={t('step_product.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_8') : t('step_registration_data.stage_7')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_PRODUCT}
                Icon={CarIcon}
                selectedStep={STEPS.STEP_PRODUCT}
                setStep={setStep}
              />

              <StepRender
                title={t('step_effects.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_9') : t('step_registration_data.stage_8')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_EFFECTS}
                Icon={ImageListIcon}
                selectedStep={STEPS.STEP_EFFECTS}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepEffects setStep={setStep} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_BRAND_ARCHETYPE */}

      <div>
        {step === STEPS.STEP_BRAND_ARCHETYPE && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {t('step_registration_data.optional_steps')}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {t('step_registration_data.optional_stage')}
                  </p>
                </div>
              </div>

              <StepRender
                title={t('step_registration_data.social_media')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_6') : t('step_registration_data.stage_5')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.target_audience')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_7') : t('step_registration_data.stage_6')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                Icon={TargetIcon}
                selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                setStep={setStep}
              />

              <StepRender
                title={t('step_product.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_8') : t('step_registration_data.stage_7')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_PRODUCT}
                Icon={CarIcon}
                selectedStep={STEPS.STEP_PRODUCT}
                setStep={setStep}
              />

              {/* <StepRender
                title={'Efeitos'}
                stage={!clientData?.uuid ? 'ETAPA 9' : 'ETAPA 8'}
                status={'complete'}
                selectedPage={step === STEPS.STEP_EFFECTS}
                Icon={ImageListIcon}
                selectedStep={STEPS.STEP_EFFECTS}
                setStep={setStep}
              /> */}

              <StepRender
                title={t('step_brand_archetype.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_9') : t('step_registration_data.stage_8')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRAND_ARCHETYPE}
                Icon={ArchetypeIcon}
                selectedStep={STEPS.STEP_BRAND_ARCHETYPE}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepBrandArchetype setStep={setStep} clientData={clientData} />
            </div>
          </div>
        )}
      </div>

      {/* STEP_VISUAL_PREFERENCES */}

      <div>
        {step === STEPS.STEP_VISUAL_PREFERENCES && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {t('step_registration_data.optional_steps')}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {t('step_registration_data.optional_stage')}
                  </p>
                </div>
              </div>

              <StepRender
                title={t('step_registration_data.social_media')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_6') : t('step_registration_data.stage_5')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.target_audience')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_7') : t('step_registration_data.stage_6')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                Icon={TargetIcon}
                selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                setStep={setStep}
              />

              <StepRender
                title={t('step_product.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_8') : t('step_registration_data.stage_7')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_PRODUCT}
                Icon={CarIcon}
                selectedStep={STEPS.STEP_PRODUCT}
                setStep={setStep}
              />

              <StepRender
                title={t('step_effects.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_9') : t('step_registration_data.stage_8')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_EFFECTS}
                Icon={ImageListIcon}
                selectedStep={STEPS.STEP_EFFECTS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_brand_archetype.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_9') : t('step_registration_data.stage_8')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRAND_ARCHETYPE}
                Icon={ArchetypeIcon}
                selectedStep={STEPS.STEP_BRAND_ARCHETYPE}
                setStep={setStep}
              />

              <StepRender
                title={t('step_visual_preferences.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_11') : t('step_registration_data.stage_10')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VISUAL_PREFERENCES}
                Icon={PreferencesIcon}
                selectedStep={STEPS.STEP_VISUAL_PREFERENCES}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepVisualPreferences
                iaData={iaData}
                setIaData={setIaData}
                setStep={setStep}
              />
            </div>
          </div>
        )}
      </div>

      {/* STEP_VISUAL_REFERENCES */}

      <div>
        {step === STEPS.STEP_VISUAL_REFERENCES && (
          <div style={{ display: 'flex', margin: '20px 80px' }}>
            <div
              style={{
                minWidth: 300,
              }}
            >
              {!clientData?.uuid && (
                <StepRender
                  title={t('step_registration_data.references')}
                  stage={t('step_registration_data.stage')}
                  status={'complete'}
                  selectedPage={step === STEPS.STEP_REGISTRATION_DATA}
                  Icon={UserIcon}
                  selectedStep={STEPS.STEP_REGISTRATION_DATA}
                  setStep={setStep}
                />
              )}
              <StepRender
                title={t('step_registration_data.business_information')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_2') : t('step_registration_data.stage')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BUSINESS_INFORMATION}
                Icon={InformationIcon}
                selectedStep={STEPS.STEP_BUSINESS_INFORMATION}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.branding')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_3') : t('step_registration_data.stage_2')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRANDING}
                Icon={BrushIcon}
                selectedStep={STEPS.STEP_BRANDING}
                setStep={setStep}
              />
              <StepRender
                title={t('step_registration_data.logos')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_4') : t('step_registration_data.stage_3')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_LOGOS}
                Icon={PhotoIcon}
                selectedStep={STEPS.STEP_LOGOS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.voice_tone')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_5') : t('step_registration_data.stage_4')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VOICE_TONE}
                Icon={ChatIcon}
                selectedStep={STEPS.STEP_VOICE_TONE}
                setStep={setStep}
              />

              <div>
                <div
                  style={{ marginRight: 10, marginTop: 20, marginBottom: 20 }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <div
                      style={{
                        flex: 1,
                        background: '#D2D2D2',
                        height: 1,
                        marginRight: 10,
                      }}
                    />
                    <p
                      style={{
                        color: '#FFFFFF',
                        margin: 0,
                        fontWeight: 600,
                        fontSize: 18,
                      }}
                    >
                      {t('step_registration_data.optional_steps')}
                    </p>
                  </div>

                  <p
                    style={{
                      color: '#747474',
                      textAlign: 'end',
                      margin: 0,
                      fontWeight: 300,
                      fontSize: 10,
                    }}
                  >
                    {t('step_registration_data.optional_stage')}
                  </p>
                </div>
              </div>

              <StepRender
                title={t('step_registration_data.social_media')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_6') : t('step_registration_data.stage_5')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_SOCIAL_MEDIA}
                Icon={SocialMediaIcon}
                selectedStep={STEPS.STEP_SOCIAL_MEDIA}
                setStep={setStep}
              />

              <StepRender
                title={t('step_registration_data.target_audience')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_7') : t('step_registration_data.stage_6')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_TARGET_AUDIENCE}
                Icon={TargetIcon}
                selectedStep={STEPS.STEP_TARGET_AUDIENCE}
                setStep={setStep}
              />

              <StepRender
                title={t('step_product.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_8') : t('step_registration_data.stage_7')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_PRODUCT}
                Icon={CarIcon}
                selectedStep={STEPS.STEP_PRODUCT}
                setStep={setStep}
              />

              <StepRender
                title={t('step_effects.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_9') : t('step_registration_data.stage_8')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_EFFECTS}
                Icon={ImageListIcon}
                selectedStep={STEPS.STEP_EFFECTS}
                setStep={setStep}
              />

              <StepRender
                title={t('step_brand_archetype.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_9') : t('step_registration_data.stage_8')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_BRAND_ARCHETYPE}
                Icon={ArchetypeIcon}
                selectedStep={STEPS.STEP_BRAND_ARCHETYPE}
                setStep={setStep}
              />

              <StepRender
                title={t('step_visual_preferences.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_11') : t('step_registration_data.stage_10')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VISUAL_PREFERENCES}
                Icon={PreferencesIcon}
                selectedStep={STEPS.STEP_VISUAL_PREFERENCES}
                setStep={setStep}
              />

              <StepRender
                title={t('step_visual_references.title')}
                stage={!clientData?.uuid ? t('step_registration_data.stage_12') : t('step_registration_data.stage_11')}
                status={'complete'}
                selectedPage={step === STEPS.STEP_VISUAL_REFERENCES}
                Icon={ImageListIcon}
                selectedStep={STEPS.STEP_VISUAL_REFERENCES}
                setStep={setStep}
              />
            </div>

            <div style={{ marginLeft: 30, width: '100%' }}>
              <StepVisualReferences setStep={setStep} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default StepsPage
