import styles from './styles.module.css'
import {
  CloseIcon,
  CloundArrowUp,
  Stars,
  Trash,
} from '../../../assets/svgs/icons'
import { BusinessService, LogosService, PythonService } from '../../../services'
import { notifyErrorCustom } from '../../../utils/notify'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { DragContainer } from '../../../components/DragUploadRemoveBackgroundContainer/DragContainer'
import { DragUploadRemoveBackgroundContainer } from '../../../components/DragUploadRemoveBackgroundContainer'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'
import { FooterNaviBar } from '../../../components/FooterNaviBar'

const STEPS = {
  STEP_PASS_SUBJECT: 'StepSubject',

  STEP_PASS_IMAGE: 'stepImage',
}

function StepLogo({ setStep, logoSelectedArray, setLogoSelectedArray }) {
  const [imageHorizontalArrayGet, setImageHorizontalArrayGet] = useState([])

  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      if (percentage < 100 && logoSelectedArray) {
        setPercentage((prevState) => prevState + 1)
      }
    }, 1)

    return () => clearInterval(timer)
  }, [logoSelectedArray, percentage])

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: true,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: true,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: true,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: true,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: false,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false,
    },
  ]

  useEffect(() => {
    getLogos()
  }, [])

  const getLogos = async () => {
    let businessId

    const { data } = await BusinessService.getBusinessByLogin()
    businessId = data[0]?.uuid

    const { data: dataLogo } = await LogosService.getLogosById(businessId)

    setImageHorizontalArrayGet(dataLogo)
  }

  const handleRemoveBackground = async (index, base64) => {
    setIsLoading(true) // Indica que o processo começou
    setProgress(10) // Progresso inicial

    try {
      // Faz a chamada ao serviço Python
      const { data } = await PythonService.postRemoveBackground({
        image: base64,
      })
      setProgress(50) // Atualiza o progresso após a chamada

      const noBgImage = data.data.images

      // Atualiza os dados da imagem
      logoSelectedArray[index].fileDataBase64 = noBgImage[1]?.image
      logoSelectedArray[index].isOriginal = noBgImage[1]?.isOriginal
      setProgress(80) // Progresso ao finalizar a atualização da imagem

      setLogoSelectedArray([...logoSelectedArray]) // Atualiza o estado

      setProgress(100) // Progresso final
    } catch (error) {
      console.error(t('fast_publishing_error_removing_background'), error)
    } finally {
      setTimeout(() => {
        setIsLoading(false) // Oculta a barra de progresso
        setProgress(0) // Reseta o progresso
      }, 500) // Pequeno delay para exibir a barra de progresso finalizada
    }
  }

  const onDragOver = (event) => {
    event.preventDefault()
    // setDropzoneHover(true)
  }

  const onDragLeave = () => {
    // setDropzoneHover(false)
  }

  const onDrop = (event) => {
    event.preventDefault()
    event.stopPropagation()
    // setDropzoneHover(false)

    Array.from(event.dataTransfer.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          origin: 'gallery',
          backgroundRemoved: false,
        }

        setLogoSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  function onFileChange(e) {
    const fileReader = new FileReader()

    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function (event) {
        let fileDataBase64 = reader.result

        let fileDataReturn = {
          fileDataBase64,
          name: file.name,
          size: file.size,
          type: file.type,
          backgroundRemoved: false,
        }

        setLogoSelectedArray([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  // function formatBytes(bytes, decimals = 0) {
  //   if (bytes === 0) return '0 Bytes'

  //   const k = 1024
  //   const dm = decimals < 0 ? 0 : decimals
  //   const sizes = ['Bytes', 'KB', 'MB']

  //   const i = Math.floor(Math.log(bytes) / Math.log(k))

  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  // }

  function formatBytesToMB(bytes, decimals = 2) {
    if (bytes === 0) return '0 MB'

    const k = 1024
    return parseFloat((bytes / Math.pow(k, 2)).toFixed(decimals)) + ' MB'
  }

  const handleRemoveFile = (index) => {
    setLogoSelectedArray([])
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (logoSelectedArray.length === 0) {
        notifyErrorCustom(
          t('fast_publishing_choose_an_image_for_art_generation')
        )
        return
      }
      postLogos()
      setStep(STEPS.STEP_PASS_IMAGE)
    } else {
      setStep(STEPS.STEP_PASS_SUBJECT)
    }
  }

  const postLogos = async () => {
    let businessId

    const { data } = await BusinessService.getBusinessByLogin()
    businessId = data[0]?.uuid

    let body = {}

    // Dividir a string base64 para separar o cabeçalho da imagem
    const parts = logoSelectedArray[0]?.fileDataBase64?.split(',')

    const header = parts[0]
    const base64Data = parts[1]

    // Extrair o formato do arquivo e o tipo de conteúdo
    const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/)
    const fileContentType = matches[0]
    const fileFormat = fileContentType.split('/')[1]

    body = {
      businessId: businessId,
      description: logoSelectedArray[0]?.name,
      fileFormat: fileFormat,
      fileContentType: fileContentType,
      fileBase64: base64Data,
    }

    if (imageHorizontalArrayGet.length === 0) {
      const { data: dataLogo, responseStatus: responseStatusLogo } =
        await LogosService.postLogo(body)
    } else {
      body = {
        businessId: businessId,
        description: logoSelectedArray[0]?.name,
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
        fileName: imageHorizontalArrayGet[0].fileName,
      }

      const { data: dataLogo, responseStatus: responseStatusLogo } =
        await LogosService.putLogos(imageHorizontalArrayGet[0]?.uuid, body)
    }
  }

  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        {/* <PreviousPass steps={stepsArray}/> */}

        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>{t('fast_publishing_logo_text')}</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
            {t('fast_publishing_logo')}
          </h1>

          <div className={styles.container_modal}>
            <div className={styles.content_up_image}>
              <div className={styles.modal_container_drag}>
                <div className={styles.modal_drag}>
                  {/* <label htmlFor="arquivo">
                <h3>Arrase & Solte o arquivo</h3>
                <p>Ou selecione o arquivo no explorador</p>
                </label> */}

                  <div
                    className={styles.modal_drag_drop}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    // onClick={handleShowListImages}

                    // style={{
                    //   height: '100px',
                    //   width: '100%',
                    //   border: '2px dashed black',
                    //   backgroundColor: dropzoneHover ? 'blue' : 'red',
                    // }}
                    // className={styles.modal_drag}
                  >
                    <label htmlFor="arquivo">
                      <CloundArrowUp color={'#B900FE'} />
                      <h2
                        className={
                          logoSelectedArray.length !== 0
                            ? styles.modal_drag_drop_title_short
                            : styles.modal_drag_drop_title
                        }
                      >
                        {t('fast_publishing_drag_and_drop')}
                      </h2>
                      <p
                        className={
                          logoSelectedArray.length !== 0
                            ? styles.modal_drag_drop_sub_title_short
                            : styles.modal_drag_drop_sub_title
                        }
                      >
                        {t('fast_publishing_drag_and_drop_subtitle')}
                      </p>
                    </label>
                    <input
                      type="file"
                      name="arquivo"
                      id="arquivo"
                      onChange={(e) => onFileChange(e)}
                    ></input>
                    {/* {fileData && (
                      <img
                      style={{ width: 50, height: 50 }}
                      src={fileData}
                      alt="file-preview"
                      />
                      )} */}
                  </div>
                </div>
              </div>

              {logoSelectedArray.length !== 0 && (
                <div className={styles.content_list_image}>
                  <div className={styles.container_box_list_image}>
                    {logoSelectedArray?.map((image, index) => (
                      <div
                        key={index}
                        className={styles.container_list_image}
                        // style={{ border: '1px solid red' }}
                      >
                        <div className={styles.item_list_image}>
                          <img
                            src={image.fileDataBase64}
                            alt="file-preview"
                            style={{
                              width: 40,
                              height: 40,
                              objectFit: 'cover',
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                          />

                          <div className={styles.box_data_info}>
                            <p className={styles.box_data_info_title}>
                              {image.name}
                            </p>
                            <p className={styles.box_data_info_size}>
                              {t('fast_publishing_logo_size_image')} &nbsp;
                              <b> {formatBytesToMB(image.size)} </b>
                            </p>
                          </div>
                        </div>
                        <div className={styles.item_list_image_buttons}>
                          <button
                            className={styles.button_cancel}
                            onClick={() => handleRemoveFile(index)}
                          >
                            <p className={styles.button_cancel_title}>
                              {t('fast_publishing_logo_size_delete_image')}
                            </p>
                            <Trash />
                          </button>

                          <button
                            className={styles.button_remove_background}
                            onClick={() =>
                              handleRemoveBackground(
                                index,
                                image.fileDataBase64
                              )
                            }
                          >
                            <p
                              className={styles.button_remove_background_title}
                            >
                              {t(
                                'fast_publishing_logo_remove_background_image'
                              )}
                            </p>
                            <Stars />
                          </button>
                        </div>
                      </div>

                      //   <motion.div
                      //   initial={{opacity:0}}
                      //   animate={{opacity:1}}
                      //   exit={{opacity:0}}
                      //   transition={{duration:0.3}}
                      //   className={styles.image_preview_container}>

                      //   <img
                      //     width={150}
                      //     height={80}
                      //     src={logoSelectedArray?.fileUrl}
                      //     alt="selected image" />
                      //   <div className={styles.progressbar_container}>

                      //     <div className={styles.label_icon_container}>
                      //       <div className={styles.progressbar_container_text}>
                      //         <p className={styles.progressbar_label}>Realizando upload</p>
                      //         <p className={styles.progressbar_percentage}>{percentage}%</p>
                      //       </div>
                      //       <div onClick={() => (console.log("teste"))} className={styles.close_button}>
                      //         <CloseIcon color='red'/>
                      //       </div>
                      //     </div>
                      //     <motion.div initial={{width:0}} animate={{width:'100%'}} className={styles.progressbar}/>
                      //   </div>

                      // </motion.div>
                    ))}
                    {isLoading && (
                      <div className={styles.progressBarContainer}>
                        <div
                          className={styles.progressBar}
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.modal_button_save}>
                <button className={styles.button_add}>
                  <label className={styles.button_add_label} htmlFor="arquivo">
                    {logoSelectedArray.length > 0
                      ? t('fast_publishing_logo_change_image')
                      : t('fast_publishing_logo_add_image')}
                  </label>
                </button>
              </div>
            </div>
          </div>
          {/* <DragUploadRemoveBackgroundContainer
           imagesArray={'logoSelectedArray.fileDataBase64'}
           //setImagesArray={setImagesArray}
           onClickRemoveBackground={handleRemoveBackground}
           //onClickButton={(images) => setImagesArray(images)}
           textButton={'Adicionar imagem ao produto'}/> */}
        </div>
        {/* <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            {t("back_buttom")}
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            {t('next_buttom')}
          </button>
        </div> */}
        <FooterNaviBar
          back={() => handleNavigate('back')}
          next={() => handleNavigate('next')}
        />
      </div>
    </motion.div>
  )
}

export default StepLogo
