import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Cross } from '../../assets/svgs/icons'
const ModalCategoryHelp = ({ onClose, handleCloseModal, isOpen }) => {
  if (!isOpen) return null

  const navigate = useNavigate()
  const { t } = useTranslation()

  // const images = [
  //   {
  //     id: 1,
  //     url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
  //     title: 'Comida saudavel',
  //     size: '32mb',
  //     origin: 'imgBank',
  //   },
  // ]

  const handleDowngrade = async () => {
    handleCloseModal('categoryHelp')
  }

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal}>
        <div className={styles.header_close}>
          <div className={styles.close_modal} onClick={onClose}>
            <Cross width={'20px'} height={'20px'} />
          </div>
        </div>

        <div className={styles.modal_title}>
          <h2>Categoria da Imagem</h2>
        </div>

        <div className={styles.modal_sub_title}>
          <p className={styles.modal_sub_title_text}>
            {t('plansPage.areYouSureYouWantToChangeYourPlan')}
          </p>
        </div>

        <div className={styles.modal_container_drag}>
          <button className={styles.button_back} onClick={onClose}>
            {t('modal_downgrade.back')}
          </button>

          <button
            className={styles.button_send}
            onClick={() => {
              handleDowngrade()
            }}
          >
            {t('modal_downgrade.continue')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalCategoryHelp
