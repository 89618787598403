import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { useEffect, useRef, useState } from 'react'
import { NavigateArrowRigth, ChevronDown } from '../../../assets/svgs/icons'

export const CustomSelect = ({
  options,
  label,
  htmlFor,
  placeholder,
  defaultSelected,
  arrowDirection = 'right',
  backgroundColor,
  borderRadius,
  fontSize,
  onClickItem,
  iconSize = 24,
  disabled,
  fontUrls,
}) => {
  const [containerVisible, setContainerVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    label: '',
    value: '',
  })

  const handleSelect = (e) => {
    setContainerVisible(false)
    setSelectedItem(e)
    onClickItem(e)
  }

  useEffect(() => {
    setSelectedItem(defaultSelected)
  }, [defaultSelected])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setContainerVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerVisible])

  const ref = useRef(null)

  return (
    <>
      <style>{`${fontUrls}`}</style>
     
      <div ref={ref} className={styles.Container_select}>
        <div className={styles.inputSelectContainer}>
          <label htmlFor={htmlFor}>{label}</label>
          <div
            className={styles.buttonSelect}
            onClick={() =>
              disabled ? null : setContainerVisible((prev) => !prev)
            }
            type="text"
            style={{
              backgroundColor,
              borderRadius,
              fontSize,
              cursor: disabled ? 'initial' : 'pointer',
            }}
          >
            <p style={{ fontSize }} className={styles.placeholder_select}>
              {selectedItem?.label || placeholder}
            </p>
            <div className={styles.box_svg}>
              <div
                className={`${styles[arrowDirection]} ${
                  containerVisible ? styles.rotate : styles.containerVisible
                }`}
              >
                {arrowDirection == 'right' && (
                  <NavigateArrowRigth size={iconSize} />
                )}
                {arrowDirection == 'down' && <ChevronDown size={iconSize} />}
              </div>
            </div>
          </div>
          {containerVisible && !disabled && (
            <div className={styles.selectContainer}>
              {options.map((option, index) => (
                <button
                  key={index}
                  className={styles.selectContainerItem}
                  onClick={() => handleSelect(option)}
                >
                  <span style={{ fontSize , fontFamily: option?.value? `${option?.value}`: ''}}>{option.label}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
