/* eslint-disable react/prop-types */
import { useState, useEffect, useRef, useCallback } from 'react'

import { InstagramChatService } from '../../services'

import {
  SearchIcon,
  Send,
  Stars,
  // Clips
} from '../../assets/svgs/icons'

import { useClient } from '../../hooks/useClient'

import {
  getBusinessByClientId,
  getBusinessByLogin,
} from '../../services/business'

import { postPromptGPT } from '../../services/pythonService'
import { BusinessService, VoiceToneService } from '../../services'

import { notifyErrorCustom } from '../../utils/notify'
import { Loading } from '../../components/Loading'

import perfilDefault from '../../assets/imgs/perfil.png'

export const ChatPage = () => {
  const { clientData } = useClient()

  const businessId = useRef(null)

  const [myInfo, setMyInfo] = useState(null)
  const [dialogs, setDialogs] = useState([])
  const [dialogSelected, setDialogSelected] = useState(null)

  const messagesRef = useRef([])

  const [isGlobalLoading, setIsGlobalLoading] = useState(false)

  useEffect(() => {
    setIsGlobalLoading(true)
    loadingDialogs()
  }, [])

  useEffect(() => {
    const jdivElements = document.getElementsByTagName('jdiv')
    const originalDisplayStyles = []

    Array.from(jdivElements).forEach((element, index) => {
      originalDisplayStyles[index] = element.style.display
      element.style.display = 'none'
    })

    return () => {
      Array.from(jdivElements).forEach((element, index) => {
        element.style.display = originalDisplayStyles[index]
      })
    }
  }, [])

  const loadingDialogs = async () => {
    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid)
      businessId.current = data[0]?.uuid
    } else {
      const { data } = await getBusinessByLogin()

      businessId.current = data[0].uuid
    }

    const dialogsResponse = await InstagramChatService.getDialogs(
      businessId.current
    )

    const participantsIds = dialogsResponse?.data?.data?.map((item) => {
      return item?.participants?.data[1]?.id
    })

    if (participantsIds.length > 0) {
      const myInfoResponse = await InstagramChatService.getMyInfo(
        businessId.current
      )

      setMyInfo(myInfoResponse?.data)

      const userInfoResponse = await InstagramChatService.postUserInfo(
        businessId.current,
        participantsIds
      )

      const dialogsWithUserInfo = dialogsResponse?.data?.data?.map((item) => {
        return {
          ...item,
          userInfo: userInfoResponse?.data?.data?.find(
            (user) => user.id === item?.participants?.data[1]?.id
          ),
        }
      })

      if (dialogsResponse.responseStatus === 200) {
        setDialogs(dialogsWithUserInfo)
      }
    }

    setIsGlobalLoading(false)
  }

  const Conversations = () => {
    const [searchInput, setSearchInput] = useState(dialogs)

    const SearchField = useCallback(({}) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 10,
          }}
        >
          <input
            onChange={(e) => {
              setSearchInput(
                dialogs.filter((item) => {
                  return (
                    item?.participants?.data[1]?.username
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    item?.userInfo?.name
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  )
                })
              )
            }}
            type="text"
            placeholder="Buscar"
            style={{
              flex: 1,
              height: 50,
              borderRadius: 8,
              border: 'none',
              backgroundColor: '#EFEFEF',
              padding: '0 10px',
              color: '#190027',
            }}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#6A0098',
              borderRadius: 8,
              cursor: 'pointer',
              height: 50,
              width: 50,
            }}
          >
            <SearchIcon color={'#fff'} width={18} height={18} />
          </div>
        </div>
      )
    }, [])

    const ConversationsCard = ({ item, name, lastMessageHour, userInfo }) => {
      return (
        <div
          onClick={() => {
            if (dialogSelected === item) {
              return
            }

            setDialogSelected({ ...item, userInfo: userInfo })
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            borderRadius: 10,
            // backgroundColor: dialogSelected === item ? '#898989' : '#e0e0e0',
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              fontSize: 14,
              flex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              <img
                src={userInfo?.profile_pic || perfilDefault}
                alt="profile"
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  backgroundColor: '#F1F1F1',
                }}
                onContextMenu={(e) => e.preventDefault()}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div>{userInfo?.name}</div>
                <div
                  style={{
                    fontWeight: '300',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                    color: '#19002799',
                  }}
                >
                  {name}
                </div>
              </div>

              <div></div>
            </div>
            {/* {name} */}
          </div>

          <div
            style={{
              fontWeight: '300',
              fontFamily: 'Poppins',
              fontSize: 12,
              color: '#19002799',
            }}
          >
            {new Date(lastMessageHour).getHours() +
              ':' +
              new Date(lastMessageHour)
                .getMinutes()
                .toString()
                .padStart(2, '0') || '00:00'}
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 400,
          borderRadius: 20,
          padding: 20,
          gap: 20,
        }}
      >
        <SearchField />

        <div
          style={{
            fontSize: 20,
            fontWeight: '500',
          }}
        >
          Chats:
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          {searchInput?.map((conv, index) => (
            <ConversationsCard
              key={index}
              item={conv?.participants?.data[1]}
              name={conv?.participants?.data[1]?.username}
              userInfo={conv?.userInfo}
              lastMessageHour={conv?.updated_time}
            />
          ))}
        </div>
      </div>
    )
  }

  const Chat = () => {
    const [searchText, setSearchText] = useState('')

    const sendMessage = async (message) => {
      const bodySendMessage = {
        message: message,
      }

      const messagesResponse = await InstagramChatService.postSendMessage(
        bodySendMessage,
        businessId.current,
        dialogSelected?.id
      )

      if (messagesResponse.responseStatus === 200) {
        if (messagesResponse?.data?.data === 'Success') {
        } else {
          notifyErrorCustom('Erro ao enviar mensagem')
        }
      } else {
        notifyErrorCustom('Erro ao enviar mensagem')
      }
    }

    if (dialogSelected === null) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            backgroundColor: '#eeeeee',
            borderRadius: 30,
            marginRight: 20,
            padding: 20,
            marginTop: 20,
          }}
        />
      )
    }

    const ChatField = () => {
      const [messages, setMessages] = useState([])

      const [currentVisibleDate, setCurrentVisibleDate] = useState('')
      const messagesEndRef = useRef(null)

      const messagesOld = useRef([])

      const disabledLoadMessages = false

      const intervalRef = useRef(null)

      useEffect(() => {
        if (dialogSelected !== null) {
          loadingMessages()

          if (intervalRef.current) {
            clearInterval(intervalRef.current)
          }

          if (disabledLoadMessages) {
            return
          }

          intervalRef.current = setInterval(() => {
            if (dialogSelected?.id !== null) {
              loadingMessages()
            }
          }, 2000)
        } else {
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
          }
        }

        return () => {
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
          }
        }
      }, [dialogSelected])

      const formatMessageDate = (date) => {
        if (!date) return ''

        const messageDate = new Date(date)
        return messageDate.toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
      }

      useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const messageDate =
                  entry.target.getAttribute('data-message-date')
                if (messageDate) {
                  setCurrentVisibleDate(formatMessageDate(messageDate))
                }
              }
            })
          },
          {
            threshold: 1.0,
            root: null,
          }
        )

        const messageElements = document.querySelectorAll('.message-item')
        messageElements.forEach((element) => observer.observe(element))

        const scrollToBottom = () => {
          if (messagesEndRef?.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
          }
        }

        scrollToBottom()

        return () => {
          messageElements.forEach((element) => observer.unobserve(element))
        }
      }, [messages])

      const loadingMessages = async () => {
        const messagesResponse = await InstagramChatService.getMessages(
          businessId.current,
          dialogSelected?.id
        )

        if (messagesResponse.responseStatus === 200) {
          if (
            messagesResponse?.data?.data[0]?.messages?.data.length !==
            messagesOld.current.length
          ) {
            setMessages(
              messagesResponse?.data?.data[0]?.messages?.data.reverse()
            )

            messagesRef.current =
              messagesResponse?.data?.data[0]?.messages?.data.reverse()

            messagesOld.current =
              messagesResponse?.data?.data[0]?.messages?.data.reverse()
          }
        }
      }

      const ChatFieldMessages = ({ msg, dialogSelected }) => {
        const highlightText = (text) => {
          if (!searchText) return text
          const parts = text.split(new RegExp(`(${searchText})`, 'gi'))
          return parts.map((part, i) =>
            part.toLowerCase() === searchText.toLowerCase() ? (
              <span
                key={i}
                style={{ backgroundColor: '#ff000033', color: '#d32f2f' }}
              >
                {part}
              </span>
            ) : (
              part
            )
          )
        }

        return (
          <>
            {msg.from.id === dialogSelected?.id ? (
              <DialogLeft
                name={dialogSelected?.username}
                message={highlightText(msg.message)}
                created_time={msg.created_time}
                userInfo={dialogSelected?.userInfo}
              />
            ) : (
              <DialogRight
                name={'Você'}
                message={highlightText(msg.message)}
                created_time={msg.created_time}
              />
            )}
          </>
        )
      }

      const DialogLeft = ({ name, message, created_time, userInfo }) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',

              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 10,
              }}
            >
              <img
                src={userInfo?.profile_pic || perfilDefault}
                alt="profile"
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  backgroundColor: '#F1F1F1',
                }}
                onContextMenu={(e) => e.preventDefault()}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      background: 'white',
                      padding: 6,
                      borderRadius: '12px 12px 12px 0px',
                      fontWeight: '300',
                      fontFamily: 'Poppins',
                      fontSize: 12,
                      color: '#19002799',
                    }}
                  >
                    {message}
                  </div>
                </div>

                <div
                  style={{
                    fontWeight: '300',
                    fontFamily: 'Poppins',
                    fontSize: 10,
                    color: '#19002799',
                    opacity: 0.7,
                  }}
                >
                  {'Mensagem enviada às '}
                  {created_time && (
                    <>
                      {' '}
                      {new Date(created_time).getHours() +
                        ':' +
                        new Date(created_time)
                          .getMinutes()
                          .toString()
                          .padStart(2, '0') || '00:00'}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }

      const DialogRight = ({ name, message, created_time }) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',

              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                gap: 10,
              }}
            >
              <img
                src={myInfo?.profile_picture_url || perfilDefault}
                alt="profile"
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  backgroundColor: '#F1F1F1',
                }}
                onContextMenu={(e) => e.preventDefault()}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div
                  style={{
                    display: 'flex-reverse',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        background: 'white',
                        padding: 6,
                        borderRadius: '12px 12px 0px 12px',
                        fontWeight: '300',
                        fontFamily: 'Poppins',
                        fontSize: 12,
                        color: '#19002799',

                        textAlign: 'right',
                      }}
                    >
                      {message}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontWeight: '300',
                    fontFamily: 'Poppins',
                    fontSize: 10,
                    color: '#19002799',

                    textAlign: 'right',

                    opacity: 0.7,
                  }}
                >
                  {'Mensagem enviada às '}
                  {created_time && (
                    <>
                      {' '}
                      {new Date(created_time).getHours() +
                        ':' +
                        new Date(created_time)
                          .getMinutes()
                          .toString()
                          .padStart(2, '0') || '00:00'}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          {messages.length > 0 && (
            <div
              style={{
                display: 'inline-flex',
                padding: '8px 16px',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: '12px',
                color: '#19002799',
                position: 'sticky',
                top: 8,
                backgroundColor: '#FFFFFF',
                zIndex: 1,
                borderRadius: 20,
                margin: '0 auto',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {currentVisibleDate}
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className="message-item"
                data-message-date={msg.created_time}
              >
                <ChatFieldMessages msg={msg} dialogSelected={dialogSelected} />
              </div>
            ))}

            <div ref={messagesEndRef} />
          </div>
        </div>
      )
    }

    const ChatSend = () => {
      const [currentMessage, setCurrentMessage] = useState('')
      const [showModal, setShowModal] = useState(false)
      const [apiResponse, setApiResponse] = useState(undefined)

      const [promptInput, setPromptInput] = useState('')
      const [isLoading, setIsLoading] = useState(false)
      // const [promptResult, setPromptResult] = useState(undefined)

      const handleSend = () => {
        if (currentMessage.trim() === '') return

        sendMessage(currentMessage)
        setCurrentMessage('')
      }

      const handleApply = () => {
        setCurrentMessage(apiResponse)
        setShowModal(false)
      }

      const handleGenerateAgain = async () => {
        setIsLoading(true)
        await fetchApiResponse(promptInput)
      }

      const fetchApiResponse = async (prompt) => {
        // Simulação de chamada de API
        // return `Resposta gerada para o prompt: ${prompt}`

        let businesData = undefined
        let voiceToneData = ''

        if (clientData?.uuid) {
          const { data } = await getBusinessByClientId(clientData?.uuid)
          businesData = data[0]

          voiceToneData = await VoiceToneService.getAllVoiceToneByBusinessId(
            businesData?.uuid
          )
        } else {
          const { data } = await BusinessService.getBusinessByLogin()

          businesData = data[0]

          voiceToneData = await VoiceToneService.getAllVoiceToneByBusiness()
        }

        const body = {
          flow: 'own_publication',
          prompt: 'legend',

          niche: businesData?.BusinessNiche?.name,
          subniche: businesData?.subNiche,
          input: prompt,
          voice_tone: voiceToneData?.data[0]?.tone,
          business_description: businesData?.description,
        }

        const response = await postPromptGPT(body)

        setApiResponse(response?.data?.data?.legenda)
        setIsLoading(false)
      }

      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            gap: 10,
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#F5F5F5',
              borderRadius: 100,
              cursor: 'pointer',
              height: 34,
              width: 34,
            }}
            onClick={() => {
              setShowModal((current) => !current)
            }}
          >
            <Stars color={'#6A0098'} width={20} height={20} />
          </div>

          {showModal && (
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                bottom: 50, // Ajuste a posição vertical conforme necessário
                left: -10,
                width: 'calc(100% + 20px)',
                backgroundColor: '#FFFFFF',
                borderRadius: 20,
                padding: 20,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                zIndex: 1000,
              }}
            >
              <input
                value={promptInput}
                onChange={(e) => setPromptInput(e.target.value)}
                placeholder="Digite seu prompt..."
                style={{
                  flex: 1,
                  height: 34,
                  borderRadius: 10,
                  padding: 10,
                  border: 'none',
                  backgroundColor: '#E8E8E8',
                  color: '#190027',

                  marginBottom: 10,
                }}
              />
              <div
                style={{
                  marginBottom: 20,
                  padding: 10,
                  // backgroundColor: '#F5F5F5',
                  border: '1px solid #E8E8E8',
                  borderRadius: 5,

                  fontFamily: 'Poppins',
                  fontSize: 12,
                  color: '#19002799',
                }}
              >
                {apiResponse || 'Aguardando resposta...'}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  disabled={isLoading}
                  onClick={handleGenerateAgain}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#E8E8E8',
                    color: '#6A0098',
                    border: 'none',
                    borderRadius: 5,
                    cursor: 'pointer',

                    fontFamily: 'Poppins',
                    fontSize: 12,
                  }}
                >
                  {isLoading
                    ? 'Gerando...'
                    : apiResponse
                    ? 'Gerar Novamente'
                    : 'Gerar'}
                </button>

                <button
                  onClick={handleApply}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#6A0098',
                    color: '#FFFFFF',
                    border: 'none',
                    borderRadius: 5,
                    cursor: 'pointer',

                    fontFamily: 'Poppins',
                    fontSize: 12,
                  }}
                >
                  Aplicar
                </button>
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              gap: 10,
            }}
          >
            <input
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSend()
                }
              }}
              type="text"
              placeholder="Escreva sua mensagem..."
              style={{
                flex: 1,
                height: 34,
                borderRadius: 10,
                padding: 10,
                border: 'none',
                backgroundColor: '#E8E8E8',
                color: '#190027',
              }}
            />

            <div
              onClick={handleSend}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#6A0098',
                borderRadius: 100,
                cursor: 'pointer',
                height: 34,
                width: 34,
              }}
            >
              <Send color={'#ffff'} size={16} />
            </div>
          </div>
        </div>
      )
    }

    const ChatHeader = useCallback(() => {
      const [searchMode, setSearchMode] = useState(false)
      const [searchResults, setSearchResults] = useState([])
      const [currentResultIndex, setCurrentResultIndex] = useState(0)

      const scrollToMessage = useCallback((index) => {
        const messageElement = document.querySelectorAll('.message-item')[index]
        if (messageElement) {
          messageElement.scrollIntoView({ behavior: 'smooth' })
        }
      }, [])

      return (
        <div style={{ display: 'flex', marginBottom: 20 }}>
          <div style={{ display: 'flex', flex: 1, gap: 10 }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                gap: 10,
              }}
            >
              <img
                src={dialogSelected?.userInfo?.profile_pic || perfilDefault}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 100,
                  backgroundColor: '#F1F1F1',
                }}
                onContextMenu={(e) => e.preventDefault()}
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <div>{dialogSelected?.userInfo?.name}</div>
                <div
                  style={{
                    fontWeight: '300',
                    fontFamily: 'Poppins',
                    fontSize: 12,
                    color: '#19002799',
                  }}
                >
                  {dialogSelected?.username}
                </div>
              </div>

              <div></div>
            </div>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                height: 50,
                background: '#F5F5F5',
                borderRadius: 100,
                overflow: 'hidden',
                width: searchMode ? 300 : 50,
                transition: 'all 0.3s ease',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 50,
                  width: 50,
                  cursor: 'pointer',
                  zIndex: 2,
                }}
                onClick={() => setSearchMode(!searchMode)}
              >
                <SearchIcon color={'#373737'} width={18} height={18} />
              </div>

              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  padding: '0 60px 0 16px',
                  opacity: searchMode ? 1 : 0,
                  transform: `translateX(${searchMode ? '0' : '-100%'})`,
                  transition: 'all 0.3s ease',
                }}
              >
                <input
                  onChange={(e) => {
                    setSearchText(e.target.value)
                    if (e.target.value.toLowerCase() === '') {
                      setSearchResults([])
                      return
                    }

                    const filteredMessages = messagesRef.current.filter((msg) =>
                      msg.message
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )

                    setSearchResults(filteredMessages)
                    setCurrentResultIndex(0)
                  }}
                  placeholder="Buscar na conversa..."
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    background: 'transparent',
                    outline: 'none',
                    fontSize: '14px',
                    color: '#333',
                  }}
                />

                {searchResults.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                      position: 'absolute',
                      right: 60,
                    }}
                  >
                    <span style={{ fontSize: 12, color: '#666' }}>
                      {/* {currentResultIndex + 1}/{searchResults.length} */}
                      {searchResults.length} resultados
                    </span>
                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <button
                        onClick={() => {
                          const newIndex =
                            currentResultIndex === 0
                              ? searchResults.length - 1
                              : currentResultIndex - 1
                          setCurrentResultIndex(newIndex)
                          scrollToMessage(newIndex)
                        }}
                        style={{
                          cursor: 'pointer',
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          fontSize: 12,
                          color: '#666',
                          height: 12,
                          lineHeight: 1,
                        }}
                      >
                        ▲
                      </button>
                      <button
                        onClick={() => {
                          const newIndex =
                            (currentResultIndex + 1) % searchResults.length
                          setCurrentResultIndex(newIndex)
                          scrollToMessage(newIndex)
                        }}
                        style={{
                          cursor: 'pointer',
                          border: 'none',
                          background: 'none',
                          padding: 0,
                          fontSize: 12,
                          color: '#666',
                          height: 12,
                          lineHeight: 1,
                        }}
                      >
                        ▼
                      </button>
                    </div> */}
                  </div>
                )}
              </div>
            </div>

            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 50,
                width: 50,
                background: '#F5F5F5',
                borderRadius: 100,

                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  marginTop: -10,
                  userSelect: 'none',
                  fontSize: 20,
                }}
              >
                ...
              </div>
            </div> */}
          </div>
        </div>
      )
    }, [])

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          paddingRight: 20,
          paddingTop: 20,
        }}
      >
        <ChatHeader />

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'calc(100vh - 170px)',
            backgroundColor: '#F1F1F1',

            padding: 20,

            borderRadius: 30,
          }}
        >
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',

              borderRadius: 20,

              overflowY: 'scroll',
            }}
          >
            <ChatField />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              background: '#FFFFFF',

              marginTop: 10,

              padding: 8,

              borderRadius: 20,
            }}
          >
            <ChatSend />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Loading enable={isGlobalLoading} />

      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
        }}
      >
        <Conversations />
        <Chat />
      </div>
    </>
  )
}

export default ChatPage
