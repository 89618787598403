import styles from './styles.module.css'
import { InterrogationCircleIcon } from '../../assets/svgs/icons'

import { useState, useEffect } from 'react'
import {
  //PinterestIcon,
  LinkedlnIcon,
  FacebookIcon,
  InstagramIcon,
  TikTokIcon,
} from '../../assets/svgs/iconsSteps'

import PropTypes from 'prop-types'
import {
  getAllLocationStatesService,
  getAllRegionsLocationService,
} from '../../services/locationsService'
import { getAllgenderService } from '../../services/genderService'
import { getAllSocialClassService } from '../../services/socialClassService'
import { getAllEducationLevelService } from '../../services/educationService'
import { TargetAudienceService } from '../../services'
import {
  postSocialClassHasTargetAudiences,
  deleteSocialClassHasTargetAudiences,
  postEducationLevelHasTargetAudiences,
  deleteEducationLevelHasTargetAudiences,
} from '../../services/socialClassService'

import { isObjEqual } from '../../utils/verifyObject'
import { ButtonNavigateStep } from '../../components/ButtonNavigateStep'
import * as yup from 'yup'
import { notifyErrorCustom } from '../../utils/notify'
import { CustomSelect } from '../../components/Select/CustomSelect'
import { MultiplySelectSquare } from '../../components/Select/MultiplySelectSquare'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_REGISTRATION_DATA: 'stepRegistrationData',
  STEP_BUSINESS_INFORMATION: 'stepBusinessInformation',
  STEP_BRANDING: 'stepBranding',
  STEP_LOGOS: 'stepLogos',
  STEP_VOICE_TONE: 'stepVoiceTone',
  STEP_VISUAL_PREFERENCES: 'stepVisualPreferences',
  STEP_EFFECTS: 'stepEffects',
  STEP_VISUAL_REFERENCES: 'stepVisualReferences',
  STEP_SOCIAL_MEDIA: 'stepSocialMedia',
  STEP_TARGET_AUDIENCE: 'stepTargetAudience',
  STEP_PRODUCT: 'stepProduct',
  STEP_BRAND_ARCHETYPE: 'StepBrandArchetype',
  STEP_NOTICE: 'NoticeStep',
}

function StepTargetAudience({ setStep, clientData }) {
  const { t } = useTranslation()
  //const [isPinterest, setIsPinterest] = useState(false)
  const [isLinkedin, setIsLinkedin] = useState(false)
  const [isFacebook, setIsFacebook] = useState(false)
  const [isInstagram, setIsInstagram] = useState(false)
  const [isTiktok, setIsTiktok] = useState(false)
  const [data, setData] = useState({})
  const [dataToCompare, setDataToCompare] = useState({})

  const storage = window.localStorage

  const dataScheema = yup.object().shape({
    name: yup.string().required('o campo nome é obrigatorio'),
    genderId: yup.number().required('selecione um gênero'),
    // educationLevelId: yup
    //   .number()
    //   .required('selecione um nivel de escolaridade'),
    // socialClassId: yup.mixed().required('selecione a classe social'),
    educationLevelItems: yup
      .mixed()
      .required('selecione um nivel de escolaridade'),
    socialClassItems: yup.mixed().required('selecione a classe social'),
    profession: yup.string().required('preencha o campo profissão'),
    stateId: yup.number().required('selecione o estado'),
    regionId: yup.number().required('selecione a região'),
    city: yup.string().required('digite a cidade'),
  })

  //valide dados com base no scheema
  const validateFields = async (dataToValidate) => {
    return await dataScheema
      .validate(dataToValidate)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })
  }

  // const [isTwitter, setIsTwitter] = useState(false)
  const [locationsStatesOptions, setLocationsStatesOptions] = useState([
    { label: '', value: '' },
  ])
  const [genderOptions, setGenderOptions] = useState([{ label: '', value: '' }])

  const [educationLevelOptions, setEducationLevelOptions] = useState([])
  const [socialClassOptions, setSocialClassOptions] = useState([])
  const [regionLocationOptions, setRegionLocationOptions] = useState([
    { label: '', value: '' },
  ])

  useEffect(() => {
    loadDropdownsData()
    loadDataTargetAudience()
  }, [])

  const loadDataTargetAudience = async () => {
    if (clientData?.uuid) {
      // const dataFromService =
      // await TargetAudienceService.getAllTargetAudienceByClientId(
      //   clientData?.uuid
      // )
      const dataFromService =
        await TargetAudienceService.getTargetAudiencesDefault(
          storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
        )
      if (
        dataFromService.data != null &&
        dataFromService.responseStatus === 200
      ) {
        delete dataFromService.data.createdAt
        delete dataFromService.data.updatedAt
        delete dataFromService.data.deletedAt

        setData(dataFromService.data)
        setDataToCompare(dataFromService.data[0])
      }
    } else {
      const dataFromService =
        await TargetAudienceService.getTargetAudiencesDefault(
          storage.i18nextLng == 'en' ? storage.i18nextLng : 'ptBR'
        )

      if (
        dataFromService.data != null &&
        dataFromService.responseStatus === 200
      ) {
        delete dataFromService.data.createdAt
        delete dataFromService.data.updatedAt
        delete dataFromService.data.deletedAt

        setData(dataFromService.data)
        setDataToCompare(dataFromService.data[0])
      }
    }
  }

  const loadDropdownsData = async () => {
    // faça chamada dos serviços

    const { data: stateLocationData, responseStatus: stateLocationStatus } =
      await getAllLocationStatesService()

    const { data: regionLocationData, responseStatus: regionLocationStatus } =
      await getAllRegionsLocationService()

    const { data: genderData, responseStatus: genderStatus } =
      await getAllgenderService()

    const { data: socialClassData, responseStatus: socialClassStatus } =
      await getAllSocialClassService()

    const { data: educationLevelData, responseStatus: educationLevelStatus } =
      await getAllEducationLevelService()

    //altere estados
    if (stateLocationStatus === 200)
      setLocationsStatesOptions(
        stateLocationData.map((e) => ({ label: e.name, value: e.id }))
      )

    if (regionLocationStatus === 200)
      setRegionLocationOptions(
        regionLocationData.map((e) => ({
          label: storage.i18nextLng == 'en' ? e.nameEn : e.name,
          value: e.id,
        }))
      )

    if (genderStatus === 200)
      setGenderOptions(
        genderData.map((e) => ({
          label: storage.i18nextLng == 'en' ? e.nameEn : e.name,
          value: e.id,
        }))
      )
    if (socialClassStatus === 200) console.log(socialClassData)
    setSocialClassOptions(
      socialClassData.map((e) => ({
        label: storage.i18nextLng == 'en' ? e.nameEn : e.name,
        value: e.id,
      }))
    )
    if (educationLevelStatus === 200)
      setEducationLevelOptions(
        educationLevelData.map((e) => ({
          label: storage.i18nextLng == 'en' ? e.nameEn : e.name,
          value: e.id,
        }))
      )
  }

  const handleChange = (id, value) => {
    setData((prevState) => ({ ...prevState, [id]: value }))
  }

  const handleSubmit = async () => {
    const isValid = await validateFields(data)
    if (!isValid) return

    data.onlinePurchaseFrequencyId = 0
    data.targetAudienceBrandsId = 0

    if (!isObjEqual(dataToCompare, data) && data.uuid) {
      data.educationLevelId = data.educationLevelId ?? -1
      data.onlinePurchaseFrequencyId = data.onlinePurchaseFrequencyId ?? -1
      data.targetAudienceBrandsId = data.targetAudienceBrandsId ?? -1
      data.guestId = data.guestId ?? ''
      data.stateId = data.stateId ?? -1
      data.regionId = data.regionId ?? -1
      data.city = data.city ?? ''
      data.businessClientId = data.businessClientId ?? ''
      data.profession = data.profession ?? ''

      const { uuid, ...rest } = { ...data }

      // delete rest.createdAt
      // delete rest.updatedAt
      // delete rest.deletedAt
      // delete rest.socialClassValue
      // delete rest.genderValue
      // delete rest.educationLevelValue
      // delete rest.stateValue
      // delete rest.userAccountId
      delete rest.createdAt
      delete rest.updatedAt
      delete rest.deletedAt
      delete rest.userAccountId
      delete rest.genderValue

      delete rest.SocialClassHasTargetAudience
      delete rest.EducationLevelHasTargetAudience
      delete rest.educationLevelValue
      delete rest.socialClassValue
      delete rest.stateValue
      delete rest.educationLevelItems
      delete rest.socialClassItems
      delete rest.educationLevelId

      if (JSON.parse(window.localStorage.getItem('userData')).guestUserId) {
        rest.guestId = JSON.parse(
          window.localStorage.getItem('userData')
        ).guestUserId
      }

      //!

      for (let i = 0; i < data?.EducationLevelHasTargetAudience?.length; i++) {
        await deleteEducationLevelHasTargetAudiences(
          data?.EducationLevelHasTargetAudience[i]?.uuid
        )
      }

      for (let i = 0; i < data?.SocialClassHasTargetAudience?.length; i++) {
        await deleteSocialClassHasTargetAudiences(
          data?.SocialClassHasTargetAudience[i]?.uuid
        )
      }

      //!

      const { responseStatus } = await TargetAudienceService.putTargetAudience(
        uuid,
        rest
      )

      const { educationLevelItems, socialClassItems } = data

      for (let i = 0; i < educationLevelItems?.length; i++) {
        const body = {
          targetAudienceId: uuid,
          educationLevelId: educationLevelItems[i].value,
        }

        const saveEducationLevel = await postEducationLevelHasTargetAudiences(
          body
        )
      }

      for (let i = 0; i < socialClassItems?.length; i++) {
        const body = {
          targetAudienceId: uuid,
          socialClassId: socialClassItems[i].value,
        }

        const saveSocialClass = await postSocialClassHasTargetAudiences(body)
      }

      if (responseStatus !== 200 && responseStatus !== 204) {
        notifyErrorCustom('erro ao Atualizar')
      }
    } else {
      if (!data.uuid) {
        let newBody = { ...data }

        delete newBody.educationLevelItems
        delete newBody.socialClassItems

        const guestId = JSON.parse(
          window.localStorage.getItem('userData')
        ).guestUserId

        if (clientData?.uuid) {
          newBody = { ...newBody, businessClientId: clientData?.uuid }
        }
        if (guestId) {
          newBody = { ...newBody, guestId: guestId }
        }

        const newTargetAudience =
          await TargetAudienceService.postTargetAudience(newBody)

        const { educationLevelItems, socialClassItems } = data

        for (let i = 0; i < educationLevelItems?.length; i++) {
          const body = {
            targetAudienceId: newTargetAudience.data.uuid,
            educationLevelId: educationLevelItems[i].value,
          }

          const saveEducationLevel = await postEducationLevelHasTargetAudiences(
            body
          )
        }

        for (let i = 0; i < socialClassItems?.length; i++) {
          const body = {
            targetAudienceId: newTargetAudience.data.uuid,
            socialClassId: socialClassItems[i].value,
          }

          const saveSocialClass = await postSocialClassHasTargetAudiences(body)
        }
      }
    }

    setStep(STEPS.STEP_PRODUCT)
  }

  return (
    <div className={styles.page}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
        <p className={styles.title_text}>{t('step_target_audience.title')}</p>
        <InterrogationCircleIcon color={'#fff'} width={22} height={22} />
      </div>

      <p className={styles.subtitle_text}>
        {t('step_target_audience.subtitle')}
      </p>
      <p className={styles.subtitle_text}>
        {t('step_target_audience.subtitle_info')}
      </p>
      <br></br>
      <p className={styles.subtitle_text}>
        {'*' + t('step_target_audience.note')}
      </p>

      <div className={styles.step_data_container}>
        <div
          className={styles.input_field}
          // onSubmit={handleSubmit}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.name')}
            </p>
            <input
              className={styles.input}
              type="text"
              placeholder={t('step_target_audience.name_placeholder')}
              id="name"
              value={data?.name}
              onChange={({ target: { id, value } }) => handleChange(id, value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.gender')}
            </p>

            <CustomSelect
              placeholder={t('step_target_audience.select')}
              arrowDirection="down"
              options={genderOptions}
              defaultSelected={genderOptions.find(
                (e) => e.value === data?.genderId
              )}
              fontSize={12}
              onClickItem={({ value }) => handleChange('genderId', value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.education_level')}
            </p>

            {data?.educationLevelValue?.length > 0 &&
              educationLevelOptions.length > 0 && (
                <MultiplySelectSquare
                  htmlFor="select"
                  // variante="grey"
                  backgroundColor="#4a0074"
                  arrowColor="#ffffffb3"
                  color={'#ffffffb3'}
                  arrowDirection="down"
                  placeholder={t('step_target_audience.select')}
                  defaultSelected={() => {
                    if (data?.educationLevelValue?.length > 0) {
                      let itemReturn = data?.educationLevelValue.map((e) => ({
                        label: e,
                        value: educationLevelOptions.find(
                          (el) => el.label === e
                        )?.value,
                      }))

                      handleChange('educationLevelItems', itemReturn)
                      return itemReturn
                    }
                    return []
                  }}
                  options={educationLevelOptions}
                  onClickItem={(value) => {
                    handleChange('educationLevelItems', value)
                  }}
                />
              )}

            {/* {console.log(data?.educationLevelValue?.length)} */}

            {(data?.educationLevelValue?.length === 0 ||
              data?.educationLevelValue?.length === undefined) && (
              <MultiplySelectSquare
                htmlFor="select"
                backgroundColor="#4a0074"
                arrowColor="#ffffffb3"
                color={'#ffffffb3'}
                arrowDirection="down"
                placeholder={t('step_target_audience.select')}
                defaultSelected={() => {
                  if (data?.educationLevelValue?.length > 0) {
                    let itemReturn = data?.educationLevelValue.map((e) => ({
                      label: e,
                      value: educationLevelOptions.find((el) => el.label === e)
                        ?.value,
                    }))

                    handleChange('educationLevelItems', itemReturn)
                    return itemReturn
                  }
                  return []
                }}
                options={educationLevelOptions}
                onClickItem={(value) => {
                  handleChange('educationLevelItems', value)
                }}
              />
            )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.social_class')}
            </p>

            {data?.socialClassValue?.length > 0 &&
              socialClassOptions.length > 0 && (
                <MultiplySelectSquare
                  htmlFor="select"
                  backgroundColor="#4a0074"
                  variante="purple"
                  arrowColor="#ffffffb3"
                  color="#ffffffb3"
                  arrowDirection="down"
                  placeholder={t('step_target_audience.select')}
                  defaultSelected={() => {
                    if (data?.socialClassValue?.length > 0) {
                      let itemReturn = data?.socialClassValue.map((e) => ({
                        label: e,
                        value: socialClassOptions.find((el) => el.label === e)
                          ?.value,
                      }))

                      handleChange('socialClassItems', itemReturn)

                      return itemReturn
                    }
                    return []
                  }}
                  options={socialClassOptions}
                  onClickItem={(value) => {
                    handleChange('socialClassItems', value)
                  }}
                />
              )}

            {(data?.socialClassValue?.length === 0 ||
              data?.socialClassValue?.length === undefined) && (
              <MultiplySelectSquare
                htmlFor="select"
                backgroundColor="#4a0074"
                variante="purple"
                arrowColor="#ffffffb3"
                color="#ffffffb3"
                arrowDirection="down"
                placeholder={t('step_target_audience.select')}
                defaultSelected={() => {
                  if (data?.socialClassValue?.length > 0) {
                    let itemReturn = data?.socialClassValue.map((e) => ({
                      label: e,
                      value: socialClassOptions.find((el) => el.label === e)
                        ?.value,
                    }))

                    handleChange('socialClassItems', itemReturn)

                    return itemReturn
                  }
                  return []
                }}
                options={socialClassOptions}
                onClickItem={(value) => {
                  handleChange('socialClassItems', value)
                }}
              />
            )}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.profession')}
            </p>
            <input
              className={styles.input}
              type="text"
              placeholder={t('step_target_audience.profession_placeholder')}
              value={data?.profession}
              id="profession"
              onChange={({ target: { id, value } }) => handleChange(id, value)}
            />
          </div>

          {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              Frequência de compra online
            </p>

            <CustomSelect
              placeholder={'Selecione'}
              arrowDirection="down"
              options={[{ label: '', value: '' }]}
              // defaultSelected={selectedSocialClass}
              fontSize={12}
              // onClickItem={() => handleChangeSelect('classe-social')}
            />
          </div> */}

          {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              Marcas que o público-alvo costuma comprar
            </p>

            <CustomSelect
              placeholder={'Selecione'}
              arrowDirection="down"
              options={[{ label: '', value: '' }]}
              // defaultSelected={selectedSocialClass}
              fontSize={12}
              // onClickItem={() => handleChangeSelect('classe-social')}
            />
          </div> */}
          {/* <div>
            <div>
              <p
                className={styles.subtitle_text}
                style={{ marginBottom: 8, color: '#898989', fontSize: 12 }}
              >
                Redes sociais que o público-alvo usa frequentemente
              </p>
            </div>
            <div className={styles.container_social_media}>
              <div
                className={styles.box_social_media}
                onClick={() => setIsPinterest(!isPinterest)}
              >
                <PinterestIcon
                  width={50}
                  height={50}
                  color={isPinterest ? '#FF8B00' : '#B9B9B9'}
                />
              </div>
              <div
                className={styles.box_social_media}
                onClick={() => setIsLinkedin(!isLinkedin)}
              >
                <LinkedlnIcon
                  width={50}
                  height={50}
                  color={isLinkedin ? '#FF8B00' : '#B9B9B9'}
                />
              </div>
              <div
                className={styles.box_social_media}
                onClick={() => setIsFacebook(!isFacebook)}
              >
                <FacebookIcon
                  width={50}
                  height={50}
                  color={isFacebook ? '#FF8B00' : '#B9B9B9'}
                />
              </div>
              <div
                className={styles.box_social_media}
                onClick={() => setIsInstagram(!isInstagram)}
              >
                <InstagramIcon
                  width={50}
                  height={50}
                  color={isInstagram ? '#FF8B00' : '#B9B9B9'}
                />
              </div>
              <div
                className={styles.box_social_media}
                onClick={() => setIsTiktok(!isTiktok)}
              >
                <TikTokIcon
                  width={50}
                  height={50}
                  color={isTiktok ? '#FF8B00' : '#B9B9B9'}
                />
              </div>
            </div>
          </div> */}

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.city')}
            </p>
            <input
              className={styles.input}
              type="text"
              placeholder={t('step_target_audience.city_placeholder')}
              defaultValue={data?.city}
              id="city"
              onChange={({ target: { id, value } }) => handleChange(id, value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.state')}
            </p>

            <CustomSelect
              placeholder={t('step_target_audience.select')}
              arrowDirection="down"
              options={locationsStatesOptions}
              fontSize={12}
              defaultSelected={locationsStatesOptions.find(
                (e) => e.value === data?.stateId
              )}
              onClickItem={({ value }) => handleChange('stateId', value)}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p className={styles.subtitle_text} style={{ marginBottom: 8 }}>
              {t('step_target_audience.region')}
            </p>

            <CustomSelect
              placeholder={t('step_target_audience.select')}
              arrowDirection="down"
              options={regionLocationOptions}
              fontSize={12}
              defaultSelected={regionLocationOptions.find(
                (e) => e.value === data?.regionId
              )}
              onClickItem={({ value }) => handleChange('regionId', value)}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttons_field}>
        <ButtonNavigateStep
          onClick={() => setStep(STEPS.STEP_SOCIAL_MEDIA)}
          variant={true}
        >
          {t('step_target_audience.back')}
        </ButtonNavigateStep>

        <ButtonNavigateStep onClick={() => handleSubmit()} variant={'next'}>
          {t('step_target_audience.next')}
        </ButtonNavigateStep>
      </div>
    </div>
  )
}

export default StepTargetAudience

StepTargetAudience.propTypes = {
  setStep: PropTypes.func,
}
