import { useState } from 'react'
import styles from './styles.module.css'
import Slider from 'rc-slider'
import {
  BrandingArchetypeService,
  BusinessService,
  EditorialLineService,
  ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services'
import Loading from '../../../components/Loading'
import { motion } from 'framer-motion'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'
import { FooterNaviBar } from '../../../components/FooterNaviBar'

const STEPS = {
  STEP_PASS_TYPOGRAPHY: 'stepTypography',

  STEP_VOICE_TONE: 'stepVoiceTone',
}

function StepBPS({ setStep, setBrandingArchetypeData, brandingArchetypeData }) {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: true,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: false,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: false,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: false,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: false,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false,
    },
  ]

  const handleChange = (k, v) => {
    setBrandingArchetypeData((prevState) => ({ ...prevState, [k]: v }))
  }

  const SliderComponent = ({ value, onChange }) => {
    return (
      <div>
        <Slider
          value={value}
          onChange={onChange}
          min={-5}
          max={5}
          trackStyle={{ backgroundColor: '#28003F', height: 6 }}
          railStyle={{ backgroundColor: '#28003F', height: 6 }}
          handleStyle={{
            width: 15,
            height: 15,
            backgroundColor: '#B900FE',
            border: '2.5px solid #FFFF',
            boxShadow: 'none',
          }}
        />
      </div>
    )
  }

  const SliderValueComponent = ({
    leftText,
    rightText,
    sliderValue,
    setSliderValue,
  }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 20, width: 180 }}>
          <p
            style={{
              margin: 0,
              fontSize: 10,
              textAlign: 'end',
              color: '#6a0098',
            }}
          >
            {leftText}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 300,
          }}
        >
          {SliderComponent({
            value: sliderValue,
            onChange: setSliderValue,
          })}
        </div>

        <div style={{ marginLeft: 20, width: 180 }}>
          <p style={{ margin: 0, fontSize: 10, color: '#6a0098' }}>
            {rightText}
          </p>
        </div>
      </div>
    )
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      const bpsFemaleOrMale = brandingArchetypeData?.bpsFemaleOrMale || 0
      const bpsSimpleOrComplex = brandingArchetypeData?.bpsSimpleOrComplex || 0
      const bpsSubtleOrExtravagant =
        brandingArchetypeData?.bpsSubtleOrExtravagant || 0
      const bpsClassicOrModern = brandingArchetypeData?.bpsClassicOrModern || 0
      const bpsFriendlyOrDistant =
        brandingArchetypeData?.bpsFriendlyOrDistant || 0
      const bpsPlayfulOrSerious =
        brandingArchetypeData?.bpsPlayfulOrSerious || 0
      const bpsNaturalOrIndustrial =
        brandingArchetypeData?.bpsNaturalOrIndustrial || 0
      const bpsPopularOrElite = brandingArchetypeData?.bpsPopularOrElite || 0
      const bpsStrippedOrSophisticated =
        brandingArchetypeData?.bpsStrippedOrSophisticated || 0
      const bpsRusticOrRefined = brandingArchetypeData?.bpsRusticOrRefined || 0

      await BrandingArchetypeService.postBrandingArchetype({
        bpsFemaleOrMale,
        bpsSimpleOrComplex,
        bpsSubtleOrExtravagant,
        bpsClassicOrModern,
        bpsFriendlyOrDistant,
        bpsPlayfulOrSerious,
        bpsNaturalOrIndustrial,
        bpsPopularOrElite,
        bpsStrippedOrSophisticated,
        bpsRusticOrRefined,
        brandHistoryAndPurpose: '',
        brandCommunicationStyle: '',
        brandCompetitors: '',
        brandValuesAndMission: '',

        layerMin: 0,
        layerMax: 0,
      })

      // saveEditorialLine()
      setStep(STEPS.STEP_VOICE_TONE)
    } else {
      setStep(STEPS.STEP_PASS_TYPOGRAPHY)
    }
  }
  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          {/* <PreviousPass steps={stepsArray}/> */}

          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>{t('fast_publishing_bps_text')}</p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
              {t('fast_publishing_bps')}
            </h1>

            <div className={styles.box_input_bps}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 330,
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      gap: '11px'
                    }}
                  >
                    <p className={styles.number_parameter}>5</p>
                    <p className={styles.number_parameter}>4</p>
                    <p className={styles.number_parameter}>3</p>
                    <p className={styles.number_parameter}>2</p>
                    <p className={styles.number_parameter}>1</p>

                    <p className={styles.number_parameter}>0</p>

                    <p className={styles.number_parameter}>1</p>
                    <p className={styles.number_parameter}>2</p>
                    <p className={styles.number_parameter}>3</p>
                    <p className={styles.number_parameter}>4</p>
                    <p className={styles.number_parameter}>5</p>
                  </div>

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_feminino')}`,
                    rightText: `${t('fast_publishing_bps_masculino')}`,
                    sliderValue: brandingArchetypeData?.bpsFemaleOrMale || 0,

                    setSliderValue: (value) =>
                      handleChange('bpsFemaleOrMale', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_simples')}`,
                    rightText: `${t('fast_publishing_bps_complexo')}`,
                    sliderValue: brandingArchetypeData?.bpsSimpleOrComplex || 0,

                    setSliderValue: (value) =>
                      handleChange('bpsSimpleOrComplex', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_sutil')}`,
                    rightText: `${t('fast_publishing_bps_extravagante')}`,
                    sliderValue:
                      brandingArchetypeData?.bpsSubtleOrExtravagant || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsSubtleOrExtravagant', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_classico')}`,
                    rightText: `${t('fast_publishing_bps_moderno')}`,
                    sliderValue: brandingArchetypeData?.bpsClassicOrModern || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsClassicOrModern', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_amigavel')}`,
                    rightText: `${t('fast_publishing_bps_distante')}`,
                    sliderValue:
                      brandingArchetypeData?.bpsFriendlyOrDistant || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsFriendlyOrDistant', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_brincalhao')}`,
                    rightText: `${t('fast_publishing_bps_serio')}`,
                    sliderValue:
                      brandingArchetypeData?.bpsPlayfulOrSerious || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsPlayfulOrSerious', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_natural')}`,
                    rightText: `${t('fast_publishing_bps_industrial')}`,
                    sliderValue:
                      brandingArchetypeData?.bpsNaturalOrIndustrial || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsNaturalOrIndustrial', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_popular')}`,
                    rightText: `${t('fast_publishing_bps_elitizado')}`,
                    sliderValue: brandingArchetypeData?.bpsPopularOrElite || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsPopularOrElite', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_despojado')}`,
                    rightText: `${t('fast_publishing_bps_sofisticado')}`,
                    sliderValue:
                      brandingArchetypeData?.bpsStrippedOrSophisticated || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsStrippedOrSophisticated', value),
                  })}

                  {SliderValueComponent({
                    leftText: `${t('fast_publishing_bps_rustico')}`,
                    rightText: `${t('fast_publishing_bps_refinado')}`,
                    sliderValue: brandingArchetypeData?.bpsRusticOrRefined || 0,
                    setSliderValue: (value) =>
                      handleChange('bpsRusticOrRefined', value),
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              {t("back_buttom")}
            </button>
            <button
              className={styles.next_button}
              onClick={() => handleNavigate('next')}
            >
              {t('next_buttom')}
            </button>
          </div> */}
          <FooterNaviBar
            back={() => handleNavigate('back')} 
            next={() => handleNavigate('next')}
          />
        </div>
      </motion.div>
    </>
  )
}

export default StepBPS
