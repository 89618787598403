/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import styles from './styles.module.css'
import {
  CircleX,
  Cross,
  Interrogation,
  // ArrowDown,
} from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'

import { MyUploadsService } from '../../../../services'
import { Loading } from '../../../../components/Loading'

import {
  notifyErrorCustom,
  // notifySuccessCustom,
} from '../../../../utils/notify'

import { useClient } from '../../../../hooks/useClient'

const ModalUploads = ({ closeModal, updateImage }) => {
  const { clientData } = useClient()

  const [selectedImages, setSelectedImages] = useState([])
  const [selectedArrayImage, setSelectedArrayImage] = useState([])

  const [images, setImages] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    loadData()
  }, [])

  const loadData = async () => {
    const response = await MyUploadsService.getMyUploadsByPublicationIdBase64(
      clientData?.uuid || null
    )

    if (response.responseStatus === 200) {
      const images = response.data

      setImages(images)
    } else {
      notifyErrorCustom('Ocorreu um erro ao carregar as imagens')
    }

    setLoading(false)
  }

  const handleImageClick = (imageUrl) => {
    const imageArray = images.find(
      (image) => image.fileBase64 === imageUrl.fileBase64
    )

    if (imageArray) {
      const arrayExists = selectedArrayImage.some(
        (arr) => JSON.stringify(arr) === JSON.stringify(imageArray)
      )

      if (arrayExists) {
        setSelectedArrayImage(
          selectedArrayImage.filter(
            (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
          )
        )
      } else {
        setSelectedArrayImage([...selectedArrayImage, imageArray])
      }
    }

    if (selectedImages.includes(imageUrl.fileBase64)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageUrl.fileBase64)
      )
    } else {
      setSelectedImages([...selectedImages, imageUrl.fileBase64])
    }
  }

  const handleRemoveImage = (imageArray) => {
    setSelectedArrayImage(
      selectedArrayImage.filter(
        (arr) => JSON.stringify(arr) !== JSON.stringify(imageArray)
      )
    )

    if (selectedImages.includes(imageArray.fileBase64)) {
      setSelectedImages(
        selectedImages.filter((image) => image !== imageArray.fileBase64)
      )
    } else {
      setSelectedImages([...selectedImages, imageArray.fileBase64])
    }
  }

  return (
    <div className={styles.modal}>
      <Loading enable={loading} />
      <div className={styles.header_close}>
        <div
          className={styles.close_modal}
          onClick={() => closeModal('uploads')}
        >
          <Cross width={'20px'} height={'20px'} />
        </div>
      </div>
      <div className={styles.modal_title}>
        <h2>
          Selecione um de seus uploads <Interrogation color={'#FF8B00'} />
        </h2>
      </div>
      <div className={styles.modal_sub_title}>
        <p className={styles.modal_sub_title_text}>
          Elementos e assets que você utilizou em outra publicações podem ser
          usados nesta publicação também!
        </p>
      </div>

      <div className={styles.container_galeyimages}>
        <Masonry columnsCount={3} gutter="10px">
          {images.map((imageUrl, i) => (
            <img
              key={i}
              src={'data:image/png;base64,' + imageUrl?.fileBase64}
              onClick={() => handleImageClick(imageUrl)}
              className={
                selectedImages.includes(imageUrl.fileBase64)
                  ? styles.selectedImage
                  : styles.image
              }
              onContextMenu={(e) => e.preventDefault()}
            />
          ))}
        </Masonry>
      </div>

      {selectedArrayImage.length !== 0 && (
        <div className={styles.contaier_caroucel_list_selected_image}>
          <p>Imagens Adicionadas</p>
          <div className={styles.caroucel_list_selected_image} key={1}>
            {selectedArrayImage.map((imageArray) => (
              <div
                className={styles.card_lis_selected_image}
                key={imageArray.id}
              >
                <div className={styles.container_card_lis_selected_image}>
                  <div
                    className={styles.delete_card_lis_selected_image}
                    onClick={() => handleRemoveImage(imageArray)}
                  >
                    <div style={{ cursor: 'pointer' }}>
                      <CircleX
                        color={'#FF2F00'}
                        width={'15px'}
                        height={'15px'}
                      />
                    </div>
                  </div>
                  <img
                    className={styles.item_selected_image}
                    src={'data:image/png;base64,' + imageArray.fileBase64}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedArrayImage.length !== 0 && (
        <button
          className={styles.button_add_image}
          onClick={() => {
            // addImages()
            updateImage(selectedArrayImage)
          }}
        >
          Adicionar Imagens
        </button>
      )}
    </div>
  )
}

export default ModalUploads
