import { Checkbox, Empty } from 'antd'
import { HistoryModal, Input } from '../../components'
import { ListTable } from '../../components/ListTable'
import { Row } from '../../components/ListTable/Row'
import { TableItem } from '../../components/ListTable/TableItem'
import styles from './styles.module.css'
import { Switch } from 'antd'
import { CloundArrowUp, Gear, Trash } from '../../assets/svgs/icons'
import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { BusinessUser, PlanService, SubscriptionService } from '../../services'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'

import * as yup from 'yup'
import InputMask from 'react-input-mask'
import { ImageCard } from '../../components/ImageCard'

import { Loading } from '../../components/Loading'

import perfil from '../../assets/imgs/perfil.png'
import { useTranslation } from 'react-i18next'

function UserList() {
  const { t } = useTranslation()
  const headers = [
    `${t('user_list_table_avatar')}`,
    `${t('user_list_table_name')}`,
    `${t('user_list_table_lastname')}`,
    `${t('user_list_table_email')}`,
    `${t('user_list_table_phone')}`,
    `${t('user_list_table_action')}`,
  ]

  const [dataUserList, setDataUserList] = useState([{}])
  const [userData, setUserData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
  })

  const [imagesToRender, setImagesToRender] = useState([])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadBusinessUser()
  }, [])

  const loadBusinessUser = async () => {
    setLoading(true)
    const { data, responseStatus } = await BusinessUser.getAllBusinessUser()

    if (responseStatus === 200 || responseStatus === 204) {
      // Limitar o email a 30 caracteres e adicionar "..." se necessário
      const updatedData = data.map((user) => {
        if (user.email && user.email.length > 30) {
          return {
            ...user,
            emailList: user.email.substring(0, 30) + '...', // Limita o email a 30 caracteres
          }
        }
        return user
      })

      setDataUserList(updatedData) // Atualiza o estado com os dados modificados
    }
    setLoading(false)
  }

  // const loadBusinessUser = async () => {
  //   setLoading(true)
  //   const { data, responseStatus } = await BusinessUser.getAllBusinessUser()

  //   if (responseStatus === 200 || responseStatus === 204) {
  //     setDataUserList(data)
  //   }
  //   setLoading(false)
  // }

  const [expanded, setExpanded] = useState(false)

  const handleExpandForm = async (params) => {
    if (!params) {
      const businessUserCount = await BusinessUser.getAllBusinessUserCount()
      const userSubscription = await SubscriptionService.getSubscriptionByUser()
      if (
        userSubscription.data.length <= 0 &&
        businessUserCount.data.count >= 1
      ) {
        notifyWarningCustom(t('user_list_plan_limit'))
        return
      } else if (userSubscription.data.length > 0) {
        const indexUser =
          await userSubscription.data[0].Plan.PlanItems.findIndex(
            (item) => item.type === 'user_quantity'
          )
        const quantityItemUser =
          userSubscription.data[0].Plan.PlanItems[indexUser].quantity

        if (businessUserCount.data.count >= quantityItemUser) {
          notifyWarningCustom(t('user_list_plan_limit'))
          return
        }
      }
    }
    if (!params) {
      setUserData({ name: '', surname: '', email: '', phone: '' })
      setImagesToRender([])
    }
    setUserData(params)
    setExpanded(!expanded)
  }

  const handleChange = (k, v) => {
    setUserData((prevState) => ({ ...prevState, [k]: v }))
  }

  const registrationDataScheema = yup.object().shape({
    phone: yup.string().required('preencha o campo telefone'),
    email: yup
      .string()
      .email('preencha o campo email corretamente')
      .required('preencha o campo email'),
    surname: yup.string().required('preencha o campo sobrenome'),
    name: yup.string().required('preencha o campo nome'),
  })

  const validateFields = async () => {
    const isValid = await registrationDataScheema
      .validate(userData)
      .then(() => {
        return true
      })
      .catch((error) => {
        notifyErrorCustom(error.message)
        return false
      })

    return isValid
  }

  const handleSave = async () => {
    const isValid = await validateFields(userData)
    if (!isValid) return

    if (userData?.uuid) {
      if (
        imagesToRender[0]?.isBase64 &&
        imagesToRender[0]?.status != 'deleted'
      ) {
        const { responseImageStatus } =
          await BusinessUser.deleteBusinessImageUser(userData.userPhotoName)

        if (responseImageStatus === 200 || responseImageStatus === 204) {
          //  alert('excluiu')
        }

        const uploadImageBody = mountImageBodyUpload(
          imagesToRender[0]?.fileUrl,
          imagesToRender[0]?.fileName,
          imagesToRender[0]?.fileFormat,
          imagesToRender[0].fileContentType
        )

        setLoading(true)
        const { dataImage, responseStatusImage } =
          await BusinessUser.postBusinessUserImage(uploadImageBody)

        setLoading(false)

        if (responseStatusImage === 200 || responseStatusImage === 204) {
          console.log(responseStatusImage)
        }

        const { uuid, ...rest } = userData

        delete rest.userAccountId
        delete rest.createdAt
        delete rest.updatedAt
        delete rest.deletedAt
        delete rest.password
        delete rest.emailList
        rest.userPhotoName = dataImage?.fileName
        rest.userPhotoUrl = dataImage?.fileUrl

        setLoading(true)

        const { responseStatus } = await BusinessUser.putBusinessUser(
          uuid,
          rest
        )

        setLoading(false)
        if (responseStatus === 200 || responseStatus === 204) {
          notifySuccessCustom('Item alterado com sucesso', '')
        } else {
          notifyErrorCustom('Não foi possível alterar os dados do usuário!')
        }
        setUserData({ name: '', surname: '', email: '', phone: '' })
        setImagesToRender([])
        setExpanded(false)
      } else if (
        userData.userPhotoName &&
        imagesToRender[0]?.status === 'deleted'
      ) {
        if (userData.userPhotoName) {
          const { responseImageStatus } =
            await BusinessUser.deleteBusinessImageUser(userData.userPhotoName)
          // await BusinessClient.deleteBusinessImageUser(
          //   userData.userPhotoName
          // )

          const { uuid, ...rest } = userData

          delete rest.userAccountId
          delete rest.createdAt
          delete rest.updatedAt
          delete rest.deletedAt
          delete rest.password
          delete rest.emailList
          //   delete rest.userPhotoName
          //   delete rest.userPhotoUrl

          rest.userPhotoName = ''
          rest.userPhotoUrl = ''

          setLoading(true)
          const { responseStatus } = await BusinessUser.putBusinessUser(
            uuid,
            rest
          )
          setLoading(false)
          if (responseStatus === 200 || responseStatus === 204) {
            notifySuccessCustom('Item alterado com sucesso', '')
          } else {
            notifyErrorCustom('Não foi possível alterar os dados do usuário!')
          }
          setUserData({ name: '', surname: '', email: '', phone: '' })
          setImagesToRender([])
          setExpanded(false)
        }
      } else {
        const { uuid, ...rest } = userData

        delete rest.userAccountId
        delete rest.createdAt
        delete rest.updatedAt
        delete rest.deletedAt
        delete rest.password
        delete rest.emailList
        //   delete rest.userPhotoName
        //   delete rest.userPhotoUrl

        setLoading(true)

        const { responseStatus } = await BusinessUser.putBusinessUser(
          uuid,
          rest
        )
        setLoading(false)
        if (responseStatus === 200 || responseStatus === 204) {
          notifySuccessCustom('Item alterado com sucesso', '')
        } else {
          notifyErrorCustom('Não foi possível alterar os dados do usuário!')
        }
        setUserData({ name: '', surname: '', email: '', phone: '' })
        setImagesToRender([])
        setExpanded(false)
      }
    } else {
      const checkEmailExists = await BusinessUser.getUserCheckEmaiUser(
        userData.email
      )

      if (checkEmailExists?.data?.exists) {
        notifyErrorCustom('E-mail já cadastrado!')
        return setLoading(false)
      }
      if (
        imagesToRender[0]?.isBase64 &&
        imagesToRender[0]?.status != 'deleted'
      ) {
        const uploadImageBody = mountImageBodyUpload(
          imagesToRender[0]?.fileUrl,
          imagesToRender[0]?.fileName,
          imagesToRender[0]?.fileFormat,
          imagesToRender[0].fileContentType
        )

        setLoading(true)
        const { dataImage, responseStatusImage } =
          await BusinessUser.postBusinessUserImage(uploadImageBody)

        setLoading(false)
        if (responseStatusImage === 200 || responseStatusImage === 204) {
          // alert('deu certo')
        }

        setLoading(true)

        const { responseStatus } = await BusinessUser.postBusinessUser({
          ...userData,
          userPhotoName: dataImage?.fileName,
          userPhotoUrl: dataImage?.fileUrl,
        })

        setLoading(false)

        if (responseStatus === 200 || responseStatus === 204) {
          notifySuccessCustom('Usuário criado com sucesso', '')
        } else {
          notifyErrorCustom('Não foi possível criar o usuário!')
        }
      } else {
        setLoading(true)
        const { responseStatus } = await BusinessUser.postBusinessUser({
          ...userData,
          userPhotoName: '',
          userPhotoUrl: '',
        })

        setLoading(false)

        if (responseStatus === 200 || responseStatus === 204) {
          notifySuccessCustom('Usuário criado com sucesso', '')
        } else {
          notifyErrorCustom('Não foi possível criar o usuário!')
        }
      }

      setUserData({ name: '', surname: '', email: '', phone: '' })
      setImagesToRender([])
      setExpanded(false)
    }

    loadBusinessUser()
  }

  const handleChecked = async (data) => {
    let uuid = data?.uuid
    let newData = data

    delete newData.uuid
    delete newData.createdAt
    delete newData.updatedAt
    delete newData.deletedAt

    delete newData.userAccountId
    delete newData.educationLevelValue
    delete newData.socialClassValue
    delete newData.genderValue
    delete newData.stateValue
    delete newData.password
    delete newData.emailList

    setLoading(true)
    const { responseStatus } = await BusinessUser.putBusinessUser(uuid, {
      ...newData,
      enabled: !newData.enabled,
    })

    setLoading(false)

    if (responseStatus === 200 || responseStatus === 204) {
      loadBusinessUser()
      notifySuccessCustom('Status alterado com sucesso!')
    } else {
      notifyErrorCustom('Não foi possível alterar o status!')
    }
  }

  const mountImageBodyUpload = (base64Str, name, type) => {
    const formats = ['jpg', 'png', 'jpeg', 'webp']
    const stringReference = 'base64,'
    const index = base64Str.indexOf(stringReference) + stringReference.length
    let fileFormat = ''
    const fileBase64 = base64Str.substr(index)
    formats.map((format) => {
      if (base64Str.includes(format)) fileFormat = format
    })

    return {
      fileName: name,
      fileFormat,
      fileContentType: type,
      fileBase64,
    }
  }

  const handleDeleteUser = async (id, fileName) => {
    const { responseImageStatus } = await BusinessUser.deleteBusinessImageUser(
      fileName
    )

    if (responseImageStatus === 200 || responseImageStatus === 204) {
      //
    }

    setLoading(true)

    const { responseStatus } = await BusinessUser.deleteBusinessUser(id)

    setLoading(false)
    if (responseStatus === 200 || responseStatus === 204) {
      notifySuccessCustom('Usuário removido com sucesso', '')

      loadBusinessUser()
    } else {
      notifyErrorCustom('Não foi possível remover o usuário!')
    }
  }

  const onFileChange = (e) => {
    Array.from(e.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = function () {
        let fileDataBase64 = reader.result
        let fileDataReturn = {
          fileUrl: fileDataBase64,
          isBase64: true,
          status: 'create',
          fileName: file.name,
          fileFormat: file.type,
          fileContentType: 'image/' + file.type,
        }

        setImagesToRender([fileDataReturn])
      }

      reader.readAsDataURL(file)
    })
  }

  const onExcludeImage = (index) => {
    if (imagesToRender[index]) {
      imagesToRender[index].status = 'deleted'
      setImagesToRender([...imagesToRender])
    } else if (index === 0) {
      let fileDataReturn = {
        fileUrl: '',
        isBase64: true,
        status: 'deleted',
        fileName: '',
        fileFormat: '',
        fileContentType: '',
      }

      setImagesToRender((current) => [...current, fileDataReturn])
    }
  }

  return (
    <div className={styles.container_page}>
      <Loading enable={loading} />
      <div className={styles.header_page}>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>{t('user_list_title')}</p>
          <p className={styles.title_new_posts_title}>
            {t('user_list_title_text')}
          </p>
        </div>
        <div>
          <button
            className={styles.button_new_user}
            onClick={() => handleExpandForm(expanded)}
          >
            {expanded
              ? `${t('user_list_title_cancel_button')}`
              : `${t('user_list_title_new_user_button')}`}
          </button>
        </div>
      </div>

      <div className={styles.container_content}>
        <AnimatePresence initial={false}>
          {expanded && (
            <motion.div
              // key={expanded}
              initial={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={styles.container_form}
            >
              <div className={styles.box_container_drag}>
                {/* <p>Novo usuário</p> */}
                <h3>
                  {userData?.uuid
                    ? `${t('user_list_new_user_edit')}`
                    : `${t('user_list_new_user')}`}
                </h3>
                <div className={styles.modal_container_drag}>
                  <div className={styles.modal_drag}>
                    <div className={styles.modal_drag_drop}>
                      <label htmlFor="arquivo">
                        <CloundArrowUp color={'#B900FE'} />
                        <h2 className={styles.modal_drag_drop_title}>
                          {t('user_list_new_user_drag_and_drop')}
                        </h2>
                        <p className={styles.modal_drag_drop_sub_title}>
                          {t('user_list_new_user_select_your_files')}
                        </p>
                      </label>
                      <input
                        type="file"
                        name="arquivo"
                        id="arquivo"
                        onChange={(e) => onFileChange(e)}
                      ></input>
                    </div>
                  </div>
                  <div className={styles.images_container}>
                    {imagesToRender?.map(
                      (e, index) =>
                        e.status !== 'deleted' && (
                          <ImageCard
                            key={index}
                            image={e}
                            onClickButton={() => onExcludeImage(index)}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '13px',
                              }}
                            >
                              <p
                                style={{
                                  color: '##190027',
                                  fontSize: '12px',
                                  margin: 0,
                                }}
                              >
                                {t('user_list_new_user_delete_image')}
                              </p>
                              <Trash color={'#000000'} />
                            </div>
                          </ImageCard>
                        )
                    )}

                    {userData?.userPhotoUrl && !imagesToRender[0] && (
                      <ImageCard
                        // image={`data:image/jpeg;base64,${userData?.userPhotoUrl}`}
                        image={`${userData?.userPhotoUrl}`}
                        onClickButton={() => onExcludeImage(0)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '13px',
                          }}
                        >
                          <p
                            style={{
                              color: '##190027',
                              fontSize: '12px',
                              margin: 0,
                            }}
                          >
                            Excluir
                          </p>
                          <Trash color={'#000000'} />
                        </div>
                      </ImageCard>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.container_input_form}>
                <div className={styles.box_input_form}>
                  <label className={styles.label_input}>
                    {t('user_list_new_user_register_first_name')}
                  </label>
                  <Input
                    id={'name'}
                    value={userData?.name || ''}
                    onChange={({ target: { id, value } }) =>
                      handleChange(id, value)
                    }
                  />
                </div>

                <div className={styles.box_input_form}>
                  <label className={styles.label_input}>
                    {t('user_list_new_user_register_last_name')}
                  </label>
                  <Input
                    id={'surname'}
                    value={userData?.surname || ''}
                    onChange={({ target: { id, value } }) =>
                      handleChange(id, value)
                    }
                  />
                </div>

                <div className={styles.box_input_form}>
                  <label className={styles.label_input}>
                    {' '}
                    {t('user_list_new_user_register_email')}{' '}
                  </label>
                  <Input
                    id={'email'}
                    value={userData?.email || ''}
                    onChange={({ target: { id, value } }) =>
                      handleChange(id, value)
                    }
                  />
                </div>

                <div className={styles.box_input}>
                  <label className={styles.label_input}>
                    {t('user_list_new_user_register_phone')}
                  </label>
                  <InputMask
                    id={'phone'}
                    className={styles.input}
                    type="text"
                    mask="(99) 99999-9999"
                    placeholder="Telefone"
                    value={userData?.phone || ''}
                    onChange={({ target: { id, value } }) =>
                      handleChange(id, value)
                    }
                  />
                </div>
              </div>

              <div className={styles.box_buttom_save}>
                <button className={styles.buttom_save} onClick={handleSave}>
                  {t('user_list_new_user_register_butom_save')}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <ListTable headers={headers}>
          {dataUserList?.map((user, index) => (
            // eslint-disable-next-line react/jsx-key
            <Row key={index}>
              <TableItem>
                <div className={`${styles.table_item_gap} ${styles.code}`}>
                  <div>
                    {user?.userPhotoUrl ? (
                      <img
                        src={user?.userPhotoUrl}
                        alt={''}
                        className={styles.user_avatar}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    ) : (
                      <img
                        src={perfil}
                        alt={''}
                        className={styles.user_avatar}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    )}
                  </div>
                </div>
              </TableItem>
              <TableItem>{user.name}</TableItem>
              <TableItem>{user.surname}</TableItem>
              <TableItem>
                <span className={styles.bold}>{user.emailList}</span>
              </TableItem>
              <TableItem>
                <span className={styles.bold}>{user.phone}</span>
              </TableItem>
              <TableItem style={{ maxWidth: 170 }}>
                <div className={styles.last_column}>
                  <div className={styles.icons_container}>
                    <div className={styles.icon}>
                      <div className={styles.box_action_icon}>
                        <Switch
                          size="small"
                          style={{
                            backgroundColor: user?.enabled ? 'black' : '',
                          }}
                          checked={user?.enabled}
                          onClick={() => handleChecked(user)}
                        />
                      </div>

                      <div
                        className={styles.box_action_icon}
                        onClick={() => handleExpandForm(user)}
                      >
                        <Gear color={'#000'} />
                      </div>
                      <div
                        className={styles.box_action_icon}
                        onClick={() =>
                          handleDeleteUser(user.uuid, user.userPhotoName)
                        }
                      >
                        <Trash color={'#000'} />
                      </div>
                    </div>
                  </div>
                </div>
              </TableItem>
            </Row>
          ))}
        </ListTable>
      </div>
    </div>
  )
}

export default UserList
