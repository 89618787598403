import { useRef, useState } from 'react'
import styles from './styles.module.css'

const CAROUSEL_PROPS = {
  CLICK: 'click',
  SCROLL: 'scroll',
}

// eslint-disable-next-line react/prop-types
const Carousel = ({ items, type = 'click' | 'scroll' }) => {
  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  const [selectedImage, setSelectedImage] = useState([])

  const handleMouseDown = (event) => {
    if (type === CAROUSEL_PROPS.SCROLL) {
      setIsDragging(true)
      setStartX(event.clientX - carouselRef.current.offsetLeft)
      setScrollLeft(carouselRef.current.scrollLeft)
      carouselRef.current.style.scrollBehavior = 'unset'
    }
  }

  const handleMouseMove = (event) => {
    if (type === CAROUSEL_PROPS.SCROLL) {
      if (!isDragging) return
      const x = event.clientX - carouselRef.current.offsetLeft
      const scrollOffset = (x - startX) * 1.0 // Ajuste a velocidade de rolagem conforme necessário
      carouselRef.current.scrollLeft = scrollLeft - scrollOffset
    }
  }

  const handleMouseUp = () => {
    if (type === CAROUSEL_PROPS.SCROLL) {
      setIsDragging(false)
      carouselRef.current.style.scrollBehavior = 'smooth'
    }
  }

  const handleScrollLeft = () => {
    carouselRef.current.scrollBy({
      left: -carouselRef.current.offsetWidth,
      behavior: 'smooth',
    })
  }

  const hadleScrollRight = () => {
    carouselRef.current.scrollBy({
      left: carouselRef.current.offsetWidth,
      behavior: 'smooth',
    })
  }

  const renderItems = () => {
    return items.map((item) => (
      <div
        key={item.id}
        className={styles.carousel_item}
        onClick={() => hendleSelectImage(item.id)}
      >
        <img
          draggable="true"
          onDragStart={handleDragStart}
          className={
            selectedImage.includes(item.id)
              ? styles.carousel_selectedImage
              : styles.carousel_img
          }
          src={item.content}
          alt="Efects"
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
    ))
  }

  const hendleSelectImage = (id) => {
    if (selectedImage.includes(id)) {
      setSelectedImage(selectedImage.filter((image) => image !== id))
    } else {
      setSelectedImage([...selectedImage, id])
    }
  }

  const handleDragStart = (event) => {
    event.preventDefault()
    // Lógica para manipular o evento de arrastar
    // ...
  }

  return (
    <div
      className={styles.carousel_container}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {type === CAROUSEL_PROPS.CLICK ? (
        <button className={styles.carousel_button} onClick={handleScrollLeft}>
          &lt;
        </button>
      ) : null}

      <div className={styles.carousel} ref={carouselRef}>
        {renderItems()}
      </div>

      {type === CAROUSEL_PROPS.CLICK ? (
        <button className={styles.carousel_button} onClick={hadleScrollRight}>
          &gt;
        </button>
      ) : null}
    </div>
  )
}

export default Carousel
