/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import { useEffect, useRef, useState } from 'react'
import { NavigateArrowRigth, ChevronDown } from '../../../../assets/svgs/icons'
import { notifyWarningCustom } from '../../../../utils/notify'
import { useTranslation } from 'react-i18next'

export const SelectSquare = ({
  backgroundColor = '#e0e0e0',
  border,
  borderColor,
  options,
  label,
  tiktokBrandedContent = false,
  htmlFor,
  placeholder,
  defaultSelected,
  arrowDirection = 'right',
  fontSize,
  onClickItem,
  round,
  color = '#0f172a',
  arrowColor = '#0f172a',
  padding = '10px 15px 10px 15px',
  radius = '10px',
  minWidth = '0px',
}) => {
  const [containerVisible, setContainerVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({
    label: '',
    value: '',
  })
  const { t } = useTranslation()
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [tooltipShowMain, setTooltipShowMain] = useState(false)
  const [tooltipShowComplementary, setTooltipShowComplementary] =
    useState(false)
  const [tooltipShowBackground, setTooltipShowBackground] = useState(false)

  const handleSelect = (e) => {
    setContainerVisible(false)
    setSelectedItem(e)
    onClickItem(e)
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setContainerVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containerVisible])

  const ref = useRef(null)

  useEffect(() => {
    setSelectedItem(defaultSelected)
  }, [defaultSelected])

  return (
    <div ref={ref} className={styles.Container_select}>
      <div className={styles.inputSelectContainer}>
        <label htmlFor={htmlFor}>{label}</label>
        <div
          style={{
            backgroundColor,
            border: border ? '1px solid ' : 'none',
            borderColor: borderColor ? borderColor : 'none',
            padding: padding,
            borderRadius: radius,
            minWidth: minWidth,
          }}
          className={round ? styles.buttonSelectRound : styles.buttonSelect}
          onClick={() => setContainerVisible((prev) => !prev)}
          type="text"
        >
          <p
            style={{ fontSize: fontSize, color: color }}
            className={styles.placeholder_select}
          >
            {selectedItem?.label || placeholder}
          </p>
          {/* <p>{value}</p> */}

          <div className={styles.box_svg}>
            <div
              className={` ${styles[arrowDirection]} ${
                containerVisible ? styles.rotate : styles.containerVisible
              }`}
            >
              {arrowDirection == 'right' && (
                <NavigateArrowRigth size="16" color={arrowColor} />
              )}
              {arrowDirection == 'down' && (
                <ChevronDown size="16" color={arrowColor} />
              )}
            </div>
          </div>
        </div>
        {containerVisible && (
          <div className={styles.selectContainer}>
            {options.map((option, index) => (
              <button
                key={index}
                className={`${styles.selectContainerItem} ${
                  option.value === 'SELF_ONLY' && tiktokBrandedContent
                    ? styles.disabled
                    : ''
                }`}
                onClick={() => {
                  if (option.value === 'SELF_ONLY' && tiktokBrandedContent) {
                    return
                  } else {
                    handleSelect(option)
                  }
                }}
                onMouseEnter={() => {
                  if (option.value === 'SELF_ONLY' && tiktokBrandedContent) {
                    setTooltipVisible(true)
                  }
                  if (option.value === 'Principal') {
                    setTooltipShowMain(true)
                  }
                  if (option.value === 'Complementar') {
                    setTooltipShowComplementary(true)
                  }
                  if (option.value === 'Background') {
                    setTooltipShowBackground(true)
                  }
                }}
                onMouseLeave={() => {
                  if (option.value === 'SELF_ONLY' && tiktokBrandedContent) {
                    setTooltipVisible(false)
                  }
                  if (option.value === 'Principal') {
                    setTooltipShowMain(false)
                  }
                  if (option.value === 'Complementar') {
                    setTooltipShowComplementary(false)
                  }
                  if (option.value === 'Background') {
                    setTooltipShowBackground(false)
                  }
                }}
              >
                <span>{option.label}</span>
                {tooltipVisible &&
                  option.value === 'SELF_ONLY' &&
                  tiktokBrandedContent && (
                    <div className={styles.tooltip}>
                      {t('tiktok_tolltip_privacy_disable')}
                    </div>
                  )}

                {option.value === 'Principal' && tooltipShowMain && (
                  <div className={styles.tooltip}>
                    {t(
                      'Categoriza a imagem com maior destaque na arte, sendo o elemento central da composição.'
                    )}
                  </div>
                )}
                {option.value === 'Complementar' &&
                  tooltipShowComplementary && (
                    <div className={styles.tooltip}>
                      {t(
                        'Elemento auxiliar para compor a arte, trazendo equilíbrio e harmonia.'
                      )}
                    </div>
                  )}
                {option.value === 'Background' && tooltipShowBackground && (
                  <div className={styles.tooltip}>
                    {t(
                      'Plano de fundo da arte, podendo ser usado para enriquecer a composição.'
                    )}
                  </div>
                )}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
