/* eslint-disable react/prop-types */
import { useEffect } from 'react'

import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import styles from './styles.module.css'

export const ImageFormatContainer = ({
  // image,
  imageFormat,
  prevImageFormat,
  title,
  // fontName,
  imageLayers,
}) => {
  // return (
  //   <div className={styles.imageContainer}>
  //     <p className={styles.headline_text} style={{ fontFamily: fontName }}>
  //       {title}
  //     </p>
  //     <motion.img
  //       transition={{ duration: 0.5, ease: 'easeInOut' }}
  //       animate={{ ...imageFormat }}
  //       initial={{ ...prevImageFormat }}
  //       src={image}
  //       alt="modal image"
  //     />
  //   </div>
  // )

  return (
    <div className={styles.imageContainer}>
      {/* <p className={styles.headline_text} style={{ fontFamily: fontName }}>
        {title}
      </p> */}
      <motion.img
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        animate={{ ...imageFormat }}
        initial={{ ...prevImageFormat }}
        src={
          imageLayers
            ? imageLayers
            : 'https://img.freepik.com/vetores-premium/conjunto-de-pontos-de-interrogacao-desenhados-a-mao-ilustracao-vetorial_36380-818.jpg?w=740'
        }
        alt="modal image"
        onContextMenu={(e) => e.preventDefault()}
      />
    </div>
  )

  // return (
  //   <div className={styles.imageContainer}>
  //     {imageLayers?.layer &&
  //       imageLayers?.layer.map((item, index) => (
  //         <motion.img
  //           key={index}
  //           transition={{ duration: 0.5, ease: 'easeInOut' }}
  //           animate={{ ...imageFormat }}
  //           initial={{ ...prevImageFormat }}
  //           src={'data:image/png;base64,' + item?.imageId}
  //           // alt="modal image"
  //           style={{
  //             position: 'absolute',
  //             left: 0,
  //           }}
  //         />
  //       ))}
  //     <p className={styles.headline_text} style={{ fontFamily: fontName }}>
  //       {title}
  //     </p>
  //   </div>
  // )
}

ImageFormatContainer.propTypes = {
  image: PropTypes.string,
  formatOptions: PropTypes.array,
  imageFormat: PropTypes.object,
  prevImageFormat: PropTypes.object,
  title: PropTypes.string,
  fontName: PropTypes.string,
}
