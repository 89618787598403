import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { useEffect, useRef, useState } from 'react'
import { Accept, Pen2, PencilSquare } from '../../../assets/svgs/icons'
import {
  BrandingService,
  BusinessService,
  EditorialLineService,
  LogosService,
  ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services'
import { isEmpty } from '../../../utils/verifyObject'
import Loading from '../../../components/Loading'
import Lottie from 'lottie-react'
import lazyLoadingAnimation from '../../../assets/lottie/lazy-loading-animation.json'
import { motion } from 'framer-motion'
import { PreviousPass } from '../../../components/PreviousPass'
import { useTranslation } from 'react-i18next'
import { FooterNaviBar } from '../../../components/FooterNaviBar'
import { notifyErrorCustom } from '../../../utils/notify'

const STEPS = {
  STEP_PASS_IMAGE: 'stepImage',
  STEP_PASS_PUBLICATION_DOWNLOAD: 'stepPublicationDownload',
}

function StepPublication({
  setStep,
  filesSelectedArray,
  headlineList,
  setHeadlineList,
  legendList,
  setLegendList,
  headlineText,
  setHeadlineText,
  setIaData,
  iaData,
  qtdLoadTemplates,
  setQtdLoadTemplates,
  qtdTemplatesLimit,
  headlineGpt,
  setHeadlineGpt,
  legend,
  subject,
  setLegend,
  setPostSelected,
  brandingArchetypeData,
  businessAccountData,
  font,
}) {
  const [expand, setExpand] = useState(false)

  const [loading, setLoading] = useState(false)
  const [responseBusinesData, setResponseBusinesData] = useState({})
  const [expandedIndex, setExpandedIndex] = useState(null)

  const [headlineGptLocal, setHeadlineGptLocal] = useState(null)

  const carouselRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [dragThreshold, setDragThreshold] = useState(5) // Threshold to differentiate between drag and click
  const [isClick, setIsClick] = useState(true) // Track if the action is click or drag
  const [legendTemp, setLegendTemp] = useState([])
  const [openEdit, setOpenEdit] = useState(true)
  const [tempImageLayers, setTempImageLayers] = useState({})

  const [mouse, setMouse] = useState(false)

  const { t } = useTranslation()

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true,
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true,
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true,
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: true,
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: true,
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: true,
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: true,
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: true,
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: true,
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false,
    },
  ]

  useEffect(() => {
    if (qtdLoadTemplates <= qtdTemplatesLimit) {
      fetchData()
    }
    //if (iaData.length === 0) {    }
    //loadProductServiceData()
    //loadtargetAudienceData()
    //legendGenerate()

    if (headlineGptLocal) {
      if (legendTemp.length === 0) {
        legendGenerate()
      }
    }
  }, [qtdLoadTemplates, headlineGptLocal])

  const handleExpand = (index, item) => {
    // Se o item clicado já estiver expandido, fecha ele
    // Caso contrário, expande o item clicado

    setExpandedIndex(expandedIndex === index ? null : index)

    setTempImageLayers(item)

    setMouse(true)
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      notifyErrorCustom('Escolha uma imagem para prosseguir', 'Erro!', 10000)
      // setStep(STEPS.STEP_PASS_PUBLICATION_DOWNLOAD)
    } else {
      setStep(STEPS.STEP_PASS_IMAGE)
    }
  }

  const fetchData = async () => {
    console.log('164 entrou aqui')
    const loadbusiness = await loadBusinessData()

    if (!headlineGptLocal) {
      console.log('168 entrou aqui')

      await getIaHeadline(loadbusiness)
    } else {
      console.log('170 entrou aqui')

      await getIaImages2(loadbusiness)
    }
    //await loadFontImages()
    //setLoading(false)
  }

  const numImage = 4
  const headlineListLocal = []
  const legendListLocal = []
  const targetAudienceRef = []
  const productRef = []

  const getIaHeadline = async (loadbusiness) => {
    setLoading(false)
    let businessData = loadbusiness

    // const totalMainImages =
    //   imageGaleryArray?.length +
    //   imageArray?.length +
    //   imageAIArray?.length +
    //   imageUploadArray?.length +
    //   imageProductsArray?.length
    // const numHeadline = numImage * totalMainImages

    if (targetAudienceRef.current) {
      if (targetAudienceRef.current.label == 'Nenhum') {
        targetAudienceRef.current = undefined
      }
    }
    if (productRef.current) {
      if (productRef.current.label == 'Nenhum') {
        productRef.current = undefined
      }
    }
    let getEditorialLine
    let editorialLine

    // if (clientData?.uuid) {
    //   getEditorialLine = await EditorialLineService.getEditorialLineByClient(
    //     clientData?.uuid
    //   )
    //   editorialLine = getEditorialLine.data[0].bodyJson
    // } else {
    getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
    editorialLine = getEditorialLine?.data[0]?.bodyJson
    //}

    let editorialBody

    editorialBody = {
      flow: 'unique_publication',
      prompt: 'headline',
      post_description: subject,
      brand: editorialLine?.dados_da_marca.nicho,
      funil: '',

      editorial_line: {
        primary: {
          titulo: editorialLine?.linha_conteudo_principal.titulo,
          descricao: editorialLine?.linha_conteudo_principal.descricao,
        },
        secondary: [
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[1].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[1].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[2].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[2].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[3].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[3].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[4].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[4].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
        ],
      },
      niche: editorialLine?.dados_da_marca.nicho,
      subniche: editorialLine?.dados_da_marca.subnicho,
      business_description: editorialLine?.dados_da_marca.descricao_negocio,
      target_public: {
        name: editorialLine?.persona.nome,
        gender: editorialLine?.persona.genero,
        social_class: 'Classes A, Classe B',
        profession: editorialLine?.persona.cargo,
        region: 'Nacional, com foco em grandes centros urbanos',
      },
      product: {
        name: productRef?.current?.name || '',
        type: productRef?.current?.productTypeValue || '',
        description: productRef?.current?.description || '',
        value: productRef?.current?.price || '',
        promotional_value: productRef?.current?.promotionalValue || '',
      },
      voice_tone: editorialLine?.tom_de_voz,
      voice_tone_description: editorialLine?.tipo_post,
      language:
        localStorage.i18nextLng == 'en' ? localStorage.i18nextLng : 'pt',
    }

    let retEditorialLine = await PythonService.postPromptGPT(editorialBody)
    // setTimeout(() => {
    //setHeadlineGpt(retEditorialLine)
    //   setHeadlineGptLocal(retEditorialLine)
    // }, 2000); // 2 segundos de delay
    //setHeadlineGpt(retEditorialLine)

    setHeadlineGptLocal(retEditorialLine)

    // let bodyHeadline = {
    //   publications_number: numHeadline,
    //   niche: businessData.businessInfos?.data[0].BusinessNiche?.name,
    //   subniche: businessData.businessInfos?.data[0].subNiche,
    //   publication_purpose: 'divulgar',
    //   description_business: businessData.businessInfos?.data[0].description,
    //   voice_tone: businessData.voiceToneInfos?.data[0].tone,

    //   social_class: targetAudienceRef.current
    //     ? targetAudienceRef.current.socialClassValue
    //     : '',

    //   gender: targetAudienceRef.current
    //     ? targetAudienceRef.current?.genderValue
    //     : '',

    //   profession: targetAudienceRef.current
    //     ? targetAudienceRef.current?.profession
    //     : '',

    //   name_product: productRef.current ? productRef.current?.name : '',

    //   product_type: productRef.current
    //     ? productRef.current?.productTypeValue
    //     : '',

    //   product_description: productRef.current
    //     ? productRef.current?.description
    //     : '',

    //   value: productRef.current ? productRef.current?.price : '',

    //   promotional_value: productRef.current
    //     ? productRef.current?.promotionalValue
    //     : '',
    // }

    // const headline = await PythonService.postHeadline(bodyHeadline)

    // await headline?.data?.data.forEach((element) => {
    //   textHeadline.push(element)
    //   headlineListLocal.push(element.titulo)
    //   legendListLocal.push(element.subtitulo)
    // })

    // await setHeadlineList(headlineListLocal)
    // await setLegendList(legendListLocal)

    // await setHeadlineText(headline?.data.data[0].titulo)
    setLoading(false)
  }

  const legendGenerate = async () => {
    // setLoading(true)

    let getEditorialLine
    let editorialLine

    // if (clientData?.uuid) {
    //   getEditorialLine = await EditorialLineService.getEditorialLineByClient(
    //     clientData?.uuid
    //   )
    //   editorialLine = getEditorialLine.data[0].bodyJson
    // } else {
    getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
    editorialLine = getEditorialLine.data[0].bodyJson
    // }

    const bodyLegend = {
      flow: 'unique_publication',
      prompt: 'legend',
      post_description: subject,
      brand: editorialLine.dados_da_marca.nicho,
      funil: '',
      niche: editorialLine.dados_da_marca.nicho,
      subniche: editorialLine.dados_da_marca.subnicho,
      business_description: editorialLine.dados_da_marca.descricao_negocio,
      target_public: {
        name: editorialLine.persona.nome,
        gender: editorialLine.persona.genero,
        social_class: 'Classes A, Classe B',
        profession: editorialLine.persona.cargo,
        region: 'Nacional, com foco em grandes centros urbanos',
      },
      voice_tone: editorialLine.tom_de_voz,
      voice_tone_description: editorialLine.tipo_post,
      product: {
        name: '',
        type: '',
        description: '',
        value: '',
        promotional_value: '',
      },
      headlines: headlineGptLocal?.data?.data?.headlines,
      language:
        localStorage.i18nextLng == 'en' ? localStorage.i18nextLng : 'pt',
    }

    let retEditorialLine = await PythonService.postPromptGPT(bodyLegend)

    setLegend(retEditorialLine?.data?.data)
    setLegendList(retEditorialLine?.data?.data)
    setLegendTemp(retEditorialLine?.data?.data)

    //setLoading(false)
  }

  const getIaImages2 = async (loadbusiness) => {
    const nincheData = {}
    let retEditorialLine = null
    let retEditorialLineMenu = null
    let getEditorialLine
    let editorialLine
    let editorialBodyId

    if (!isEmpty(nincheData)) {
      getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
      editorialLine = getEditorialLine?.data[0]?.bodyJson

      editorialBodyId = {
        flow: 'template_publication',
        prompt: 'headline',
        post_description: subject,
        brand: editorialLine?.dados_da_marca.nicho,
        funil: '',
        template_id:
          nincheData.templateId.replace('.json', '') ?? nincheData.templateId,
        niche: editorialLine?.dados_da_marca.nicho,
        subniche: editorialLine?.dados_da_marca.subnicho,
        product: {
          name: productRef.current?.name || '',
          type: productRef.current?.productTypeValue || '',
          description: productRef.current?.description || '',
          value: productRef.current?.price || '',
          promotional_value: productRef.current?.promotionalValue || '',
        },
        target_public: {
          name: targetAudienceRef.current.name || '',
          gender: targetAudienceRef.current.genderValue || '',
          social_class: targetAudienceRef.current.socialClassValue.join(', '),
          profession: targetAudienceRef.current.profession || '',
          region: 'Nacional, com foco em grandes centros urbanos',
        },
        voice_tone: editorialLine?.tom_de_voz,
      }
      retEditorialLineMenu = await PythonService.postPromptGPT(editorialBodyId)
    }

    let businessData = loadbusiness

    const imgBase64 = []
    const logoBase64 = []

    const findFont1 = font || ''
    const findFont2 = font || ''
    const findFont3 = font || ''

    const userLogo = await LogosService.getLogosUserBase64()

    if (userLogo?.length !== 0 && userLogo) {
      userLogo.data.forEach((logo) => {
        logoBase64.push({ base64: logo.fileBase64 })
      })
    }

    // const templates = [
    //   'ID14.json',
    //   'ID62.json',
    //   'ID43.json',
    //   'ID59.json',
    //   'ID33.json',
    //   'ID65.json',
    //   'ID45.json',
    // ]

    //const templates = [headlineGptLocal?.data?.data?.headlines[0]?.id + '.json']

    let bodyGenerateArt = {
      selected_templates: [
        nincheData?.templateId ??
          headlineGptLocal?.data?.data?.headlines[qtdLoadTemplates]?.id +
            '.json',
      ],

      publications_number: 1, //! Padrao
      template: false,
      art_width: 1080,
      art_height: 1350,
      color_palette_1: hexToRgb(
        businessData.brandingInfos?.data[0]?.primaryColor
      ),
      color_palette_2: hexToRgb(
        businessData.brandingInfos?.data[0]?.secondaryColor
      ),
      color_palette_3: hexToRgb(
        businessData.brandingInfos?.data[0]?.tertiaryColor
      ),
      color_palette_4: '(0,0,0)', //! Padrao
      color_palette_5: '(255,255,255)', //! Padrao
      // text: [[headlineListLocal[0]]],
      text: retEditorialLineMenu
        ? retEditorialLineMenu.data.data
        : headlineGptLocal?.data?.data?.headlines[qtdLoadTemplates],
      // categoria: [['titulo']],
      niche: businessData.businessInfos?.data[0].BusinessNiche?.name || '',
      subniche: businessData.businessInfos?.data[0].subNiche || '',
      escolaridade: targetAudienceRef.current?.educationLevelValue || '',
      publication_purpose: 'divulgar', //! Padrao
      description_business:
        businessData.businessInfos?.data[0].description || '',
      voice_tone: businessData.voiceToneInfos?.data[0]?.tone || '',
      social_class: targetAudienceRef.current
        ? targetAudienceRef.current.socialClassValue
        : '',
      gender: targetAudienceRef.current?.genderValue || '',
      profession: targetAudienceRef.current?.profession || '',
      name_product: productRef.current?.name || '',
      product_type: productRef.current?.productTypeValue || '',
      product_description: productRef.current?.description || '',
      value: productRef.current?.price || '',
      promotional_value: productRef.current?.promotionalValue || '',
      produto: productRef.current?.name || '',

      complexidade: brandingArchetypeData?.bpsSubtleOrExtravagant ?? 0,
      masculino: brandingArchetypeData?.bpsFemaleOrMale ?? 0,
      extravagante: brandingArchetypeData?.bpsSimpleOrComplex ?? 0,
      moderno: brandingArchetypeData?.bpsClassicOrModern ?? 0,
      distante: brandingArchetypeData?.bpsFriendlyOrDistant ?? 0,
      serio: brandingArchetypeData?.bpsPlayfulOrSerious ?? 0,
      industrial: brandingArchetypeData?.bpsNaturalOrIndustrial ?? 0,
      elitizado: brandingArchetypeData?.bpsPopularOrElite ?? 0,
      sofisticado: brandingArchetypeData?.bpsStrippedOrSophisticated ?? 0,
      refinado: brandingArchetypeData?.bpsRusticOrRefined ?? 0,

      macro_effects: false,

      num_imgs: [1],

      has_logo: logoBase64.length > 0 ? true : false,

      // fonte_1: 'montserrat',
      // fonte_2: 'roboto',
      // fonte_3: 'protestriot',

      fonte_1: findFont1 || 'alef',
      fonte_2: findFont2 || 'alef',
      fonte_3: findFont3 || 'alef',

      // background_img_base64: null,

      logo_base64: logoBase64,
    }

    let sequenceId = 1

    if (filesSelectedArray?.length !== 0 && filesSelectedArray) {
      const parts = filesSelectedArray[0]?.fileDataBase64?.split(',')

      const header = parts[0]
      const base64Data = parts[1]
      filesSelectedArray.forEach((image2) => {
        imgBase64.push({
          base64: base64Data,
          // imageCategory: image2.categoryId,
          imageCategory: 'Principal',
          id: sequenceId,
          fileFormat: image2.type,
        })
        sequenceId++
      })
    }

    if (nincheData && nincheData.VariantSelected) {
      bodyGenerateArt.specificTemplate = nincheData.VariantSelected
    }

    bodyGenerateArt = {
      ...bodyGenerateArt,
      img_base64: imgBase64,

      art_width: 1080,
      art_height: 1350,
    }
    const generateArtReturn = await PythonService.postGenerateArtTemplate(
      bodyGenerateArt
    )

    // if (generateArtReturn?.data?.status === 200) {
    // setIaData(generateArtReturn?.data?.data)

    // setItems(
    //   items.map((item, index) => {
    //     if (index === qtdLoadTemplates) {
    //       return {
    //         ...generateArtReturn?.data?.data[0],
    //       }
    //     }
    //     return item
    //   })
    // )
    // setQtdLoadTemplates(qtdLoadTemplates + 1)

    setIaData(
      iaData?.map((item, index) => {
        if (index === qtdLoadTemplates) {
          return {
            ...generateArtReturn?.data?.data[0],
          }
        }
        return item
      })
    )
    setQtdLoadTemplates(qtdLoadTemplates + 1)
  }

  function handleSaveImage(image, legendImage) {
    setPostSelected({
      base64: image,
      legend: legendImage,
    })

    setStep(STEPS.STEP_PASS_PUBLICATION_DOWNLOAD)
  }

  function capitalizeFirstLetter(string) {
    if (!string) return ''

    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const HandleInputItem = ({ param, index, indexImage }) => {
    const [value, setValue] = useState(param?.value)

    useEffect(() => {
      setValue(param?.value)
    }, [param])

    return (
      <div className={styles.inputContainer}>
        <div className={styles.labelContainer}>
          <label>
            {capitalizeFirstLetter(
              param.name === 'titulo'
                ? `${t('fast_publishing_post_label_title')}`
                : param.name === 'subtitulo'
                ? `${t('fast_publishing_post_label_subtitle')}`
                : param.name
            )}
          </label>
          {/* <PencilSquare size="31" color={'rgba(255, 139, 0, 1)'} /> */}
        </div>

        <div style={{ display: 'flex', gap: '10px' }}>
          <input
            type="text"
            name={param.name}
            value={value}
            className={
              openEdit ? styles.input_text_readOnly : styles.input_text
            }
            readOnly={openEdit}
            onChange={(e) => setValue(e.target.value)}
            onMouseDown={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                editTemplate(index, value, indexImage)
              }
            }}
          />

          {!openEdit && (
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={() => {
                if (isClick) {
                  editTemplate(index, value, indexImage)
                }
              }}
              style={{
                alignItems: 'center',
                display: 'flex',
                width: '40px',
                height: '40px',
                backgroundColor: '#0fbe0033',
                padding: '6px 12px',

                borderRadius: '500px',
                cursor: 'pointer',

                color: '#190027',

                fontFamily: 'poppins',
                fontWeight: '500',
                fontSize: 12,

                // border: '1px solid #6A0098',
              }}
            >
              <Accept color="#0FBE00" />
            </div>
          )}
        </div>
      </div>
    )
  }

  const editTemplate = async (index, newData, indexImage) => {
    setLoading(true)

    const newImageLayers = tempImageLayers?.params?.map((item, i) => {
      if (i === index) {
        return { ...item, value: newData }
      }

      return { ...item }
    })

    const newSelectedLayer = { ...tempImageLayers, params: newImageLayers }
    setTempImageLayers(newSelectedLayer)

    editTemplateServiceExecute(newSelectedLayer, indexImage)

    //return
    //setLoading(false)
  }

  const editTemplateServiceExecute = async (newSelectedLayer, indexImage) => {
    setLoading(true)

    const returnNewTemplate = await PythonService.postGenerateArtEditTemplate([
      newSelectedLayer,
    ])

    // setImageUrl(
    //   'data:image/png;base64,' +
    //     returnNewTemplate?.data?.data[0]?.srcComTratativa
    // )

    // const newIaData = iaData

    const updatedData = iaData.map((item, i) => {
      if (i === indexImage) {
        // Retorna o item com o campo "texto" atualizado
        return {
          ...item,
          srcComTratativa: returnNewTemplate?.data?.data[0]?.srcComTratativa,
        }
      }
      // Retorna o item original se não for o índice correto
      return item
    })

    setIaData(updatedData)

    setLoading(false)
  }

  function hexToRgb(hex) {
    // Remove o símbolo '#' do início, se presente
    hex = hex.replace(/^#/, '')

    // Verifica se o valor hexadecimal é válido
    if (/^[0-9A-Fa-f]{6}$|^[0-9A-Fa-f]{3}$/.test(hex)) {
      // Se o valor é uma representação de 3 dígitos, expanda para 6 dígitos
      if (hex.length === 3) {
        hex = hex
          .split('')
          .map(function (char) {
            return char + char
          })
          .join('')
      }

      // Divide o valor em pares de dígitos (R, G, B)
      const r = parseInt(hex.slice(0, 2), 16)
      const g = parseInt(hex.slice(2, 4), 16)
      const b = parseInt(hex.slice(4, 6), 16)

      return `(${r},${g},${b})`
    } else {
      // Retorna uma mensagem de erro para valores inválidos
      return `(${0},${0},${0})`
    }
  }

  const loadBusinessData = async () => {
    const productInfos = await ProductService.getProduct()
    // const brandingInfos = await BrandingService.getBrandingByBusinessId(businessAccountData.data.uuid)
    const brandingInfos = await BrandingService.getAllBrandingByBusiness()

    const voiceToneInfos = await VoiceToneService.getAllVoiceTone()
    const businessInfos = await BusinessService.getBusinessByLogin()
    const targetAudiencesInfos =
      await TargetAudienceService.getAllTargetAudience()

    const obj = {
      productInfos: productInfos,
      brandingInfos: brandingInfos,
      voiceToneInfos: voiceToneInfos,
      businessInfos: businessInfos,
      targetAudiencesInfos: targetAudiencesInfos,
    }

    setResponseBusinesData(obj)

    return obj
  }

  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.clientX - carouselRef.current.offsetLeft)
    setScrollLeft(carouselRef.current.scrollLeft)
    carouselRef.current.style.scrollBehavior = 'unset'
    setIsClick(true) // Assume it's a click initially
  }

  const handleMouseMove = (event) => {
    if (!isDragging) return
    const x = event.clientX - carouselRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0
    carouselRef.current.scrollLeft = scrollLeft - scrollOffset

    if (Math.abs(event.clientX - startX) > dragThreshold) {
      setIsClick(false)
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    carouselRef.current.style.scrollBehavior = 'smooth'
  }

  const legendUpdate = (index) => {
    setOpenEdit((current) => !current)
  }

  const handleLegendChange = (value, index) => {
    setLegendList((prevLegendList) => {
      const newLegendList = [...prevLegendList]
      newLegendList[index] = value
      return newLegendList
    })
  }

  return (
    <>
      <Loading enable={loading} />
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          {/* <PreviousPass steps={stepsArray}/> */}
          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>{t('fast_publishing_post_text')}</p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>
              {t('fast_publishing_post')}
            </h1>

            <div className={styles.box_atention}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.40123 3.0034C10.5557 1.00229 13.4439 1.00229 14.5983 3.0034L21.9527 15.7509C23.1065 17.7509 21.6631 20.2501 19.3541 20.2501H4.64546C2.33649 20.2501 0.893061 17.7509 2.04691 15.7509L9.40123 3.0034ZM12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V12.75C12.75 13.1642 12.4142 13.5 12 13.5C11.5858 13.5 11.25 13.1642 11.25 12.75V9C11.25 8.58579 11.5858 8.25 12 8.25ZM12 16.5C12.4142 16.5 12.75 16.1642 12.75 15.75C12.75 15.3358 12.4142 15 12 15C11.5858 15 11.25 15.3358 11.25 15.75C11.25 16.1642 11.5858 16.5 12 16.5Z"
                  fill="#ffc800"
                />
              </svg>
              <p>
                <label>
                  {' '}
                  Atenção! O Tempo médio para geração de cada arte pode variar
                  de <strong> 20 segundos </strong> a <strong>2 minutos</strong>
                  .
                </label>
                <br></br>
                <label>{t('fast_publishing_art_warning')}</label>
              </p>
            </div>
            <div
              className={styles.container_publication}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              // onMouseLeave={handleMouseUp}
              draggable="false"
            >
              <div className={styles.carousel_publication} ref={carouselRef}>
                {iaData?.map((item, indexImage) => (
                  <div key={indexImage} className={styles.box_image}>
                    {item?.srcComTratativa && (
                      <div className={styles.publication}>
                        <div
                          className={styles.box_publication}
                          onClick={() => handleExpand(indexImage, item)} // Passa o índice para o handleExpand
                        >
                          <img
                            className={styles.image_publication}
                            src={
                              'data:image/png;base64,' + item?.srcComTratativa
                            }
                            alt="teste"
                            draggable="false"
                            onMouseDown={(e) => e.stopPropagation()} // Evita que o arraste seja acionado
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </div>
                        {expandedIndex === indexImage && ( // Verifica se o item atual está expandido
                          <div className={styles.info_publication}>
                            <div className={styles.container_box_module}>
                              <div className={styles.box_module}>
                                <p className={styles.description_module}>
                                  {headlineList[0]}
                                </p>
                                <span className={styles.headline}>
                                  {item &&
                                    item?.params &&
                                    item?.params.map((param, index) => {
                                      if (param.type !== 'texto') return

                                      return (
                                        <HandleInputItem
                                          key={index}
                                          param={param}
                                          index={index}
                                          indexImage={indexImage}
                                        />
                                      )
                                    })}
                                </span>
                              </div>
                              <div className={styles.box_description_module}>
                                <textarea
                                  //className={styles.description_module}
                                  className={
                                    openEdit
                                      ? styles.input_legend_readOnly
                                      : styles.input_legend
                                  }
                                  readOnly={openEdit}
                                  onChange={(e) =>
                                    handleLegendChange(
                                      e.target.value,
                                      indexImage
                                    )
                                  }
                                >
                                  {legend[indexImage]}
                                </textarea>

                                <div className={styles.box_actions}>
                                  <div
                                    className={styles.action}
                                    onClick={() => legendUpdate(indexImage)}
                                  >
                                    <Pen2 color="#B900FE" />
                                  </div>
                                  <button
                                    className={styles.action_save}
                                    onClick={() =>
                                      handleSaveImage(
                                        item?.srcComTratativa,
                                        legendList[indexImage]
                                      )
                                    }
                                  >
                                    {t('fast_publishing_post_button_select')}
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* <div className={styles.box_actions}>
                              <div
                                className={styles.action}
                                onClick={() => legendUpdate(indexImage)}
                              >
                                <Pen2 color="#B900FE" />
                              </div>
                              <button
                                className={styles.action_save}
                                onClick={() =>
                                  handleSaveImage(
                                    item?.srcComTratativa,
                                    legendList[indexImage]
                                  )
                                }
                              >
                                {t('fast_publishing_post_button_select')}
                              </button>
                            </div> */}
                          </div>
                        )}
                      </div>
                    )}
                    {!item?.srcComTratativa && (
                      <div className={styles.box_example_image}>
                        <Lottie
                          animationData={lazyLoadingAnimation}
                          style={{ width: '100%', height: 100 }}
                          loop={true}
                          autoplay={true}
                        />
                      </div>
                    )}
                  </div>
                ))}
                {/* {iaData?.map((item, index) => (
              <>
              {item?.srcComTratativa && (
                <div
                className={styles.box_example_image}
                key={index}
                onClick={() => {
                  handleOpenModal(
                    'data:image/png;base64,' + item?.srcComTratativa,
                    item,
                    item
                    )
                    }}
                    >
                    <img
                    src={'data:image/png;base64,' + item?.srcComTratativa}
                    alt=""
                    className={styles.example_image}
                    />
                    </div>
                    )}
                    
                    
                    </>
                    ))} */}
              </div>
            </div>
          </div>
          {/* <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              {t('back_buttom')}
            </button>
            
            <button
              className={styles.next_button}
              onClick={() => handleNavigate('next')}
            >
              Avançar
            </button>
              
             
          </div> */}
          <FooterNaviBar
            back={() => handleNavigate('back')}
            next={() => handleNavigate('next')}
          />
        </div>
      </motion.div>
    </>
  )
}

export default StepPublication
