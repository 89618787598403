import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'
import {
  Clips,
  Folder,
  NavigateArrowRigth,
  NumberOne,
  NumberTwo,
  Picture,
  Stars,
  SupermarketCart,
  Trash,
} from '../../../assets/svgs/icons'
import { useState } from 'react'
import ModalComponent from '../../../components/ModalComponent'
import ModalArtiificialInteligency from './ModalArtificalInteligency'
import ModalUploads from './ModalUploads'
import Proptypes from 'prop-types'
import BackgroundRemoverModal from '../../../components/BackgroundRemoverModal'
import { PythonService } from '../../../services'
import ModalProducts from './ModalProducts'

const STEPS = {
  STEP_FIRST_PASS_HEADLINES: 'stepHeadlines',
  STEP_IMAGE_BANK: 'stepImageBank',
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
}

function StepSecondPass({
  setStep,
  imageArray,
  setImageArray,
  imageGaleryArray,
  setImageGaleryArray,
  imageAIArray,
  setImageAIArray,
  imageUploadArray,
  setImageUploadArray,
  imageProductsArray,
  setImageProductsArray,
}) {
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalAIOpen, setModalAIOpen] = useState(false)
  const [isModalUploadsOpen, setModalUploadsOpen] = useState(false)
  const [isModalProductsOpen, setModalProductsOpen] = useState(false)

  const handleOpenModal = (modal) => {
    if (modal === 'galeria') {
      setModalOpen(true)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(true)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(true)
    }
    if (modal === 'produtos') {
      setModalProductsOpen(true)
    }
  }

  const handleCloseModal = (modal) => {
    if (modal === 'galeria') {
      setModalOpen(false)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(false)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(false)
    }
    if (modal === 'produtos') {
      setModalProductsOpen(false)
    }
  }

  const nextButton = async (navigation) => {
    if (navigation === 'back') {
      setStep(STEPS.STEP_FIRST_PASS_HEADLINES)
    }
    if (navigation === 'next') {
      setStep(STEPS.STEP_THIRD_PASS_PUBLICATION)
    }
    if (navigation === 'imageBank') {
      setStep(STEPS.STEP_IMAGE_BANK)
    }
  }

  const handleRemoveFile = (index) => {
    var indice = imageGaleryArray.indexOf(index)
    imageGaleryArray.splice(indice, 1)

    setImageGaleryArray((current) => [...current])
  }

  const handleRemoveImages = (index) => {
    var indice = imageArray.indexOf(index)
    imageArray.splice(indice, 1)

    setImageArray((current) => [...current])
  }

  const handleRemoveUploadImage = (index) => {
    var indice = imageUploadArray.indexOf(index)
    imageUploadArray.splice(indice, 1)

    setImageUploadArray((current) => [...current])
  }

  const handleRemoveAIImage = (index) => {
    var indice = imageAIArray.indexOf(index)
    imageAIArray.splice(indice, 1)

    setImageAIArray((current) => [...current])
  }

  const handleRemoveBackground = async (image, typeImage) => {
    let noBgImage = []

    if (typeImage === 'imageProduct') {
      const { data } = await PythonService.postRemoveBackground({
        image: image[0].fileDataBase64,
      })
      noBgImage = data.data.images
    } else {
      const { data } = await PythonService.postRemoveBackground({
        image: image[0].image,
      })

      noBgImage = data.data.images
    }

    switch (typeImage) {
      case 'imageAi':
        imageAIArray[0].image = noBgImage[1]?.image
        imageAIArray[0].isOriginal = noBgImage[1]?.isOriginal
        setImageAIArray([...imageAIArray])
        break
      case 'imageUpload':
        imageUploadArray[0].image = noBgImage[1]?.image
        imageUploadArray[0].isOriginal = noBgImage[1]?.isOriginal
        setImageUploadArray([...imageUploadArray])
        break
      case 'imageProduct':
        imageProductsArray[0].fileDataBase64 = noBgImage[1]?.image
        imageProductsArray[0].isOriginal = noBgImage[1]?.isOriginal
        setImageProductsArray([...imageProductsArray])
        break
      default:
        break
    }
  }

  return (
    <div className={styles.container_header}>
      <div className={styles.title_new_posts}>
        <p className={styles.title_new_posts_label}>1° Passo</p>
        <p className={styles.title_new_posts_title}>Headlines</p>
      </div>
      <div className={styles.title_steps}>
        <div className={styles.first_pass}>
          <div>
            <NumberOne width={'40px'} height={'40px'} />
          </div>
          <div className={styles.first_pass_title}>
            <p className={styles.first_pass_title_text}>1° Passo</p>
            <p className={styles.first_pass_subtitle}>Configurações</p>
          </div>
        </div>

        <div className={styles.second_pass}>
          <div className={styles.second_pass_box_number}>
            <NavigateArrowRigth color={'#EEEEEE'} />
            <NumberTwo width={'40px'} height={'40px'} />
          </div>
          <div>
            <p className={styles.first_pass_title_text}>2° Passo</p>
            <p className={styles.first_pass_subtitle}>Imagem</p>
          </div>
        </div>

        <div className={styles.next_pass}>
          <NavigateArrowRigth />
        </div>
      </div>
      <div className={styles.container_imagem}>
        <div
          className={
            imageArray.length +
              imageAIArray.length +
              imageGaleryArray.length +
              imageUploadArray.length !==
            0
              ? styles.box_imagem_short
              : styles.box_imagem
          }
        >
          <div className={styles.container_content_imagem}>
            <p className={styles.container_content_imagem_title}>
              Escolha as imagens para gerar sua{' '}
              <span className={styles.content_imagem_title_bold}>
                publicação{' '}
              </span>
              ou{' '}
              <span className={styles.content_imagem_title_bold}>
                carrossel...
              </span>
            </p>
          </div>

          <div className={styles.container_box_image}>
            <ModalComponent
              isOpen={isModalOpen}
              onClose={() => handleCloseModal('galeria')}
              className={styles.teste}
            >
              <BackgroundRemoverModal
                onCloseModal={setImageGaleryArray}
                closeModal={handleCloseModal}
                imageArray={imageGaleryArray}
              />
            </ModalComponent>

            <ModalComponent
              isOpen={isModalAIOpen}
              onClose={() => handleCloseModal('inteligenciaArtificial')}
              className={styles.teste}
            >
              <ModalArtiificialInteligency
                setImageAIArray={setImageAIArray}
                closeModal={handleCloseModal}
              />
            </ModalComponent>

            <ModalComponent
              isOpen={isModalUploadsOpen}
              onClose={() => handleCloseModal('uploads')}
              className={styles.teste}
            >
              <ModalUploads
                closeModal={handleCloseModal}
                setImageUploadArray={setImageUploadArray}
              />
            </ModalComponent>
            <ModalComponent
              isOpen={isModalProductsOpen}
              onClose={() => handleCloseModal('produtos')}
              className={styles.teste}
            >
              <ModalProducts
                closeModal={handleCloseModal}
                setImageProductsArray={setImageProductsArray}
              />
            </ModalComponent>
            <div
              className={styles.box_image}
              onClick={() => handleOpenModal('galeria')}
            >
              <div className={styles.box_image_svg}>
                <Clips width={30} height={30} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>Galeria</p>
              </div>
            </div>

            <div
              className={styles.box_image}
              onClick={() => {
                nextButton('imageBank')
              }}
            >
              <div className={styles.box_image_svg}>
                <Picture width={30} height={30} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>Banco de imagens</p>
              </div>
            </div>

            <div
              className={styles.box_image}
              onClick={() => handleOpenModal('inteligenciaArtificial')}
            >
              <div className={styles.box_image_svg}>
                <Stars width={30} height={30} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>
                  Inteligência Artificial
                </p>
              </div>
            </div>

            {/* <div
              className={styles.box_image}
              onClick={() => handleOpenModal('uploads')}
            >
              <div className={styles.box_image_svg}>
                <Folder width={30} height={30} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>Meus Uploads</p>
              </div>
            </div> */}

            <div
              className={styles.box_image}
              onClick={() => handleOpenModal('produtos')}
            >
              <div className={styles.box_image_svg}>
                <SupermarketCart width={30} height={30} />
              </div>
              <div className={styles.box_image_text}>
                <p className={styles.box_image_title}>Produtos</p>
              </div>
            </div>
          </div>

          <div className={styles.container_itens_selected}>
            {imageArray.length !== 0 &&
              imageArray.map((image) => (
                <div key={image.id} className={styles.item_selected}>
                  <div className={styles.box_item}>
                    <div className={styles.box_item_image}>
                      <img
                        className={styles.image}
                        src={image.url}
                        alt=""
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className={styles.box_item_title}>
                      <p className={styles.item_description_title}>
                        {image.title}
                      </p>
                      <p className={styles.item_description_size}>
                        Tamanho: <b>{image.size}</b>
                      </p>
                    </div>
                  </div>
                  <div className={styles.box_actions}>
                    <div className={styles.button_galery}>
                      <Picture color={'#660098'} width={12} height={12} />
                      <p className={styles.button_galery_title}>
                        Banco de imagens
                      </p>
                    </div>
                    <button
                      className={styles.button_delete}
                      onClick={() => handleRemoveImages(image.id)}
                    >
                      Excluir
                      <Trash width={'15px'} height={'15px'} />
                    </button>
                    <button className={styles.button_remove_background}>
                      Remover fundo <Stars width={'15px'} height={'15px'} />
                    </button>
                  </div>
                </div>
              ))}

            {imageGaleryArray.length !== 0 &&
              imageGaleryArray.map((imageGalery) => (
                <div key={imageGalery.id} className={styles.item_selected}>
                  <div className={styles.box_item}>
                    <div className={styles.box_item_image}>
                      <img
                        className={styles.image}
                        src={imageGalery.fileDataBase64}
                        alt=""
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className={styles.box_item_title}>
                      <p className={styles.item_description_title}>
                        {imageGalery.name}
                      </p>
                      <p className={styles.item_description_size}>
                        Tamanho: <b>{imageGalery.size}</b>
                      </p>
                    </div>
                  </div>
                  <div className={styles.box_actions}>
                    <div className={styles.button_galery}>
                      <Clips color={'#660098'} width={12} height={12} />
                      <p className={styles.button_galery_title}>Galeria</p>
                    </div>
                    <button
                      className={styles.button_delete}
                      onClick={() => handleRemoveFile(imageGalery.id)}
                    >
                      Excluir <Trash width={'15px'} height={'15px'} />
                    </button>
                    <button
                      disabled={imageGalery.isBackgroundRemoved}
                      style={{
                        opacity: !imageGalery.isBackgroundRemoved ? 1 : 0.5,
                        cursor: !imageGalery.isBackgroundRemoved
                          ? 'pointer'
                          : 'default',
                      }}
                      onClick={() => handleOpenModal('galeria')}
                      className={styles.button_remove_background}
                    >
                      Remover fundo <Stars width={'15px'} height={'15px'} />
                    </button>
                  </div>
                </div>
              ))}

            {imageAIArray.length !== 0 &&
              imageAIArray.map((image) => (
                <div key={image.id} className={styles.item_selected}>
                  <div className={styles.box_item}>
                    <div className={styles.box_item_image}>
                      <img
                        className={styles.image}
                        src={image.image}
                        alt=""
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>

                    <div className={styles.box_item_title}>
                      <p className={styles.item_description_title}></p>
                      <p className={styles.item_description_size}></p>
                    </div>
                  </div>
                  <div className={styles.box_actions}>
                    <div className={styles.button_galery}>
                      <Stars color={'#660098'} width={'12px'} height={'12px'} />
                      <p className={styles.button_galery_title}>
                        Inteligência Artificial
                      </p>
                    </div>
                    <button
                      className={styles.button_delete}
                      onClick={() => handleRemoveAIImage(imageAIArray.id)}
                    >
                      Excluir <Trash width={'15px'} height={'15px'} />
                    </button>

                    <button
                      className={styles.button_remove_background}
                      onClick={() =>
                        handleRemoveBackground(imageAIArray, 'imageAi')
                      }
                    >
                      Remover fundo{' '}
                      <Stars color={'#fff'} width={'15px'} height={'15px'} />
                    </button>
                  </div>
                </div>
              ))}

            {imageUploadArray.length !== 0 &&
              imageUploadArray.map((image) => (
                <div key={image.id} className={styles.item_selected}>
                  <div className={styles.box_item}>
                    <div className={styles.box_item_image}>
                      <img
                        className={styles.image}
                        src={image.image}
                        alt=""
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                    {/* <div className={styles.box_item_title}>
                      <p className={styles.item_description_title}>
                        {image.title}
                      </p>
                      <p className={styles.item_description_size}>
                        Tamanho: <b>{image.size}</b>
                      </p>
                    </div> */}
                  </div>
                  <div className={styles.box_actions}>
                    <div className={styles.button_galery}>
                      <Folder
                        color={'#660098'}
                        width={'12px'}
                        height={'12px'}
                      />
                      <p className={styles.button_galery_title}>Uploads</p>
                    </div>
                    <button
                      className={styles.button_delete}
                      onClick={() => handleRemoveUploadImage(image.id)}
                    >
                      Excluir <Trash width={'15px'} height={'15px'} />
                    </button>

                    <button
                      className={styles.button_remove_background}
                      onClick={() =>
                        handleRemoveBackground(imageUploadArray, 'imageUpload')
                      }
                    >
                      Remover fundo{' '}
                      <Stars color={'#fff'} width={'15px'} height={'15px'} />
                    </button>
                    {/* <button className={styles.button_remove_background}>
                      Remover fundo <Stars width={'15px'} height={'15px'} />
                    </button> */}
                  </div>
                </div>
              ))}

            {imageProductsArray.length !== 0 &&
              imageProductsArray.map((image) => (
                <div key={image.id} className={styles.item_selected}>
                  <div className={styles.box_item}>
                    <div className={styles.box_item_image}>
                      <img
                        className={styles.image}
                        src={image.fileDataBase64}
                        alt=""
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className={styles.box_item_title}>
                      <p className={styles.item_description_title}>
                        {image.name}
                      </p>
                      <p className={styles.item_description_size}>
                        Tamanho: <b>{image.size}</b>
                      </p>
                    </div>
                  </div>
                  <div className={styles.box_actions}>
                    <div className={styles.button_galery}>
                      <SupermarketCart
                        color={'#660098'}
                        width={'12px'}
                        height={'12px'}
                      />
                      <p className={styles.button_galery_title}>Produtos</p>
                    </div>
                    <button
                      className={styles.button_delete}
                      onClick={() => handleRemoveUploadImage(image.id)}
                    >
                      Excluir <Trash width={'15px'} height={'15px'} />
                    </button>

                    <button
                      className={styles.button_remove_background}
                      onClick={() =>
                        handleRemoveBackground(
                          imageProductsArray,
                          'imageProduct'
                        )
                      }
                    >
                      Remover fundo{' '}
                      <Stars color={'#fff'} width={'15px'} height={'15px'} />
                    </button>
                    {/* <button className={styles.button_remove_background}>
                      Remover fundo <Stars width={'15px'} height={'15px'} />
                    </button> */}
                  </div>
                </div>
              ))}
          </div>

          {
            <div className={styles.button_nav}>
              <button
                className={styles.button_back}
                onClick={() => {
                  nextButton('back')
                }}
              >
                <p className={styles.button_title}>Voltar</p>
              </button>

              <button
                className={styles.button_next}
                onClick={() => {
                  nextButton('next')
                }}
              >
                <p className={styles.button_title}>Prosseguir</p>
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default StepSecondPass

StepSecondPass.propTypes = {
  setStep: Proptypes.func,
  imageArray: Proptypes.array,
  setImageArray: Proptypes.array,
  imageGaleryArray: Proptypes.array,
  setImageGaleryArray: Proptypes.array,
  imageAIArray: Proptypes.array,
  setImageAIArray: Proptypes.func,
  imageUploadArray: Proptypes.array,
  setImageUploadArray: Proptypes.func,
  setImageProductsArray: Proptypes.func,
}
