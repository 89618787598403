/* eslint-disable react/prop-types */
import { useState } from 'react'
import styles from './styles.module.css'
// import { CircleX, Cross, Interrogation } from '../../../../assets/svgs/icons'
import Masonry from 'react-responsive-masonry'

import { CircleX, Cross, Interrogation } from '../../../assets/svgs/icons'
import {
  BrandingService,
  BusinessClient,
  BusinessService,
  BusinessUser,
  ProductService,
  PublicationService,
  PythonNodeService,
  PythonService,
  SocialMediaConnectionService,
  TargetAudienceService,
  UserService,
  VoiceToneService,
} from '../../../services'
import Loading from '../../../components/Loading'
import { notifyErrorCustom } from '../../../utils/notify'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
const ModalDowngrade = ({
  onClose,
  handleCloseModal,
  isOpen,
  setDowngradeNow,
  tier,
}) => {
  if (!isOpen) return null

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  // const images = [
  //   {
  //     id: 1,
  //     url: 'https://i.pinimg.com/564x/97/32/aa/9732aaf44ab60074a9c5a07df77ffa1c.jpg',
  //     title: 'Comida saudavel',
  //     size: '32mb',
  //     origin: 'imgBank',
  //   },
  // ]

  const handleDowngrade = async () => {
    setDowngradeNow(true)
    handleCloseModal(false)
  }

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal}>
        <Loading enable={loading} />

        <div className={styles.header_close}>
          <div className={styles.close_modal} onClick={onClose}>
            <Cross width={'20px'} height={'20px'} />
          </div>
        </div>

        <div className={styles.modal_title}>
          <h2>Downgrade</h2>
        </div>

        <div className={styles.modal_sub_title}>
          {tier === 0 ? (
            <p className={styles.modal_sub_title_text}>
              {t('plansPage.areYouSureYouWantToChangeYourPlanFree')}
            </p>
          ) : (
            <p className={styles.modal_sub_title_text}>
              {t('plansPage.areYouSureYouWantToChangeYourPlan')}
            </p>
          )}
        </div>

        <div className={styles.modal_container_drag}>
          <button className={styles.button_back} onClick={onClose}>
            {t('modal_downgrade.back')}
          </button>

          <button
            className={styles.button_send}
            onClick={() => {
              setLoading(true)
              handleDowngrade()
            }}
          >
            {t('modal_downgrade.continue')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalDowngrade
